import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MAINTENANCE_TASK_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import MaintenanceTaskToolbar from './MaintenanceTaskToolbar';
import MaintenanceTaskBasicInformationResource from './resource/MaintenanceTaskBasicInformationResource';
import {
  clearMaintenanceTask,
  getMaintenanceTask,
} from '../../../../actions/mechanical-maintenance/MaintenanceTask';


export const MaintenanceTask = ({
  breadcrumbs,
  dispatchClearMaintenanceTask,
  dispatchGetMaintenanceTask,
  match: {
    params: { id: maintenanceTaskId },
  },
  loading,
  maintenanceTask,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceTask({ maintenanceTaskId });

    return () => dispatchClearMaintenanceTask();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (maintenanceTask.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_TASK_PATH} />;
  else {
    const maintenanceTaskJson = maintenanceTask.toJS();


    content = (
      <>
        <h3>Información Básica</h3>
        <MaintenanceTaskBasicInformationResource {...maintenanceTaskJson} />
      </>
    );

    toolbar = (
      <MaintenanceTaskToolbar maintenanceTaskId={maintenanceTaskJson.id} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Tarea"
      content={content}
    />
  );
};

MaintenanceTask.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaintenanceTask: PropTypes.func.isRequired,
  dispatchGetMaintenanceTask: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  maintenanceTask: PropTypes.instanceOf(Immutable.Map).isRequired,
};

MaintenanceTask.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaintenanceTask: clearMaintenanceTask,
  dispatchGetMaintenanceTask: getMaintenanceTask,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tareas',
      href: MAINTENANCE_TASK_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenanceTask: MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTask);
