import {
  string,
  shape,
  number,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';

const registeredBusPropTypes = {
  id: number.isRequired,
  companyBusId: string.isRequired,
  licensePlate: string.isRequired,
};

const MaintenanceBusGroupBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  registeredBusList: arrayOf(shape(registeredBusPropTypes)).isRequired,
  createDate: oneOfType([number, instanceOf(Date)]).isRequired,
  lastUpdate: oneOfType([number, instanceOf(Date)]).isRequired,
};

const maintenancePlanningDefaultProps = {
  name: '',
  registeredBusList: [],
};

export {
  MaintenanceBusGroupBasicInformationPropTypes,
  maintenancePlanningDefaultProps,
};
