import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { SERVICE_MERGE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearServiceMerge, getServiceMerge } from '../../../../actions';
import ServiceMergeBasicInformationResource from './resource/ServiceMergeBasicInformationResource';
import ServiceMergeToolbar from './ServiceMergeToolbar';

export const ServiceMerge = ({
  breadcrumbs,
  dispatchGetServiceMerge,
  dispatchClearServiceMerge,
  match: {
    params: { id: serviceMergeId },
  },
  loading,
  serviceMerge,
}) => {
  useLayoutEffect(() => {
    dispatchGetServiceMerge({ serviceMergeId });

    return () => dispatchClearServiceMerge();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (serviceMerge.isEmpty())
    content = <NoDataResource returnPage={SERVICE_MERGE_PATH} />;
  else {
    const serviceMergeJson = serviceMerge.toJS();

    content = <ServiceMergeBasicInformationResource {...serviceMergeJson} />;

    toolbar = <ServiceMergeToolbar serviceMergeId={serviceMergeJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Fusión de servicio"
      content={content}
    />
  );
};

ServiceMerge.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearServiceMerge: PropTypes.func.isRequired,
  dispatchGetServiceMerge: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  serviceMerge: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ServiceMerge.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearServiceMerge: clearServiceMerge,
  dispatchGetServiceMerge: getServiceMerge,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de fusiones de servicios',
      href: SERVICE_MERGE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  serviceMerge: ItineraryUnit.ServiceMerge.getIn(['current', 'content']),
  loading: !ItineraryUnit.ServiceMerge.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMerge);
