import { combineReducers } from 'redux';
import SeatMap from './SeatMap';
import RegisteredBus from './RegisteredBus';
import ServiceType from './ServiceType';
import UnitHome from './UnitHome';
import BusBodyWork from './BusBodyWork';
import BusChassis from './BusChassis';
import DocumentType from './DocumentType';

export default combineReducers({
  SeatMap,
  RegisteredBus,
  UnitHome,
  ServiceType,
  BusBodyWork,
  BusChassis,
  DocumentType,
});
