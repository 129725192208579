import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearMaintenanceBusGroups,
  getMaintenanceBusGroups,
} from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';

export const MaintenanceBusGroupSelect = ({
  loading,
  maintenanceBusGroupOptions,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchGetMaintenanceBusGroups,
  dispatchClearMaintenanceBusGroups,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceBusGroups(), []);

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    if (inputValueLength > 4) {
      const query = [];

      if (inputValueLength && inputValueLength >= 4)
        query.push(`name:${inputValue}`);

      dispatchGetMaintenanceBusGroups({
        ...DEFAULT_QUERY_GET_ALL,
        query,
      });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={maintenanceBusGroupOptions}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

MaintenanceBusGroupSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMaintenanceBusGroups: PropTypes.func.isRequired,
  dispatchClearMaintenanceBusGroups: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  maintenanceBusGroupOptions: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

MaintenanceBusGroupSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  maintenanceBusGroupOptions: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por nombre de grupo',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => {
  const maintenanceBusGroupOptions =
    MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
      'all',
      'content',
      'content',
    ]).map((maintenanceBusGroup) => ({
      value: maintenanceBusGroup.id,
      label: maintenanceBusGroup.name,
    }));

  return {
    maintenanceBusGroupOptions,
    loading: MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
      'all',
      'loading',
    ]),
  };
};
const mapDispatchToProps = {
  dispatchGetMaintenanceBusGroups: getMaintenanceBusGroups,
  dispatchClearMaintenanceBusGroups: clearMaintenanceBusGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceBusGroupSelect);
