import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY } from '../../../../config/queries';
import {
  clearBusChassis,
  getBusesChassis,
} from '../../../../actions/bus/BusChassis';

export const BusChassisSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearBusesChassis,
  dispatchGetBusesChassis,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearBusesChassis(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetBusesChassis({
        ...DEFAULT_QUERY,
        query: inputValue,
      });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={handleInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

BusChassisSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetBusesChassis: PropTypes.func.isRequired,
  dispatchClearBusesChassis: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

BusChassisSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca así: código,nombre',
};

const mapStateToProps = ({ BusUnit }) => ({
  options: BusUnit.BusChassis.getIn(['all', 'content', 'content']).map(
    ({ id, make, model, year }) => ({
      value: id,
      label: `${make} | ${model} | ${year}`,
    }),
  ),
  loading: BusUnit.BusChassis.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetBusesChassis: getBusesChassis,
  dispatchClearBusesChassis: clearBusChassis,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusChassisSelect);
