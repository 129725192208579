import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MAINTENANCE_BUS_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearMaintenanceBusGroup,
  getMaintenanceBusGroup,
} from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';
import MaintenanceBusGroupBasicInformationResource from './resource/MaintenanceBusGroupBasicInformationResource';
import MaintenanceBusGroupToolbar from './MaintenanceBusGroupToolbar';

export const MaintenanceBusGroup = ({
  breadcrumbs,
  dispatchClearMaintenanceBusGroup,
  dispatchGetMaintenanceBusGroup,
  match: {
    params: { id: maintenanceBusGroupId },
  },
  loading,
  maintenanceBusGroup,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceBusGroup({ maintenanceBusGroupId });

    return () => dispatchClearMaintenanceBusGroup();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (maintenanceBusGroup.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_BUS_GROUP_PATH} />;
  else {
    const maintenanceBusGroupJson = maintenanceBusGroup.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <MaintenanceBusGroupBasicInformationResource
          {...maintenanceBusGroupJson}
        />
      </>
    );

    toolbar = (
      <MaintenanceBusGroupToolbar
        maintenanceBusGroupId={maintenanceBusGroupJson.id}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Grupo de Buses para Mantenimiento"
      content={content}
    />
  );
};

MaintenanceBusGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaintenanceBusGroup: PropTypes.func.isRequired,
  dispatchGetMaintenanceBusGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  maintenanceBusGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

MaintenanceBusGroup.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaintenanceBusGroup: clearMaintenanceBusGroup,
  dispatchGetMaintenanceBusGroup: getMaintenanceBusGroup,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupo de Buses para Mantenimiento',
      href: MAINTENANCE_BUS_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenanceBusGroup: MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceBusGroup);
