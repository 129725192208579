import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteMaintenanceServiceOrder } from '../../../../actions';

const MaintenanceServiceOrderDeleteButton = ({
  dispatchDeleteMaintenanceServiceOrder,
  maintenanceServiceOrderId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteMaintenanceServiceOrder({ maintenanceServiceOrderId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

MaintenanceServiceOrderDeleteButton.propTypes = {
  dispatchDeleteMaintenanceServiceOrder: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maintenanceServiceOrderId: PropTypes.number.isRequired,
};

MaintenanceServiceOrderDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteMaintenanceServiceOrder: deleteMaintenanceServiceOrder,
};

export default connect(
  null,
  mapDispatchToProps,
)(MaintenanceServiceOrderDeleteButton);
