import { string, number, shape, arrayOf, bool } from 'prop-types';
import { maintenanceWarningBasicInformationPropTypes } from '../../maintenance-warning/prop-types/MaintenanceWarningPropTypes';
import { activityBusExecutionBasicInformationPropTypes } from '../../register-of-execution/prop-types/ActivityBusExecutionPropTypes';

const maintenanceServiceOrderBasicInformationPropTypes = {
  deadline: number,
  priority: string.isRequired,
  comments: string,
  status: string.isRequired,
  registeredBus: shape({ companyBusId: string.isRequired }),
  companyResource: shape({
    id: number.isRequired,
    name: string.isRequired,
    companyResourceTypeId: number.isRequired,
    companyResourceType: shape({
      id: number,
      description: string,
    }),
    active: bool.isRequired,
  }),
};

const maintenanceServiceOrderBasicDefaultProps = {
  comments: null,
  maintenanceWarning: null,
  deadline: null,
  registeredBus: null,
  companyResource: null,
};

const maintenanceServiceOrderInternalInformationPropTypes = {
  id: number.isRequired,
  createDate: number.isRequired,
  lastUpdate: number,
};

const maintenanceServiceOrderInternalDefaultProps = {
  lastUpdate: null,
};

const maintenanceServiceOrderMaintenanceWarningListPropTypes = {
  maintenanceWarningList: arrayOf(
    shape(maintenanceWarningBasicInformationPropTypes),
  ),
};

const maintenanceServiceOrderMaintenanceWarningListDefaultProps = {
  maintenanceWarningList: [],
};

const maintenanceServiceOrderActivityBusExecutionListPropTypes = {
  activityBusExecutionList: arrayOf(
    shape(activityBusExecutionBasicInformationPropTypes),
  ),
};

const maintenanceServiceOrderActivityBusExecutionListDefaultProps = {
  activityBusExecutionList: [],
};

export {
  maintenanceServiceOrderBasicInformationPropTypes,
  maintenanceServiceOrderBasicDefaultProps,
  maintenanceServiceOrderInternalInformationPropTypes,
  maintenanceServiceOrderInternalDefaultProps,
  maintenanceServiceOrderActivityBusExecutionListPropTypes,
  maintenanceServiceOrderActivityBusExecutionListDefaultProps,
  maintenanceServiceOrderMaintenanceWarningListPropTypes,
  maintenanceServiceOrderMaintenanceWarningListDefaultProps,
};
