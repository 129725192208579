import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearLocations, getLocations } from '../../../actions';

const LocationFilter = ({
  locations,
  onChangeFilter,
  loading,
  dispatchGetLocations,
  dispatchClearLocations,
  isMulti,
  isClearable,
  placeholder,
}) => {
  useLayoutEffect(() => () => dispatchClearLocations(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 3) {
      dispatchGetLocations({ query: inputValue });
    }
  };

  const locationFilterOption = (options) => options;

  return (
    <SelectFilter
      isMulti={isMulti}
      isClearable={isClearable}
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      onInputChange={handleInputChange}
      options={locations}
      filterOption={locationFilterOption}
      placeholder={placeholder}
    />
  );
};

LocationFilter.propTypes = {
  locations: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  dispatchGetLocations: PropTypes.func.isRequired,
  dispatchClearLocations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

LocationFilter.defaultProps = {
  locations: [],
  isMulti: false,
  loading: false,
  isClearable: false,
  placeholder: 'Seleccione ubicación',
};

const mapStateToProps = ({ LocationUnit }) => ({
  locations: LocationUnit.Location.getIn(['all', 'content', 'content']).map(
    (option) => ({
      value: option.id,
      label: option.name,
    }),
  ),
  loading: LocationUnit.Location.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetLocations: getLocations,
  dispatchClearLocations: clearLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilter);
