import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  clearMaintenanceWarning,
  getMaintenanceWarning,
} from '../../../../actions';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { MAINTENANCE_WARNING_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';
import MaintenanceWarningToolbar from './MaintenanceWarningToolbar';
import MaintenanceWarningBasicInformation from './resource/MaintenanceWarningBasicInformation';
import MaintenanceWarningInternalInformation from './resource/MaintenanceWarningInternalInformation';

const MaintenanceWarning = ({
  match: {
    params: { id: maintenanceWarningId },
  },
  dispacthGetMaintenanceWarning,
  dispacthClearMaintenanceWarning,
  maintenanceWarning,
  loading,
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispacthGetMaintenanceWarning({ maintenanceWarningId });

    return () => dispacthClearMaintenanceWarning();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (maintenanceWarning.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_WARNING_PATH} />;
  else {
    const maintenanceWarningJson = maintenanceWarning.toJS();
    const { id, status, serviceOrderId } = maintenanceWarningJson;

    toolbar = (
      <MaintenanceWarningToolbar
        maintenanceWarningId={id}
        status={status}
        serviceOrderId={serviceOrderId}
      />
    );
    content = (
      <>
        <Row>
          <Col md={6}>
            <h3>Información Básica</h3>
            <MaintenanceWarningBasicInformation {...maintenanceWarningJson} />
          </Col>
          <Col md={6}>
            <h3>Información Interna</h3>
            <MaintenanceWarningInternalInformation
              {...maintenanceWarningJson}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Content
      title="Reporte de Ocurrencia"
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      content={content}
    />
  );
};

MaintenanceWarning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispacthGetMaintenanceWarning: PropTypes.func.isRequired,
  dispacthClearMaintenanceWarning: PropTypes.func.isRequired,
  maintenanceWarning: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

MaintenanceWarning.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Reportes de Ocurrencia',
      href: MAINTENANCE_WARNING_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenanceWarning: MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispacthGetMaintenanceWarning: getMaintenanceWarning,
  dispacthClearMaintenanceWarning: clearMaintenanceWarning,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceWarning);
