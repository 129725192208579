import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { printPrintedTickets } from '../../../../utils/printers/Ticket';
import { getPrintedMergedTickets } from '../../../../actions';

const ServiceMergePrintButton = ({
  dispatchGetPrintedMergedTickets,
  serviceMergeId,
}) => {
  const onClickPrint = async () => {
    const printedTickets = await dispatchGetPrintedMergedTickets({
      serviceMergeId,
    });
    if (printedTickets) {
      printPrintedTickets(printedTickets);
    }
  };

  return (
    <>
      <Button color="light" className="border" onClick={onClickPrint}>
        <i className="fa fa-print" /> Imprimir Tickets Migrados
      </Button>
    </>
  );
};

ServiceMergePrintButton.propTypes = {
  dispatchGetPrintedMergedTickets: PropTypes.func.isRequired,
  serviceMergeId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchGetPrintedMergedTickets: getPrintedMergedTickets,
};

export default connect(null, mapDispatchToProps)(ServiceMergePrintButton);
