import React, { useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  ACTIVITY_ACTION,
} from '../../../../config/constants';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  ACTIVITY_MATERIAL_FORM_COLUMNS,
  generateActivityTaskFormColumns,
} from '../../../../config/dynamicFormFields';

export const ActivityFormFields = ({
  showActivityLabel,
  initialValues,
  materialList,
}) => {
  const materialOptions = materialList
    .filter(
      (material) =>
        material.materialId &&
        material.materialId.label &&
        material.materialId.value,
    )
    .map((material) => ({
      label: material.materialId.label,
      value: material.materialId.value,
    }));

  const [fieldRequired, setIsFieldRequired] = useState(
    initialValues &&
      initialValues.activityType.value !==
        MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
  );

  const onChangeActivityType = (option) =>
    setIsFieldRequired(
      !!(option && option.value !== MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value),
    );

  return (
    <>
      <FormGroup row>
        <FormItem label="Tipo de Actividad" required={fieldRequired}>
          <Field
            name="activityType"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_ACTIVITY_TYPE)}
            onChange={onChangeActivityType}
            isClearable={false}
            validate={fieldRequired ? [isRequired] : []}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem
          label={showActivityLabel ? 'Actividad' : ''}
          required={showActivityLabel}
        >
          <Row>
            <Col lg={2} md={2} sm={6}>
              <Field
                name="companyActivityId"
                id="companyActivityId"
                component={TextInput}
                type="text"
                showToolTip
                placeholder="Código"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={3} sm={6}>
              <Field
                name="action"
                id="action"
                component={Select}
                options={enumToSelectOptions(ACTIVITY_ACTION)}
                showToolTip
                placeholder="Acción"
                validate={fieldRequired ? [isRequired] : []}
              />
            </Col>
            <Col lg={7} md={7} sm={12}>
              <Field
                name="name"
                id="name"
                component={TextInput}
                showToolTip
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <h3>Materiales asignados a la actividad</h3>
      <DynamicForm
        name="materialList"
        columns={ACTIVITY_MATERIAL_FORM_COLUMNS}
      />
      <h3>Tareas asignadas</h3>
      <DynamicForm
        name="activityMaintenanceTaskList"
        columns={generateActivityTaskFormColumns(materialOptions)}
      />
    </>
  );
};

ActivityFormFields.propTypes = {
  showActivityLabel: PropTypes.bool,
  initialValues: PropTypes.shape({
    activityType: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  materialList: PropTypes.arrayOf(
    PropTypes.shape({
      materialId: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
      quantity: PropTypes.number,
    }),
  ),
};

ActivityFormFields.defaultProps = {
  showActivityLabel: true,
  initialValues: null,
  materialList: [],
};

export default ActivityFormFields;
