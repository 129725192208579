import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { BUS_PATH, NEW_BUS_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { BUSSES_COLUMNS } from '../../../../config/columns';
import { clearRegisteredBuses, getRegisteredBuses } from '../../../../actions';

export const RegisteredBusses = ({
  breadcrumbs,
  registeredBusses,
  loading,
  dispatchGetRegisteredBuses,
  dispatchClearRegisteredBuses,
}) => {
  useLayoutEffect(() => () => dispatchClearRegisteredBuses(), []);

  const data = registeredBusses.get('content') || [];
  const pages = registeredBusses.get('totalPages') || null;
  const defaultPageSize = registeredBusses.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Buses"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_BUS_PATH}
      tableStructure={{
        columns: BUSSES_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetRegisteredBuses,
        modelPath: BUS_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetRegisteredBuses: getRegisteredBuses,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
};

const mapStateToProps = ({ BusUnit }) => ({
  breadcrumbs: [
    ...BusUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Buses',
      href: BUS_PATH,
    },
  ],
  registeredBusses: BusUnit.RegisteredBus.getIn(['all', 'content']),
  loading: BusUnit.RegisteredBus.getIn(['all', 'loading']),
});

RegisteredBusses.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  registeredBusses: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetRegisteredBuses: PropTypes.func.isRequired,
  dispatchClearRegisteredBuses: PropTypes.func.isRequired,
};

RegisteredBusses.defaultProps = {
  registeredBusses: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredBusses);
