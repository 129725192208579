import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ACTIVITY_PATH } from '../../../../config/paths';
import { postActivity } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ActivityForm from './ActivityForm';
import { DEFAULT_ACTIVITY_FACTOR_TYPE_OPTION } from '../../../../config/defaults';
import { MAINTENANCE_ACTIVITY_TYPE } from '../../../../config/constants';

const NewActivity = ({ breadcrumbs, dispatchPostActivity }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.action = formValues.action && formValues.action.value;
    newFormValues.activityType =
      formValues.activityType && formValues.activityType.value;
    newFormValues.materialList =
      formValues.materialList &&
      formValues.materialList.map(({ materialId, quantity }) => ({
        materialId: materialId.value,
        quantity: quantity || 1,
      }));
    newFormValues.activityMaintenanceTaskList =
      formValues.activityMaintenanceTaskList &&
      formValues.activityMaintenanceTaskList.map(({ maintenanceTask }) => ({
        maintenanceTaskId: maintenanceTask.value,
      }));
    newFormValues.maintenanceTaskMaterialActivityList = Array.isArray(
      formValues.activityMaintenanceTaskList,
    )
      ? formValues.activityMaintenanceTaskList.flatMap(
          ({ maintenanceTask, materialId }) =>
            Array.isArray(materialId)
              ? materialId.map(({ value: materialIdValue }) => ({
                  maintenanceTaskId: maintenanceTask.value,
                  materialId: materialIdValue,
                }))
              : [],
        )
      : [];

    dispatchPostActivity(newFormValues);
  };

  const content = (
    <ActivityForm
      onSubmit={onSubmit}
      initialValues={{
        factorType: DEFAULT_ACTIVITY_FACTOR_TYPE_OPTION,
        activityType: MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE,
      }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo registro de Actividad"
      subtitle="Crear nuevo registro de actividad"
      content={content}
    />
  );
};

NewActivity.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostActivity: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostActivity: postActivity,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Actividades',
      href: ACTIVITY_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewActivity);
