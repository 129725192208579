import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
  putMaintenanceServiceOrder,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceServiceOrderForm from './MaintenanceServiceOrderForm';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { maintenanceWarningGenerator } from '../../../../utils/app/json-generator-from-reducer';

const EditMaintenanceServiceOrder = ({
  breadcrumbs,
  dispatchPutMaintenanceServiceOrder,
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  match: {
    params: { id: maintenanceServiceOrderId },
  },
}) => {
  useEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });

    return () => dispacthClearMaintenanceServiceOrder();
  }, []);

  const onSubmit = (formValues) => {
    const maintenanceWarningList =
      formValues.preventiveMaintenanceWarningList.concat(
        formValues.correctiveMaintenanceWarningList,
      );

    dispatchPutMaintenanceServiceOrder(maintenanceServiceOrderId, {
      maintenanceWarningList: maintenanceWarningList.map((warning) => ({
        id: warning.maintenanceWarningId.value,
        maintenancePlanningActivityResourceId:
          warning.maintenanceWarningId.maintenancePlanningActivityResourceId,
        serviceOrderId: maintenanceServiceOrderId,
      })),
      registeredBusId: formValues.registeredBusId
        ? formValues.registeredBusId.value
        : null,
      companyResourceId: formValues.companyResourceId
        ? formValues.companyResourceId.value
        : null,
      deadline: tzNormalizeDate({ date: formValues.deadline }),
      priority: formValues.priority.value,
      description: formValues.description,
      status: formValues.status.value,
    });
  };

  const generateInitialValues = (maintenanceServiceOrderJson) => {
    const {
      priority,
      deadline,
      description,
      maintenanceWarningList,
      registeredBus,
      status,
      registeredBusId,
      companyResourceId,
      companyResource,
    } = maintenanceServiceOrderJson;

    const preventiveMaintenanceWarningList = maintenanceWarningList
      .filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value,
      )
      .map((warning) => ({
        maintenanceWarningId: maintenanceWarningGenerator(warning),
      }));

    const correctiveMaintenanceWarningList = maintenanceWarningList
      .filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
      )
      .map((warning) => ({
        maintenanceWarningId: maintenanceWarningGenerator(warning),
      }));

    return {
      preventiveMaintenanceWarningList,
      correctiveMaintenanceWarningList,
      registeredBusId:
        registeredBus !== null
          ? {
              value: registeredBusId,
              label: registeredBus.companyBusId,
            }
          : null,
      companyResourceId:
        companyResource !== null
          ? {
              value: companyResourceId,
              label: companyResource.name,
            }
          : null,
      deadline: deadline && tzNormalizeDate({ date: deadline }).slice(0, 16),
      description,
      priority: MAINTENANCE_SERVICE_ORDER_PRIORITY[priority],
      status: MAINTENANCE_SERVICE_ORDER_STATUS[status],
    };
  };

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceServiceOrder.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_SERVICE_ORDER_PATH} />;
  else {
    content = (
      <MaintenanceServiceOrderForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(maintenanceServiceOrder.toJS())}
        editionMode
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Orden de Servicio"
      subtitle="Editar orden de servicio"
      content={content}
    />
  );
};

EditMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispatchPutMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
};

EditMaintenanceServiceOrder.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispatchPutMaintenanceServiceOrder: putMaintenanceServiceOrder,
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceServiceOrder);
