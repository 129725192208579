import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import DateCell from '../components/common/cells/DateCell';
import ReportStatusCell from '../components/common/cells/ReportStatusCell';
import IsActive from '../components/common/cells/IsActive';

import {
  POSTPAID_BOOKING_REJECTED,
  EXTRAORDINARY_MOVEMENT_CONTROL_STATUS,
  FUEL_VOUCHER_STATUS,
  FUEL_VOUCHER_EXTRAORDINARY_OPTIONS,
  USER_ENABLED_OPTIONS,
  ITINERARY_SCHEDULE_MACIVA_OPTIONS,
  COMPANY_TYPE,
  IN_SYSTEM_NOTIFICATION_SEVERITY,
  EXPENSE_STATUS,
  ITINERARY_GROUP_STATUS,
  KILOGRAM_SYMBOL,
  GENDERS,
  KILOMETER_SYMBOL,
  MINUTE_SYMBOL,
  ITINERARY_TYPE,
  CONTRACT_STATUS,
  CREDIT_NOTE_STATUS_OPTIONS,
  PURCHASE_ORDER_STATUS,
  MANUAL_VOUCHER_SERVICE_TYPE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MAINTENANCE_FACTOR_TYPE,
  ACTIVITY_STATUS,
  MATERIAL_MEASUREMENT_UNIT,
  RELATIONSHIP,
  TICKET_STATUS,
  EXPENSE_TYPE_LABEL,
  CONTRACT_TYPE,
  MAINTENANCE_WARNING_TYPE,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_STATUS,
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
  MAINTENANCE_ACTIVITY_TYPE,
  ACTIVITY_ACTION,
  PEN_SYMBOL,
  TICKET_BOARDED_STATUS,
  CARGO_ITEM_PERISHABLE_OPTIONS,
  PARCEL_PAYMENT_STATUS,
  PAYMENT_STATUS,
  COMPANY_STAFF_TYPE,
  TRIP_REQUEST_STATUS,
  COMPANY_BUS_SEAT_COUNT,
  PARCEL_PRICE_STATUS,
  MAINTENANCE_WARNING_STATUS_ACTIVITY,
  DRIVER_STATUS_OPTION,
  PARCEL_FINANCIAL_STATUS,
  MAINTENANCE_PLANNING_STATUS,
} from './constants';
import * as ColumnFilterDataTypes from './columnFilterDataTypes';
import AgencyFilter from '../components/common/filters/AgencyFilter';
import PostpaidBookingStatusCell from '../components/common/cells/postpaid-booking/PostpaidBookingStatusCell';
import PostpaidBookingStatusFilter from '../components/common/filters/PostpaidBookingStatusFilter';
import PostpaidBookingActionsCell from '../components/common/cells/postpaid-booking/PostpaidBookingActionsCell';
import RowExpander from '../components/common/cells/postpaid-booking/RowExpander';
import TicketPaymentMethodCell from '../components/common/cells/sales-session/TicketPaymentMethodCell';
import TicketStatusFilter from '../components/common/filters/TicketStatusFilter';
import TicketReportAgenciesCell from '../components/common/cells/ticket-report/TicketReportAgenciesCell';
import ExpenseTypeFilter from '../components/common/filters/ExpenseTypeFilter';
import CircuitFilter from '../components/common/filters/CircuitFilter';
import EnumStatus from '../components/common/EnumStatus';
import EnumSelectFilter from '../components/common/filters/EnumSelectFilter';
import SelectFilter from '../components/common/filters/SelectFilter';
import VoucherTypeFilter from '../components/common/filters/VoucherTypeFilter';
import PaymentMethodFilter from '../components/common/filters/PaymentMethodFilter';
import CityFilter from '../components/common/filters/CityFilter';
import Checkbox from '../components/common/forms/Checkbox';
import TicketActiveFilter from '../components/common/filters/TicketActiveFilter';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from './locale';
import SeatMapSelect from '../components/common/forms/select/SeatMapSelect';
import RouteSelect from '../components/common/forms/select/RouteSelect';
import { enumToSelectOptions } from '../utils/enum';
import { tzNormalizeDate } from '../utils/date';
import {
  ITINERARY_FOR_COUNTER_PATH,
  ITINERARY_PATH,
  SALES_SESSION_PATH,
} from './paths';
import CustomerFilter from '../components/common/filters/CustomerFilter';
import RegisteredBusFilter from '../components/common/filters/RegisteredBusFilter';
import { numberFormatter } from '../utils/number';
import { padStart } from '../utils/string';
import HierarchyFilter from '../components/common/filters/HierarchyFilter';
import ActionsCell from '../components/common/cells/ActionsCell';
import Badge from '../components/common/Badge';
import BadgeList from '../components/common/BadgeList';
import OrganizationChartSelect from '../components/common/forms/select/OrganizationChartSelect';
import LinkToModuleResource from '../components/common/resource/LinkToModuleResource';
import ExpenseTypeLabelSelect from '../components/common/forms/select/ExpenseTypeLabelSelect';
import AgencyContractTypeSelect from '../components/common/forms/select/AgencyContractTypeSelect';
import CargoItemMovementStatusCell from '../components/common/cells/cargo/cargo-item/CargoItemMovementStatusCell';
import CargoItemStatusCell from '../components/common/cells/cargo/cargo-item/CargoItemStatusCell';
import ParcelPaymentStatusCell from '../components/common/cells/cargo/cargo/ParcelPaymentStatusCell';
import CargoItemStatusFilter from '../components/common/filters/CargoItemStatusFilter';
import CommonProductFilter from '../components/common/filters/CommonProductFilter';
import CargoCategoryFilter from '../components/common/filters/CargoCategoryFilter';
import BusinessFilter from '../components/common/filters/BusinessFilter';
import DriverStatusCell from '../components/units/traffic/driver/DriverStatusCell';
import LocationFilter from '../components/common/filters/LocationFilter';

export const REPORT_COLUMNS = [
  {
    Header: 'Fecha de Inicio',
    accessor: 'fromDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Fin',
    accessor: 'toDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'created',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Última Actualización',
    accessor: 'lastUpdate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Estado',
    accessor: 'reportStatus',
    Cell: (row) => <ReportStatusCell value={row.value} />,
  },
];

export const TICKET_REPORT_COLUMNS = [
  {
    Header: 'Fecha de Inicio',
    accessor: 'fromDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Fin',
    accessor: 'toDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Agencias',
    accessor: 'agencySet',
    Cell: (row) => <TicketReportAgenciesCell agencies={row.value} />,
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'created',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Última Actualización',
    accessor: 'lastUpdate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Estado',
    accessor: 'reportStatus',
    Cell: (row) => <ReportStatusCell value={row.value} />,
  },
];

export const LOCATION_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Dirección',
    accessor: 'address.address',
    className: 'text-center',
  },
  {
    Header: 'Distrito',
    accessor: 'address.district',
    className: 'text-center',
  },
  {
    Header: 'Ciudad',
    accessor: 'address.city.name',
    className: 'text-center',
  },
  {
    Header: 'ID de Correlación Civa',
    accessor: 'locationCorrelationId',
    className: 'text-center',
  },
];

export const ITINERARIES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    Cell: (row) => {
      let badgeForItineraryWithoutExpectedConsumption = '';
      if (+row.original.expectedFuelConsumption === 0)
        badgeForItineraryWithoutExpectedConsumption = (
          <Badge color="danger" text="1" />
        );

      let badgeForItineraryWithoutExtraordinaryRealValue = '';
      if (+row.original.hasNotExtraordinaryRealValue)
        badgeForItineraryWithoutExtraordinaryRealValue = (
          <Badge color="warning" text="2" />
        );

      return (
        <div>
          {row.value} {badgeForItineraryWithoutExpectedConsumption}{' '}
          {badgeForItineraryWithoutExtraordinaryRealValue}
        </div>
      );
    },
    width: 100,
  },
  {
    Header: 'Ruta',
    accessor: 'route.name',
    className: 'text-center',
    minWidth: 300,
    maxWidth: 450,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType.name',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Activo',
    accessor: 'active',
    className: 'text-center',
    Cell: (row) => <IsActive value={row.value} />,
    Filter: (row) => <TicketActiveFilter onChangeFilter={row.onChange} />,
  },
  {
    Header: 'Programado',
    accessor: 'trip',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Fecha',
    accessor: 'departureTime',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Hora',
    accessor: 'departureTime',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Tipo',
    accessor: 'type',
    Cell: (row) => ITINERARY_TYPE[row.value.toLowerCase()].label,
    className: 'text-center',
  },
];

export const TICKETS_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    Cell: (row) => padStart(row.value, '7'),
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Fecha de Emisión',
    accessor: 'documentAssignmentDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 160,
  },
  {
    Header: 'Nro. documento',
    accessor: 'customer.idDocumentNumber',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Nombres',
    accessor: 'customer.firstName',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Apellidos',
    accessor: 'customer.lastName',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocation.name',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocation.name',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Fecha de Viaje',
    accessor: 'departureDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 160,
  },
  {
    Header: 'Precio',
    accessor: 'salePrice',
    Cell: (row) =>
      numberFormatter({
        style: 'currency',
        value: row.value,
      }),
    className: 'text-right',
    width: 150,
  },
  {
    Header: 'Estado',
    accessor: 'ticketStatus',
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={TICKET_STATUS} enumValue={row.value} />
    ),
    Filter: (row) => <TicketStatusFilter onChangeFilter={row.onChange} />,
    width: 200,
    filterable: false,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const COUNTRIES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'País',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Código',
    accessor: 'countryCode',
    className: 'text-center',
  },
];

export const ADDRESS_COLUMNS = [
  {
    Header: 'Dirección 1',
    accessor: 'address',
    className: 'text-center',
  },
  {
    Header: 'Dirección 2',
    accessor: 'address2',
    className: 'text-center',
  },
  {
    Header: 'Distrito',
    accessor: 'district',
    className: 'text-center',
  },
  {
    Header: 'Ciudad',
    accessor: 'city.name',
    className: 'text-center',
  },
  {
    Header: 'Código Postal',
    accessor: 'postalCode',
    className: 'text-center',
  },
  {
    Header: 'Teléfono',
    accessor: 'phone',
    className: 'text-center',
  },
];

export const SALES_SESSIONS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Nombre Usuario',
    accessor: 'user.customer.firstName',
    className: 'text-center',
    width: 300,
  },
  {
    Header: 'Apellido Usuario',
    accessor: 'user.customer.lastName',
    className: 'text-center',
    width: 300,
  },
  {
    Header: 'Agencia',
    accessor: 'agency.name',
    className: 'text-center',
    width: 400,
  },
  {
    Header: 'Caja',
    accessor: 'workstation.name',
    className: 'text-center',
  },
  {
    Header: 'Inicio',
    accessor: 'sessionStart',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Fin',
    accessor: 'sessionEnd',
    dataType: ColumnFilterDataTypes.DATE,
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    className: 'text-center',
  },
];

export const DEPOSITS_COLUMNS = [
  {
    Header: 'Operador',
    accessor: 'operatorUser.customer.fullName',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Agencia',
    accessor: 'dailyLiquidationSessionSet',
    Cell: (row) => {
      if (!row.value) return null;
      const agencyList = row.value.map(({ agency }) => ({ text: agency.name }));
      return <BadgeList cell textArray={agencyList} />;
    },
    filterable: true,
    Filter: (row) => <AgencyFilter onChangeFilter={row.onChange} />,
    id: 'dailyLiquidationSession.agency.id',
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
  },
  {
    Header: 'Fecha de Liquidación',
    accessor: 'dailyLiquidationSessionSet',
    Cell: (row) => {
      if (!row.value) return null;
      const dailyLiquidationDateList = row.value.map(
        ({ dailyLiquidationDate }) => ({
          text: tzNormalizeDate({
            date: dailyLiquidationDate,
            format: DATE_FORMAT,
          }),
        }),
      );
      return <BadgeList cell textArray={dailyLiquidationDateList} />;
    },
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Fecha de Depósito',
    accessor: 'depositDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Número de Operación',
    accessor: 'operationNumber',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'actualAmountDeposited',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Aprobado',
    accessor: 'settled',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Notas',
    accessor: 'depositNoteList',
    Cell: (row) => row.value.length,
    className: 'text-center',
    filterable: false,
  },
];

export const EXPENSE_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Agencia',
    accessor: 'transactionList',
    id: 'transaction.salesSession.agencyId',
    Cell: (row) =>
      row.value[0].salesSession ? row.value[0].salesSession.agency.name : null,
    Filter: (row) => <AgencyFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    width: 250,
  },
  {
    Header: 'Estado',
    accessor: 'expenseStatus',
    Cell: (row) => (row.value ? EXPENSE_STATUS[row.value].label : '-'),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={EXPENSE_STATUS}
      />
    ),
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Centro de Costo',
    accessor: 'costCenter.name',
    className: 'text-center',
    width: 250,
  },
  {
    Header: 'Nombre Counter',
    accessor: 'transactionList',
    id: 'transaction.user.customer.firstName',
    Cell: (row) =>
      row.value[0].user.customer ? row.value[0].user.customer.firstName : null,
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Apellido Counter',
    accessor: 'transactionList',
    id: 'transaction.user.customer.lastName',
    Cell: (row) =>
      row.value[0].user.customer ? row.value[0].user.customer.lastName : null,
    className: 'text-center',
    width: 300,
  },
  {
    Header: 'Tipo de comprobante',
    accessor: 'transactionList',
    id: 'transaction.voucherType.name',
    Cell: (row) => {
      if (!row.value) return null;
      const voucherTypeList = [];
      row.value.forEach(({ voucherType }) => {
        if (voucherType) voucherTypeList.push({ text: voucherType.name });
      });
      return <BadgeList cell textArray={voucherTypeList} />;
    },
    className: 'text-center',
    width: 300,
  },
  {
    Header: 'RUC',
    accessor: 'transactionList',
    id: 'transaction.business.businessTaxId',
    Cell: (row) =>
      row.value[0].business ? row.value[0].business.businessTaxId : null,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Razón Social',
    accessor: 'transactionList',
    id: 'transaction.business.name',
    Cell: (row) => (row.value[0].business ? row.value[0].business.name : null),
    className: 'text-center',
    width: 350,
  },
  {
    Header: 'Tipo de egreso',
    accessor: 'transactionList',
    id: 'transaction.expenseTypeId',
    Cell: (row) => {
      if (!row.value) return null;
      const expenseTypeList = row.value.map(({ expenseType }) => ({
        text: expenseType.name,
      }));
      return <BadgeList cell textArray={expenseTypeList} />;
    },
    Filter: (row) => <ExpenseTypeFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    width: 350,
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
    width: 350,
  },
  {
    Header: 'Total',
    accessor: 'transaction.amount',
    Cell: (row) => row.value && numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const EXPENSE_ITEMS = [
  {
    Header: 'Tipo de egreso',
    accessor: 'expenseType.name',
    className: 'text-center',
  },
  {
    Header: 'Total',
    accessor: 'amount',
    Cell: (row) => row.value && numberFormatter({ value: row.value }),
    className: 'text-center',
  },
];

export const POSTPAID_BOOKINGS_COLUMNS = [
  {
    className: 'text-center',
    Expander: (expander) => (
      <RowExpander
        isExpanded={Boolean(expander.isExpanded)}
        status={expander.original.status}
      />
    ),
    getProps: (state, rowInfo) => {
      if (rowInfo) {
        // same test as above
        if (rowInfo.original.status === POSTPAID_BOOKING_REJECTED) {
          // hijack the onClick so it doesn't open
          return {
            onClick: () => {},
          };
        }
      }
      return {
        className: 'show-pointer',
      };
    },
    expander: true,
  },
  {
    Header: 'Reserva',
    accessor: 'customerOrder.reservation.id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Usuario',
    accessor: 'userSummary.fullName',
    className: 'text-center',
  },
  {
    Header: 'Método de pago',
    accessor: 'postpaidPreTransaction.paymentMethod.name',
    className: 'text-center',
  },
  {
    Header: 'Código voucher',
    accessor: 'postpaidPreTransaction.voucherCode',
    Cell: (row) => row.value || '-',
    className: 'text-center',
  },
  {
    Header: 'Destinatario',
    accessor: 'customerFullName',
    className: 'text-center',
  },
  {
    Header: 'Ruta',
    accessor: 'routeName',
    className: 'text-center',
  },
  {
    Header: 'Fecha de reserva',
    accessor: 'createDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Fecha de Expiración CIP',
    accessor: 'pagoEfectivoExpirationDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Fecha de viaje',
    accessor: 'departureDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Monto',
    accessor: 'customerOrder.reservation.ticketList',
    Cell: (row) => {
      let salePrice = 0;
      row.value.forEach((ticket) => {
        salePrice += ticket.salePrice;
      });
      return numberFormatter({ value: salePrice });
    },
    filterable: false,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => <PostpaidBookingStatusCell value={row.value} />,
    Filter: (row) => (
      <PostpaidBookingStatusFilter onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <PostpaidBookingActionsCell
        customerOrderId={row.original.customerOrder.id}
        transactionCode={
          row.original.postpaidPreTransaction.paymentMethod.transactionCode
        }
        status={row.original.status}
      />
    ),
    width: 150,
    filterable: false,
  },
];

export const DISCOUNT_CODE_REPORT_COLUMNS = [
  {
    Header: 'Agencia',
    accessor: 'agency',
  },
  {
    Header: 'Counter',
    accessor: 'counter',
  },
  {
    Header: 'Código de Descuento',
    accessor: 'discountCode',
  },
  {
    Header: `Descuento`,
    accessor: 'discountedAmount',
    Cell: (row) => numberFormatter({ value: row.value }),
  },
  {
    Header: 'Descuento (%)',
    accessor: 'discountPercentage',
  },
  {
    Header: 'Boleto',
    accessor: 'ticketCode',
  },
  {
    Header: 'Fecha de Uso',
    accessor: 'useDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
];

export const CALL_CENTER_REPORT_COLUMNS = [
  {
    Header: 'Código de Referencia',
    accessor: 'referenceCode',
  },
  {
    Header: 'Vendedor',
    accessor: 'sellerName',
  },
  {
    Header: 'Agencia',
    accessor: 'agencyName',
  },
];

export const SALES_SESSION_TICKETS_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Precio de Venta',
    accessor: 'salePrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethodList',
    Cell: (row) => <TicketPaymentMethodCell paymentMethodList={row.value} />,
    filterMethod: (filter, row) => {
      const result = row.paymentMethodList.some((paymentMethod) =>
        paymentMethod.moneyFlowConcept
          .toLowerCase()
          .includes(filter.value.toLowerCase()),
      );
      return result;
    },
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'ticketStatus',
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={TICKET_STATUS} enumValue={row.value} />
    ),
    Filter: (row) => <TicketStatusFilter onChangeFilter={row.onChange} />,
  },
];

export const SALES_SESSION_INCOME_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Beneficiario',
    accessor: 'payee',
    className: 'text-center',
  },
  {
    Header: 'Autorizado por',
    accessor: 'authorizer',
    className: 'text-center',
  },
];

export const SALES_SESSION_EXPENSE_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Egreso',
    accessor: 'expenseType',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Beneficiario',
    accessor: 'payee',
    className: 'text-center',
  },
  {
    Header: 'Autorizado por',
    accessor: 'authorizer',
    className: 'text-center',
  },
];

export const SALES_SESSION_BAGGAGE_EXCESSES_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Peso',
    accessor: 'weight',
    Cell: (row) => (row.value ? `${row.value.toFixed(2)} kg` : 0),
    className: 'text-center',
  },
  {
    Header: 'Precio Sin Descuento',
    accessor: 'priceBeforeDiscount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Descuento',
    accessor: 'discountAmount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Precio Final',
    accessor: 'price',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethod',
    Cell: (row) => row.value,
    className: 'text-center',
  },
  {
    Header: 'Anulado',
    accessor: 'excessBaggageVoidId',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    className: 'text-center',
    filterable: false,
  },
];

export const SALES_SESSION_PARCELS_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Emisión',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'financialStatus',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={PARCEL_FINANCIAL_STATUS}
      />
    ),
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_FINANCIAL_STATUS} enumValue={row.value} />
    ),
  },
  {
    Header: 'Precio de Venta',
    accessor: 'salePrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethodName',
    className: 'text-center',
    Filter: (row) => <PaymentMethodFilter onChangeFilter={row.onChange} />,
    id: 'paymentMethodId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Estado de Pago',
    accessor: 'paymentStatus',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={PARCEL_PAYMENT_STATUS}
      />
    ),
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_PAYMENT_STATUS} enumValue={row.value} />
    ),
  },
];

export const SALES_SESSION_OTHER_INCOMES_PARCELS_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Emisión',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Cliente',
    accessor: 'customerId',
    Filter: (row) => (
      <CustomerFilter isClearable onChangeFilter={row.onChange} />
    ),
    Cell: (row) =>
      row.value
        ? `${row.original.customerIdentificationTypeName} ${row.original.customerIdDocumentNumber} - ${row.original.customerFullName} (${row.original.customerIdOriginOfCountry})`
        : '-',
    className: 'text-center',
  },
  {
    Header: 'Empresa',
    accessor: 'businessId',
    Filter: (row) => (
      <BusinessFilter isClearable onChangeFilter={row.onChange} />
    ),
    Cell: (row) =>
      row.value
        ? `RUC ${row.original.businessTaxIdentificationNumber} - ${row.original.businessName}`
        : '-',
    className: 'text-center',
  },
  {
    Header: 'Precio de Venta',
    accessor: 'salePrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    filterable: false,
    className: 'text-center',
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethodId',
    Cell: (row) => row.original.paymentMethodName,
    className: 'text-center',
    Filter: (row) => <PaymentMethodFilter onChangeFilter={row.onChange} />,
  },
];

export const EXPECTED_FUEL_CONSUMTION_COLUMNS = [
  {
    Header: 'Ruta',
    accessor: 'route.name',
    id: 'route.id',
    Filter: (row) => <RouteSelect onChangeFilter={row.onChange} useForFilter />,
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    filterable: true,
  },
  {
    Header: 'Comentario',
    accessor: 'comment',
    className: 'text-center',
    filterable: false,
  },
];

export const DRIVER_BUS_ASSIGNMENT_COLUMNS = [
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    id: 'companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Conductor',
    accessor: 'driverAssignmentDateList[0].driver.driverName',
    id: 'fullName',
    className: 'text-center',
  },
  {
    Header: 'DNI',
    accessor: 'driverAssignmentDateList[0].driver.driverIdDocumentNumber',
    id: 'idDocumentNumber',
    className: 'text-center',
  },
];

export const BUS_FUEL_GROUP_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Rendimiento Km/Galón',
    accessor: 'performance',
    className: 'text-center',
    filterable: false,
    Cell: (row) => (row.value ? row.value.toFixed(2) : null),
  },
];

export const FUEL_PROFILE_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Circuito',
    accessor: 'circuit.name',
    id: 'circuitId',
    className: 'text-center',
    Filter: (row) => <CircuitFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
  },
];

export const PURCHASE_ORDER_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    id: 'agency.id',
    Header: 'Agencia',
    accessor: 'agency.name',
    className: 'text-center',
    Filter: (row) => <AgencyFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Total',
    accessor: 'totalAmount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => (
      <EnumStatus enumObject={PURCHASE_ORDER_STATUS} enumValue={row.value} />
    ),
    filterable: false,
    className: 'text-center',
  },
];

export const EXTRAORDINARY_MOVEMENT_CONTROL_COLUMNS = [
  {
    Header: 'Fecha Creación',
    accessor: 'createDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
  },
  {
    Header: 'Fecha Salida Inicial',
    accessor: 'initialDepartureTime',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
  },
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    id: 'registeredBusId',
    Filter: (row) => <RegisteredBusFilter onChangeFilter={row.onChange} />,
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
    Filter: (row) => (
      // Apparently the backend does not support filter with enum, pending review
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={EXTRAORDINARY_MOVEMENT_CONTROL_STATUS}
      />
    ),
    Cell: (row) => (
      <EnumStatus
        enumObject={EXTRAORDINARY_MOVEMENT_CONTROL_STATUS}
        enumValue={row.value}
      />
    ),
    filterable: false,
  },
  {
    Header: 'KM Inicial',
    accessor: 'initialKilometers',
    className: 'text-center',
  },
  {
    Header: 'KM Real',
    accessor: 'realKilometers',
    className: 'text-center',
  },
  {
    Header: 'Combustible Inicial',
    accessor: 'expectedFuelConsumption',
    className: 'text-center',
  },
  {
    Header: 'Combustible Real',
    accessor: 'authorizedFuelConsumption',
    className: 'text-center',
  },
  {
    Header: 'Origen Real',
    accessor: 'realSourceLocationName',
    className: 'text-center',
  },
  {
    Header: 'Destino Real',
    accessor: 'realDestinationLocationName',
    className: 'text-center',
  },
];

export const FUEL_VOUCHER_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
  },
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    className: 'text-center',
  },
  {
    Header: 'ID de Itinerario',
    accessor: 'itineraryId',
    className: 'text-center',
  },
  {
    Header: 'Galones',
    accessor: 'numberOfGallons',
    Cell: (row) => {
      const gallons = row.original.realNumberOfGallons || row.value;
      return numberFormatter({
        style: 'decimal',
        value: gallons,
        minimumFractionDigits: 2,
      });
    },
    className: 'text-center',
  },
  {
    Header: 'Tipo',
    accessor: 'extraordinary',
    className: 'text-center',
    Cell: (row) => (row.value ? 'Extraordinario' : 'Regular'),
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={FUEL_VOUCHER_EXTRAORDINARY_OPTIONS}
      />
    ),
  },
  {
    Header: 'Estado',
    accessor: 'fuelVoucherStatus',
    className: 'text-center',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={FUEL_VOUCHER_STATUS}
      />
    ),
    Cell: (row) => (
      <EnumStatus enumObject={FUEL_VOUCHER_STATUS} enumValue={row.value} />
    ),
  },
];

export const PURCHASE_ORDER_DETAILS_COLUMNS_TABLE = [
  {
    Header: 'Serie',
    accessor: 'fuelVoucher.documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'fuelVoucher.documentCode',
    className: 'text-center',
  },
  {
    Header: 'Galones Autorizados',
    accessor: 'fuelVoucher.numberOfGallons',
    className: 'text-center',
  },
  {
    Header: 'Galones Utilizados',
    accessor: 'fuelVoucher.realNumberOfGallons',
    className: 'text-center',
  },
  {
    Header: 'Bus',
    accessor: 'fuelVoucher.companyBusId',
    className: 'text-center',
  },
];

export const EXPENSE_TYPES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Código de Egreso',
    accessor: 'expenseCode',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Etiqueta de Tipo De Egreso',
    accessor: 'expenseTypeLabel',
    Cell: (row) => (row.value ? EXPENSE_TYPE_LABEL[row.value].label : '-'),
    Filter: (row) => (
      <ExpenseTypeLabelSelect onChangeFilter={row.onChange} useForFilter />
    ),
    className: 'text-center',
  },
  {
    Header: 'Interno',
    accessor: 'internal',
    className: 'text-center',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    filterable: false,
  },
  {
    Header: 'Centro de Costo',
    accessor: 'costCenter.description',
    className: 'text-center',
    filterable: false,
  },
];

export const USERS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'customer.firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellido',
    accessor: 'customer.lastName',
    className: 'text-center',
  },
  {
    Header: 'Usuario',
    accessor: 'username',
    className: 'text-center',
  },
  {
    Header: 'Perfil de Seguridad',
    accessor: 'securityProfileSet',
    id: 'securityProfileSet.name',
    Cell: (row) => {
      if (!row.value) return null;
      const securityProfile = row.value.map(({ name }) => ({ text: name }));
      return <BadgeList cell textArray={securityProfile} />;
    },
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Estado',
    accessor: 'enabled',
    className: 'text-center',
    Cell: (row) => <IsActive value={row.value} />,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={USER_ENABLED_OPTIONS}
      />
    ),
  },
  {
    Header: 'Ultimo Ingreso',
    accessor: 'lastLogin',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    filterable: false,
    className: 'text-center',
  },
];

export const CUSTOMER_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Nombre',
    accessor: 'firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellido',
    accessor: 'lastName',
    className: 'text-center',
  },
  {
    Header: 'Género',
    accessor: 'gender',
    className: 'text-center',
    Cell: (row) => {
      const customerGender = GENDERS.find(
        (gender) => gender.value === row.value,
      );
      return customerGender ? customerGender.label : null;
    },
    filterable: false,
  },
  {
    Header: 'País de Origen',
    accessor: 'idCountryOfOrigin',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Tipo de Documento',
    accessor: 'identificationType.name',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Número de Documento',
    accessor: 'idDocumentNumber',
    className: 'text-center',
  },
];

export const ITINERARIES_FOR_COUNTER_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Ruta',
    accessor: 'routeName',
    className: 'text-center',
    width: 580,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Programado',
    accessor: 'tripId',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    className: 'text-center',
    filterable: false,
    width: 70,
  },
  {
    Header: 'Número de Bus',
    accessor: 'companyBusId',
    className: 'text-center',
    width: 70,
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: (row) => <IsActive value={row.value} />,
    className: 'text-center',
    width: 90,
  },
  {
    Header: 'Fecha de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    width: 100,
  },
  {
    Header: 'Hora de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    width: 100,
  },
  {
    Header: 'Tipo de Itinerario',
    accessor: 'type',
    className: 'text-center',
    Cell: (row) => ITINERARY_TYPE[row.value.toLowerCase()].label,
    width: 100,
  },
];

export const BAGGAGES_COLUMNS = [
  {
    Header: 'Equipaje',
    columns: [
      {
        Header: 'ID',
        accessor: 'id',
        className: 'text-center',
        width: 60,
      },
      {
        Header: 'Boleto de Viaje',
        accessor: 'ticketList',
        Cell: (row) => {
          if (!row.value) return null;
          const ticketList = row.value.map(
            ({ documentSeries, documentCode }) => ({
              text: `${documentSeries}-${padStart(documentCode.toString(), 7)}`,
            }),
          );
          return <BadgeList cell textArray={ticketList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Embarque',
        accessor: 'ticketList',
        Cell: (row) => {
          if (!row.value) return null;
          const sourceList = row.value.map(({ sourceLocation }) => ({
            text: sourceLocation.name,
          }));
          return <BadgeList cell textArray={sourceList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Desembarque',
        accessor: 'ticketList',
        Cell: (row) => {
          if (!row.value) return null;
          const destinationList = row.value.map(({ destinationLocation }) => ({
            text: destinationLocation.name,
          }));
          return <BadgeList cell textArray={destinationList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Items',
        accessor: 'itemList',
        Cell: (row) => {
          if (!row.value) return null;
          const itemList = row.value.map(({ description }) => ({
            text: description,
          }));
          return <BadgeList cell textArray={itemList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Tickets',
        accessor: 'itemList',
        Cell: (row) => {
          if (!row.value) return null;
          const itemList = row.value.map(({ itemSeries, itemCode }) => ({
            text: `${itemSeries}-${itemCode}`,
          }));
          return <BadgeList cell textArray={itemList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Correlativo Automático',
        accessor: 'itemList',
        Cell: (row) => {
          if (!row.value) return null;
          const itemList = row.value.map(({ itemCorrelation }) => ({
            text: itemCorrelation,
          }));
          return <BadgeList cell textArray={itemList} />;
        },
        className: 'text-center',
      },
      {
        Header: 'Peso',
        accessor: 'weight',
        className: 'text-center',
        Cell: (row) => `${row.value} ${KILOGRAM_SYMBOL}`,
        width: 120,
      },
    ],
  },
  {
    Header: 'Exceso',
    columns: [
      {
        Header: 'Boleta',
        accessor: 'excessBaggage',
        Cell: (row) => {
          const { value } = row;
          if (!value) return null;
          return (
            <Badge
              text={`${value.documentSeries}-${padStart(
                value.documentCode.toString(),
                7,
              )}`}
            />
          );
        },
        className: 'text-center',
      },
      {
        Header: 'Peso',
        accessor: 'excessBaggage',
        Cell: (row) => row.value && `${row.value.weight} ${KILOGRAM_SYMBOL}`,
        className: 'text-center',
        width: 120,
      },
      {
        Header: `Precio`,
        accessor: 'excessBaggage.price',
        Cell: (row) => numberFormatter({ value: row.value }),
        className: 'text-center',
        width: 120,
      },
    ],
  },
];

export const DISTRICTS_COLUMNS = [
  {
    Header: 'Distrito',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Ciudad',
    accessor: 'city.name',
    className: 'text-center',
  },
  {
    Header: 'Departamento',
    accessor: 'city.region.name',
    className: 'text-center',
  },
  {
    Header: 'Pais',
    accessor: 'city.region.country.name',
    className: 'text-center',
  },
];

export const DEPRECIATION_RULES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Depreciación',
    accessor: 'depreciationType',
    className: 'text-center',
  },
  {
    Header: 'Perdiodo',
    accessor: 'depreciationPeriodicity',
    className: 'text-center',
  },
  {
    Header: 'Porcentaje de Dep.',
    accessor: 'depreciationPercentage',
    className: 'text-center',
  },
];

export const DELIVERY_GROUPS_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const BASE_PRICE_PER_KILOMETER_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Kilómetro Inicial',
    accessor: 'fromKilometer',
    className: 'text-center',
  },
  {
    Header: 'Kilómetro Final',
    accessor: 'toKilometer',
    className: 'text-center',
  },
  {
    Header: 'Precio Base',
    accessor: 'price',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Factor Kilometraje',
    accessor: 'kilometerFactor',
    className: 'text-center',
  },
];

export const GEOGRAPHIC_ZONES_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Ciudades',
    accessor: 'cityList',
    className: 'text-center',
    filterable: false,
    Cell: (row) => {
      if (!row.value) return null;
      const cityList = row.value.map(({ name }) => ({ text: name }));
      return <BadgeList cell textArray={cityList} />;
    },
  },
];

export const GEOGRAPHIC_ZONES_COMBINATION_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Zona Origen',
    accessor: 'sourceGeographicZone.name',
    className: 'text-center',
  },
  {
    Header: 'Zona Destino',
    accessor: 'destinationGeographicZone.name',
    className: 'text-center',
  },
  {
    Header: 'Factor',
    accessor: 'factor',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const COMMON_PRODUCT_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre Producto',
    accessor: 'productName',
    className: 'text-center',
  },
  {
    Header: 'Categoria',
    accessor: 'cargoCategory.name',
    className: 'text-center',
    Filter: (row) => <CargoCategoryFilter onChangeFilter={row.onChange} />,
    id: 'cargoCategoryId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Descripción Producto',
    accessor: 'productDescription',
    className: 'text-center',
  },
  {
    Header: 'Producto Interno',
    accessor: 'internalProduct',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: (row) => <IsActive value={row.value} />,
    className: 'text-center',
    filterable: false,
  },
];

export const CARGO_DISTANCE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Ciudad Origen',
    accessor: 'sourceCity.name',
    className: 'text-center',
  },
  {
    Header: 'Ciudad Destino',
    accessor: 'destinationCity.name',
    className: 'text-center',
  },
  {
    Header: 'Kilómetros',
    accessor: 'kilometers',
    className: 'text-center',
  },
];

export const CARGO_CATEGORY_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Categoría',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const PARCELS_COLUMNS = [
  {
    Header: 'Tipo de Comprobante',
    accessor: 'voucherTypeName',
    className: 'text-center',
    Filter: (row) => <VoucherTypeFilter onChangeFilter={row.onChange} />,
    id: 'voucherTypeId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
    bsSize: 'lg',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'financialStatus',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={PARCEL_FINANCIAL_STATUS}
      />
    ),
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_FINANCIAL_STATUS} enumValue={row.value} />
    ),
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    id: 'sourceLocationId',
    className: 'text-center',
    Filter: (row) => (
      <LocationFilter onChangeFilter={row.onChange} isClearable />
    ),
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    id: 'destinationLocationId',
    className: 'text-center',
    Filter: (row) => (
      <LocationFilter onChangeFilter={row.onChange} isClearable />
    ),
  },
  {
    Header: 'Cliente',
    accessor: 'customerFullName',
    id: 'customerId',
    Filter: (row) => (
      <CustomerFilter isClearable onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Empresa',
    accessor: 'businessName',
    id: 'businessId',
    Filter: (row) => (
      <BusinessFilter isClearable onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Remitente',
    accessor: 'senderCustomerFullName',
    id: 'senderCustomerId',
    Filter: (row) => (
      <CustomerFilter isClearable onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Consignado',
    accessor: 'consigneeCustomerFullName',
    id: 'consigneeCustomerId',
    Filter: (row) => (
      <CustomerFilter isClearable onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethodName',
    className: 'text-center',
    Filter: (row) => <PaymentMethodFilter onChangeFilter={row.onChange} />,
    id: 'paymentMethodId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Cantidad de Encomiendas',
    accessor: 'cargoItemSize',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Monto Total',
    accessor: 'salePrice',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
  },
  {
    Header: 'Codigo de Descuento',
    accessor: 'discountCodeString',
    className: 'text-center',
  },
  {
    Header: 'Estado de Pago',
    accessor: 'paymentStatus',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={PARCEL_PAYMENT_STATUS}
      />
    ),
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_PAYMENT_STATUS} enumValue={row.value} />
    ),
  },
];

export const INTERNAL_PARCELS_COLUMNS = [
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
    bsSize: 'lg',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Origen',
    accessor: 'sourceCityName',
    className: 'text-center',
    Filter: (row) => <CityFilter onChangeFilter={row.onChange} />,
    id: 'sourceCityId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Destino',
    accessor: 'destinationCityName',
    className: 'text-center',
    Filter: (row) => <CityFilter onChangeFilter={row.onChange} />,
    id: 'destinationCityId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Remitente',
    accessor: 'senderCustomerFullName',
    id: 'senderCustomer.idDocumentNumber',
    className: 'text-center',
  },
  {
    Header: 'Consignado',
    accessor: 'consigneeCustomerFullName',
    id: 'consigneeCustomer.idDocumentNumber',
    className: 'text-center',
  },
];

export const CARGO_ITEMS_COLUMNS = [
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Producto Frecuente',
    accessor: 'commonProductName',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Categoría',
    accessor: 'cargoCategoryName',
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
    Cell: (row) => <CargoItemStatusCell value={row.value} />,
    Filter: (row) => <CargoItemStatusFilter onChangeFilter={row.onChange} />,
  },
  {
    Header: 'Monto total',
    accessor: 'salePrice',
    className: 'text-center',
    Cell: (row) => `${PEN_SYMBOL} ${row.value.toFixed(2)}`,
  },
];

export const CARGO_ITEMS_COLUMNS_TABLE = [
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    id: 'commonProductId',
    Header: 'Producto Frecuente',
    accessor: 'commonProductName',
    className: 'text-center',
    Filter: (row) => <CommonProductFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    id: 'cargoCategoryId',
    Header: 'Categoría',
    accessor: 'cargoCategoryName',
    className: 'text-center',
    Filter: (row) => <CargoCategoryFilter onChangeFilter={row.onChange} />,
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
    Cell: (row) => <CargoItemStatusCell value={row.value} />,
    Filter: (row) => <CargoItemStatusFilter onChangeFilter={row.onChange} />,
  },
  {
    Header: 'Perecible',
    accessor: 'perishable',
    className: 'text-center',
    id: 'isPerishable',
    Cell: (row) => <Checkbox checked={row.value} />,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={CARGO_ITEM_PERISHABLE_OPTIONS}
      />
    ),
  },
  {
    Header: 'Ubicación',
    accessor: 'locationName',
    className: 'text-center',
    id: 'locationId',
    Filter: (row) => (
      <LocationFilter onChangeFilter={row.onChange} isClearable />
    ),
  },
  {
    Header: 'Estado de Pago',
    accessor: 'parcelPaymentStatusType',
    className: 'text-center',
    id: 'parcel.paymentStatus',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_PAYMENT_STATUS} enumValue={row.value} />
    ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={PARCEL_PAYMENT_STATUS}
      />
    ),
  },
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethod.name',
    className: 'text-center',
    id: 'parcel.paymentMethodId',
    Filter: (row) => (
      <PaymentMethodFilter onChangeFilter={row.onChange} isClearable />
    ),
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Precio de Venta',
    accessor: 'salePrice',
    className: 'text-center',
    Cell: (row) => `${PEN_SYMBOL} ${row.value.toFixed(2)}`,
  },
];

export const CARGO_MINIMAL_PRICE_COLUMNS = [
  {
    Header: 'Precio Mínimo',
    accessor: 'minimalPrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
];

export const ITINERARY_SEARCH_OMISSION_COLUMNS = [
  {
    Header: 'Ubicación Origen Id',
    accessor: 'sourceLocationId',
    className: 'text-center',
  },
  {
    Header: 'Ubicación Origen',
    accessor: 'sourceLocation.name',
    className: 'text-center',
  },
  {
    Header: 'Ubicación Destino Id',
    accessor: 'destinationLocationId',
    className: 'text-center',
  },
  {
    Header: 'Ubicación Destino',
    accessor: 'destinationLocation.name',
    className: 'text-center',
  },
];

export const ITINERARY_SCHEDULE_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'itineraryCode',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Inicio',
    accessor: 'startDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Fecha de Fin',
    accessor: 'endDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Maciva',
    accessor: 'isMaciva',
    className: 'text-center',
    Cell: (row) => <Checkbox checked={row.value} />,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={ITINERARY_SCHEDULE_MACIVA_OPTIONS}
      />
    ),
  },
];

export const COMPANY_ITINERARY_SCHEDULE_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Régimen',
    accessor: 'shift',
    className: 'text-center',
  },
  {
    Header: 'Tipo',
    accessor: 'staffType',
    Cell: (row) => COMPANY_STAFF_TYPE[row.value].label,
    className: 'text-center',
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(COMPANY_STAFF_TYPE)}
      />
    ),
  },
  {
    Header: 'Fecha de Inicio',
    accessor: 'startDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Fecha de Fin',
    accessor: 'endDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
];

export const AGENCY_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Contrato',
    accessor: 'contractType',
    Cell: (row) => (row.value ? CONTRACT_TYPE[row.value].label : '-'),
    Filter: (row) => (
      <AgencyContractTypeSelect onChangeFilter={row.onChange} useForFilter />
    ),
    className: 'text-center',
  },
  {
    Header: 'Ubicación',
    accessor: 'location.name',
    className: 'text-center',
  },
  {
    Header: 'Número de Usuarios',
    accessor: 'userList',
    Cell: (row) => (row.value ? row.value.length : null),
    className: 'text-center',
    filterable: false,
  },
];

export const PRINT_STATION_COLUMNS = [
  {
    Header: 'Número de Serie',
    accessor: 'serialNumber',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const WORKSTATION_COLUMNS = [
  {
    Header: 'Número de Serie',
    accessor: 'serialNumber',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const PRICING_PROFILE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Perfil de Precio',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Precio Base',
    accessor: 'basePrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Mapa de Asiento',
    accessor: 'seatMap',
    id: 'seatMapId',
    Cell: (row) => (row.value ? row.value.name : null),
    className: 'text-center',
    Filter: (row) => (
      <SeatMapSelect onChangeFilter={row.onChange} useForFilter />
    ),
    dataType: ColumnFilterDataTypes.ARRAY,
  },
];

export const LIQUIDATION_SESSIONS_COLUMNS = [
  {
    Header: 'Agencia',
    accessor: 'agency.name',
    className: 'text-center',
    filterable: true,
    Filter: (row) => <AgencyFilter onChangeFilter={row.onChange} />,
    id: 'agencyId',
    dataType: ColumnFilterDataTypes.ARRAY,
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Aprobado',
    accessor: 'isSettled',
    className: 'text-center',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    filterable: false,
  },
  {
    Header: 'Depositado',
    accessor: 'depositId',
    className: 'text-center',
    Cell: (row) => <Checkbox checked={!!row.value} />,
    filterable: false,
  },
  {
    Header: 'Notas',
    accessor: 'dailyLiquidationSessionNoteList',
    Cell: (row) => row.value.length,
    className: 'text-center',
    filterable: false,
  },
];

export const COMPANY_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Empresa Relacionada',
    accessor: 'business.name',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Empresa Tercera',
    accessor: 'companyType',
    Cell: (row) => COMPANY_TYPE[row.value].label,
    filterable: false,
    className: 'text-center',
  },
];

export const COMPANY_CREDIT_NOTE_COLUMNS = [
  {
    Header: 'Nombre Cliente',
    accessor: 'customer.firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellido Cliente',
    accessor: 'customer.lastName',
    className: 'text-center',
  },
  {
    Header: 'Documento de Identidad',
    accessor: 'customer.idDocumentNumber',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: (row) => row.value && numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Fecha de expiración',
    accessor: 'expirationDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
];

export const AGENCY_GROUP_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const PRICING_PROFILE_REPORT_COLUMNS = [
  {
    Header: 'Fecha de Inicio',
    accessor: 'fromDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Fin',
    accessor: 'toDate',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Última Actualización',
    accessor: 'lastUpdate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Estado',
    accessor: 'reportStatus',
    Cell: (row) => <ReportStatusCell value={row.value} />,
  },
];

export const AUTHORIZED_PROFILES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Perfil',
    accessor: 'profile.name',
    className: 'text-center',
  },
  {
    Header: 'Perfil Autorizado',
    accessor: 'authorizedSecurityProfile.name',
    className: 'text-center',
  },
];

export const COMPANY_USER_CONFIGURATION_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Usuario',
    accessor: 'user.customer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Usuario Padre',
    accessor: 'parentUser.customer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Empresa Tercera',
    accessor: 'company.name',
    className: 'text-center',
  },
];

export const GENERAL_INCOME_COLUMNS = [
  {
    Header: 'Fecha',
    accessor: 'createDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Pagador',
    accessor: 'payerCustomer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Beneficiario',
    accessor: 'payeeUser.customer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Ingreso',
    accessor: 'transaction.generalIncomeType.name',
    className: 'text-center',
  },
  {
    Header: 'Total',
    accessor: 'total',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
  },
];

export const FUEL_STATIONS_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'location.name',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'location.description',
    className: 'text-center',
  },
  {
    Header: 'Empresa',
    accessor: 'business.name',
    className: 'text-center',
  },
];

export const EVENTS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'URI',
    accessor: 'uri',
    className: 'text-center',
  },
  {
    Header: 'Método',
    accessor: 'method',
    className: 'text-center',
  },
  {
    Header: 'Usuario',
    accessor: 'user.customer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Inicio',
    accessor: 'startTime',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Duración',
    accessor: 'duration',
    className: 'text-center',
  },
];

export const IN_SYSTEM_NOTIFICATIONS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Mensaje',
    accessor: 'message',
    className: 'text-center',
  },
  {
    Header: 'Gravedad',
    accessor: 'notificationSeverity',
    className: 'text-center',
    Cell: (row) => <Badge color={row.value.toLowerCase()} text={row.value} />,
  },
  {
    Header: 'Valido Desde',
    accessor: 'validFrom',
    className: 'text-center',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Valido Hasta',
    accessor: 'validUntil',
    className: 'text-center',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Usuario',
    accessor: 'userId',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'active',
    className: 'text-center',
    Cell: (row) => <IsActive value={row.value} />,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(IN_SYSTEM_NOTIFICATION_SEVERITY)}
      />
    ),
  },
  {
    Header: 'Fecha de creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    filterable: false,
  },
];

export const EXPENSE_STATUS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
  },
  {
    Header: 'Agencia',
    accessor: 'transactionList',
    id: 'transaction.salesSession.agencyId',
    Cell: (row) =>
      row.value[0].salesSession ? row.value[0].salesSession.agency.name : null,
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Beneficiario',
    accessor: 'payeeCustomer.fullName',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
    width: 350,
  },
  {
    Header: 'Total',
    accessor: 'transaction.amount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Tipo de egreso',
    accessor: 'transactionList',
    id: 'transaction.expenseTypeId',
    Cell: (row) => {
      if (!row.value) return null;
      const expenseTypeList = row.value.map(({ expenseType }) => ({
        text: expenseType.name,
      }));
      return <BadgeList cell textArray={expenseTypeList} />;
    },
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Centro de Costo',
    accessor: 'costCenter.name',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Tipo de comprobante',
    accessor: 'transactionList',
    id: 'transaction.voucherType.name',
    Cell: (row) => {
      if (!row.value) return null;
      const voucherTypeList = [];
      row.value.forEach(({ voucherType }) => {
        if (voucherType) voucherTypeList.push({ text: voucherType.name });
      });
      return <BadgeList cell textArray={voucherTypeList} />;
    },
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Razón Social',
    accessor: 'transactionList',
    id: 'transaction.business.name',
    Cell: (row) => (row.value[0].business ? row.value[0].business.name : null),
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    Cell: (row) => padStart(row.value, 7),
    className: 'text-center',
  },
  {
    Header: 'RUC',
    accessor: 'transactionList',
    id: 'transaction.business.businessTaxId',
    Cell: (row) =>
      row.value[0].business ? row.value[0].business.businessTaxId : null,
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Estado',
    accessor: 'expenseStatus',
    Cell: (row) => EXPENSE_STATUS[row.value].label,
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Autorizador',
    accessor: 'authorizerUser.customer.fullName',
    className: 'text-center',
    width: 200,
  },
];

export const ITINERARY_GROUPS_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Circuito',
    accessor: 'circuitId',
    className: 'text-center',
  },
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => ITINERARY_GROUP_STATUS[row.value].label,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(ITINERARY_GROUP_STATUS)}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'Itinerarios asociados',
    accessor: 'circuitGroupItineraryList',
    Cell: (row) => row.value.length,
    className: 'text-center',
  },
];

export const BASE_FUEL_REFILL_COLUMNS = [
  {
    Header: 'ID de Itinerario',
    accessor: 'itineraryId',
    Cell: (row) => {
      let itineraryId = row.value;

      if (row.original.duplicated)
        itineraryId = <Badge color="danger" text={row.value} />;

      return itineraryId;
    },
    className: 'text-center',
  },
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    id: 'registeredBusId',
    Filter: (row) => <RegisteredBusFilter onChangeFilter={row.onChange} />,
    className: 'text-center',
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    Cell: (row) => tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
    className: 'text-center',
  },
  {
    Header: 'Combustible Abastecido',
    accessor: 'fuelQuantity',
    Cell: (row) =>
      `${numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 2,
      })} ${MATERIAL_MEASUREMENT_UNIT[1]}`,
    className: 'text-center',
  },
  {
    Header: 'AdBlue Abastecido',
    accessor: 'adblueQuantity',
    Cell: (row) =>
      `${numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 2,
      })} ${MATERIAL_MEASUREMENT_UNIT[1]}`,
    className: 'text-center',
  },
];

export const ITINERARY_SALES_CONTROL_COLUMNS = [
  {
    className: 'text-center',
    Expander: (expander) => {
      if (expander.original.soldSeats === 0) return null;

      if (expander.isExpanded) return <i className="fa fa-caret-up" />;

      return <i className="fa fa-caret-down" />;
    },
    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original.soldSeats === 0) {
        return {
          onClick: () => {},
        };
      }
      return {
        className: 'show-pointer',
      };
    },
    expander: true,
  },
  {
    Header: 'Fecha de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    width: 150,
  },
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
    Cell: (row) => (
      <Link to={`${ITINERARY_PATH}/${row.value}`} target="_blank">
        {row.value}
      </Link>
    ),
    width: 100,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    className: 'text-center',
    width: 280,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    className: 'text-center',
    width: 280,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Hora de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    width: 130,
  },
  {
    Header: 'Capacidad de Asiento',
    accessor: 'numberOfSeats',
    className: 'text-center',
    width: 175,
  },
  {
    Header: 'Asientos Vendidos',
    accessor: 'soldSeats',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Ocupabilidad',
    accessor: 'occupancy',
    className: 'text-center',
    Cell: (row) => `${row.value}%`,
    width: 110,
  },
  {
    Header: 'Precio Inicial',
    columns: [
      {
        Header: 'Precio I. 1',
        accessor: 'prices',
        className: 'text-center',
        width: 120,
        Cell: (row) => row.value && numberFormatter({ value: row.value[0] }),
        filterable: false,
      },
      {
        Header: 'Precio I. 2',
        accessor: 'prices',
        className: 'text-center',
        width: 120,
        Cell: (row) => row.value && numberFormatter({ value: row.value[1] }),
        filterable: false,
      },
    ],
  },
  {
    Header: 'Precio Final',
    columns: [
      {
        Header: 'Precio F. 1',
        accessor: 'prices',
        className: 'text-center',
        width: 120,
        Cell: (row) => row.value && numberFormatter({ value: row.value[2] }),
        filterable: false,
      },
      {
        Header: 'Precio F. 2',
        accessor: 'prices',
        className: 'text-center',
        width: 120,
        Cell: (row) => row.value && numberFormatter({ value: row.value[3] }),
        filterable: false,
      },
    ],
  },
  {
    Header: 'Rentabilidad',
    accessor: 'profitability',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 110,
  },
  {
    Header: 'Rentabilidad Total',
    accessor: 'totalProfitability',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 150,
  },
  {
    Header: 'Programado',
    accessor: 'scheduled',
    Cell: (row) => <Checkbox checked={row.value} />,
    className: 'text-center',
    width: 110,
  },
  {
    Header: 'Estado',
    accessor: 'active',
    Cell: (row) => <IsActive value={row.value} />,
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Tipo de Itinerario',
    accessor: 'type',
    className: 'text-center',
    width: 140,
  },
  {
    Header: 'Ingreso Pasaje',
    accessor: 'ticketIncome',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 140,
  },
  {
    Header: 'Ingreso Carga',
    accessor: 'loadIncome',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 140,
  },
  {
    Header: 'Ingreso Equipajes',
    accessor: 'excessBaggageIncome',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 150,
  },
  {
    Header: 'Costo de Operación',
    accessor: 'operationalCost',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 160,
  },
  {
    Header: 'Kilometraje',
    accessor: 'kilometers',
    className: 'text-center',
    Cell: (row) => `${row.value} ${KILOMETER_SYMBOL}`,
    width: 140,
  },
];

export const ITINERARY_SALES_CONTROL_DETAIL_COLUMNS = [
  {
    Header: 'Fecha de Compra',
    accessor: 'documentAssignmentDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
    width: 150,
  },
  {
    Header: 'Hora de Compra',
    accessor: 'documentAssignmentDate',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    width: 130,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocation',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocation',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Tipo de Comprobante',
    accessor: 'voucherType',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Asiento',
    accessor: 'seatNumber',
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Número de Boleto',
    accessor: 'ticketCode',
    className: 'text-center',
    width: 160,
  },
  {
    Header: 'Nombre de Pasajero',
    accessor: 'customerName',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Teléfono',
    accessor: 'customerPhoneNumber',
    className: 'text-center',
    width: 140,
  },
  {
    Header: 'Email',
    accessor: 'customerMail',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Monto',
    accessor: 'salePrice',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value }),
    width: 80,
  },
  {
    Header: 'Embarque',
    accessor: 'embarking',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Desembarque',
    accessor: 'disembarking',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Agencia de Venta',
    accessor: 'sellingAgency',
    className: 'text-center',
    // width: 280,
  },
  {
    Header: 'Tipo de Pago',
    accessor: 'paymentType',
    className: 'text-center',
    // width: 160,
  },
];

export const SEGMENT_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocation.name',
    className: 'text-center',
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocation.name',
    className: 'text-center',
  },
  {
    Header: 'Distancia',
    accessor: 'distance',
    className: 'text-center',
    Cell: (row) => `${row.value} ${KILOMETER_SYMBOL}`,
  },
  {
    Header: 'Duración',
    accessor: 'duration',
    className: 'text-center',
    Cell: (row) => `${row.value} ${MINUTE_SYMBOL}`,
  },
];

export const DRIVER_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'customer.firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellido',
    accessor: 'customer.lastName',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Documento',
    accessor: 'customer.identificationType.name',
    Cell: (row) => row.value || null,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Número de Documento',
    accessor: 'customer.idDocumentNumber',
    Cell: (row) => row.value || null,
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'crewStatus.id',
    Cell: (row) => <DriverStatusCell value={row.value} />,
    className: 'text-center',
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(DRIVER_STATUS_OPTION)}
      />
    ),
  },
];

export const DRIVER_TRIPS_COLUMNS = [
  {
    Header: 'Fecha',
    accessor: 'departureTime',
    Cell: (row) =>
      tzNormalizeDate({ date: row.value, format: DATE_TIME_FORMAT }),
    className: 'text-center',
  },
  {
    Header: 'Número de Bus',
    accessor: 'companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Ruta',
    accessor: 'route.name',
    className: 'text-center',
  },
  {
    Header: 'KM',
    accessor: 'route.routeSegmentList',
    Cell: (row) => {
      const km = row.value.reduce(
        (accumulated, currentValue) =>
          accumulated + currentValue.segment.distance,
        0,
      );

      return km.toFixed(2);
    },
    className: 'text-center',
  },
  {
    Header: 'Horas',
    accessor: 'route.routeSegmentList',
    Cell: (row) =>
      row.value.reduce(
        (accumulated, currentValue) =>
          accumulated + currentValue.segment.duration,
        0,
      ),
    className: 'text-center',
  },
  {
    Header: 'Rendimiento',
    accessor: 'city.region.country.name',
    className: 'text-center',
  },
  {
    Header: 'Condición de Trabajo',
    accessor: 'city.region.country.name',
    className: 'text-center',
  },
];

export const PRIVATE_SERVICE_COLUMNS = [
  {
    Header: 'N° de Documento',
    accessor: 'business.businessTaxId',
    Cell: (row) => {
      const {
        original: { customer, business },
      } = row;
      const data = customer
        ? `${customer.identificationType.name}: ${customer.idDocumentNumber}`
        : `RUC: ${business.businessTaxId}`;

      return data;
    },
    className: 'text-center',
    width: 170,
  },
  {
    Header: 'Razón Social/Nombre',
    accessor: 'business.name',
    Cell: (row) => {
      const {
        original: { customer, business },
      } = row;
      const data = customer ? customer.fullName : business.name;

      return data;
    },
    className: 'text-center',
    filterable: false,
    width: 230,
  },
  {
    Header: 'Número de Contrato',
    accessor: 'contractNumber',
    className: 'text-center',
    width: 170,
  },
  {
    Header: 'Unidades',
    accessor: 'numberOfBusses',
    className: 'text-center',
    width: 60,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Origen',
    accessor: 'source',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Destino',
    accessor: 'destination',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Fecha de Contrato',
    accessor: 'createDate',
    Cell: (row) => tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 140,
  },
  {
    Header: 'Estado',
    accessor: 'contractStatus',
    Cell: (row) => (
      <EnumStatus enumObject={CONTRACT_STATUS} enumValue={row.value} />
    ),
    className: 'text-center',
    filterable: false,
    width: 100,
  },
  {
    Header: 'Monto',
    accessor: 'totalAmount',
    Cell: (row) => row.value && numberFormatter({ value: row.value }),
    className: 'text-right',
    width: 130,
  },
  {
    Header: 'Nombre del Ejecutivo',
    accessor: 'executiveName',
    className: 'text-center',
    width: 210,
  },
  {
    Header: 'Telf. del Ejecutivo',
    accessor: 'executivePhone',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const CREDIT_NOTE_COLUMNS = [
  {
    Header: 'Estado',
    accessor: 'isValid',
    className: 'text-center',
    Cell: (row) => <IsActive value={row.value} />,
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(CREDIT_NOTE_STATUS_OPTIONS)}
      />
    ),
    width: 170,
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
    width: 140,
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Cliente',
    accessor: 'customerId',
    className: 'text-center',
    Cell: (row) =>
      `${row.original.customer.idDocumentNumber} (${row.original.customer.idCountryOfOrigin}) - ${row.original.customer.fullName}`,
    Filter: (row) => <CustomerFilter onChangeFilter={row.onChange} />,
    width: 520,
  },
  {
    Header: 'Empresa',
    accessor: 'businessId',
    className: 'text-center',
    Cell: (row) =>
      row.original.business
        ? `${row.original.business.businessTaxId} - ${row.original.business.name}`
        : '',
    Filter: (row) => <BusinessFilter onChangeFilter={row.onChange} />,
    width: 400,
  },
  {
    Header: 'Referencia',
    accessor: 'reference',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    className: 'text-center',
    Cell: (row) => row.value && numberFormatter({ value: row.value }),
    width: 180,
  },
  {
    Header: 'Fecha de Expiración',
    accessor: 'expirationDate',
    className: 'text-center',
    Cell: (row) => tzNormalizeDate({ date: row.value, format: DATE_FORMAT }),
    width: 200,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const ITINERARY_STATUS_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
    width: 60,
  },
  {
    Header: 'Ruta',
    accessor: 'routeName',
    className: 'text-center',
    width: 450,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Número de Bus',
    accessor: 'companyBusId',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: (row) => <Checkbox checked={row.value || false} />,
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Salida',
    accessor: 'departuretime',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Boletos Vendidos',
    accessor: 'ticketsSold',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Tipo de Itinerario',
    accessor: 'type',
    Cell: (row) => ITINERARY_TYPE[row.value.toLowerCase()].label,
    className: 'text-center',
    width: 220,
  },
];

export const generateManualVoucherColumns = (voucherTypeCleanupOnUnmount) => [
  {
    Header: 'N° de Documento',
    accessor: 'business.businessTaxId',
    Cell: (row) => {
      const {
        original: { customer, business },
      } = row;
      const data = customer
        ? `${customer.identificationType.name}: ${customer.idDocumentNumber}`
        : `RUC: ${business.businessTaxId}`;

      return data;
    },
    className: 'text-center',
    width: 250,
  },
  {
    Header: 'Razón Social/Nombre',
    accessor: 'business.name',
    Cell: (row) => {
      const {
        original: { customer, business },
      } = row;
      const data = customer ? customer.fullName : business.name;

      return data;
    },
    className: 'text-center',
    filterable: false,
    width: 350,
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
    width: 130,
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    Cell: (row) => padStart(row.value, 7),
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Tipo de Comprobante',
    accessor: 'voucherType.name',
    id: 'voucherTypeId',
    Filter: (row) => (
      <VoucherTypeFilter
        onChangeFilter={row.onChange}
        cleanupOnUnmount={voucherTypeCleanupOnUnmount}
      />
    ),
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    width: 300,
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    Cell: (row) => MANUAL_VOUCHER_SERVICE_TYPE[row.value].label,
    className: 'text-center',
    filterable: false,
    width: 150,
  },
  {
    Header: 'Fecha de Expedición',
    accessor: 'issueDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Tipo de Pago',
    accessor: 'paymentType',
    Cell: (row) => MANUAL_VOUCHER_PAYMENT_TYPE[row.value].label,
    className: 'text-center',
    filterable: false,
    width: 160,
  },
  {
    Header: 'Cuotas',
    accessor: 'installments',
    Cell: (row) => (row.value || row.value > 0 ? row.value : '-'),
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Fecha de Expiración',
    accessor: 'dueDate',
    Cell: (row) => <DateCell value={row.value} />,
    dataType: ColumnFilterDataTypes.DATE,
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Total Gravada',
    accessor: 'totalAmountWithoutTaxes',
    Cell: (row) =>
      numberFormatter({
        currency: row.original.currencyCode,
        value: row.value,
      }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'Total IGV 18%',
    accessor: 'taxes',
    Cell: (row) =>
      numberFormatter({
        currency: row.original.currencyCode,
        value: row.value,
      }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'Importe Total',
    accessor: 'totalAmount',
    Cell: (row) =>
      numberFormatter({
        currency: row.original.currencyCode,
        value: row.value,
      }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'Detracción %',
    accessor: 'detraction',
    Cell: (row) => numberFormatter({ value: row.value, style: 'percent' }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'Monto Detracción',
    accessor: 'detractionAmount',
    Cell: (row) =>
      numberFormatter({
        currency: row.original.currencyCode,
        value: row.value,
      }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'Total a Pagar',
    accessor: 'totalAmountPayable',
    Cell: (row) =>
      numberFormatter({
        currency: row.original.currencyCode,
        value: row.value,
      }),
    className: 'text-right',
    width: 180,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const ORGANIZATION_CHART_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Tipo',
    accessor: 'companyAreaType.name',
    Filter: (row) => <HierarchyFilter onChangeFilter={row.onChange} />,
    id: 'companyAreaTypeId',
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'Organigrama Padre',
    accessor: 'parentCompanyArea.name',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const MECHANIC_COLUMNS = [
  {
    Header: 'Tipo de Documento',
    accessor: 'customer.identificationType.name',
    className: 'text-center',
    minWidth: 150,
  },
  {
    Header: 'Número de Documento',
    accessor: 'customer.idDocumentNumber',
    className: 'text-center',
    minWidth: 150,
  },
  {
    Header: 'Nombres',
    accessor: 'customer.firstName',
    className: 'text-center',
    minWidth: 200,
  },
  {
    Header: 'Apellidos',
    accessor: 'customer.lastName',
    className: 'text-center',
    minWidth: 200,
  },
  {
    Header: 'Compañia',
    accessor: 'company.name',
    className: 'text-center',
    filterable: false,
    minWidth: 150,
  },
  {
    Header: 'Especialidad',
    accessor: 'speciality.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'Sección',
    accessor: 'section.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'Área',
    accessor: 'area.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'Departamento',
    accessor: 'department.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'Gerencia',
    accessor: 'management.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 220,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    minWidth: 100,
  },
];

export const ACTIVITY_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'companyActivityId',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Acción',
    accessor: 'action',
    Cell: (row) => (row.value ? ACTIVITY_ACTION[row.value].label : '-'),
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(ACTIVITY_ACTION)}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'Nombre de la Actividad',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Mantenimiento',
    accessor: 'activityType',
    Cell: (row) =>
      row.value ? MAINTENANCE_ACTIVITY_TYPE[row.value].label : '-',
    Filter: (row) => (
      <SelectFilter
        onChangeFilter={row.onChange}
        options={enumToSelectOptions(MAINTENANCE_ACTIVITY_TYPE)}
      />
    ),
    className: 'text-center',
  },
];

export const BUS_ACTIVITY_ASSIGNMENT_COLUMNS = [
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    id: 'registeredBusId',
    Filter: (row) => (
      <RegisteredBusFilter onChangeFilter={row.onChange} isMulti />
    ),
    dataType: ColumnFilterDataTypes.ARRAY,
    className: 'text-center',
    minWidth: 160,
  },
  {
    Header: 'Actividad',
    accessor: 'activityName',
    id: 'activity.name',
    className: 'text-center',
    minWidth: 200,
  },
  {
    Header: 'Código de Actividad',
    accessor: 'companyActivityId',
    id: 'activity.companyActivityId',
    className: 'text-center',
    width: 180,
  },
];

export const MAINTENANCE_ACTIVITY_HISTORY_COLUMNS = [
  {
    Header: 'Bus/Recurso',
    accessor: 'companyBusIdOrResourceName',
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Código Actividad',
    accessor: 'companyActivityId',
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Actividad',
    accessor: 'activityName',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Factor',
    accessor: 'factor',
    Cell: (row) =>
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    className: 'text-center',
    minWidth: 120,
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => (
      <EnumStatus enumObject={ACTIVITY_STATUS} enumValue={row.value} />
    ),
    className: 'text-center',
    minWidth: 120,
  },
  {
    Header: 'Personal',
    accessor: 'employeeList',
    Cell: (row) => {
      if (!row.value) return null;
      const employeeList = row.value.map((employee) => ({
        text: employee.fullName,
      }));
      return <BadgeList cell textArray={employeeList} />;
    },
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Fecha/Hora Inicio',
    accessor: 'startDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Fecha/Hora Final',
    accessor: 'endDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Horas Trabajadas',
    accessor: 'workingHours',
    Cell: (row) => row.value || '0',
    className: 'text-center',
    minWidth: 180,
  },
];

export const MATERIAL_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'externalId',
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Unidad de Medida',
    accessor: 'measurementUnit',
    className: 'text-center',
    minWidth: 180,
  },
];

export const generateKidOnItineraryColumns = (onClickRemoveKidOnItinerary) => [
  {
    Header: '',
    className: 'text-center',
    width: 50,
    Cell: (row) => {
      const {
        original: { id, itineraryId },
      } = row;

      return (
        <div className="actionsContainer">
          <i
            tabIndex="0"
            role="button"
            className="fa fa-times text-danger"
            onKeyPress={() => onClickRemoveKidOnItinerary(id, itineraryId)}
            onClick={() => onClickRemoveKidOnItinerary(id, itineraryId)}
          />
        </div>
      );
    },
    filterable: false,
  },
  {
    Header: 'Doc. Ident. Pasajero',
    accessor: 'parentCustomer.idDocumentNumber',
    Cell: (row) => (
      <div>
        {row.original.parentCustomer.identificationType
          ? row.original.parentCustomer.identificationType.name
          : '-'}
        : {row.value} ({row.original.parentCustomer.idCountryOfOrigin})
      </div>
    ),
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Nombre Completo Pasajero',
    accessor: 'parentCustomer.fullName',
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Doc. Ident. Menor de Edad',
    accessor: 'kidCustomer.idDocumentNumber',
    Cell: (row) => (
      <i>
        {row.original.kidCustomer.identificationType
          ? row.original.kidCustomer.identificationType.name
          : '-'}
        : {row.value} ({row.original.kidCustomer.idCountryOfOrigin})
      </i>
    ),
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Nombre Completo Menor de Edad',
    accessor: 'kidCustomer.fullName',
    Cell: (row) => <i>{row.value}</i>,
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Relación con el Menor',
    accessor: 'relationship',
    Cell: (row) => (row.value ? RELATIONSHIP[row.value].label : '-'),
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Asiento',
    accessor: 'seatNumber',
    className: 'text-center',
    minWidth: 80,
  },
  {
    Header: 'Piso',
    accessor: 'floorNumber',
    className: 'text-center',
    minWidth: 60,
  },
  {
    Header: 'Observaciones',
    accessor: 'comments',
    className: 'text-center',
    minWidth: 250,
  },
];

export const ACTIVITY_PROJECTION_COLUMNS = [
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Area',
    accessor: 'areaName',
    className: 'text-center',
    Filter: (row) => (
      <OrganizationChartSelect
        useForFilter
        labelAsValue
        onChangeFilter={row.onChange}
      />
    ),
  },
  {
    Header: 'Código Actividad',
    accessor: 'companyActivityId',
    className: 'text-center',
  },
  {
    Header: 'Actividad',
    accessor: 'activityDescription',
    className: 'text-center',
  },
  {
    Header: 'Factor Actividad',
    accessor: 'factor',
    Cell: (row) =>
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    className: 'text-center',
  },
  {
    Header: 'Fecha de Ultima Ejecución',
    accessor: 'executionDate',
    className: 'text-center',
  },
  {
    Header: 'Tipo Factor',
    accessor: 'factorType',
    Cell: (row) => MAINTENANCE_FACTOR_TYPE[row.value].label,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Km. Recorrido/Días Transcurridos',
    accessor: 'factorQuantity',
    Cell: (row) =>
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    className: 'text-center',
  },
  {
    Header: 'Factor Restante',
    accessor: 'factorQuantity',
    Cell: (row) => {
      const { status } = row.original;
      // show for DONE
      let value = row.value - row.original.factor;
      // show for EXTENDED
      if (status === ACTIVITY_STATUS.EXTENDED.value)
        value = row.original.lastExtensionFactor - row.value;
      return numberFormatter({
        style: 'decimal',
        value,
        minimumFractionDigits: 0,
      });
    },
    className: 'text-center',
  },
  {
    Header: 'Estado de Alerta',
    accessor: 'activityStatus',
    Cell: (row) => (
      <EnumStatus
        enumObject={MAINTENANCE_WARNING_STATUS_ACTIVITY}
        enumValue={row.value}
      />
    ),
    className: 'text-center',
  },
];

export const BUSINESS_COLUMNS = [
  {
    Header: 'RUC',
    accessor: 'businessTaxId',
    className: 'text-center',
    minWidth: 150,
  },
  {
    Header: 'Razón Social',
    accessor: 'name',
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Fecha Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} />,
    className: 'text-center',
    minWidth: 120,
  },
  {
    Header: 'Contacto',
    accessor: 'primaryContact',
    className: 'text-center',
    minWidth: 200,
  },
  {
    Header: 'Teléfono',
    accessor: 'phone',
    className: 'text-center',
    minWidth: 120,
  },
  {
    Header: 'Email',
    accessor: 'email',
    className: 'text-center',
    minWidth: 200,
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const generateExecutionRegistrationColumns = ({
  handleConfirm,
  handleClear,
}) => [
  {
    Header: '',
    className: 'text-center',
    Cell: (row) => (
      <ActionsCell
        handleConfirm={handleConfirm}
        handleClear={handleClear}
        data={row.original}
        id={row.index}
        showClearButton={!!row.original.mechanics}
      />
    ),
    width: 100,
    filterable: false,
  },
  {
    Header: 'Area',
    accessor: 'areaName',
    className: 'text-center',
    Filter: (row) => (
      <OrganizationChartSelect
        useForFilter
        labelAsValue
        onChangeFilter={row.onChange}
      />
    ),
    minWidth: 220,
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => (
      <EnumStatus enumObject={ACTIVITY_STATUS} enumValue={row.value} />
    ),
    className: 'text-center',
    minWidth: 100,
    filterable: false,
  },
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    className: 'text-center',
    minWidth: 70,
  },
  {
    Header: 'Código Actividad',
    accessor: 'companyActivityId',
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Actividad',
    accessor: 'activityName',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Factor',
    accessor: 'factor',
    Cell: (row) =>
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    className: 'text-center',
    minWidth: 120,
    filterable: false,
  },
  {
    Header: 'Último Mantenimiento',
    accessor: 'lastExecutionDate',
    className: 'text-center',
    minWidth: 180,
    filterable: false,
  },
  {
    Header: 'KM a Agregar',
    accessor: 'extensionFactor',
    Cell: (row) =>
      row.value &&
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    className: 'text-center',
    minWidth: 120,
    filterable: false,
  },
  {
    Header: 'Personal',
    accessor: 'mechanics',
    Cell: (row) => {
      if (!row.value) return null;
      const mechanicList = row.value.map(({ label }) => ({ text: label }));
      return <BadgeList cell textArray={mechanicList} />;
    },
    className: 'text-center',
    minWidth: 320,
    filterable: false,
  },
  {
    Header: 'Observaciones',
    accessor: 'comment',
    className: 'text-center',
    minWidth: 180,
    filterable: false,
  },
  {
    Header: 'Fecha/Hora Inicio',
    accessor: 'startDate',
    Cell: (row) =>
      row.value && <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
    minWidth: 180,
    filterable: false,
  },
  {
    Header: 'Fecha/Hora Final',
    accessor: 'endDate',
    Cell: (row) =>
      row.value && <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
    minWidth: 180,
    filterable: false,
  },
  {
    Header: 'Hrs. Trabajadas',
    accessor: 'workingHour',
    className: 'text-center',
    minWidth: 150,
    filterable: false,
  },
  {
    Header: 'Base',
    accessor: 'busHomeBaseName',
    className: 'text-center',
    minWidth: 150,
    filterable: false,
  },
];

export const generateItinerariesForSchedulingTableColumns = ({
  handleConfirm,
  handleClear,
}) => [
  {
    Header: '',
    className: 'text-center',
    Cell: (row) => (
      <ActionsCell
        handleConfirm={handleConfirm}
        handleClear={handleClear}
        data={row.original}
        id={row.index}
        showClearButton={row.original.highligth}
        showConfirmButton={!row.original.tripId && row.original.active}
      />
    ),
    width: 100,
    filterable: false,
  },
  {
    Header: 'Fecha',
    accessor: 'departuretime',
    Cell: (row) => <DateCell value={row.value} format={DATE_FORMAT} />,
    className: 'text-center',
    minWidth: 120,
    filterable: false,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Hora',
    accessor: 'departuretime',
    Cell: (row) => <DateCell value={row.value} format={TIME_FORMAT} />,
    className: 'text-center',
    minWidth: 80,
    filterable: false,
  },
  {
    Header: 'Tipo Servicio',
    accessor: 'serviceType',
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    Cell: (row) => (row.value ? row.value.label : '-'),
    className: 'text-center',
    minWidth: 100,
    filterable: false,
  },
  {
    Header: 'Orden en Circuito',
    accessor: 'tripCircuitStatus',
    Cell: (row) => (row.value ? row.value.label : '-'),
    className: 'text-center',
    minWidth: 150,
    filterable: false,
  },
  {
    Header: 'Estado',
    accessor: 'active',
    Cell: (row) => <IsActive value={row.value} />,
    className: 'text-center',
    minWidth: 100,
    filterable: false,
  },
  {
    Header: 'Boletos Vendidos',
    accessor: 'ticketsSold',
    className: 'text-center',
    minWidth: 150,
  },
  {
    Header: 'Tipo de Itinerario',
    accessor: 'type',
    className: 'text-center',
    minWidth: 250,
  },
  {
    Header: 'Ruta',
    accessor: 'routeName',
    className: 'text-center',
    minWidth: 400,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (row) => (
      <LinkToModuleResource
        text={row.value}
        href={`${ITINERARY_FOR_COUNTER_PATH}/${row.value}`}
        isExternalPath
      />
    ),
    className: 'text-center',
    minWidth: 150,
  },
];

export const ODOMETER_BY_BUS_COLUMNS = [
  {
    Header: 'Fecha',
    accessor: 'departureTime',
    // TODO the backend must return the date in UTC
    Cell: (row) =>
      tzNormalizeDate({
        date: row.value,
        format: DATE_TIME_FORMAT,
        addTime: { amount: 5, unit: 'hour' },
      }),
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    className: 'text-center',
    width: 320,
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    className: 'text-center',
    width: 320,
  },
  {
    Header: (row) =>
      `Kilometraje: (Total: ${numberFormatter({
        style: 'decimal',
        value: row.data.reduce(
          (accumulated, { kilometers }) => accumulated + kilometers,
          0,
        ),
        minimumFractionDigits: 0,
      })})`,
    accessor: 'kilometers',
    className: 'text-center',
    Cell: (row) =>
      row.value &&
      numberFormatter({
        style: 'decimal',
        value: row.value,
        minimumFractionDigits: 0,
      }),
    width: 320,
  },
];

const STATUS_TRANSLATIONS = {
  READY_FOR_PROCESS: 'Listo para procesar',
  PROCESSED: 'Procesado',
  ERROR: 'Error',
  IN_PROGRESS: 'En progreso',
  MANUAL_IN_PROGRESS: 'En progreso manual',
};

export const BILLING_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Tipo de Proceso',
    accessor: 'processType',
    className: 'text-center',
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Serie Doc. Ref.',
    accessor: 'refDocumentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo Doc. Ref.',
    accessor: 'refDocumentCode',
    className: 'text-center',
  },
  {
    Header: 'ID Itinerario',
    accessor: 'itineraryId',
    className: 'text-center',
  },
  {
    Header: 'Intentos',
    accessor: 'retry',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: ({ value }) => STATUS_TRANSLATIONS[value] || value,
    className: 'text-center',
  },
];

export const ACTIVITY_TASK_COLUMNS = [
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Horas Trabajadas',
    accessor: 'workingHours',
    className: 'text-center',
  },
  {
    Header: 'Organigrama',
    accessor: 'companyArea.name',
    className: 'text-center',
  },
  {
    Header: 'Tarea Ordenada',
    accessor: 'orderDependent',
    Cell: (row) => <Checkbox checked={row.value} />,
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Orden',
    accessor: 'taskOrder',
    className: 'text-center',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const MAINTENANCE_WARNING_COLUMNS = [
  {
    Header: 'Fecha y hora',
    accessor: 'issueDate',
    Cell: (row) =>
      row.value ? (
        <DateCell value={row.value} format={DATE_TIME_FORMAT} />
      ) : (
        '-'
      ),
    className: 'text-center',
  },
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    className: 'text-center',
  },
  {
    Header: 'KM Actual',
    accessor: 'actualVirtualOdometer',
    Cell: (row) =>
      row.value
        ? numberFormatter({
            style: 'decimal',
            value: row.value,
            minimumFractionDigits: 0,
          })
        : '-',
    className: 'text-center',
  },
  {
    Header: 'KM Ocurrencia',
    accessor: 'actualRegisteredBusOdometer',
    Cell: (row) =>
      row.value
        ? numberFormatter({
            style: 'decimal',
            value: row.value,
            minimumFractionDigits: 0,
          })
        : '-',
    className: 'text-center',
  },
  {
    Header: 'Modo Fallo',
    accessor: 'maintenanceFailMode.description',
    Cell: (row) => row.value || '-',
    className: 'text-center',
  },
  {
    Header: 'Tipo Alerta',
    accessor: 'maintenanceWarningType',
    Cell: (row) =>
      row.value ? MAINTENANCE_WARNING_TYPE[row.value].label : '-',
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={MAINTENANCE_WARNING_TYPE}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: (row) =>
      row.value ? (
        <Badge
          text={MAINTENANCE_WARNING_PRIORITY[row.value].label}
          color={MAINTENANCE_WARNING_PRIORITY[row.value].color}
        />
      ) : (
        '-'
      ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={MAINTENANCE_WARNING_PRIORITY}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'Reportado por',
    accessor: 'reporterCustomer.firstName',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) =>
      row.value ? (
        <EnumStatus
          enumObject={MAINTENANCE_WARNING_STATUS}
          enumValue={row.value}
        />
      ) : (
        '-'
      ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={MAINTENANCE_WARNING_STATUS}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const FORMATTED_MAINTENANCE_WARNING_COLUMNS = [
  {
    Header: 'Fecha y hora',
    accessor: 'issueDate',
    className: 'text-center',
  },
  {
    Header: 'Categoría',
    accessor: 'category',
    className: 'text-center',
  },
  {
    Header: 'Subcategoría',
    accessor: 'subCategory',
    className: 'text-center',
  },
  {
    Header: 'Modo Fallo',
    accessor: 'maintenanceFailMode',
    className: 'text-center',
  },
  {
    Header: 'Tipo Alerta',
    accessor: 'maintenanceWarningType',
    className: 'text-center',
  },
  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: (row) =>
      row.value ? (
        <Badge
          text={MAINTENANCE_WARNING_PRIORITY[row.original.priorityValue].label}
          color={MAINTENANCE_WARNING_PRIORITY[row.original.priorityValue].color}
        />
      ) : (
        '-'
      ),
    className: 'text-center',
  },
  {
    Header: 'Reportado por',
    accessor: 'reporterCustomer',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const MAINTENANCE_SERVICE_ORDER_COLUMNS = [
  {
    Header: 'Fecha Creación',
    accessor: 'createDate',
    Cell: (row) =>
      row.value ? (
        <DateCell value={row.value} format={DATE_TIME_FORMAT} />
      ) : (
        '-'
      ),
    className: 'text-center',
  },
  {
    Header: 'Fecha Límite',
    accessor: 'deadline',
    Cell: (row) =>
      row.value ? (
        <DateCell value={row.value} format={DATE_TIME_FORMAT} />
      ) : (
        '-'
      ),
    className: 'text-center',
  },
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Prioridad',
    accessor: 'priority',
    Cell: (row) =>
      row.value ? (
        <Badge
          text={MAINTENANCE_SERVICE_ORDER_PRIORITY[row.value].label}
          color={MAINTENANCE_SERVICE_ORDER_PRIORITY[row.value].color}
        />
      ) : (
        '-'
      ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={MAINTENANCE_SERVICE_ORDER_PRIORITY}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) =>
      row.value ? (
        <EnumStatus
          enumObject={MAINTENANCE_SERVICE_ORDER_STATUS}
          enumValue={row.value}
        />
      ) : (
        '-'
      ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={MAINTENANCE_SERVICE_ORDER_STATUS}
      />
    ),
    className: 'text-center',
  },
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
];

export const MAINTENANCE_SERVICE_ORDER_ACTIVITY_BUS_EXECUTION_LIST_COLUMNS = [
  {
    Header: 'Actividad',
    accessor: 'activityName',
    className: 'text-center',
  },
  {
    Header: 'Tareas',
    accessor: 'taskList',
    className: 'text-center',
    Cell: (row) => {
      if (!row.value) return null;
      const TaskList = row.value.map(({ name }) => ({ text: name }));
      return <BadgeList cell textArray={TaskList} />;
    },
  },
  {
    Header: 'Materiales',
    accessor: 'materialList',
    className: 'text-center',
    Cell: (row) => {
      if (!row.value) return null;
      const MaterialList = row.value.map(({ name }) => ({ text: name }));
      return <BadgeList cell textArray={MaterialList} />;
    },
  },
  {
    Header: 'Empleados',
    accessor: 'employeeList',
    Cell: (row) => {
      if (!row.value) return null;
      const EmployeeList = row.value.map(({ name }) => ({ text: name }));
      return <BadgeList cell textArray={EmployeeList} />;
    },
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
  },
];

export const BUS_COLUMN = [
  {
    Header: 'Bus',
    accessor: 'companyBusId',
    className: 'text-center',
    minWidth: 70,
  },
  {
    Header: 'Placa',
    accessor: 'licensePlate',
    className: 'text-center',
    minWidth: 100,
  },
];

export const ACTIVITY_COLUMN = [
  {
    Header: 'Código',
    accessor: 'companyActivityId',
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Actividad',
    accessor: 'name',
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Factor',
    accessor: 'factor',
    Cell: (row) =>
      row.value
        ? numberFormatter({
            style: 'decimal',
            value: row.value,
            minimumFractionDigits: 0,
          })
        : '-',
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Tipo Factor',
    accessor: 'factorType',
    Cell: (row) => (row.value ? MAINTENANCE_FACTOR_TYPE[row.value].label : '-'),
    className: 'text-center',
    minWidth: 100,
  },
  {
    Header: 'Área',
    accessor: 'companyArea.name',
    Cell: (row) => row.value || '-',
    className: 'text-center',
    minWidth: 150,
  },
];

export const LEGAL_ABANDONMENT_CARGO_ITEMS_COLUMNS = [
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Peso',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Precio Lista',
    accessor: 'listPrice',
    className: 'text-center',
  },
  {
    Header: 'Precio Venta',
    accessor: 'salePrice',
    className: 'text-center',
  },
];

export const generateUnloadingCargoItemsColumns = (
  onClickRemoveItemFromItinerary,
) => [
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Remover',
    className: 'text-center',
    Cell: (row) => (
      <i
        tabIndex="0"
        role="button"
        className="fa fa-times text-danger"
        onClick={() =>
          onClickRemoveItemFromItinerary(
            row.original.correlation,
            row.original.id,
            row.original.internalCargoItem,
          )
        }
        onKeyPress={() =>
          onClickRemoveItemFromItinerary(
            row.original.correlation,
            row.original.id,
            row.original.internalCargoItem,
          )
        }
      />
    ),
    width: 150,
    filterable: false,
  },
];

export const CARGO_ITINERARY_COLUMNS = [
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
];

export const generateParcelsToPickupColumns = (
  onClickPickupParcel,
  handleSubmit,
) => [
  {
    className: 'text-center',
    Expander: (expander) => {
      if (expander.isExpanded) return <i className="fa fa-caret-up" />;

      return <i className="fa fa-caret-down" />;
    },
    getProps: () => ({
      className: 'show-pointer',
    }),
    expander: true,
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Número de serie',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Origen',
    accessor: 'sourceCityName',
    className: 'text-center',
  },
  {
    Header: 'Destino',
    accessor: 'destinationCityName',
    className: 'text-center',
  },
  {
    Header: 'Entregar',
    className: 'text-center',
    Cell: (row) =>
      row.original.status === 'DELIVERED' ? (
        <Checkbox checked />
      ) : (
        <i
          tabIndex="0"
          role="button"
          className="fa fa-plus-circle text-success"
          onClick={() => onClickPickupParcel(row.original, handleSubmit)}
          onKeyPress={() => onClickPickupParcel(row.original, handleSubmit)}
        />
      ),
    width: 150,
    sortable: false,
  },
];

export const CARGO_ITEMS_TO_PICKUP_COLUMNS = [
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
];

export const MANUAL_GRR_COLUMNS = [
  {
    Header: 'Codigo',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Número Guía',
    accessor: 'guideNumber',
    className: 'text-center',
  },
  {
    Header: 'Remitente',
    accessor: 'sender',
    className: 'text-center',
  },
  {
    Header: 'Destinatario',
    accessor: 'consignee',
    className: 'text-center',
  },
  {
    Header: 'Partida',
    accessor: 'sourceName',
    className: 'text-center',
  },
  {
    Header: 'Llegada',
    accessor: 'destinationName',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
];

export const DETRACTION_COLUMNS = [
  {
    Header: 'País',
    accessor: 'country.name',
    className: 'text-center',
  },
  {
    Header: 'Código de detracción',
    accessor: 'detractionCode',
    Cell: (row) => numberFormatter({ value: row.value, digits: 3 }),
    className: 'text-center',
  },
  {
    Header: 'Porcentaje de detracción',
    accessor: 'detractionRate',
    Cell: (row) => numberFormatter({ value: row.value, style: 'percent' }),
    className: 'text-center',
  },
  {
    Header: 'Monto mínimo para la detracción',
    accessor: 'minDetractionAmount',
    className: 'text-center',
  },
  {
    Header: 'Número de cuenta',
    accessor: 'bankAccountNumber',
    className: 'text-center',
  },
];

export const CARGO_ITEM_MOVEMENT_COLUMNS = [
  {
    Header: 'Usuario',
    accessor: 'user.fullName',
    className: 'text-center',
  },
  {
    Header: 'Comentario',
    accessor: 'comments',
    className: 'text-center',
  },
  {
    Header: 'Ubicacion',
    accessor: 'location.name',
    className: 'text-center',
  },
  {
    Header: 'Tipo movimiento',
    accessor: 'movementType',
    Cell: (row) => <CargoItemMovementStatusCell value={row.value} />,
    className: 'text-center',
  },
  {
    Header: 'Estado de Traqueo',
    accessor: 'cargoItemStatus',
    Cell: (row) => <CargoItemStatusCell value={row.value} />,
    className: 'text-center',
  },
  {
    Header: 'Fecha',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
];
export const CARGO_ITEM_PARCEL_DETAIL_COLUMNS = [
  {
    Header: 'Correlativo',
    accessor: 'parcelCorrelation',
    className: 'text-center',
  },
  {
    Header: 'Creado Por',
    accessor: 'createUserFullName',
    className: 'text-center',
  },
  {
    Header: 'Correo Usuario',
    accessor: 'createUserEmail',
    className: 'text-center',
  },
  {
    Header: 'Fecha',
    accessor: 'parcelCreateDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Estado de Pago',
    accessor: 'parcelPaymentStatus',
    Cell: (row) => <ParcelPaymentStatusCell value={row.value} />,
    className: 'text-center',
  },
];

export const CARGO_CORPORATE_CREDIT_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Crédito Inicial',
    accessor: 'initialBalance',
    className: 'text-center',
  },
  {
    Header: 'Crédito Actual',
    accessor: 'actualBalance',
    className: 'text-center',
  },
];
export const LIVE_ITINERARY_SALES_CONTROL_COLUMNS = [
  {
    Header: 'Fecha de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
    Cell: (row) => (
      <Link to={`${ITINERARY_PATH}/${row.value}`} target="_blank">
        {row.value}
      </Link>
    ),
  },
  {
    Header: 'Origen',
    accessor: 'sourceLocationName',
    className: 'text-center',
  },
  {
    Header: 'Destino',
    accessor: 'destinationLocationName',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Servicio',
    accessor: 'serviceType',
    className: 'text-center',
  },
  {
    Header: 'Hora de Salida',
    accessor: 'departuretime',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Capacidad de Asiento',
    accessor: 'numberOfSeats',
    className: 'text-center',
  },
  {
    Header: 'Asientos Vendidos',
    accessor: 'soldSeats',
    className: 'text-center',
    Cell: (row) => <span style={{ fontWeight: 'bold' }}>{row.value}</span>,
  },
  {
    Header: 'Ocupabilidad',
    accessor: 'occupancy',
    className: 'text-center',
    Cell: (row) => (
      <span style={{ fontWeight: 'bold' }}>{`${row.value}%`}</span>
    ),
  },
  {
    Header: 'Precio Inicial',
    columns: [
      {
        Header: 'Precio I. 1',
        accessor: 'prices',
        className: 'text-center',
        Cell: (row) => row.value && numberFormatter({ value: row.value[0] }),
        filterable: false,
      },
      {
        Header: 'Precio I. 2',
        accessor: 'prices',
        className: 'text-center',
        Cell: (row) => row.value && numberFormatter({ value: row.value[1] }),
        filterable: false,
      },
    ],
  },
  {
    Header: 'Precio Final',
    columns: [
      {
        Header: 'Precio F. 1',
        accessor: 'prices',
        className: 'text-center',
        Cell: (row) => row.value && numberFormatter({ value: row.value[2] }),
        filterable: false,
      },
      {
        Header: 'Precio F. 2',
        accessor: 'prices',
        className: 'text-center',
        Cell: (row) => row.value && numberFormatter({ value: row.value[3] }),
        filterable: false,
      },
    ],
  },
];

export const SEATS_INFORMATION_COLUMS = [
  {
    Header: 'Asientos Ofertados',
    accessor: 'offeredSeats',
    className: 'text-center',
  },
  {
    Header: 'Asientos Vendidos',
    accessor: 'soldSeats',
    className: 'text-center',
  },
  {
    Header: 'Pendientes',
    accessor: 'pendingSeats',
    className: 'text-center',
  },
];

export const ITINERARIES_OCCUPANCY_COLUMS = [
  {
    Header: 'Servicio',
    accessor: 'serviceType',
    className: 'text-center',
  },
  {
    Header: 'Ocupabilidad',
    accessor: 'occupancy',
    className: 'text-center',
  },
  {
    Header: 'Itinerarios',
    accessor: 'itineraries',
    className: 'text-center',
  },
];

export const PAYMENT_METHOD_FOR_SECURITY_PROFILE_COLUMNS = [
  {
    Header: 'Metodo de Pago',
    accessor: 'paymentMethod.name',
    className: 'text-center',
  },
  {
    Header: 'Perfil de Seguridad',
    accessor: 'securityProfile.name',
    className: 'text-center',
  },
];

export const PARCEL_TRANSACTIONS_COLUMNS = [
  {
    Header: 'Método de Pago',
    accessor: 'paymentMethodName',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: (row) =>
      numberFormatter({
        value: row.value,
      }),
    className: 'text-center',
  },
  {
    Header: 'Creado Por',
    accessor: 'userFullName',
    className: 'text-center',
  },
  {
    Header: 'Sesión de Ventas',
    accessor: 'salesSessionId',
    Cell: (row) => (
      <LinkToModuleResource
        text={row.value}
        href={`${SALES_SESSION_PATH}/${row.value}`}
        isExternalPath
      />
    ),
    className: 'text-center',
  },
];

export const PASSENGER_FLOW_COLUMNS = [
  {
    Header: 'Boleto',
    accessor: 'ticketCode',
    className: 'text-center',
  },
  {
    Header: 'Pasajero',
    accessor: 'customerFullName',
    className: 'text-center',
  },
  {
    Header: 'DNI del Pasajero',
    accessor: 'customerDocumentNumber',
    className: 'text-center',
  },
  {
    Header: 'Asiento',
    accessor: 'seatNumber',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'boarded',
    Cell: (row) => {
      let enumValue;

      switch (row.value) {
        case true:
          enumValue = TICKET_BOARDED_STATUS.BOARDED.value;
          break;
        case false:
          enumValue = TICKET_BOARDED_STATUS.UNBOARDED.value;
          break;
        case null:
        default:
          enumValue = TICKET_BOARDED_STATUS.UNREGISTERED.value;
          break;
      }

      return (
        <EnumStatus enumObject={TICKET_BOARDED_STATUS} enumValue={enumValue} />
      );
    },
    className: 'text-center',
  },
];

export const generateCargoItemManifestColumns = (printManifest) => [
  {
    Header: 'Itinerario',
    accessor: 'itinerary.id',
    className: 'text-center',
    Cell: (row) => (
      <Link to={`${ITINERARY_FOR_COUNTER_PATH}/${row.value}`} target="_blank">
        {row.value}
      </Link>
    ),
  },
  {
    Header: 'Fecha de Salida',
    accessor: 'exitDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Origen',
    id: 'sourceLocation',
    accessor: 'sourceLocation.name',
    className: 'text-center',
    Cell: (row) => row.value,
  },
  {
    Header: 'Destino',
    id: 'destinationLocation',
    accessor: 'destinationLocation.name',
    className: 'text-center',
    Cell: (row) => row.value,
  },
  {
    Header: 'Hora de Salida',
    accessor: 'exitDate',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <Button onClick={() => printManifest(row.original.id)}>Imprimir</Button>
    ),
  },
];

export const CARGO_CORPORATE_CREDIT_UNIFY_INVOICES_COLUMNS = [
  {
    Header: 'Correlativo',
    accessor: 'correlative',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'salePrice',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
];

export const generatePrintBillingColumns = (printBilling) => [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Tipo de Proceso',
    accessor: 'processType',
    className: 'text-center',
  },
  {
    Header: 'Serie',
    accessor: 'documentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'documentCode',
    className: 'text-center',
  },
  {
    Header: 'Serie Doc. Ref.',
    accessor: 'refDocumentSeries',
    className: 'text-center',
  },
  {
    Header: 'Correlativo Doc. Ref.',
    accessor: 'refDocumentCode',
    className: 'text-center',
  },
  {
    Header: 'ID Itinerario',
    accessor: 'itineraryId',
    className: 'text-center',
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <Button onClick={() => printBilling(row.original.ticketId)}>
        Imprimir
      </Button>
    ),
  },
];

export const MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS = [
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Recepción',
    accessor: 'createDate',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    className: 'text-center',
    Cell: (row) =>
      row.original.locationId === null ? (
        <Badge color="success" text="SUBIDO" />
      ) : (
        <Badge color="warning" text="NO SUBIDO" />
      ),
  },
];

export const generateManifestCargoColumns = (printManifest) => [
  {
    className: 'text-center',
    Expander: (expander) => {
      if (expander.original.manifestCargoItemDetails === 0) return null;

      if (expander.isExpanded) return <i className="fa fa-caret-up" />;

      return <i className="fa fa-caret-down" />;
    },
    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original.manifestCargoItemDetails === 0) {
        return {
          onClick: () => {},
        };
      }
      return {
        className: 'show-pointer',
      };
    },
    expander: true,
  },
  {
    Header: 'Fecha de Salida',
    accessor: 'exitDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Origen',
    id: 'sourceLocation',
    accessor: 'sourceLocation.name',
    className: 'text-center',
    Cell: (row) => row.value,
  },
  {
    Header: 'Destino',
    id: 'destinationLocation',
    accessor: 'destinationLocation.name',
    className: 'text-center',
    Cell: (row) => row.value,
  },
  {
    Header: 'Hora de Salida',
    accessor: 'exitDate',
    className: 'text-center',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <Button onClick={() => printManifest(row.original.id)}>Imprimir</Button>
    ),
  },
];

export const generateManifestCargoDetailsColumns = (
  onClickRemoveItemFromManifest,
  disabledRemoved,
) => [
  {
    Header: 'Correlativo',
    accessor: 'item.correlation',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'item.description',
    className: 'text-center',
  },
  {
    Header: 'Consignado',
    accessor: 'item.consigned',
    className: 'text-center',
  },
  {
    Header: 'Peso',
    accessor: 'item.weight',
    className: 'text-center',
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <i
        tabIndex="0"
        role="button"
        className={`fa fa-times ${
          disabledRemoved ? 'text-muted' : 'text-danger'
        }`}
        style={{ pointerEvents: disabledRemoved ? 'none' : 'auto' }}
        onClick={() =>
          !disabledRemoved && onClickRemoveItemFromManifest(row.original)
        }
        onKeyPress={() =>
          !disabledRemoved && onClickRemoveItemFromManifest(row.original)
        }
      />
    ),
    width: 150,
    filterable: false,
  },
];

export const generateManifestCargoItemsColumns = (onClickRemoveItem) => [
  {
    Header: 'Correlativo',
    accessor: 'correlation',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Peso',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Acción',
    className: 'text-center',
    Cell: (row) => (
      <i
        tabIndex="0"
        role="button"
        className="fa fa-times text-danger"
        onClick={() => onClickRemoveItem(row.original)}
        onKeyPress={() => onClickRemoveItem(row.original)}
      />
    ),
  },
];

export const PAYMENT_COLUMNS = [
  {
    Header: 'Empresa',
    accessor: 'companyName',
    id: 'company.name',
    className: 'text-center',
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: (row) => numberFormatter({ value: row.value }),
    className: 'text-center',
  },
  {
    Header: 'Fecha de pago',
    accessor: 'paymentDate',
    Cell: (row) => <DateCell format={DATE_FORMAT} value={row.value} />,
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) => (
      <EnumStatus enumObject={PAYMENT_STATUS} enumValue={row.value} />
    ),
  },
];

export const generateManualVoucherItemsColumns = ({ currencyCode }) => [
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Precio',
    accessor: 'unitaryPrice',
    Cell: (row) =>
      numberFormatter({
        style: 'currency',
        value: row.value,
        currency: currencyCode,
      }),
    className: 'text-center',
  },
  {
    Header: 'Total',
    accessor: 'totalPrice',
    Cell: (row) =>
      numberFormatter({
        style: 'currency',
        value: row.value,
        currency: currencyCode,
      }),
    className: 'text-center',
  },
];

export const CONTRACTOR_COLUMNS = [
  {
    Header: 'Contratista',
    accessor: 'customer.fullName',
    className: 'text-center',
  },
  {
    Header: 'Empresa',
    accessor: 'business.name',
    className: 'text-center',
  },
  {
    Header: 'Company',
    accessor: 'company.name',
    className: 'text-center',
  },
];

export const TRIP_REQUEST_COLUMNS = [
  {
    Header: 'Contratista',
    accessor: 'businessName',
    id: 'contractor.business.businessTaxId',
    className: 'text-center',
  },
  {
    Header: 'Itinerario',
    accessor: 'itineraryRouteName',
    id: 'itinerary.route.name',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: (row) =>
      row.value ? (
        <EnumStatus enumObject={TRIP_REQUEST_STATUS} enumValue={row.value} />
      ) : (
        '-'
      ),
    Filter: (row) => (
      <EnumSelectFilter
        onChangeFilter={row.onChange}
        enumOptions={TRIP_REQUEST_STATUS}
      />
    ),
    className: 'text-center',
  },
];

export const TRIP_REQUEST_CUSTOMER_COLUMNS = [
  {
    Header: 'Nombres',
    accessor: 'firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellidos',
    accessor: 'lastName',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Documento',
    accessor: 'identificationType.name',
    className: 'text-center',
  },
  {
    Header: 'Número de Documento',
    accessor: 'idDocumentNumber',
    className: 'text-center',
  },
];

export const PERSONNEL_COLUMNS = [
  {
    Header: 'Nombres',
    accessor: 'firstName',
    className: 'text-center',
  },
  {
    Header: 'Apellidos',
    accessor: 'lastName',
    className: 'text-center',
  },
  {
    Header: 'Tipo de Documento',
    accessor: 'identificationType.name',
    className: 'text-center',
  },
  {
    Header: 'Número de Documento',
    accessor: 'idDocumentNumber',
    className: 'text-center',
  },
  {
    Header: 'Tipo',
    accessor: 'type',
    className: 'text-center',
  },
  {
    Header: 'Estado de embarque',
    accessor: 'boarded',
    Cell: (row) => {
      let enumValue;

      switch (row.value) {
        case true:
          enumValue = TICKET_BOARDED_STATUS.BOARDED.value;
          break;
        case false:
          enumValue = TICKET_BOARDED_STATUS.UNBOARDED.value;
          break;
        case null:
        default:
          enumValue = TICKET_BOARDED_STATUS.UNREGISTERED.value;
          break;
      }

      return (
        <EnumStatus enumObject={TICKET_BOARDED_STATUS} enumValue={enumValue} />
      );
    },
    className: 'text-center',
  },
  {
    Header: 'Hora de embarque',
    accessor: 'boardingTime',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    className: 'text-center',
  },
];

export const PROCESS_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Régimen',
    accessor: 'shift',
    className: 'text-center',
  },
  {
    Header: 'Fecha y Hora de Salida',
    accessor: 'departureTime',
    Cell: (row) => <DateCell format={DATE_TIME_FORMAT} value={row.value} />,
    className: 'text-center',
  },
];

export const ITINERARY_PROCESS_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Ruta',
    accessor: 'routeName',
    className: 'text-center',
  },
  {
    Header: 'Hora de Salida',
    accessor: 'departureTime',
    Cell: (row) => <DateCell format={TIME_FORMAT} value={row.value} />,
    className: 'text-center',
  },
  {
    Header: 'Número de pasajeros',
    accessor: 'numPassengers',
    Cell: (row) => (
      <>
        {row.value} / {COMPANY_BUS_SEAT_COUNT}
      </>
    ),
    className: 'text-center',
  },
];

export const PARCEL_PRICE_CONFIGURATION_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Valor',
    accessor: 'value',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Ultima Actualización',
    accessor: 'lastUpdate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
];

export const PARCEL_PRICE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Archivo',
    accessor: 'file',
    className: 'text-center',
    Cell: (row) => (
      <LinkToModuleResource
        href={row.value}
        text={row.value}
        colorIcon="text-primary"
        isExternalPath
      />
    ),
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Activo',
    accessor: 'status',
    Cell: (row) => (
      <EnumStatus enumObject={PARCEL_PRICE_STATUS} enumValue={row.value} />
    ),
    className: 'text-center',
  },
];

export const PARCEL_SERVICE_TYPE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Aumento Porcentual',
    accessor: 'increasePercentage',
    className: 'text-center',
    Cell: (row) => numberFormatter({ value: row.value, style: 'percent' }),
  },
  {
    Header: 'Fecha de Creación',
    accessor: 'createDate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
  {
    Header: 'Ultima Actualización',
    accessor: 'lastUpdate',
    Cell: (row) => <DateCell value={row.value} format={DATE_TIME_FORMAT} />,
    className: 'text-center',
  },
];

export const BUS_DOCUMENTS_COLUMNS = [
  {
    Header: 'Tipo de Documento',
    accessor: 'documentType.name',
    className: 'text-center',
  },
  {
    Header: 'Número de Serie',
    accessor: 'serialNumber',
    className: 'text-center',
  },
  {
    Header: 'Fecha de Expedición',
    accessor: 'effectiveDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
  {
    Header: 'Fecha de Expiración',
    accessor: 'expirationDate',
    className: 'text-center',
    Cell: (row) => <DateCell value={row.value} />,
  },
];

export const BUSSES_COLUMNS = [
  {
    Header: 'Número de Bus',
    accessor: 'companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Matrícula',
    accessor: 'licensePlate',
    className: 'text-center',
  },
  {
    Header: 'Base',
    accessor: 'homeBaseLocation.name',
    className: 'text-center',
  },
  {
    Header: 'Chasís',
    accessor: 'busChassis.make',
    className: 'text-center',
  },
  {
    Header: 'Carrocería',
    accessor: 'busBodywork.make',
    className: 'text-center',
  },
  {
    Header: 'Mapa de Asientos',
    accessor: 'seatMap.name',
    className: 'text-center',
  },
];

export const INTERNAL_CARGO_ITEM_COLUMNS = [
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
  },
  {
    Header: 'Producto Frecuente',
    accessor: 'commonProductName',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Categoría',
    accessor: 'cargoCategoryName',
    className: 'text-center',
  },
  {
    Header: 'Peso (kg)',
    accessor: 'weight',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
    Cell: (row) => <CargoItemStatusCell value={row.value} />,
  },
];

export const MAINTENANCE_PLANNING_ACTIVITY_ORDER_COLUMNS = [
  {
    Header: 'Descripción',
    accessor: 'activity.name',
    className: 'text-center',
  },
  {
    Header: 'Factor',
    accessor: 'factor',
    className: 'text-center',
  },
  {
    Header: 'Orden',
    accessor: 'activityOrder',
    className: 'text-center',
  },
];

export const MAINTENANCE_TASK_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'companyMaintenanceTaskId',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Horas de Trabajo',
    accessor: 'workingHours',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'active',
    Cell: (row) => <IsActive value={row.value} />,
    className: 'text-center',
  },
];

export const ACTIVITY_MAINTENANCE_TASK_COLUMNS = [
  {
    Header: 'Descripción',
    accessor: 'taskName',
    className: 'text-center',
  },
  {
    Header: 'Horas de Trabajo',
    accessor: 'workingHours',
    className: 'text-center',
  },
  {
    Header: 'Materiales Asignados',
    accessor: 'materials',
    className: 'text-center',
  },
];

export const MAINTENANCE_PLANNINGS_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
  },
  {
    Header: 'Organigrama',
    accessor: 'companyAreaName',
    className: 'text-center',
  },
];

export const MAINTENANCE_TASK_MATERIAL_COLUMNS = [
  {
    Header: 'Código',
    accessor: 'material.externalId',
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Nombre',
    accessor: 'material.name',
    className: 'text-center',
    minWidth: 320,
  },
  {
    Header: 'Unidad de Medida',
    accessor: 'material.measurementUnit',
    className: 'text-center',
    minWidth: 180,
  },
  {
    Header: 'Cantidad',
    accessor: 'quantity',
    className: 'text-center',
    minWidth: 180,
  },
];

export const MAINTENANCE_BUS_GROUP_COLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
    filterable: false,
  },
  {
    Header: 'Descripción',
    accessor: 'description',
    className: 'text-center',
    filterable: false,
  },
];

export const MAINTENANCE_PLANNING_RESOURCE_COLUMNS = [
  {
    Header: 'Bus',
    accessor: 'registeredBus.companyBusId',
    className: 'text-center',
  },
  {
    Header: 'Grupo de Bus',
    accessor: 'maintenanceBusGroup.name',
    className: 'text-center',
  },
  {
    Header: 'Recurso de la Compañia',
    accessor: 'companyResource.name',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    className: 'text-center',
    Cell: (row) => (
      <EnumStatus
        enumObject={MAINTENANCE_PLANNING_STATUS}
        enumValue={row.value}
      />
    ),
  },
];

export const SERVICE_MERGE_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Itinerario inicial',
    accessor: 'sourceItineraryId',
    Cell: (row) =>
      `${row.original.sourceItineraryId} - ${row.original.sourceItineraryRouteName}`,
    className: 'text-center',
  },
  {
    Header: 'Itinerario final',
    accessor: 'destinationItineraryId',
    Cell: (row) =>
      `${row.original.destinationItineraryId} - ${row.original.destinationItineraryRouteName}`,
    className: 'text-center',
  },
];

export const TICKET_REASSIGNMENT_COLUMNS = [
  {
    Header: 'Id del Ticket',
    accessor: 'ticketId',
    className: 'text-center',
  },
  {
    Header: 'Ticket',
    accessor: 'ticketCode',
    className: 'text-center',
  },
  {
    Header: 'Cliente',
    accessor: 'customerId',
    Cell: (row) =>
      `${row.original.customerIdentificationTypeName} ${row.original.customerIdDocumentNumber} (${row.original.customerIdCountryOfOrigin}) - ${row.original.customerFullName}`,
    Filter: (row) => (
      <CustomerFilter isClearable onChangeFilter={row.onChange} />
    ),
    className: 'text-center',
  },
  {
    Header: 'Origen y destino anteriores',
    accessor: 'previousSourceLocationName',
    Cell: (row) =>
      `${row.original.previousSourceLocationName} - ${row.original.previousDestinationLocationName}`,
    filterable: false,
    sortable: false,
    className: 'text-center',
  },
  {
    Header: 'Origen y destino nuevos',
    accessor: 'newSourceLocationName',
    Cell: (row) =>
      `${row.original.newSourceLocationName} - ${row.original.newDestinationLocationName}`,
    filterable: false,
    sortable: false,
    className: 'text-center',
  },
  {
    Header: 'Número de asiento anterior',
    accessor: 'previousSeatNumber',
    className: 'text-center',
  },
  {
    Header: 'Nuevo número de asiento',
    accessor: 'newSeatNumber',
    className: 'text-center',
  },
];

export const COMPANY_RESOURCES_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'text-center',
  },
  {
    Header: 'Nombre',
    accessor: 'name',
    className: 'text-center',
  },
  {
    Header: 'Tipo',
    accessor: 'companyResourceType.description',
    className: 'text-center',
  },
  {
    Header: 'Estado',
    accessor: 'active',
    className: 'text-center',
    Cell: (row) => <IsActive value={row.value} />,
  },
];
