import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import {
  clearMaintenanceTasks,
  getMaintenanceTasks,
} from '../../../../actions/mechanical-maintenance/MaintenanceTask';
import { isIntegerNumber } from '../../../../utils/validators';

export const MaintenanceTaskSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearMaintenanceTasks,
  dispatchGetMaintenanceTasks,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceTasks(), []);

  const onInputChange = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    const inputValueIsNumber =
      isIntegerNumber(inputValue.trim()) && inputValueLength;

    const query = ['active:true'];

    if (inputValueIsNumber) {
      query.push(`companyMaintenanceTaskId:${inputValue}`);

      dispatchGetMaintenanceTasks({
        query,
      });
    } else if (inputValueLength && inputValueLength >= 4) {
      query.push(`description:${inputValue}`);
      dispatchGetMaintenanceTasks({ query });
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

MaintenanceTaskSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMaintenanceTasks: PropTypes.func.isRequired,
  dispatchClearMaintenanceTasks: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

MaintenanceTaskSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por código o nombre de tarea',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, description, companyMaintenanceTaskId }) => ({
    value: id,
    label: `${companyMaintenanceTaskId} - ${description}`,
  })),
  loading: MechanicalMaintenanceUnit.MaintenanceTask.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetMaintenanceTasks: getMaintenanceTasks,
  dispatchClearMaintenanceTasks: clearMaintenanceTasks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceTaskSelect);
