import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Content from '../../../layout/Content';
import { BUS_ACTIVITY_HISTORY_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearBusActivityHistory,
  getBusActivityHistory,
} from '../../../../actions';
import PreventiveMaintenanceReportSearchForm from '../preventive-maintenance-report/PreventiveMaintenanceReportSearchForm';
import { ENTER_DATA_TO_SHOW_DATA } from '../../../../config/messages';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import Alert from '../../../common/informative/Alert';
import BusActivityHistoryResult from './BusActivityHistoryResult';

const BusActivityHistory = ({
  breadcrumbs,
  dispatchGetBusActivityHistory,
  dispatchClearBusActivityHistory,
}) => {
  const [searchValues, setSearchValues] = useState(null);

  const today = tzNormalizeDate({ format: INT_DATE_FORMAT });
  const tomorrow = tzNormalizeDate({ format: INT_DATE_FORMAT });

  const onSearch = (values) => {
    dispatchClearBusActivityHistory();
    dispatchGetBusActivityHistory(values);
  };

  const onSubmitSearch = (formValues) => {
    const newSearchValues = {
      registeredBusIdList:
        formValues.registeredBusIdList &&
        formValues.registeredBusIdList.length > 0
          ? formValues.registeredBusIdList.map((bus) => bus.value)
          : null,
      companyResourceIdList:
        formValues.companyResourceIdList &&
        formValues.companyResourceIdList.length > 0
          ? formValues.companyResourceIdList.map((resource) => resource.value)
          : null,
      dateFrom: tzNormalizeDate({ date: formValues.dateFrom, time: 'start' }),
      dateTo: tzNormalizeDate({ date: formValues.dateTo, time: 'end' }),

      ...DEFAULT_QUERY_GET_ALL,
    };

    setSearchValues(newSearchValues);
    onSearch(newSearchValues);
  };

  let table = <Alert type="info" message={ENTER_DATA_TO_SHOW_DATA} />;
  if (searchValues) table = <BusActivityHistoryResult />;

  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmitSearch}
        initialValues={{
          dateFrom: today,
          dateTo: tomorrow,
        }}
        registeredBusIsMulti
        showDateFrom
        registeredBusIsRequired
        showCompanyResource
      />
      <div className="mt-3">{table}</div>
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Historial de Actividades"
      content={content}
    />
  );
};

BusActivityHistory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetBusActivityHistory: PropTypes.func.isRequired,
  dispatchClearBusActivityHistory: PropTypes.func.isRequired,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Historial de Actividades',
      href: BUS_ACTIVITY_HISTORY_PATH,
    },
  ],
});

const mapDispatchToProps = {
  dispatchGetBusActivityHistory: getBusActivityHistory,
  dispatchClearBusActivityHistory: clearBusActivityHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusActivityHistory);
