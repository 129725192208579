import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import PrintCargoTicketButton from '../../reservation/ticket/ticket-toolbar/PrintCargoTicketButton';
import DeliverInternalParcelButton from './DeliverInternalParcelButton';
import { CARGO_ITEM_STATUS } from '../../../../config/constants';

const InternalParcelToolbar = ({ internalParcel }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintCargoTicketButton ticket={internalParcel} />{' '}
      {internalParcel.status !== CARGO_ITEM_STATUS.DELIVERED.value && (
        <DeliverInternalParcelButton internalParcelId={internalParcel.id} />
      )}
    </div>
  </ButtonToolbar>
);

InternalParcelToolbar.propTypes = {
  internalParcel: PropTypes.instanceOf(Object).isRequired,
};

export default InternalParcelToolbar;
