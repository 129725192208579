import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearCompanyResource,
  getCompanyResource,
  putCompanyResource,
} from '../../../../actions/mechanical-maintenance/CompanyResource';
import CompanyResourceForm from './CompanyResourceForm';

const EditActivity = ({
  breadcrumbs,
  dispatchGetCompanyResource,
  dispatchClearCompanyResource,
  dispatchPutCompanyResource,
  match: {
    params: { id: companyResourceId },
  },
  loading,
  companyResource,
}) => {
  useLayoutEffect(() => {
    dispatchGetCompanyResource({ companyResourceId });

    return () => dispatchClearCompanyResource();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      companyResourceTypeId: formValues.companyResourceTypeId.value,
    };

    dispatchPutCompanyResource(companyResourceId, newFormValues);
  };

  const generateInitialValues = ({ name, companyResourceType, active }) => ({
    name,
    companyResourceTypeId: companyResourceType && {
      value: companyResourceType.id,
      label: companyResourceType.description,
    },
    active,
  });

  let content;

  if (loading) content = <Loader />;
  else if (companyResource.isEmpty())
    content = <NoDataResource returnPage={COMPANY_RESOURCE_PATH} />;
  else
    content = (
      <CompanyResourceForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(companyResource.toJS())}
        edit
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Recurso de la Compañia"
      subtitle="Editar recurso de la compañia"
      content={content}
    />
  );
};

EditActivity.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCompanyResource: PropTypes.func.isRequired,
  dispatchGetCompanyResource: PropTypes.func.isRequired,
  dispatchPutCompanyResource: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyResource: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditActivity.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCompanyResource: clearCompanyResource,
  dispatchGetCompanyResource: getCompanyResource,
  dispatchPutCompanyResource: putCompanyResource,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Recursos de la Compañia',
      href: COMPANY_RESOURCE_PATH,
    },
    {
      text: 'Ver',
      href: `${COMPANY_RESOURCE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  companyResource: MechanicalMaintenanceUnit.CompanyResource.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.CompanyResource.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActivity);
