import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { BUS_PATH } from '../../../../config/paths';

const RegisteredBusToolbar = ({ registeredBusId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${BUS_PATH}/${registeredBusId}/edit`} />{' '}
    </div>
  </ButtonToolbar>
);

RegisteredBusToolbar.propTypes = {
  registeredBusId: PropTypes.string.isRequired,
};

export default RegisteredBusToolbar;
