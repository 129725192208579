import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  MAINTENANCE_PLANNING_PATH,
  MAINTENANCE_TASK_PATH,
} from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import Table from '../../../common/Table';
import {
  MAINTENANCE_PLANNING_ACTIVITY_ORDER_COLUMNS,
  MAINTENANCE_PLANNING_RESOURCE_COLUMNS,
} from '../../../../config/columns';
import Alert from '../../../common/informative/Alert';
import {
  MAINTENANCE_PLANNING_WITHOUT_ACTIVITY,
  MAINTENANCE_PLANNING_WITHOUT_RESOURCE,
} from '../../../../config/messages';
import MaintenancePlanningBasicInformationResource from './resource/MaintenancePlanningBasicInformationResource';
import {
  clearMaintenancePlanning,
  getMaintenancePlanning,
} from '../../../../actions';
import MaintenancePlanningToolbar from './MaintenancePlanningToolbar';
import {
  MAINTENANCE_PLANNING_STATUS_CREATED,
  MAINTENANCE_PLANNING_STATUS_DONE,
  MAINTENANCE_PLANNING_STATUS_STARTED,
} from '../../../../config/constants';

export const MaintenancePlanning = ({
  breadcrumbs,
  dispatchClearMaintenancePlanning,
  dispatchGetMaintenancePlanning,
  match: {
    params: { id: maintenancePlanningId },
  },
  loading,
  maintenancePlanning,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenancePlanning({ maintenancePlanningId });

    return () => dispatchClearMaintenancePlanning();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (maintenancePlanning.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_TASK_PATH} />;
  else {
    const maintenancePlanningJson = maintenancePlanning.toJS();

    const tableActivities =
      maintenancePlanningJson.maintenancePlanningActivityOrderList !== null &&
      maintenancePlanningJson.maintenancePlanningActivityOrderList.length !==
        0 ? (
        <Table
          columns={MAINTENANCE_PLANNING_ACTIVITY_ORDER_COLUMNS}
          data={maintenancePlanningJson.maintenancePlanningActivityOrderList}
          showPagination={false}
          navigateToModelOnRowClick
          defaultPageSize={
            maintenancePlanningJson.maintenancePlanningActivityOrderList.length
          }
        />
      ) : (
        <Alert message={MAINTENANCE_PLANNING_WITHOUT_ACTIVITY} type="warning" />
      );

    const tableResources =
      maintenancePlanningJson.maintenancePlanningResourceList !== null &&
      maintenancePlanningJson.maintenancePlanningResourceList.length !== 0 ? (
        <Table
          columns={MAINTENANCE_PLANNING_RESOURCE_COLUMNS}
          data={maintenancePlanningJson.maintenancePlanningResourceList}
          showPagination={false}
          navigateToModelOnRowClick
          defaultPageSize={
            maintenancePlanningJson.maintenancePlanningResourceList.length
          }
        />
      ) : (
        <Alert message={MAINTENANCE_PLANNING_WITHOUT_RESOURCE} type="warning" />
      );

    const checkPlanningStatus = (maintenancePlanningResourceList) => {
      if (
        Array.isArray(maintenancePlanningResourceList) &&
        maintenancePlanningResourceList.length > 0
      ) {
        const allFinished = maintenancePlanningResourceList.every(
          (resource) => resource.status === MAINTENANCE_PLANNING_STATUS_DONE,
        );

        if (allFinished) {
          return MAINTENANCE_PLANNING_STATUS_DONE;
        }

        const allCreated = maintenancePlanningResourceList.every(
          (resource) => resource.status === MAINTENANCE_PLANNING_STATUS_CREATED,
        );

        if (allCreated) {
          return MAINTENANCE_PLANNING_STATUS_CREATED;
        }

        return MAINTENANCE_PLANNING_STATUS_STARTED;
      }

      return MAINTENANCE_PLANNING_STATUS_STARTED;
    };

    content = (
      <>
        <h3>Información Básica</h3>
        <MaintenancePlanningBasicInformationResource
          {...maintenancePlanningJson}
          status={checkPlanningStatus(
            maintenancePlanningJson.maintenancePlanningResourceList,
          )}
        />
        <h3>Actividades</h3>
        {tableActivities}
        <h3>Recursos asignados</h3>
        {tableResources}
      </>
    );

    toolbar = (
      <MaintenancePlanningToolbar
        maintenancePlanningId={maintenancePlanningJson.id}
        status={checkPlanningStatus(
          maintenancePlanningJson.maintenancePlanningResourceList,
        )}
        maintenancePlanning={maintenancePlanningJson}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Plan de Mantenimiento"
      content={content}
    />
  );
};

MaintenancePlanning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaintenancePlanning: PropTypes.func.isRequired,
  dispatchGetMaintenancePlanning: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  maintenancePlanning: PropTypes.instanceOf(Immutable.Map).isRequired,
};

MaintenancePlanning.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaintenancePlanning: clearMaintenancePlanning,
  dispatchGetMaintenancePlanning: getMaintenancePlanning,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Planes de Mantenimiento',
      href: MAINTENANCE_PLANNING_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  maintenancePlanning: MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenancePlanning);
