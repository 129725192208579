import { BASE_URL } from './endpoints';

export const SIGN_IN_PATH = '/sign-in';
export const SIGN_OUT_PATH = '/sign-out';
export const HOME_PATH = process.env.REACT_APP_HOME_PATH || '/';

// bus unit
export const BUS_UNIT_PATH = '/bus';
export const OLD_FRONT_SEAT_MAP_PATH = `${BASE_URL}/secure/app#view/seat-map`;
export const BUS_PATH = `${BUS_UNIT_PATH}/registered-busses`;
export const NEW_BUS_PATH = `${BUS_PATH}/new`;

// user unit
export const USER_UNIT_PATH = '/user';
export const USER_PATH = `${USER_UNIT_PATH}/users`;
export const NEW_USER_PATH = `${USER_PATH}/new`;
export const CUSTOMER_PATH = `${USER_UNIT_PATH}/customer`;
export const NEW_CUSTOMER_PATH = `${CUSTOMER_PATH}/new`;
// user self
export const USER_SELF_PATH = `${USER_UNIT_PATH}/self`;
export const BUSINESS_PATH = `${USER_UNIT_PATH}/business`;
export const NEW_BUSINESS_PATH = `${BUSINESS_PATH}/new`;

// location unit
export const LOCATION_UNIT_PATH = '/location';
export const LOCATION_PATH = `${LOCATION_UNIT_PATH}/location`;
export const NEW_LOCATION_PATH = `${LOCATION_PATH}/new`;
export const DISTRICT_PATH = `${LOCATION_UNIT_PATH}/district`;
export const NEW_DISTRICT_PATH = `${DISTRICT_PATH}/new`;
export const COUNTRY_PATH = `${LOCATION_UNIT_PATH}/country`;
export const NEW_COUNTRY_PATH = `${COUNTRY_PATH}/new`;
export const GEOGRAPHIC_ZONE_PATH = `${LOCATION_UNIT_PATH}/geographic-zone`;
export const NEW_GEOGRAPHIC_ZONE_PATH = `${GEOGRAPHIC_ZONE_PATH}/new`;
export const OLD_FRONT_NEW_ADDRESS_PATH = `${BASE_URL}/secure/app#new/address`;
export const ADDRESS_PATH = `${LOCATION_UNIT_PATH}/address`;
export const NEW_ADDRESS_PATH = `${ADDRESS_PATH}/new`;

// route unit
export const ROUTE_UNIT_PATH = '/route';
export const ROUTE_PATH = `${ROUTE_UNIT_PATH}/route`;
export const SEGMENT_PATH = `${ROUTE_UNIT_PATH}/segment`;
export const NEW_SEGMENT_PATH = `${SEGMENT_PATH}/new`;
export const TOLL_PATH = `${ROUTE_UNIT_PATH}/toll`;
export const FUEL_STATION_PATH = `${ROUTE_UNIT_PATH}/fuel-stations`;
export const NEW_FUEL_STATION_PATH = `${FUEL_STATION_PATH}/new`;
export const CIRCUIT_PATH = `${ROUTE_UNIT_PATH}/circuit`;
export const AGENCY_TO_FUEL_STATION_PATH = `${ROUTE_UNIT_PATH}/agency-to-fuel-station`;
export const ITINERARY_GROUP_PATH = `${ROUTE_UNIT_PATH}/itinerary-group`;
export const NEW_ITINERARY_GROUP_PATH = `${ITINERARY_GROUP_PATH}/new`;
export const OLD_FRONT_ROUTE_PATH = `${BASE_URL}/secure/app#view/route`;
export const OLD_FRONT_CIRCUIT_PATH = `${BASE_URL}/secure/app#view/circuit`;

// itinerary unit
export const ITINERARY_UNIT_PATH = '/itinerary';
export const ITINERARY_SCHEDULE_PATH = `${ITINERARY_UNIT_PATH}/itinerary-schedule`;
export const NEW_ITINERARY_SCHEDULE_PATH = `${ITINERARY_SCHEDULE_PATH}/new`;
export const generateItineraryStatusEditPath = (id = ':id') =>
  `${ITINERARY_SCHEDULE_PATH}/${id}/update-statuses`;
export const ITINERARY_PATH = `${ITINERARY_UNIT_PATH}/itinerary`;
export const NEW_ITINERARY_PATH = NEW_ITINERARY_SCHEDULE_PATH;
export const generateNewTripPath = ({ id = ':id' }) =>
  `${ITINERARY_PATH}/${id}/new-trip`;
export const ITINERARY_FOR_COUNTER_PATH = `${ITINERARY_UNIT_PATH}/itinerary-for-counter`;
export const ITINERARY_FOR_SCHEDULING_PATH = `${ITINERARY_UNIT_PATH}/itinerary-for-scheduling`;
export const SERVICE_MERGE_PATH = '/itinerary/service-merge';
export const NEW_SERVICE_MERGE_PATH = `${SERVICE_MERGE_PATH}/new`;
export const ITINERARY_SEARCH_OMISSION_PATH = `${ITINERARY_UNIT_PATH}/itinerary-search-omission`;
export const NEW_ITINERARY_SEARCH_OMISSION_PATH = `${ITINERARY_SEARCH_OMISSION_PATH}/new`;
export const ITINERARY_EXTRAORDINARY_MOVEMENT_PATH = `${ITINERARY_UNIT_PATH}/extraordinary-movement`;
export const NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH = `${ITINERARY_EXTRAORDINARY_MOVEMENT_PATH}/new`;
export const generateRescueItineraryPath = (id = ':id') =>
  `${ITINERARY_EXTRAORDINARY_MOVEMENT_PATH}/${id}/rescue-itinerary`;
export const KIDS_ON_ITINERARY_PATH = `${ITINERARY_UNIT_PATH}/kids-on-itinerary`;
export const SALES_CONTROL_PATH = `${ITINERARY_UNIT_PATH}/sales-control`;
export const BILLINGS_ITINERARY_PATH = `${ITINERARY_UNIT_PATH}/billings`;
export const LIVE_SALES_CONTROL_PATH = `${ITINERARY_UNIT_PATH}/live-sales-control`;
export const TRIP_REQUEST_PATH = `${ITINERARY_UNIT_PATH}/trip-request`;
export const NEW_TRIP_REQUEST_PATH = `${ITINERARY_UNIT_PATH}/trip-request/new`;
export const PROCESS_PATH = `${ITINERARY_UNIT_PATH}/process`;
export const NEW_PROCESS_PATH = `${ITINERARY_UNIT_PATH}/process/new`;
export const generateItineraryByProcessStatusEditPath = (id = ':id') =>
  `${ITINERARY_SCHEDULE_PATH}/process/${id}/update-statuses`;

// reservation unit
export const RESERVATION_UNIT_PATH = '/reservation';
export const TICKET_PATH = `${RESERVATION_UNIT_PATH}/ticket`;
export const TICKET_CUSTOMER_PATH = `${RESERVATION_UNIT_PATH}/ticket-customer`;
export const TICKET_MASSIVE_POSTPONE_PATH = `${RESERVATION_UNIT_PATH}/ticket-massive-postpone`;

// sales unit
export const SALES_UNIT_PATH = '/sales';
export const PRICING_PROFILE_PATH = `${SALES_UNIT_PATH}/pricing-profile`;
export const NEW_PRICING_PROFILE_PATH = `${PRICING_PROFILE_PATH}/new`;
export const SALES_SESSION_PATH = `${SALES_UNIT_PATH}/sales-session`;
export const NEW_SALES_SESSION_PATH = `${SALES_SESSION_PATH}/new`;
export const EXPENSE_PATH = `${SALES_UNIT_PATH}/expense`;
export const NEW_EXPENSE_PATH = `${EXPENSE_PATH}/new`;
export const OLD_EXPENSE_PATH = `${BASE_URL}/secure/app#view/expense`;
export const GENERAL_INCOME_PATH = `${SALES_UNIT_PATH}/general-income`;
export const NEW_GENERAL_INCOME_PATH = `${GENERAL_INCOME_PATH}/new`;
export const LIQUIDATION_SESSION_PATH = `${SALES_UNIT_PATH}/liquidation-session`;
export const NEW_LIQUIDATION_SESSION_PATH = `${LIQUIDATION_SESSION_PATH}/new`;
export const CREDIT_NOTE_PATH = `${SALES_UNIT_PATH}/credit-note`;
export const NEW_CREDIT_NOTE_PATH = `${CREDIT_NOTE_PATH}/new`;
export const DEPOSIT_PATH = `${SALES_UNIT_PATH}/deposit`;
export const NEW_DEPOSIT_PATH = `${DEPOSIT_PATH}/new`;
export const DISCOUNT_TO_BENEFICIARIES_PATH = `${SALES_UNIT_PATH}/discount-to-beneficiaries`;
export const EXPENSE_STATUS_PATH = `${SALES_UNIT_PATH}/expense-authorization`;
export const generateExpenseStatusAddRemarkPath = (id = ':id') =>
  `${EXPENSE_STATUS_PATH}/${id}/add-remark`;

// traffic unit
export const TRAFFIC_UNIT_PATH = '/traffic';
export const DRIVER_BUS_ASSIGNMENT_PATH = `${TRAFFIC_UNIT_PATH}/driver-bus-assignment`;
export const NEW_DRIVER_BUS_ASSIGNMENT_PATH = `${DRIVER_BUS_ASSIGNMENT_PATH}/new`;
export const BUS_FUEL_GROUP_PATH = `${TRAFFIC_UNIT_PATH}/bus-fuel-group`;
export const NEW_BUS_FUEL_GROUP_PATH = `${BUS_FUEL_GROUP_PATH}/new`;
export const EXPECTED_FUEL_CONSUMPTION_PATH = `${TRAFFIC_UNIT_PATH}/expected-fuel-consumption`;
export const NEW_EXPECTED_FUEL_CONSUMPTION_PATH = `${EXPECTED_FUEL_CONSUMPTION_PATH}/new`;
export const FUEL_PROFILE_PATH = `${TRAFFIC_UNIT_PATH}/fuel-profile`;
export const NEW_FUEL_PROFILE_PATH = `${FUEL_PROFILE_PATH}/new`;
export const FUEL_VOUCHER_PATH = `${TRAFFIC_UNIT_PATH}/fuel-voucher`;
export const NEW_FUEL_VOUCHER_PATH = `${FUEL_VOUCHER_PATH}/new`;
export const EXTRAORDINARY_MOVEMENT_CONTROL_PATH = `${TRAFFIC_UNIT_PATH}/extraordinary-movement-control`;
export const generateExtraordinaryMovementControlEditPath = ({ id = ':id' }) =>
  `${EXTRAORDINARY_MOVEMENT_CONTROL_PATH}/${id}/edit`;
export const PURCHASE_ORDER_PATH = `${TRAFFIC_UNIT_PATH}/purchase-order`;
export const NEW_PURCHASE_ORDER_PATH = `${PURCHASE_ORDER_PATH}/new`;
export const generatePurchaseOrderViewPath = (id = ':id') =>
  `${PURCHASE_ORDER_PATH}/${id}`;
export const generateEditPurchaseOrderPath = (id = ':id') =>
  `${PURCHASE_ORDER_PATH}/${id}/edit`;
export const BASE_FUEL_REFILL_PATH = `${TRAFFIC_UNIT_PATH}/base-fuel-refill`;
export const NEW_BASE_FUEL_REFILL_PATH = `${BASE_FUEL_REFILL_PATH}/new`;
export const FUEL_REPORT_PREVIEW_PATH = `${TRAFFIC_UNIT_PATH}/fuel-report-preview`;
export const FUEL_CONSUMPTION_REPORT_PER_BUS_PATH = `${TRAFFIC_UNIT_PATH}/fuel-consumption-report-per-bus`;
export const FUEL_CONSUMPTION_REPORT_PER_DRIVER_PATH = `${TRAFFIC_UNIT_PATH}/fuel-consumption-report-per-driver`;
export const DRIVER_PATH = `${TRAFFIC_UNIT_PATH}/driver`;
export const NEW_DRIVER_PATH = `${DRIVER_PATH}/new`;
export const generateDriverTripsPath = (id = ':id') =>
  `${DRIVER_PATH}/${id}/trip`;

// accounting unit
export const ACCOUNTING_UNIT_PATH = '/accounting';
export const EXPENSE_TYPE_PATH = `${ACCOUNTING_UNIT_PATH}/expense-type`;
export const NEW_EXPENSE_TYPE_PATH = `${EXPENSE_TYPE_PATH}/new`;
export const COST_CENTER_PATH = `${ACCOUNTING_UNIT_PATH}/cost-center`;
export const NEW_COST_CENTER_PATH = `${COST_CENTER_PATH}/new`;
export const TICKET_TAXABLE_INFORMATION_PATH = `${ACCOUNTING_UNIT_PATH}/ticket-taxable-information`;
export const MANUAL_VOUCHER_PATH = `${ACCOUNTING_UNIT_PATH}/manual-voucher`;
export const NEW_MANUAL_VOUCHER_PATH = `${MANUAL_VOUCHER_PATH}/new`;
export const BILLING_PATH = `${ACCOUNTING_UNIT_PATH}/billing`;
export const DETRACTION_PATH = `${ACCOUNTING_UNIT_PATH}/detraction`;
export const NEW_DETRACTION_PATH = `${DETRACTION_PATH}/new`;

// booking unit
export const BOOKING_UNIT_PATH = `/booking`;
export const SEARCH_STEP_PATH = `${BOOKING_UNIT_PATH}/search`;
export const ITINERARIES_STEP_PATH = `${BOOKING_UNIT_PATH}/itineraries`;
export const SEATS_STEP_PATH = `${BOOKING_UNIT_PATH}/seats`;
export const PASSENGERS_STEP_PATH = `${BOOKING_UNIT_PATH}/passengers`;
export const PAYMENT_STEP_PATH = `${BOOKING_UNIT_PATH}/payment`;
export const CONFIRMATION_STEP_PATH = `${BOOKING_UNIT_PATH}/confirmation`;

// post payment booking unit;
export const POSTPAID_BOOKING_UNIT_PATH = '/postpaid-booking';
export const POSTPAID_BOOKING_NEW_PATH = `${POSTPAID_BOOKING_UNIT_PATH}/new`;
export const POSTPAID_BOOKING_SEARCH_PATH = `${POSTPAID_BOOKING_NEW_PATH}/search`;
export const POSTPAID_BOOKING_ITINERARIES_PATH = `${POSTPAID_BOOKING_NEW_PATH}/itineraries`;
export const POSTPAID_BOOKING_SEATS_PATH = `${POSTPAID_BOOKING_NEW_PATH}/seats`;
export const POSTPAID_BOOKING_PASSENGERS_PATH = `${POSTPAID_BOOKING_NEW_PATH}/passengers`;
export const POSTPAID_BOOKING_RECEIVER_PATH = `${POSTPAID_BOOKING_NEW_PATH}/receiver`;
export const POSTPAID_BOOKING_SUMMARY_PATH = `${POSTPAID_BOOKING_NEW_PATH}/summary`;

// human resources
export const HUMAN_RESOURCE_UNIT_PATH = '/human-resources';
export const AGENCY_PATH = `${HUMAN_RESOURCE_UNIT_PATH}/agency`;
export const NEW_AGENCY_PATH = `${AGENCY_PATH}/new`;
export const AGENCY_GROUP_PATH = `${HUMAN_RESOURCE_UNIT_PATH}/agency-group`;
export const NEW_AGENCY_GROUP_PATH = `${AGENCY_GROUP_PATH}/new`;
export const DRIVER_COMISSION_PATH = `${HUMAN_RESOURCE_UNIT_PATH}/driver-comission`;
export const WORKSTATION_PATH = `${HUMAN_RESOURCE_UNIT_PATH}/workstation`;
export const NEW_WORKSTATION_PATH = `${WORKSTATION_PATH}/new`;
export const PRINT_STATION_PATH = `${HUMAN_RESOURCE_UNIT_PATH}/print-station`;
export const NEW_PRINT_STATION_PATH = `${PRINT_STATION_PATH}/new`;

// security
export const SECURITY_UNIT_PATH = '/security';
export const PROFILE_AUTHORIZED_BY_PROFILE_PATH = `${SECURITY_UNIT_PATH}/profiles-authorized-by-profile`;
export const NEW_PROFILE_AUTHORIZED_BY_PROFILE_PATH = `${PROFILE_AUTHORIZED_BY_PROFILE_PATH}/new`;
export const PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH = `${SECURITY_UNIT_PATH}/payment-method-for-security-profile`;
export const NEW_PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH = `${PAYMENT_METHOD_FOR_SECURITY_PROFILE_PATH}/new`;

// baggage unit
export const BAGGAGE_UNIT_PATH = '/baggage';
export const BAGGAGE_PATH = `${BAGGAGE_UNIT_PATH}/baggage`;
export const NEW_BAGGAGE_PATH = `${BAGGAGE_PATH}/new`;
export const BAGGAGE_ITEM_MOVEMENT_PATH = `${BAGGAGE_UNIT_PATH}/baggage-item-movement`;

// cargo unit
export const CARGO_UNIT_PATH = '/cargo';
export const BASE_PRICE_PER_KILOMETER_PATH = `${CARGO_UNIT_PATH}/base-price-per-kilometer`;
export const NEW_BASE_PRICE_PER_KILOMETER_PATH = `${BASE_PRICE_PER_KILOMETER_PATH}/new`;
export const GEOGRAPHIC_ZONE_COMBINATION_PATH = `${CARGO_UNIT_PATH}/geographic-zones-combination`;
export const NEW_GEOGRAPHIC_ZONE_COMBINATION_PATH = `${GEOGRAPHIC_ZONE_COMBINATION_PATH}/new`;
export const CARGO_DISTANCE_PATH = `${CARGO_UNIT_PATH}/cargo-distance`;
export const NEW_CARGO_DISTANCE_PATH = `${CARGO_DISTANCE_PATH}/new`;
export const DELIVERY_GROUP_PATH = `${CARGO_UNIT_PATH}/delivery-group`;
export const NEW_DELIVERY_GROUP_PATH = `${DELIVERY_GROUP_PATH}/new`;
export const DEPRECIATION_RULE_PATH = `${CARGO_UNIT_PATH}/depreciation-rule`;
export const NEW_DEPRECIATION_RULE_PATH = `${DEPRECIATION_RULE_PATH}/new`;
export const COMMON_PRODUCT_PATH = `${CARGO_UNIT_PATH}/common-product`;
export const NEW_COMMON_PRODUCT_PATH = `${COMMON_PRODUCT_PATH}/new`;
export const CARGO_CATEGORY_PATH = `${CARGO_UNIT_PATH}/cargo-category`;
export const NEW_CARGO_CATEGORY_PATH = `${CARGO_CATEGORY_PATH}/new`;
export const CARGO_MINIMAL_PRICE_PATH = `${CARGO_UNIT_PATH}/cargo-minimal-price`;
export const NEW_CARGO_MINIMAL_PRICE_PATH = `${CARGO_MINIMAL_PRICE_PATH}/new`;
export const CARGO_PATH = `${CARGO_UNIT_PATH}/cargo`;
export const NEW_CARGO_PATH = `${CARGO_PATH}/new`;
export const ADD_CARGO_ITEM_TO_ITINERARY_PATH = `${CARGO_UNIT_PATH}/add-item-to-itinerary`;
export const MANIFEST_PATH = `${CARGO_UNIT_PATH}/manifest`;
export const MANIFEST_ITINERARY_PATH = `${CARGO_UNIT_PATH}/manifest-itinerary`;
export const LEGAL_ABANDONMENT_PATH = `${CARGO_UNIT_PATH}/legal-abandonment`;
export const INTERNAL_TRANSFER_PATH = `${CARGO_UNIT_PATH}/internal-transfer`;
export const NEW_INTERNAL_TRANSFER_PATH = `${INTERNAL_TRANSFER_PATH}/new`;
export const UNLOADING_CARGO_PATH = `${CARGO_UNIT_PATH}/unloading-cargo`;
export const CARGO_PICKUP_PATH = `${CARGO_UNIT_PATH}/cargo-pickup`;
export const NEW_MANUAL_SENDER_REMISSION_GUIDE_PATH = `${CARGO_UNIT_PATH}/manual-sender-remission-guide/new`;
export const MANUAL_SENDER_REMISSION_GUIDE_PATH = `${CARGO_UNIT_PATH}/manual-sender-remission-guide`;
export const CARGO_ITEM_PATH = `${CARGO_UNIT_PATH}/item`;
export const INTERNAL_CARGO_ITEM_PATH = `${CARGO_UNIT_PATH}/internal/item`;
export const NEW_CARGO_CORPORATE_CREDIT_PATH = `${CARGO_UNIT_PATH}/corporate-credit/new`;
export const CARGO_CORPORATE_CREDIT_PATH = `${CARGO_UNIT_PATH}/corporate-credit`;
export const PARCEL_PRICE_PATH = `${CARGO_UNIT_PATH}/parcel-price-schedule`;
export const NEW_PARCEL_PRICE_PATH = `${PARCEL_PRICE_PATH}/new`;
export const PARCEL_PRICE_CONFIGURATION_PATH = `${CARGO_UNIT_PATH}/parcel-price-configuration`;
export const PARCEL_SERVICE_PATH = `${CARGO_UNIT_PATH}/parcel-service`;
export const PARCEL_SERVICE_TYPE_PATH = `${CARGO_UNIT_PATH}/parcel-service-type`;
export const NEW_PARCEL_SERVICE_TYPE_PATH = `${PARCEL_SERVICE_TYPE_PATH}/new`;

// contract unit
export const CONTRACT_UNIT_PATH = '/contract';
export const COMPANY_PATH = `${CONTRACT_UNIT_PATH}/third-party-company`;
export const NEW_COMPANY_PATH = `${COMPANY_PATH}/new`;
export const COMPANY_TICKET_PATH = `${CONTRACT_UNIT_PATH}/company-ticket`;
export const COMPANY_CREDIT_NOTE_PATH = `${CONTRACT_UNIT_PATH}/company-credit-note`;
export const COMPANY_USER_CONFIGURATION_PATH = `${CONTRACT_UNIT_PATH}/company-user-configuration`;
export const NEW_COMPANY_USER_CONFIGURATION_PATH = `${COMPANY_USER_CONFIGURATION_PATH}/new`;
export const PRIVATE_SERVICE_PATH = `${CONTRACT_UNIT_PATH}/private-service`;
export const NEW_PRIVATE_SERVICE_PATH = `${PRIVATE_SERVICE_PATH}/new`;
export const PAYMENT_PATH = `${CONTRACT_UNIT_PATH}/payment`;
export const NEW_PAYMENT_PATH = `${PAYMENT_PATH}/new`;
export const CONTRACTOR_PATH = `${CONTRACT_UNIT_PATH}/contractor`;
export const NEW_CONTRACTOR_PATH = `${CONTRACTOR_PATH}/new`;

// report unit
export const REPORT_UNIT_PATH = '/reports';
export const ITINERARY_REPORTS_PATH = `${REPORT_UNIT_PATH}/itinerary`;
export const NEW_ITINERARY_REPORT_PATH = `${ITINERARY_REPORTS_PATH}/new`;
export const TICKET_REPORTS_PATH = `${REPORT_UNIT_PATH}/ticket`;
export const NEW_TICKET_REPORT_PATH = `${TICKET_REPORTS_PATH}/new`;
export const NOT_BOARDED_TICKET_REPORTS_PATH = `${REPORT_UNIT_PATH}/not-boarded-ticket`;
export const NEW_NOT_BOARDED_TICKET_REPORT_PATH = `${NOT_BOARDED_TICKET_REPORTS_PATH}/new`;
export const OCCUPANCY_REPORTS_PATH = `${REPORT_UNIT_PATH}/occupancy`;
export const NEW_OCCUPANCY_REPORT_PATH = `${OCCUPANCY_REPORTS_PATH}/new`;
export const ACCOUNTING_REPORTS_PATH = `${REPORT_UNIT_PATH}/accounting`;
export const NEW_ACCOUNTING_REPORT_PATH = `${ACCOUNTING_REPORTS_PATH}/new`;
export const DISCOUNT_CODE_REPORTS_PATH = `${REPORT_UNIT_PATH}/discount-code`;
export const NEW_DISCOUNT_CODE_REPORT_PATH = `${DISCOUNT_CODE_REPORTS_PATH}/new`;
export const CALL_CENTER_REPORTS_PATH = `${REPORT_UNIT_PATH}/call-center`;
export const NEW_CALL_CENTER_REPORT_PATH = `${CALL_CENTER_REPORTS_PATH}/new`;
export const EXPENSE_REPORTS_PATH = `${REPORT_UNIT_PATH}/expense`;
export const NEW_EXPENSE_REPORT_PATH = `${EXPENSE_REPORTS_PATH}/new`;
export const PRICING_PROFILE_REPORTS_PATH = `${REPORT_UNIT_PATH}/pricing-profile`;
export const NEW_PRICING_PROFILE_REPORT_PATH = `${PRICING_PROFILE_REPORTS_PATH}/new`;

// system unit
export const SYSTEM_UNIT_PATH = '/system';
export const EVENT_PATH = `${SYSTEM_UNIT_PATH}/event`;
export const IN_SYSTEM_NOTIFICATION_PATH = `${SYSTEM_UNIT_PATH}/in-system-notification`;
export const NEW_IN_SYSTEM_NOTIFICATION_PATH = `${IN_SYSTEM_NOTIFICATION_PATH}/new`;

// maintenance unit
export const MECHANICAL_MAINTENANCE_UNIT_PATH = '/mechanical-maintenance';
export const ORGANIZATION_CHART_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/organization-chart`;
export const NEW_ORGANIZATION_CHART_PATH = `${ORGANIZATION_CHART_PATH}/new`;
export const MECHANIC_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/mechanic`;
export const NEW_MECHANIC_PATH = `${MECHANIC_PATH}/new`;
export const ACTIVITY_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/activity`;
export const NEW_ACTIVITY_PATH = `${ACTIVITY_PATH}/new`;
export const BUS_ACTIVITY_ASSIGNMENT_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/bus-activity-assigment`;
export const NEW_BUS_ACTIVITY_ASSIGNMENT_PATH = `${BUS_ACTIVITY_ASSIGNMENT_PATH}/assign`;
export const PREVENTIVE_MAINTENANCE_REPORT_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/preventive-maintenance-report`;
export const PREVENTIVE_EXECUTION_REGISTER_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/preventive-execution-register`;
export const ADVANCED_EXECUTION_REGISTER_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/advanced-execution-register`;
export const BUS_ACTIVITY_HISTORY_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/bus-activity-history`;
export const MATERIAL_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/material`;
export const NEW_MATERIAL_PATH = `${MATERIAL_PATH}/new`;
export const ACTIVITY_PROJECTION_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/activity-projection`;
export const ODOMETER_BY_BUS_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/odometer-by-bus`;
export const ACTIVITY_TASK_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/activity-task`;
export const NEW_ACTIVITY_TASK_PATH = `${ACTIVITY_TASK_PATH}/new`;
export const MAINTENANCE_WARNING_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/maintenance-occurrence-report`;
export const NEW_MAINTENANCE_WARNING_PATH = `${MAINTENANCE_WARNING_PATH}/new`;
export const MAINTENANCE_FAIL_MODE_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/maintenance-fail-mode`;
export const generateServiceOrderByMaintenanceWarningIdPath = (
  maintenanceWarningId = ':maintenanceWarningId',
) => `${MAINTENANCE_WARNING_PATH}/${maintenanceWarningId}/add-service-order`;
export const MAINTENANCE_SERVICE_ORDER_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/service-order`;
export const MY_MAINTENANCE_SERVICE_ORDER_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/my-service-orders`;
export const NEW_MAINTENANCE_SERVICE_ORDER_PATH = `${MAINTENANCE_SERVICE_ORDER_PATH}/new`;
export const generateEditMaintenanceServiceOrderStep1 = (
  maintenanceServiceOrderId,
) =>
  `${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/update-information`;
export const generateEditMaintenanceServiceOrderStep2 = (
  maintenanceServiceOrderId,
) =>
  `${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/assign-activities`;
export const generateEditMaintenanceServiceOrderStep3 = (
  maintenanceServiceOrderId,
) =>
  `${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/assign-employees`;
export const MAINTENANCE_TASK_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/maintenance-task`;
export const NEW_MAINTENANCE_TASK_PATH = `${MAINTENANCE_TASK_PATH}/new`;

export const MAINTENANCE_PLANNING_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/maintenance-planning`;

export const NEW_MAINTENANCE_PLANNING_PATH = `${MAINTENANCE_PLANNING_PATH}/new`;

export const MAINTENANCE_BUS_GROUP_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/maintenance-bus-group`;

export const NEW_MAINTENANCE_BUS_GROUP_PATH = `${MAINTENANCE_BUS_GROUP_PATH}/new`;

export const COMPANY_RESOURCE_PATH = `${MECHANICAL_MAINTENANCE_UNIT_PATH}/company-resource`;

export const NEW_COMPANY_RESOURCE_PATH = `${COMPANY_RESOURCE_PATH}/new`;
