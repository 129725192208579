import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCabinCrews,
  clearCabinCrews,
  getFreeCabinCrews,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';

class CabinCrewSelect extends Component {
  componentDidMount() {
    const { dispatchGetFreeCabinCrews, itineraryId } = this.props;

    if (itineraryId) dispatchGetFreeCabinCrews({ itineraryId });
  }

  componentWillUnmount() {
    const { dispatchClearCabinCrews } = this.props;
    dispatchClearCabinCrews();
  }

  handleCabinCrewChange = (inputValue) => {
    const { dispatchGetCabinCrews } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCabinCrews({ query: inputValue });
    }
  };

  render() {
    const {
      cabinCrews,
      isLoading,
      isMulti,
      input,
      meta,
      itineraryId,
      isDisabled,
      ...rest
    } = this.props;

    const props = {
      options: cabinCrews,
      isLoading,
      isMulti,
      input,
      meta,
      isDisabled,
      ...rest,
    };

    if (!itineraryId) {
      props.onInputChange = this.handleCabinCrewChange;
    }

    return <Select {...props} />;
  }
}

CabinCrewSelect.propTypes = {
  dispatchGetFreeCabinCrews: PropTypes.func.isRequired,
  dispatchGetCabinCrews: PropTypes.func.isRequired,
  dispatchClearCabinCrews: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  cabinCrews: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  itineraryId: PropTypes.number,
  isDisabled: PropTypes.bool,
};

CabinCrewSelect.defaultProps = {
  isLoading: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  cabinCrews: [],
  itineraryId: null,
  isDisabled: false,
};

const mapStateToProps = ({ TrafficUnit }) => {
  const cabinCrews = TrafficUnit.CabinCrew.getIn([
    'all',
    'content',
    'content',
  ]).map((cabinCrew) => ({
    value: cabinCrew.id,
    label: cabinCrew.customer.fullName,
  }));
  const loading = TrafficUnit.CabinCrew.getIn(['all', 'loading']);
  return {
    cabinCrews,
    loading,
  };
};

const mapDispatchToprops = {
  dispatchGetCabinCrews: getCabinCrews,
  dispatchGetFreeCabinCrews: getFreeCabinCrews,
  dispatchClearCabinCrews: clearCabinCrews,
};

export default connect(mapStateToProps, mapDispatchToprops)(CabinCrewSelect);
