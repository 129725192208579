import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { isRequired } from '../../../../../utils/validators';
import FormItem from '../../../../common/forms/FormItem';
import FormFooter from '../../../../common/forms/FormFooter';
import RegisteredBusSelect from '../../../../common/forms/select/RegisteredBusSelect';
import TextInput from '../../../../common/forms/input/TextInput';
import TripCircuitStatusSelect from '../../../../common/forms/select/TripCircuitStatusSelect';
import { getTotalPassengerSeats } from '../itinerary-for-counter/ItineraryForCounterForm';
import { generateDifferentBusCapacityWarningMessage } from '../../../../../config/messages';

const ItinerariesForSchedulingForm = ({
  handleSubmit,
  passengerSeatCapacity,
}) => {
  const [selectedSeatMap, setSelectedSeatMap] = useState(null);
  const [companyBusId, setCompanyBusId] = useState(null);

  let registeredBusRef;
  let tripCircuitStatusRef;

  useEffect(() => {
    registeredBusRef.focus();
  }, []);

  const onChangeRegisteredBus = (bus) => {
    if (tripCircuitStatusRef && tripCircuitStatusRef.focus)
      tripCircuitStatusRef.focus();

    if (bus.value) {
      setSelectedSeatMap(bus.seatMap);
      setCompanyBusId(bus.companyBusId);
    }
  };

  let warningComponent = null;
  if (selectedSeatMap && passengerSeatCapacity > 0) {
    const newTotalPassengerSeats = getTotalPassengerSeats(selectedSeatMap);
    if (newTotalPassengerSeats < passengerSeatCapacity) {
      warningComponent = (
        <span className="text-warning">
          <i className="fa fa-warning" /> <strong>Advertencia:</strong>{' '}
          {generateDifferentBusCapacityWarningMessage({
            newCompanyBusId: companyBusId,
            newTotalPassengerSeats,
            currentTotalPassengerSeats: passengerSeatCapacity,
          })}
          .
        </span>
      );
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Bus" required>
          <Field
            name="companyBusId"
            component={RegisteredBusSelect}
            onChange={onChangeRegisteredBus}
            validate={[isRequired]}
            isClearable={false}
            setFieldToBeFocused={(input) => {
              registeredBusRef = input;
            }}
            onlyActive
          />
          {warningComponent}
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Orden en Circuito" required>
          <Field
            name="tripCircuitStatus"
            component={TripCircuitStatusSelect}
            validate={[isRequired]}
            isClearable={false}
            setFieldToBeFocused={(input) => {
              tripCircuitStatusRef = input;
            }}
          />
        </FormItem>
      </FormGroup>
      <Field name="id" component={TextInput} type="hidden" />
      <FormFooter />
    </Form>
  );
};

ItinerariesForSchedulingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  passengerSeatCapacity: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ItinerariesForSchedulingForm',
})(ItinerariesForSchedulingForm);

export default connect(null, mapDispatchToProps)(formComponent);
