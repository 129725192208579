import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  maintenanceTaskDefaultProps,
  maintenanceTaskPropTypes,
} from './MaintenanceTaskBasicInformationPropTypes';

const MaintenanceTaskBasicInformationResource = ({
  description,
  workingHours,
  companyMaintenanceTaskId,
}) => (
  <>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="Código:">
      {companyMaintenanceTaskId || '-'}
    </ResourceProperty>
    <ResourceProperty label="Horas de Trabajo:">
      {workingHours || '-'}
    </ResourceProperty>
  </>
);

MaintenanceTaskBasicInformationResource.propTypes = maintenanceTaskPropTypes;

MaintenanceTaskBasicInformationResource.defaultProps =
  maintenanceTaskDefaultProps;

export default MaintenanceTaskBasicInformationResource;
