import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { SERVICE_MERGE_COLUMNS } from '../../../../config/columns';
import {
  NEW_SERVICE_MERGE_PATH,
  SERVICE_MERGE_PATH,
} from '../../../../config/paths';
import { clearServiceMerges, getServiceMerges } from '../../../../actions';

export const ServiceMerges = ({
  breadcrumbs,
  materials,
  loading,
  dispatchGetServiceMerges,
  dispatchClearServiceMerges,
}) => {
  useLayoutEffect(() => () => dispatchClearServiceMerges(), []);

  const data = materials.get('content') || [];
  const pages = materials.get('totalPages') || null;
  const defaultPageSize = materials.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de fusiones de servicio"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_SERVICE_MERGE_PATH}
      tableStructure={{
        columns: SERVICE_MERGE_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetServiceMerges,
        modelPath: SERVICE_MERGE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetServiceMerges: getServiceMerges,
  dispatchClearServiceMerges: clearServiceMerges,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de fusiones de servicio',
      href: SERVICE_MERGE_PATH,
    },
  ],
  materials: ItineraryUnit.ServiceMerge.getIn(['all', 'content']),
  loading: ItineraryUnit.ServiceMerge.getIn(['all', 'loading']),
});

ServiceMerges.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  materials: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetServiceMerges: PropTypes.func.isRequired,
  dispatchClearServiceMerges: PropTypes.func.isRequired,
};

ServiceMerges.defaultProps = {
  materials: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMerges);
