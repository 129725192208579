import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_BUSES_CHASSIS,
  FLAG_GETTING_BUSES_CHASSIS,
  GET_BUSES_CHASSIS,
} from '../types';
import { BUS_CHASSIS_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingBusesChassis = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_BUSES_CHASSIS,
  });

const getBusesChassis = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingBusesChassis(true));
    const query = tableFilters;
    const url = `${BUS_CHASSIS_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_BUSES_CHASSIS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingBusesChassis(false));
  }
};

const clearBusChassis = () => (dispatch) =>
  dispatch({ type: CLEAR_BUSES_CHASSIS });

export { getBusesChassis, clearBusChassis };
