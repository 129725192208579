import {
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  addQrCode,
  addText,
  createPdfDocument,
  paragraphSize,
  setFont,
  y,
} from '../pdf';

const myX = 3;
let myY = y;
const margin = 3;

const preFooter =
  'Autorizado mediante Resolución de Intendencia: Número 0180050002242/SUNAT Para mayor información sobre todos los términos y condiciones puede ingresar a nuestra página web: http://www.civa.com.pe';

const footer =
  'Al recibir el presente documento acepto todos los términos del contrato del servicio de transporte publicados en las agencias y página web. Documentos de embarque: el pasajero debe presentar obligatoriamente el boleto de viaje en físico o virtual y documento de identificación en físico (DNI o pasaporte o carnet de extranjería o cédula de identificación). Nota: La condición migratoria es responsabilidad del pasajero. Pasajeros menores de edad que viajen solos o en compañía de un adulto que no sean sus padres, deben presentar DNI y el acta de autorización de viaje notarial suscrita por uno de los padres para viajes nacionales, para viajes internacionales deben presentar el acta de autorización de viaje notarial suscrita por ambos padres. Cambio de titularidad/ postergación/ cambio de ruta, hora o tipo de servicio: 4 horas antes de la fecha y hora de viaje de manera presencial y 8 horas antes de la fecha y hora de viaje de manera virtual al siguiente correo electrónico postventa@civa.com.pe o a través de WhatsApp 986658043. El horario de atención es de 6:00 am - 10:00 pm, los requerimientos recibidos fuera de este horario se consideran como enviados a las 6:00 am del día siguiente y se evaluará si cumple con las 8 (ocho) horas de comunicación previa.';

const qrWidth = 35;
const qrHeight = 35;

const printLine = (doc, factor = 1) => {
  addText(
    doc,
    '----------------------------------------------------------------------------',
    40,
    (myY += margin * factor),
    80,
  );
};

const printCompanyFields = (doc) => {
  setFont(doc, 'bold');
  doc.setFontSize(paragraphSize);
  addText(doc, 'TURISMO CIVA S.A.C.', 40, myY, 80);
  setFont(doc);
  addText(doc, 'Si va de viaje, ¡viaje en Civa!', 40, (myY += margin), 80);
  addText(doc, 'R.U.C 20102427891', 40, (myY += margin), 80);
  addText(doc, 'JR SANCHO DE RIVERA 1184', 40, (myY += margin), 80);
  addText(doc, 'URB. MONSERRATE LIMA', 40, (myY += margin), 80);
  addText(doc, 'Central Telefónica: (511) 418-1111', 40, (myY += margin), 80);
  printLine(doc);
};

const printTicketFields = (
  doc,
  { issuingAgency, createDate, voucherType, ticketCode },
) => {
  const agencyName = doc.splitTextToSize(
    `Agencia de Emisión: ${issuingAgency}`,
    75,
  );
  addText(doc, agencyName, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Fecha/Hora Emisión: ${createDate}`,
    myX,
    (myY += margin * agencyName.length + 0.3),
    75,
    'left',
  );
  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += 2;

  const voucherText = doc.splitTextToSize(voucherType.toUpperCase(), 75);
  addText(doc, voucherText, 40, (myY += margin), 75);

  addText(doc, ticketCode, 40, (myY += 5.5 * voucherText.length), 75);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printTripFields = (
  doc,
  {
    origin,
    boarding,
    boardingAddress,
    disembarking,
    destination,
    disembarkingAddress,
    tripDate,
    tripTime,
    seatNumber,
    floor,
    serviceType,
    // foodType = '',
  },
) => {
  const newFoodType = 'Sin alimentación';

  setFont(doc, 'bold');
  addText(doc, 'DATOS DE VIAJE:', myX, (myY += margin * 2), 75, 'left');

  setFont(doc);
  addText(doc, 'Origen', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${origin}`, 23, myY, 55, 'left');

  setFont(doc, 'bold');
  addText(doc, 'Embarque', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${boarding}`, 23, myY, 55, 'left');
  const newBoardingAddress = doc.splitTextToSize(boardingAddress, 55);
  addText(doc, newBoardingAddress, 25, (myY += margin), 57, 'left');

  setFont(doc);
  addText(
    doc,
    'Destino',
    myX,
    (myY += margin * newBoardingAddress.length),
    30,
    'left',
  );
  addText(doc, `: ${destination}`, 23, myY, 55, 'left');

  setFont(doc, 'bold');
  addText(doc, 'Desembarque', myX, (myY += margin), 30, 'left');
  addText(doc, `: ${disembarking}`, 23, myY, 55, 'left');
  const newDisembarkingAddress = doc.splitTextToSize(disembarkingAddress, 55);
  addText(doc, newDisembarkingAddress, 25, (myY += margin), 57, 'left');

  setFont(doc);
  addText(
    doc,
    'Fecha',
    myX,
    (myY += margin * newDisembarkingAddress.length + margin * 2),
    15,
    'left',
  );
  addText(doc, `: ${tripDate}`, 15, myY, 25, 'left');

  addText(doc, 'Hora', 40, myY, 15, 'left');
  addText(doc, `: ${tripTime}`, 50, myY, 25, 'left');

  addText(doc, 'Asiento', myX, (myY += margin), 15, 'left');
  addText(doc, `: ${seatNumber}`, 15, myY, 25, 'left');

  addText(doc, 'Piso', 40, myY, 15, 'left');
  addText(doc, `: ${floor}`, 50, myY, 25, 'left');

  addText(doc, 'Servicio', myX, (myY += margin), 15, 'left');
  addText(doc, `: ${serviceType}`, 15, myY, 27, 'left');

  addText(doc, 'Alimentacion', 40, myY, 20, 'left');
  addText(doc, `: ${newFoodType}`, 56, myY, 25, 'left');

  myY += margin * 3;

  setFont(doc, 'bold');
  doc.setFontSize(13);
  addText(doc, '*Presentarse 45 minutos antes', 40, myY, 80);
  doc.setFontSize(paragraphSize);
  setFont(doc);
  printLine(doc);
};

const printPassengerFields = (
  doc,
  { fullName, identificationType, idDocumentNumber },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL PASAJERO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, `Nombre: ${fullName}`, myX, (myY += margin), 75, 'left');
  addText(
    doc,
    `Tipo Documento: ${identificationType}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `Numero de Documento: ${idDocumentNumber}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  printLine(doc);
};

const printPaymentFields = (
  doc,
  { ticketPrice, ticketPriceString, paymentMethod, userName },
) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DE PAGO:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(doc, 'OP. GRATUITO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'OP. EXONERADO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: ticketPrice })}`, myX + 34, myY);
  addText(doc, 'OP. INAFECTO', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'IGV', myX, (myY += margin), 75, 'left');
  addText(doc, `: ${numberFormatter({ value: 0 })}`, myX + 34, myY);
  addText(doc, 'IMPORTE TOTAL A PAGAR', myX, (myY += margin), 75, 'left');
  addText(doc, numberFormatter({ value: ticketPrice }), myX + 34, myY);
  addText(doc, 'Son:', myX, (myY += margin), 10, 'left');
  const newTicketPriceString = doc.splitTextToSize(ticketPriceString, 40);
  addText(doc, newTicketPriceString, myX + 6, myY, 40, 'left');
  addText(
    doc,
    'Tipo de Pago',
    myX,
    (myY += margin * newTicketPriceString.length),
    75,
    'left',
  );
  addText(doc, ':', myX + 34, myY);
  const newPaymentMethod = doc.splitTextToSize(paymentMethod, 40);
  addText(doc, newPaymentMethod, myX + 36, myY, 40, 'left');
  addText(
    doc,
    'Numero Tarjeta',
    myX,
    (myY += margin * newPaymentMethod.length),
    75,
    'left',
  );
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Numero Voucher', myX, (myY += margin), 75, 'left');
  addText(doc, `: -`, myX + 34, myY);
  addText(doc, 'Usuario', myX, (myY += margin), 75, 'left');
  addText(doc, ':', myX + 34, myY);
  const newUserName = doc.splitTextToSize(userName, 40);
  addText(doc, newUserName, myX + 35, myY, 40, 'left');

  setFont(doc, 'bold');
  doc.setFontSize(14);
  myY += newUserName.length * margin * 2 + (8 - newUserName.length * margin);
  addText(doc, 'Asegurado con SOAT RIMAC', 40, myY, 80);
  addText(doc, 'POLIZA N°2003-8678718', 40, (myY += 5.5), 80);
  doc.setFontSize(paragraphSize);
  addText(doc, preFooter, myX, (myY += margin * 2), 74, 'justify');
  myY += 15;
  setFont(doc);
  addText(doc, footer, myX, (myY += margin * 1), 74, 'justify');
};

const printBusinessFields = (doc, { companyDocumentNumber, companyName }) => {
  setFont(doc, 'bold');
  addText(doc, 'DATOS DEL CLIENTE:', myX, (myY += margin * 2), 75, 'left');
  setFont(doc);
  addText(
    doc,
    `RUC: ${companyDocumentNumber}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  const newCompanyName = doc.splitTextToSize(companyName, 75);
  addText(doc, newCompanyName, myX, (myY += margin), 75, 'left');
  printLine(doc, newCompanyName.length);
};

const printOperationDetails = (
  doc,
  { createDate, ticketCode, ticketPrice, voucherType, paymentMethod, userName },
) => {
  myY = 20;
  doc.addPage('p', 'mm', [80, 40]);
  setFont(doc, 'bold');
  addText(doc, 'Detalle de Operación', myX, (myY += margin), 75, 'left');
  setFont(doc);
  printLine(doc);
  addText(
    doc,
    `Fecha/Hora Emisión : ${createDate}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `${voucherType} : ${ticketCode}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `Tipo Pago : ${paymentMethod}`,
    myX,
    (myY += margin),
    75,
    'left',
  );
  addText(
    doc,
    `IMPORTE TOTAL A PAGAR : ${numberFormatter({ value: ticketPrice })}`,
    myX,
    (myY += margin),
  );
  addText(doc, `Usuario : ${userName}`, myX, (myY += margin));
};

const addTicket = async (
  doc,
  {
    ticketFields: { issuingAgency, createDate, voucherType, ticketCode },
    tripFields: {
      origin,
      boarding,
      boardingAddress,
      disembarking,
      destination,
      disembarkingAddress,
      tripDate,
      tripTime,
      seatNumber,
      floor,
      serviceType,
      foodType = '',
    },
    passengerFields: { fullName, identificationType, idDocumentNumber },
    paymentFields: {
      ticketPrice,
      ticketPriceString,
      paymentMethod,
      userName,
      companyDocumentNumber = null,
      companyName = null,
    },
  },
) => {
  await addQrCode(doc, ticketCode, 22.5, 0, qrWidth, qrHeight);
  myY += 32;
  printCompanyFields(doc);
  printTicketFields(doc, {
    issuingAgency,
    createDate,
    voucherType,
    ticketCode,
  });
  printTripFields(doc, {
    origin,
    boarding,
    boardingAddress,
    disembarking,
    destination,
    disembarkingAddress,
    tripDate,
    tripTime,
    seatNumber,
    floor,
    serviceType,
    foodType,
  });
  printPassengerFields(doc, {
    fullName,
    identificationType,
    idDocumentNumber,
  });
  // FACTURA
  if (companyDocumentNumber)
    printBusinessFields(doc, {
      companyDocumentNumber,
      companyName,
    });
  printPaymentFields(doc, {
    ticketPrice,
    ticketPriceString,
    paymentMethod,
    userName,
  });
  printOperationDetails(doc, {
    createDate,
    ticketCode,
    ticketPrice,
    voucherType,
    paymentMethod,
    userName,
  });
  myY = 5;
};

const formatPrintedTicket = (printedTicket) => {
  const formatedPrintedTicket = {
    ticketFields: {
      issuingAgency: printedTicket.issuingAgency,
      createDate: printedTicket.createDate,
      voucherType: printedTicket.voucherType,
      ticketCode: printedTicket.ticketCode,
    },
    tripFields: {
      origin: printedTicket.origin,
      boarding: printedTicket.boarding,
      boardingAddress: printedTicket.boardingAddress,
      disembarking: printedTicket.disembarking,
      destination: printedTicket.destination,
      disembarkingAddress: printedTicket.disembarkingAddress,
      tripDate: printedTicket.tripDate,
      tripTime: printedTicket.tripTime,
      seatNumber: printedTicket.seatNumber,
      floor: printedTicket.floor,
      serviceType: printedTicket.serviceType,
      foodType: printedTicket.foodType,
    },
    passengerFields: {
      fullName: printedTicket.passenger.fullName,
      identificationType: printedTicket.passenger.identificationType.name,
      idDocumentNumber: printedTicket.passenger.idDocumentNumber,
    },
    paymentFields: {
      ticketPrice: printedTicket.ticketPrice,
      ticketPriceString: printedTicket.ticketPriceString,
      paymentMethod: printedTicket.paymentMethod,
      userName: printedTicket.userName,
      companyDocumentNumber: printedTicket.companyDocumentNumber,
      companyName: printedTicket.companyName,
    },
  };
  return formatedPrintedTicket;
};

const printPrintedTickets = async (printedTickets) => {
  const height = 350;
  const doc = createPdfDocument('p', 'mm', [80, height]);

  // using for-of-loop for readability when using await inside a loop
  // where await is needed due to requirement of sequential steps
  // check for discussion: http://bit.ly/2JcMMLk
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, printedTicket] of printedTickets.entries()) {
    if (index > 0) {
      doc.addPage('p', 'mm', [80, height]);
      myY = 5;
    }

    const formatedPrintedTicket = formatPrintedTicket(printedTicket);
    // eslint-disable-next-line no-await-in-loop
    await addTicket(doc, formatedPrintedTicket);
  }
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

const printPrintedTicket = async (printedTicket) => {
  const doc = createPdfDocument('p', 'mm', [80, 330]);
  const formatedPrintedTicket = formatPrintedTicket(printedTicket);
  await addTicket(doc, formatedPrintedTicket);
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

const formatTicket = (ticket) => {
  const transaction = ticket.transactions[0];
  const formatedTicket = {
    ticketFields: {
      issuingAgency: `${ticket.agency.name}\n${ticket.agency.address}`,
      createDate: tzNormalizeDate({
        date: ticket.documentAssignmentDate,
        format: DATE_TIME_FORMAT,
      }),
      voucherType: transaction.voucherType.name,
      ticketCode: ticket.fullDocumentCode,
    },
    tripFields: {
      origin: ticket.sourceLocation.name,
      boarding: ticket.sourceLocation.name,
      boardingAddress: ticket.sourceLocation.address,
      disembarking: ticket.destinationLocation.name,
      destination: ticket.destinationLocation.name,
      disembarkingAddress: ticket.destinationLocation.address,
      tripDate: tzNormalizeDate({
        date: ticket.departureDate,
        format: DATE_FORMAT,
      }),
      tripTime: tzNormalizeDate({
        date: ticket.departureDate,
        format: TIME_FORMAT,
      }),
      seatNumber: ticket.seatReservation.seatNumber,
      floor: ticket.seatReservation.floorNumber,
      serviceType: ticket.itinerary.serviceType.name,
      foodType: ticket.food ? ticket.food.name : '',
    },
    passengerFields: {
      fullName: ticket.customer.fullName,
      identificationType: ticket.customer.identificationType.name,
      idDocumentNumber: ticket.customer.idDocumentNumber,
    },
    paymentFields: {
      ticketPrice: ticket.salePrice,
      ticketPriceString: ticket.salePriceString,
      paymentMethod: transaction.paymentMethod.name,
      userName: ticket.user.customer.fullName,
      companyDocumentNumber: transaction.business
        ? transaction.business.businessTaxId
        : undefined,
      companyName: transaction.business ? transaction.business.name : undefined,
    },
  };

  return formatedTicket;
};

const printTicket = async (ticket) => {
  const doc = createPdfDocument('p', 'mm', [80, 330]);
  const formatedTicket = formatTicket(ticket);
  await addTicket(doc, formatedTicket);
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

export { printPrintedTickets, printPrintedTicket, printTicket };
