import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { activityBasicInformationPropTypes } from '../proptypes/ActivityPropTypes';
import {
  ACTIVITY_ACTION,
  MAINTENANCE_ACTIVITY_TYPE,
} from '../../../../../config/constants';

const ActivityBasicInformationResource = ({
  companyActivityId,
  name,
  description,
  activityType,
  action,
}) => (
  <>
    <ResourceProperty label="Código de la Actividad:">
      {companyActivityId || '-'}
    </ResourceProperty>
    <ResourceProperty label="Acción:">
      {action ? ACTIVITY_ACTION[action].label : '-'}
    </ResourceProperty>
    <ResourceProperty label="Nombre de la Actividad:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Mantenimiento:">
      {activityType ? MAINTENANCE_ACTIVITY_TYPE[activityType].label : '-'}
    </ResourceProperty>
  </>
);

ActivityBasicInformationResource.propTypes = activityBasicInformationPropTypes;

export default ActivityBasicInformationResource;
