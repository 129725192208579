import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BUS_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { clearRegisteredBus, getRegisteredBus } from '../../../../actions';
import RegisteredBusBasicInformationResource from './resource/RegisteredBusBasicInformationResource';
import RegisteredBusToolbar from './RegisteredBusToolbar';

export const RegisteredBus = ({
  breadcrumbs,
  dispatchGetRegisteredBus,
  dispatchClearRegisteredBus,
  match: {
    params: { id: registeredBusId },
  },
  loading,
  registeredBus,
}) => {
  useLayoutEffect(() => {
    dispatchGetRegisteredBus({ registeredBusId });

    return () => dispatchClearRegisteredBus();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (registeredBus.isEmpty())
    content = <NoDataResource returnPage={BUS_PATH} />;
  else {
    const registeredBusJson = registeredBus.toJS();

    content = <RegisteredBusBasicInformationResource {...registeredBusJson} />;

    toolbar = <RegisteredBusToolbar registeredBusId={registeredBusId} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Bus"
      content={content}
    />
  );
};

RegisteredBus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearRegisteredBus: PropTypes.func.isRequired,
  dispatchGetRegisteredBus: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  registeredBus: PropTypes.instanceOf(Immutable.Map).isRequired,
};

RegisteredBus.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearRegisteredBus: clearRegisteredBus,
  dispatchGetRegisteredBus: getRegisteredBus,
};

const mapStateToProps = ({ BusUnit }) => ({
  breadcrumbs: [
    ...BusUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Buses',
      href: BUS_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  registeredBus: BusUnit.RegisteredBus.getIn(['current', 'content']),
  loading: !BusUnit.RegisteredBus.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredBus);
