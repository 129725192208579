import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import serviceMergeBasicInformationPropTypes from '../proptypes/ServiceMergePropTypes';
import Table from '../../../../common/Table';
import { TICKET_REASSIGNMENT_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { TICKET_PATH } from '../../../../../config/paths';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';

const ServiceMergeBasicInformationResource = ({
  id,
  sourceItineraryId,
  sourceItineraryRouteName,
  sourceItineraryDepartureTime,
  destinationItineraryId,
  destinationItineraryRouteName,
  destinationItineraryDepartureTime,
  ticketReassignmentList,
}) => (
  <>
    <ResourceProperty label="Código:">{id || '-'}</ResourceProperty>
    <ResourceProperty label="Itinerario de origen:">
      {`${sourceItineraryId} - ${sourceItineraryRouteName} - ${tzNormalizeDate({
        date: sourceItineraryDepartureTime,
        format: DATE_TIME_FORMAT,
      })}` || '-'}
    </ResourceProperty>
    <ResourceProperty label="Itinerario de destino:">
      {`${destinationItineraryId} - ${destinationItineraryRouteName} -  ${tzNormalizeDate(
        { date: destinationItineraryDepartureTime, format: DATE_TIME_FORMAT },
      )}` || '-'}
    </ResourceProperty>
    <h4>Reasignación de asientos</h4>
    <Table
      columns={TICKET_REASSIGNMENT_COLUMNS}
      data={ticketReassignmentList}
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
      modelPath={TICKET_PATH}
      navigateToModelOnRowClick
      openPathInNewTab
      modelId="ticketId"
    />
  </>
);

ServiceMergeBasicInformationResource.propTypes =
  serviceMergeBasicInformationPropTypes;

export default ServiceMergeBasicInformationResource;
