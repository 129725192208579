export const TIME_ZONE = 'America/Lima';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const FULL_DATE_TIME_FORMAT = 'dddd DD [de] MMMM [del] YYYY hh:mma';
export const FULL_DATE_FORMAT = 'dddd DD [de] MMMM [del] YYYY';
export const TIME_FORMAT = 'HH:mm';
export const LOCAL_TIME_FORMAT = 'hh:mma';
export const CURRENCY = 'PEN';
export const LOCALE = 'es';
export const TIMESTAMP_FORMAT = 'x';
export const PERUVIAN_LOCALE = 'es-PE';
export const INT_DATE_FORMAT = 'YYYY-MM-DD';
export const INT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const LOCAL_FULL_TIME_FORMAT = 'hh:mm:ss a';
export const YEAR_FORMAT = 'YYYY';
