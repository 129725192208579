export const FLAG_GETTING_MAINTENANCE_PLANNINGS =
  'FLAG_GETTING_MAINTENANCE_PLANNINGS';
export const GET_MAINTENANCE_PLANNINGS = 'GET_MAINTENANCE_PLANNINGS';
export const CLEAR_MAINTENANCE_PLANNINGS = 'CLEAR_MAINTENANCE_PLANNINGS';
export const FLAG_MAINTENANCE_PLANNING_ACTIVITY =
  'FLAG_MAINTENANCE_PLANNING_ACTIVITY';
export const GET_MAINTENANCE_PLANNING = 'GET_MAINTENANCE_PLANNING';
export const CLEAR_MAINTENANCE_PLANNING = 'CLEAR_MAINTENANCE_PLANNING';
export const GET_MAINTENANCE_PLANNING_ACTIVITIES =
  'GET_MAINTENANCE_PLANNING_ACTIVITIES';
export const CLEAR_MAINTENANCE_PLANNING_ACTIVITIES =
  'CLEAR_MAINTENANCE_PLANNING_ACTIVITIES';
