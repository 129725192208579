import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ServiceMergePrintButton from './ServiceMergePrintButton';

const ServiceMergeToolbar = ({ serviceMergeId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <ServiceMergePrintButton serviceMergeId={serviceMergeId} />
    </div>
  </ButtonToolbar>
);

ServiceMergeToolbar.propTypes = {
  serviceMergeId: PropTypes.number.isRequired,
};

export default ServiceMergeToolbar;
