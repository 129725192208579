import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import {
  isRequired,
  validateEndDate,
  validateNumber,
} from '../../../../../utils/validators';
import { tzDiff } from '../../../../../utils/date';
import { ACTIVITY_STATUS } from '../../../../../config/constants';
import FormItem from '../../../../common/forms/FormItem';
import { enumToSelectOptions } from '../../../../../utils/enum';
import TextInput from '../../../../common/forms/input/TextInput';
import MechanicSelect from '../../../../common/forms/select/MechanicSelect';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import LocationSelect from '../../../../common/forms/select/LocationSelect';
import FormFooter from '../../../../common/forms/FormFooter';
import { optionPropTypes } from '../../../../common/forms/select/SelectPropTypes';
import { datePropTypes } from '../../../../common/forms/input/InputPropTypes';
import Select from '../../../../common/forms/select/Select';

const validateMyEndDate = (value, allValues) =>
  validateEndDate(value, allValues.startDate);

const ServiceOrderExecutionForm = ({
  handleSubmit,
  initialValues,
  dispatchChange,
  disabledStatus,
}) => {
  let statusRef;
  let extensionFactorRef;
  let mechanicsRef;

  useEffect(() => {
    if (!disabledStatus) statusRef.focus();
    else mechanicsRef.focus();
  }, []);

  const [extensionFactorRequired, setExtensionFactorRequired] = useState(false);
  const [startDate, setStartDate] = useState(initialValues.startDate);
  const [endDate, setEndDate] = useState(initialValues.endDate);

  const onCalculateWorkingHours = ({ startDateSelected, endDateSelected }) => {
    let hours = tzDiff({
      startDate: startDateSelected,
      endDate: endDateSelected,
      unit: 'hours',
    });
    if (hours > 8) hours = 8;
    if (hours < 0) hours = 0;

    dispatchChange('ServiceOrderExecutionForm', 'workingHour', hours);
  };

  const onChangeStatus = (option) => {
    dispatchChange('ServiceOrderExecutionForm', 'extensionFactor', null);
    setExtensionFactorRequired(option.value === ACTIVITY_STATUS.EXTENDED.value);
    if (option.value === ACTIVITY_STATUS.EXTENDED.value)
      extensionFactorRef.focus();
    if (option.value === ACTIVITY_STATUS.DONE.value && mechanicsRef.focus)
      mechanicsRef.focus();
  };

  const onChangeStartDate = (e, value) => {
    setStartDate(value);
    onCalculateWorkingHours({
      startDateSelected: value,
      endDateSelected: endDate,
    });
  };

  const onChangeEndDate = (e, value) => {
    setEndDate(value);
    onCalculateWorkingHours({
      startDateSelected: startDate,
      endDateSelected: value,
    });
  };

  const validateExtensionFactor = [validateNumber];
  if (extensionFactorRequired) validateExtensionFactor.push(isRequired);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Estado" required>
          <Field
            name="status"
            component={Select}
            options={enumToSelectOptions(ACTIVITY_STATUS).splice(1, 2)}
            onChange={onChangeStatus}
            validate={[isRequired]}
            isClearable={false}
            setFieldToBeFocused={(input) => {
              statusRef = input;
            }}
            isDisabled={disabledStatus}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="KM a Agregar" required={extensionFactorRequired}>
          <Field
            name="extensionFactor"
            component={TextInput}
            type="text"
            placeholder="KM a Agregar"
            validate={validateExtensionFactor}
            setFieldToBeFocused={(input) => {
              extensionFactorRef = input;
            }}
            disabled={disabledStatus}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Personal" required>
          <Field
            name="mechanics"
            component={MechanicSelect}
            isMulti
            validate={[isRequired]}
            setFieldToBeFocused={(input) => {
              mechanicsRef = input;
            }}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha/Hora Inicio">
          <Field
            name="startDate"
            component={TextInput}
            type="datetime-local"
            placeholder={DATE_TIME_FORMAT}
            onChange={onChangeStartDate}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha/Hora Final" required>
          <Field
            name="endDate"
            component={TextInput}
            type="datetime-local"
            placeholder={DATE_TIME_FORMAT}
            onChange={onChangeEndDate}
            validate={[isRequired, validateMyEndDate]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Horas Trabajadas">
          <Field
            name="workingHour"
            component={TextInput}
            type="text"
            placeholder="Horas Trabajadas"
            validate={[validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Base" required>
          <Field
            name="baseLocationId"
            component={LocationSelect}
            forHomeBase
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Observaciones">
          <Field
            name="comment"
            component={TextInput}
            type="textarea"
            placeholder="Observaciones"
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

ServiceOrderExecutionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    status: optionPropTypes,
    startDate: datePropTypes,
    endDate: datePropTypes,
  }).isRequired,
  dispatchChange: PropTypes.func.isRequired,
  disabledStatus: PropTypes.bool,
};

ServiceOrderExecutionForm.defaultProps = {
  disabledStatus: false,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ServiceOrderExecutionForm',
})(ServiceOrderExecutionForm);

export default connect(null, mapDispatchToProps)(formComponent);
