import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { putInternalParcelPickup } from '../../../../actions';

const DeliverInternalParcelButton = ({
  internalParcelId,
  dispatchPutInternalParcelPickup,
}) => {
  const handleDeliverInternalParcel = () => {
    dispatchPutInternalParcelPickup({ internalParcelId });
  };

  return (
    <Button
      color="success"
      className="border"
      onClick={handleDeliverInternalParcel}
      disabled={!internalParcelId}
    >
      <i className="fa fa-truck" /> Entregar
    </Button>
  );
};

DeliverInternalParcelButton.propTypes = {
  internalParcelId: PropTypes.string.isRequired,
  dispatchPutInternalParcelPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutInternalParcelPickup: putInternalParcelPickup,
};

export default connect(null, mapDispatchToProps)(DeliverInternalParcelButton);
