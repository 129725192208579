import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MAINTENANCE_TASK_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceTaskForm from './MaintenanceTaskForm';
import { postMaintenanceTask } from '../../../../actions/mechanical-maintenance/MaintenanceTask';

const NewMaintenanceTask = ({ breadcrumbs, dispatchPostMaintenanceTask }) => {
  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    newFormValues.active = true;
    dispatchPostMaintenanceTask(newFormValues);
  };

  const content = <MaintenanceTaskForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo registro de Tarea"
      subtitle="Crear nuevo registro de Tarea"
      content={content}
    />
  );
};

NewMaintenanceTask.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenanceTask: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaintenanceTask: postMaintenanceTask,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tareas',
      href: MAINTENANCE_TASK_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMaintenanceTask);
