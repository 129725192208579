import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Label, FormText } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import {
  arrayMultiIsRequired,
  isRequired,
  validateEndDate,
  validateNumber,
} from '../../../../utils/validators';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import TextInput from '../../../common/forms/input/TextInput';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import OrganizationChartSelect from '../../../common/forms/select/OrganizationChartSelect';
import FormFooter from '../../../common/forms/FormFooter';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  ACTIVITY_STATUS,
  REGISTER_OF_EXECUTION_TYPE,
} from '../../../../config/constants';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../common/forms/select/SelectPropTypes';
import { REPORT_REGENERATE_INFO_MESSAGE } from '../../../../config/messages';
import Alert from '../../../common/informative/Alert';
import ActivitySelect from '../../../common/forms/select/ActivitySelect';
import CompanyResourceSelect from '../../../common/forms/select/CompanyResourceSelect';

const validateDate = (value, allValues) =>
  validateEndDate(value, allValues.dateFrom);

const validateBusOrCompanyResourceOrBase = (value, allValues) => {
  const { registeredBusIdList, companyResourceIdList, homeBaseLocationId } =
    allValues;

  const isNotEmpty = (val) => {
    if (val === null || val === undefined) {
      return false;
    }
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    if (typeof val === 'object') {
      return Object.keys(val).length > 0;
    }
    return val !== '';
  };

  const validFields = [
    registeredBusIdList,
    companyResourceIdList,
    homeBaseLocationId,
  ].filter(isNotEmpty);

  if (validFields.length === 0) {
    return 'Debe seleccionar al menos uno: Bus, Recurso de la Compañía o Base.';
  }

  if (validFields.length > 1) {
    return 'Solo debe seleccionar uno: Bus, Recurso de la Compañía o Base.';
  }

  return undefined;
};

const PreventiveMaintenanceReportSearchForm = ({
  handleSubmit,
  onModal,
  showSimulation,
  showDaily,
  showBase,
  registeredBusIsMulti,
  registeredBusIsRequired,
  loading,
  searchButtonDisabled,
  showOrganizationChart,
  isForDownload,
  disableDateTo,
  showDateFrom,
  showRegisterOfExecutionType,
  initialValues,
  showForceReport,
  dispatchChange,
  organizationCharts,
  loadingOrganizationChart,
  companyAreaIsMulti,
  showActivityField,
  showCompanyResource,
}) => {
  const [selectedType, setSelectedType] = useState(
    initialValues.registerOfExecutionType
      ? initialValues.registerOfExecutionType.value
      : REGISTER_OF_EXECUTION_TYPE.false.value,
  );
  const [forceReport, setForceReport] = useState(false);

  let formProps = { onSubmit: handleSubmit };
  let buttonProps = {
    saveButtonAction: null,
    saveButtonColor: 'secondary',
    saveButtonDisabled: loading || searchButtonDisabled,
    saveButtonIcon: 'fa fa-search',
    saveButtonType: 'submit',
    saveButtonText: 'Buscar',
  };

  const previousButtonProps = { ...buttonProps };
  if (onModal) {
    formProps = null;
    buttonProps = {
      ...previousButtonProps,
      saveButtonType: 'button',
      saveButtonAction: handleSubmit,
    };
  }

  if (isForDownload)
    buttonProps = {
      ...previousButtonProps,
      saveButtonText: 'Generar PDF',
      saveButtonIcon: 'fa fa-file-pdf-o',
      saveButtonColor: 'primary',
    };

  if (loading || searchButtonDisabled)
    buttonProps = {
      ...previousButtonProps,
      saveButtonIcon: 'fa fa-spinner fa-spin fa-fw',
      saveButtonText: 'Cargando...',
    };

  const dailyField = showDaily && (
    <FormGroup row>
      <FormItem>
        <Label>
          <Field name="daily" component="input" type="checkbox" /> Diario
        </Label>
      </FormItem>
    </FormGroup>
  );

  const simulationFields = showSimulation && (
    <FormGroup row>
      <FormItem label="Simulación">
        <Row>
          <Col md={6}>
            <Field
              name="simulationDays"
              component={TextInput}
              type="text"
              id="simulationDays"
              showToolTip
              placeholder="Simulación en Días"
              validate={[validateNumber]}
            />
          </Col>
          <Col md={6}>
            <Field
              name="simulationKilometers"
              component={TextInput}
              type="text"
              id="simulationKilometers"
              showToolTip
              placeholder="Simulación en Kilómetros"
              validate={[validateNumber]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
  );

  const forceAlertMessage = forceReport && (
    <Alert type="warning" message={REPORT_REGENERATE_INFO_MESSAGE} />
  );

  const onSelectAll = (checked) => {
    let companyAreaIdValue = null;
    if (checked) {
      companyAreaIdValue = organizationCharts;
    }
    dispatchChange(
      'PreventiveMaintenanceReportSearchForm',
      'companyAreaId',
      companyAreaIdValue,
    );
  };

  const activityField = showActivityField && (
    <FormGroup row>
      <FormItem label="Actividad">
        <Field name="activityId" component={ActivitySelect} />
      </FormItem>
    </FormGroup>
  );

  const organizationChartField = showOrganizationChart && (
    <>
      <FormGroup row>
        <FormItem label="Organigrama" required>
          <Field
            name="companyAreaId"
            component={OrganizationChartSelect}
            isMulti={companyAreaIsMulti}
            validate={[companyAreaIsMulti ? arrayMultiIsRequired : isRequired]}
          />
          {companyAreaIsMulti && (
            <Label>
              <Field
                name="selectAll"
                component="input"
                type="checkbox"
                onChange={(e) => onSelectAll(e.target.checked)}
                disabled={loadingOrganizationChart}
              />{' '}
              Seleccionar todos
            </Label>
          )}
        </FormItem>
      </FormGroup>
      {showForceReport && (
        <FormGroup row>
          <FormItem label="">
            <Label>
              <Field
                name="force"
                component="input"
                type="checkbox"
                onChange={(e) => setForceReport(e.target.checked)}
              />{' '}
              Forzar la generación del reporte
            </Label>
            {forceAlertMessage}
          </FormItem>
        </FormGroup>
      )}
    </>
  );

  const registerOfExecutionTypeMessage =
    selectedType === REGISTER_OF_EXECUTION_TYPE.true.value ? (
      <div>Mostrará todas las actividades sin importar el estado.</div>
    ) : (
      <div>
        Mostrará sólo las actividades con estado {ACTIVITY_STATUS.PENDING.label}
        .
      </div>
    );

  const maintenanceTypeField = showRegisterOfExecutionType && (
    <FormGroup row>
      <FormItem label="Tipo de Registro" required>
        <Field
          name="registerOfExecutionType"
          options={enumToSelectOptions(REGISTER_OF_EXECUTION_TYPE)}
          component={Select}
          onChange={(option) => setSelectedType(option.value)}
          validate={[isRequired]}
          isClearable={false}
          isDisabled
        />
        <FormText>{registerOfExecutionTypeMessage}</FormText>
      </FormItem>
    </FormGroup>
  );

  const busAndBaseLabel =
    showBase || showCompanyResource ? 'Bus/Base/Recurso' : 'Bus';

  const busField = (
    <Field
      name="registeredBusIdList"
      id="registeredBusIdList"
      component={RegisteredBusSelect}
      isMulti={registeredBusIsMulti}
      validate={[validateBusOrCompanyResourceOrBase]}
      autoFocus
      placeholder="Bus"
      showToolTip
    />
  );

  const companyResourceField = showCompanyResource && (
    <Field
      name="companyResourceIdList"
      id="companyResourceIdList"
      component={CompanyResourceSelect}
      placeholder="Recurso"
      showToolTip
      isMulti
      validate={[validateBusOrCompanyResourceOrBase]}
    />
  );

  const busAndBaseField =
    showBase || showCompanyResource ? (
      <Row>
        <Col>{busField}</Col>
        {showBase && (
          <Col>
            <Field
              name="homeBaseLocationId"
              id="homeBaseLocationId"
              component={LocationSelect}
              forHomeBase
              validate={[validateBusOrCompanyResourceOrBase]}
              placeholder="Base"
              showToolTip
              isClearable
            />
          </Col>
        )}
        {showCompanyResource && <Col>{companyResourceField}</Col>}
      </Row>
    ) : (
      <Row>
        <Col>{busField}</Col>
      </Row>
    );

  const dateToValidations = [isRequired];
  if (showDateFrom) dateToValidations.push(validateDate);

  const dateToField = (
    <Field
      name="dateTo"
      id="dateTo"
      component={TextInput}
      type="date"
      validate={dateToValidations}
      disabled={disableDateTo}
      showToolTip
      placeholder={showDateFrom ? 'Fecha Final' : 'Fecha'}
    />
  );

  const dateFromAndToField = showDateFrom ? (
    <Row>
      <Col>
        <Field
          name="dateFrom"
          id="dateFrom"
          component={TextInput}
          type="date"
          validate={[isRequired]}
          disabled={disableDateTo}
          showToolTip
          placeholder={showDateFrom ? 'Fecha Inicial' : null}
        />
      </Col>
      <Col>{dateToField}</Col>
    </Row>
  ) : (
    dateToField
  );

  return (
    <Form {...formProps}>
      {dailyField}
      <FormGroup row>
        <FormItem label={busAndBaseLabel} required={registeredBusIsRequired}>
          {busAndBaseField}
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label={showDateFrom ? 'Fechas' : 'Fecha'} required>
          {dateFromAndToField}
        </FormItem>
      </FormGroup>
      {organizationChartField}
      {activityField}
      {simulationFields}
      {maintenanceTypeField}
      <FormFooter {...buttonProps} />
    </Form>
  );
};

PreventiveMaintenanceReportSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    dateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    registerOfExecutionType: optionPropTypes,
  }),
  onModal: PropTypes.bool,
  showSimulation: PropTypes.bool,
  showDaily: PropTypes.bool,
  showBase: PropTypes.bool,
  registeredBusIsMulti: PropTypes.bool,
  registeredBusIsRequired: PropTypes.bool,
  searchButtonDisabled: PropTypes.bool,
  showOrganizationChart: PropTypes.bool,
  isForDownload: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  disableDateTo: PropTypes.bool,
  showDateFrom: PropTypes.bool,
  showRegisterOfExecutionType: PropTypes.bool,
  showForceReport: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  organizationCharts: optionsPropTypes,
  loadingOrganizationChart: PropTypes.bool.isRequired,
  companyAreaIsMulti: PropTypes.bool,
  showActivityField: PropTypes.bool,
  showCompanyResource: PropTypes.bool,
};

PreventiveMaintenanceReportSearchForm.defaultProps = {
  initialValues: { dateTo: null },
  onModal: false,
  showSimulation: false,
  showDaily: false,
  showBase: false,
  registeredBusIsMulti: false,
  registeredBusIsRequired: false,
  searchButtonDisabled: false,
  showOrganizationChart: false,
  isForDownload: false,
  disableDateTo: false,
  showDateFrom: false,
  showRegisterOfExecutionType: REGISTER_OF_EXECUTION_TYPE.false.value,
  showForceReport: false,
  organizationCharts: [],
  companyAreaIsMulti: false,
  showActivityField: false,
  showCompanyResource: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: MechanicalMaintenanceUnit.PreventiveMaintenanceReport.getIn([
    'all',
    'loading',
  ]),
  organizationCharts: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name, companyAreaType }) => ({
    value: id,
    label: `${name} (${companyAreaType.name})`,
    name,
  })),
  loadingOrganizationChart: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'PreventiveMaintenanceReportSearchForm',
})(PreventiveMaintenanceReportSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
