import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_MAINTENANCE_BUS_GROUP,
  CLEAR_MAINTENANCE_BUS_GROUPS,
  FLAG_GETTING_MAINTENANCE_BUS_GROUPS,
  FLAG_MAINTENANCE_BUS_GROUP_ACTIVITY,
  GET_MAINTENANCE_BUS_GROUP,
  GET_MAINTENANCE_BUS_GROUPS,
} from '../types/mechanical-maintenance';
import { MAINTENANCE_BUS_GROUP_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MAINTENANCE_BUS_GROUP_PATH } from '../../config/paths';

const flagMaintenanceBusGroup = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_BUS_GROUPS,
    payload: flag,
  });

const getMaintenanceBusGroups = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagMaintenanceBusGroup(true));
    const query = tableFilters;
    const url = `${MAINTENANCE_BUS_GROUP_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();

    dispatch({
      type: GET_MAINTENANCE_BUS_GROUPS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagMaintenanceBusGroup(false));
  }
};

const clearMaintenanceBusGroups = () => (dispatch) => {
  dispatch({
    type: CLEAR_MAINTENANCE_BUS_GROUPS,
  });
};

const flagMaintenanceBusGroupActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_BUS_GROUP_ACTIVITY,
    payload: flag,
  });

const getMaintenanceBusGroup =
  async ({ maintenanceBusGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceBusGroupActivity(true));
      const url = `${MAINTENANCE_BUS_GROUP_ENDPOINT}/${maintenanceBusGroupId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_BUS_GROUP,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceBusGroupActivity(false));
    }
  };

const clearMaintenanceBusGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_BUS_GROUP,
  });

const postMaintenanceBusGroup =
  async ({ name, description, registeredBusList }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceBusGroupActivity(true));

      const payload = {
        name,
        description,
        registeredBusList,
      };
      const url = MAINTENANCE_BUS_GROUP_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const busFuelGroup = await response.json();
      if (busFuelGroup) {
        dispatch(push(`${MAINTENANCE_BUS_GROUP_PATH}/${busFuelGroup.id}`));
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceBusGroupActivity(false));
    }
  };

const deleteMaintenanceBusGroup =
  async ({ maintenanceBusGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceBusGroupActivity(true));
      const url = `${MAINTENANCE_BUS_GROUP_ENDPOINT}/${maintenanceBusGroupId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_BUS_GROUP_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceBusGroupActivity(false));
    }
  };

const putMaintenanceBusGroup =
  async (maintenanceBusGroupId, { name, description, registeredBusList }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceBusGroupActivity(true));
      const payload = {
        id: maintenanceBusGroupId,
        name,
        description,
        registeredBusList,
      };
      const url = `${MAINTENANCE_BUS_GROUP_ENDPOINT}/${maintenanceBusGroupId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(`${MAINTENANCE_BUS_GROUP_PATH}/${maintenanceBusGroupId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceBusGroupActivity(false));
    }
  };

export {
  getMaintenanceBusGroups,
  clearMaintenanceBusGroups,
  getMaintenanceBusGroup,
  clearMaintenanceBusGroup,
  postMaintenanceBusGroup,
  putMaintenanceBusGroup,
  deleteMaintenanceBusGroup,
};
