import { string, shape, number, bool, arrayOf } from 'prop-types';

const companyAreaTypePropTypes = {
  id: number.isRequired,
  name: string.isRequired,
};

const materialPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  externalId: string,
  measurementUnit: string.isRequired,
};
const maintenanceTaskMaterialPropTypes = {
  id: number.isRequired,
  maintenanceTaskId: number.isRequired,
  material: shape(materialPropTypes),
  quantity: number.isRequired,
};

const maintenanceTaskPropTypes = {
  id: number.isRequired,
  description: string.isRequired,
  workingHours: number.isRequired,
  companyMaintenanceTaskId: string.isRequired,
  active: bool.isRequired,
  materialList: arrayOf(shape(maintenanceTaskMaterialPropTypes)).isRequired,
};

const maintenanceTaskDefaultProps = {
  description: null,
  companyArea: null,
  materialList: [],
};

export {
  maintenanceTaskPropTypes,
  maintenanceTaskDefaultProps,
  companyAreaTypePropTypes,
  materialPropTypes,
};
