import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import Select from './Select';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import { clearWorkstations, getWorkstations } from '../../../../actions';

class WorkstationSelect extends Component {
  componentDidMount() {
    const { dispatchGetWorkstations } = this.props;
    dispatchGetWorkstations(DEFAULT_QUERY_GET_ALL);
  }

  componentWillUnmount() {
    const { dispatchClearWorkstations } = this.props;
    dispatchClearWorkstations();
  }

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

WorkstationSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetWorkstations: PropTypes.func.isRequired,
  dispatchClearWorkstations: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

WorkstationSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  options: HumanResourcesUnit.Workstation.getIn([
    'all',
    'content',
    'content',
  ]).map((workstation) => ({
    value: workstation.id,
    label: workstation.name,
    serialNumber: workstation.serialNumber,
    printStationList: workstation.printStationList,
    contingency: workstation.contingency,
  })),
  loading: HumanResourcesUnit.Workstation.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetWorkstations: getWorkstations,
  dispatchClearWorkstations: clearWorkstations,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkstationSelect);
