import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ServiceMergeForm from './ServiceMergeForm';
import { postServiceManualMerge, postServiceMerge } from '../../../../actions';
import Content from '../../../layout/Content';
import { getSubstring } from '../../../../utils/string';
import { SERVICE_MERGE_PATH } from '../../../../config/paths';

export class NewServiceMerge extends Component {
  onSubmit = (formValues) => {
    const { dispatchPostServiceMerge, dispatchPostServiceManualMerge } =
      this.props;
    if (formValues.manual) {
      dispatchPostServiceManualMerge({
        ...formValues,
        sourceItineraryId: getSubstring(formValues.sourceItineraryId),
        destinationItineraryId: getSubstring(formValues.destinationItineraryId),
      });
      return;
    }
    dispatchPostServiceMerge({
      forced: formValues.forced,
      sourceItineraryId: getSubstring(formValues.sourceItineraryId),
      destinationItineraryId: getSubstring(formValues.destinationItineraryId),
      comment: formValues.comment,
    });
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Fusión Servicios - Trasbordo"
        subtitle="Fusión servicios - trasbordo"
        content={<ServiceMergeForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

NewServiceMerge.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostServiceMerge: PropTypes.func.isRequired,
  dispatchPostServiceManualMerge: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de fusiones de servicio',
      href: SERVICE_MERGE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostServiceMerge: postServiceMerge,
  dispatchPostServiceManualMerge: postServiceManualMerge,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewServiceMerge);
