import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_WARNING_PATH } from '../../../../config/paths';
import ChangeStatusMaintenanceWarningButton from './MaintenanceWarningRejectedButton';
import { MAINTENANCE_WARNING_STATUS } from '../../../../config/constants';
import DeleteMaintenanceWarningButton from './DeleteMaintenanceWarningButton';

const MaintenanceWarningToolbar = ({
  maintenanceWarningId,
  status,
  serviceOrderId,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      {status !== MAINTENANCE_WARNING_STATUS.REJECTED.value && (
        <>
          {serviceOrderId == null && (
            <ChangeStatusMaintenanceWarningButton
              maintenanceWarningId={maintenanceWarningId}
              action={MAINTENANCE_WARNING_STATUS.REJECTED.value}
            />
          )}{' '}
          <EditButton
            path={`${MAINTENANCE_WARNING_PATH}/${maintenanceWarningId}/edit`}
          />
        </>
      )}
      {status === MAINTENANCE_WARNING_STATUS.REJECTED.value && (
        <DeleteMaintenanceWarningButton
          maintenanceWarningId={maintenanceWarningId}
        />
      )}
    </div>
  </ButtonToolbar>
);

MaintenanceWarningToolbar.propTypes = {
  maintenanceWarningId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  serviceOrderId: PropTypes.number,
};

MaintenanceWarningToolbar.defaultProps = {
  serviceOrderId: null,
};

export default MaintenanceWarningToolbar;
