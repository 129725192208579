import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_REGISTERED_BUSES,
  GET_REGISTERED_BUSES,
  CLEAR_REGISTERED_BUSES,
  FLAG_REGISTERED_BUS_ACTIVITY,
  GET_REGISTERED_BUS,
  CLEAR_REGISTERED_BUS,
} from '../types';
import {
  REGISTERED_BUS_ENDPOINT,
  REGISTERED_BUS_ENDPOINT_V2,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { BUS_PATH } from '../../config/paths';

const flagGettingRegisteredBuses = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_REGISTERED_BUSES,
  });

const getRegisteredBuses = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingRegisteredBuses(true));
    const query = tableFilters;
    const url = `${REGISTERED_BUS_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_REGISTERED_BUSES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingRegisteredBuses(false));
  }
};

const getRegisteredBusesV2 = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingRegisteredBuses(true));
    const query = tableFilters;
    const url = `${REGISTERED_BUS_ENDPOINT_V2}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_REGISTERED_BUSES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingRegisteredBuses(false));
  }
};

const clearRegisteredBuses = () => (dispatch) =>
  dispatch({ type: CLEAR_REGISTERED_BUSES });

const flagRegisteredBusActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_REGISTERED_BUS_ACTIVITY,
    payload: flag,
  });

const postRegisteredBus =
  async ({
    companyBusId,
    licensePlate,
    homeBaseLocationId,
    busChassisId,
    chassisSerialNumber,
    busBodyworkId,
    bodyworkSerialNumber,
    seatMapId,
    year,
    documentSet,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagRegisteredBusActivity(true));
      const payload = {
        companyBusId,
        licensePlate,
        homeBaseLocationId,
        busChassisId,
        chassisSerialNumber,
        busBodyworkId,
        bodyworkSerialNumber,
        seatMapId,
        year,
        documentSet,
      };
      const url = REGISTERED_BUS_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const registeredBus = await promise.json();
      dispatch(push(`${BUS_PATH}/${registeredBus.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagRegisteredBusActivity(false));
    }
  };

const putRegisteredBus =
  async (
    registeredBusId,
    {
      companyBusId,
      licensePlate,
      homeBaseLocationId,
      busChassisId,
      chassisSerialNumber,
      busBodyworkId,
      bodyworkSerialNumber,
      seatMapId,
      year,
      documentSet,
      active,
      actualVirtualOdometer,
      busFuelGroupId,
      virtualOdometerStartDate,
      cargo,
      maintenanceBusGroupId,
      bulkSaleOnly,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagRegisteredBusActivity(true));
      const payload = {
        id: registeredBusId,
        companyBusId,
        licensePlate,
        homeBaseLocationId,
        busChassisId,
        chassisSerialNumber,
        busBodyworkId,
        bodyworkSerialNumber,
        seatMapId,
        year,
        documentSet,
        active,
        actualVirtualOdometer,
        busFuelGroupId,
        virtualOdometerStartDate,
        cargo,
        maintenanceBusGroupId,
        bulkSaleOnly,
      };
      const url = `${REGISTERED_BUS_ENDPOINT}/${registeredBusId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const registeredBus = await promise.json();
      dispatch(push(`${BUS_PATH}/${registeredBus.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagRegisteredBusActivity(false));
    }
  };

const getRegisteredBus =
  async ({ registeredBusId }) =>
  async (dispatch) => {
    try {
      dispatch(flagRegisteredBusActivity(true));
      const url = `${REGISTERED_BUS_ENDPOINT}/${registeredBusId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_REGISTERED_BUS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagRegisteredBusActivity(false));
    }
  };

const clearRegisteredBus = () => (dispatch) =>
  dispatch({
    type: CLEAR_REGISTERED_BUS,
  });

export {
  clearRegisteredBuses,
  getRegisteredBuses,
  postRegisteredBus,
  getRegisteredBus,
  clearRegisteredBus,
  putRegisteredBus,
  getRegisteredBusesV2,
};
