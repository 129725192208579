import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { Field, reduxForm, Form, change } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import {
  arrayMultiIsRequired,
  isRequired,
} from '../../../../../utils/validators';
import TextInput from '../../../../common/forms/input/TextInput';
import Loader from '../../../../common/Loader';
import DatePicker from '../../../../common/forms/input/DatePicker';
import DriverSelect from '../../../../common/forms/select/DriverSelect';
import CabinCrewSelect from '../../../../common/forms/select/CabinCrewSelect';
import RegisteredBusSelect from '../../../../common/forms/select/RegisteredBusSelect';
import {
  getDriverBusAssignments,
  clearDriverBusAssignments,
} from '../../../../../actions';
import FormFooter from '../../../../common/forms/FormFooter';
import { optionPropTypes } from '../../../../common/forms/select/SelectPropTypes';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import { generateDifferentBusCapacityWarningMessage } from '../../../../../config/messages';

export const getTotalPassengerSeats = (seatMap) =>
  seatMap.seats.reduce((accumulated, seat) => {
    let increment = 0;
    if (seat.seatMapElement.isSeat) increment = 1;
    return accumulated + increment;
  }, 0);

const ItineraryForCounterForm = ({
  driversAssignment,
  dispatchChange,
  dispatchGetDriverBusAssignments,
  dispatchClearDriverBusAssignments,
  loading,
  handleSubmit,
  loadingDriversAssignment,
  onSubmit,
  initialValues,
  seatMap,
}) => {
  const [isChangingDrivers, setIsChangingDrivers] = useState(false);
  const [companyBusId, setCompanyBusId] = useState(
    initialValues.companyBusId.value,
  );
  const [selectedSeatMap, setSelectedSeatMap] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (isChangingDrivers && driversAssignment.length > 0) {
      const drivers = [];

      driversAssignment.forEach((driver) => {
        const { driverAssignmentDateList } = driver;
        drivers.push({
          value: driverAssignmentDateList[0].driverId,
          label: driverAssignmentDateList[0].driver.driverName,
        });
      });

      dispatchChange('ItineraryForCounterForm', 'driverIdList', drivers);

      if (driversAssignment.length > 0) setIsChangingDrivers(false);
    }
  }, [isChangingDrivers, driversAssignment]);

  const onHandleSubmit = (values) => {
    if (selectedSeatMap) {
      const currentTotalPassengerSeats = getTotalPassengerSeats(seatMap);
      const newTotalPassengerSeats = getTotalPassengerSeats(selectedSeatMap);
      if (newTotalPassengerSeats < currentTotalPassengerSeats) {
        const body = (
          <>
            {generateDifferentBusCapacityWarningMessage({
              currentCompanyBusId: initialValues.companyBusId.value,
              newCompanyBusId: companyBusId,
              newTotalPassengerSeats,
              currentTotalPassengerSeats,
            })}
            . Este cambio puede alterar los boletos emitidos. ¿Desea continuar
            con el cambio?
          </>
        );
        setFormValues(values);
        setModalBody(body);
        setShowModal(true);
      } else onSubmit(values);
    } else onSubmit(values);
  };

  const onRegisteredBusChange = (bus) => {
    dispatchClearDriverBusAssignments();
    dispatchChange('ItineraryForCounterForm', 'driverIdList', []);

    if (bus.value) {
      setIsChangingDrivers(true);
      setSelectedSeatMap(bus.seatMap);
      setCompanyBusId(bus.companyBusId);
      dispatchGetDriverBusAssignments({ query: `companyBusId:${bus.label}` });
    }
  };

  if (loading || loadingDriversAssignment) return <Loader />;

  let warningComponent = null;
  if (selectedSeatMap) {
    const currentTotalPassengerSeats = getTotalPassengerSeats(seatMap);
    const newTotalPassengerSeats = getTotalPassengerSeats(selectedSeatMap);
    if (newTotalPassengerSeats < currentTotalPassengerSeats) {
      warningComponent = (
        <span className="text-warning">
          <i className="fa fa-warning" /> <strong>Advertencia:</strong>{' '}
          {generateDifferentBusCapacityWarningMessage({
            currentCompanyBusId: initialValues.companyBusId.value,
            newCompanyBusId: companyBusId,
            newTotalPassengerSeats,
            currentTotalPassengerSeats,
          })}
          .
        </span>
      );
    }
  }

  const modalTitle = (
    <>
      <i className="text-warning fa fa-warning" /> Advertencia
    </>
  );

  return (
    <>
      <ConfirmationModal
        show={showModal}
        body={modalBody}
        onClickConfirm={() => onSubmit(formValues)}
        onClickCancel={() => setShowModal(false)}
        cancelButtonColor="warning"
        title={modalTitle}
      />
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Número de Bus" required>
            <Field
              name="companyBusId"
              component={RegisteredBusSelect}
              onChange={onRegisteredBusChange}
              isClearable={false}
              validate={[isRequired]}
              onlyActive
            />
            {warningComponent}
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Conductores" required>
            <Field
              name="driverIdList"
              component={DriverSelect}
              isClearable={false}
              isMulti
              validate={[arrayMultiIsRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Servicio a Bordo">
            <Field
              name="cabinCrewIdList"
              component={CabinCrewSelect}
              isClearable
              isMulti
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hora Real de Salida">
            <Field
              name="actualDepartureTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Hora Real de Llegada">
            <Field
              name="actualArrivalTime"
              props={{
                time: true,
              }}
              component={DatePicker}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Observaciones Adicionales">
            <Field
              name="comment"
              component={TextInput}
              type="textarea"
              placeholder="Observaciones Adicionales"
            />
          </FormItem>
        </FormGroup>
        <FormFooter />
      </Form>
    </>
  );
};

ItineraryForCounterForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    companyBusId: optionPropTypes,
    comment: PropTypes.string,
  }),
  dispatchGetDriverBusAssignments: PropTypes.func.isRequired,
  dispatchClearDriverBusAssignments: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loadingDriversAssignment: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  driversAssignment: PropTypes.arrayOf(PropTypes.object),
  seatMap: PropTypes.shape({}).isRequired,
};

ItineraryForCounterForm.defaultProps = {
  initialValues: {},
  loadingDriversAssignment: false,
  driversAssignment: [],
};

const mapStateToProps = ({ ItineraryUnit, TrafficUnit }) => ({
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  loadingDriversAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'loading',
  ]),
  driversAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'content',
  ]).get('content'),
});

const mapDispatchToProps = {
  dispatchGetDriverBusAssignments: getDriverBusAssignments,
  dispatchClearDriverBusAssignments: clearDriverBusAssignments,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ItineraryForCounterForm',
})(ItineraryForCounterForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
