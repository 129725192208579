import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MAINTENANCE_PLANNING_ACTIVITY_RESOURCE_ENDPOINT } from '../../config/endpoints';
import {
  CLEAR_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
  FLAG_GETTING_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
  GET_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
} from '../types';

const flagGettingPlanningActivitiesResources = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
    payload: flag,
  });

const getMaintenancePlanningActivitiesResources =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingPlanningActivitiesResources(true));
      const url = `${MAINTENANCE_PLANNING_ACTIVITY_RESOURCE_ENDPOINT}?${QueryString.stringify(
        tableFilters,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingPlanningActivitiesResources(false));
    }
  };

const clearMaintenancePlanningActivitiesResources = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_PLANNING_ACTIVITY_RESOURCES,
  });

export {
  getMaintenancePlanningActivitiesResources,
  clearMaintenancePlanningActivitiesResources,
};
