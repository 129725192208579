import React from 'react';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../../config/constants';
import EnumStatus from '../../../../common/EnumStatus';
import {
  maintenanceServiceOrderBasicDefaultProps,
  maintenanceServiceOrderBasicInformationPropTypes,
} from '../prop-types/MaintenanceServiceOrderPropTypes';
import Badge from '../../../../common/Badge';

const MaintenanceServiceOrderBasicInformation = ({
  deadline,
  priority,
  description,
  status,
  registeredBus,
  companyResource,
}) => (
  <>
    {registeredBus !== null && (
      <ResourceProperty label="Bus:">
        {registeredBus ? registeredBus.companyBusId : '-'}
      </ResourceProperty>
    )}
    {companyResource !== null && (
      <ResourceProperty label="Recurso de la Compañia:">
        {companyResource ? companyResource.name : '-'}
      </ResourceProperty>
    )}
    <ResourceProperty label="Fecha Límite:">
      {deadline
        ? tzNormalizeDate({ date: deadline, format: DATE_TIME_FORMAT })
        : '-'}
    </ResourceProperty>
    <ResourceProperty label="Prioridad:">
      {priority ? (
        <Badge
          text={MAINTENANCE_SERVICE_ORDER_PRIORITY[priority].label}
          color={MAINTENANCE_SERVICE_ORDER_PRIORITY[priority].color}
        />
      ) : (
        '-'
      )}
    </ResourceProperty>
    <ResourceProperty label="Descripción:">{description}</ResourceProperty>
    <ResourceProperty label="Estado:">
      <EnumStatus
        enumObject={MAINTENANCE_SERVICE_ORDER_STATUS}
        enumValue={status}
      />
    </ResourceProperty>
  </>
);

MaintenanceServiceOrderBasicInformation.propTypes =
  maintenanceServiceOrderBasicInformationPropTypes;

MaintenanceServiceOrderBasicInformation.defaultProps =
  maintenanceServiceOrderBasicDefaultProps;

export default MaintenanceServiceOrderBasicInformation;
