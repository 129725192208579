import {
  COLOR,
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../config/constants';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../config/locale';
import LogoCiva from '../../resources/images/logo-civa.png';
import { tzNormalizeDate } from '../date';
import {
  x,
  y,
  createPdfDocument,
  addText,
  addImage,
  paragraphSize,
  titleSize,
  setFont,
  isNewPageNeeded,
  cellHeight,
  textSpacing,
  subtitleSize,
  reduceText,
} from '../pdf';

const initialY = y;
let myY = initialY;
let pageWidth = 0;
const newTitleSize = titleSize - 2;

let page = 1;

let serviceOrderId = 0;

const printTableHeader = (doc) => {
  doc.setFontSize(subtitleSize);
  setFont(doc, 'bold');
  addText(
    doc,
    'Actividades de Mantenimiento',
    pageWidth / 2,
    (myY += textSpacing * 2),
    pageWidth,
  );

  doc.rect(x, (myY += 1.5), pageWidth - 10, cellHeight);
  doc.line(22, myY, 22, myY + cellHeight);
  doc.line(38, myY, 38, myY + cellHeight);
  doc.line(68, myY, 68, myY + cellHeight);
  doc.line(85, myY, 85, myY + cellHeight);
  doc.line(144, myY, 144, myY + cellHeight);
  doc.line(160, myY, 160, myY + cellHeight);
  doc.line(191, myY, 191, myY + cellHeight);

  doc.setFontSize(paragraphSize);
  addText(doc, 'Fecha', 13, (myY += textSpacing), 16, 'center');
  addText(doc, 'Tipo Act.', 30, myY, 16, 'center');
  addText(doc, 'Subcategoria', 53, myY, 28, 'center');
  addText(doc, 'ID Act.', 76, myY, 17, 'center');
  addText(doc, 'Nombre de Actividad', 115, myY, 55, 'center');
  addText(doc, 'Prioridad', 152, myY, 16, 'center');
  addText(doc, 'Reportador', 175, myY, 28, 'center');
  addText(doc, 'ID', 198, myY, 12, 'center');

  setFont(doc);
};

const printDocumentHeader = async (doc, data) => {
  const {
    registeredBus,
    companyResource,
    status,
    priority,
    createDate,
    deadline,
    lastUpdate,
    description,
  } = data;

  const companyBusId = registeredBus ? registeredBus.companyBusId : null;
  const resourceName = companyResource ? companyResource.name : null;

  addImage(doc, LogoCiva, x, myY - 2, 35, 10);

  doc.setFontSize(newTitleSize);
  setFont(doc, 'bold');
  addText(
    doc,
    'Departamento de Mantenimiento - CIVA',
    pageWidth / 2,
    (myY += 5),
    pageWidth,
  );

  addText(
    doc,
    `Orden de Servicio: #${serviceOrderId}`,
    pageWidth / 2,
    (myY += 8),
    pageWidth,
  );

  doc.setFontSize(paragraphSize);

  // Mostrar Bus / Unidad según la lógica condicional
  setFont(doc, 'bold');
  if (registeredBus) {
    addText(doc, 'Bus / Unidad:', 35, (myY += textSpacing), 30, 'right');
  }
  addText(doc, companyBusId || resourceName || '-', 45, myY, 30);

  addText(doc, 'Fecha de Creacion:', 173, myY, 35, 'right');
  setFont(doc);
  addText(
    doc,
    tzNormalizeDate({ date: createDate, format: DATE_TIME_FORMAT }),
    190,
    myY,
    30,
  );

  setFont(doc, 'bold');
  addText(doc, 'Estado:', 35, (myY += textSpacing), 30, 'right');
  doc.setTextColor(MAINTENANCE_SERVICE_ORDER_STATUS[status].hexColor);
  addText(doc, MAINTENANCE_SERVICE_ORDER_STATUS[status].label, 45, myY, 30);
  doc.setTextColor(COLOR.black);

  setFont(doc);
  addText(doc, 'Fecha Limite:', 173, myY, 35, 'right');
  addText(
    doc,
    tzNormalizeDate({ date: deadline, format: DATE_TIME_FORMAT }),
    190,
    myY,
    30,
  );

  addText(doc, 'Prioridad:', 35, (myY += textSpacing), 30, 'right');
  addText(doc, MAINTENANCE_SERVICE_ORDER_PRIORITY[priority].label, 45, myY, 30);

  setFont(doc);
  addText(doc, 'Hoja:', 173, myY, 35, 'right');
  addText(doc, page.toString(), 190, myY, 30);

  addText(
    doc,
    'Fecha Ult. Actualizacion:',
    173,
    (myY += textSpacing),
    35,
    'right',
  );
  addText(
    doc,
    tzNormalizeDate({ date: lastUpdate, format: DATE_TIME_FORMAT }),
    190,
    myY,
    30,
  );

  if (description) {
    const descriptionText = doc.splitTextToSize(
      `Observaciones: ${description}`,
      pageWidth - x * 2,
    );
    descriptionText.forEach((text) => {
      addText(doc, text, x, (myY += textSpacing), pageWidth - x * 2, 'left');
    });
  }

  printTableHeader(doc);
};

const addPage = (doc, height, data) => {
  myY = isNewPageNeeded(doc, x, myY, height);

  if (myY === 5) {
    page += 1;
    printDocumentHeader(doc, data);
  }
};

const printTableBody = (doc, warning) => {
  const {
    issueDate,
    subCategory,
    priority,
    reporterCustomer,
    id,
    creationType,
    comments,
    maintenancePlanningActivityResource: {
      maintenancePlanningActivityOrder,
    } = {},
  } = warning;

  const activityName = maintenancePlanningActivityOrder.activity.name || '-';
  const companyActivityId =
    maintenancePlanningActivityOrder.activity.companyActivityId || '-';

  let newHeight = cellHeight * 2 + 19;

  if (comments) {
    const commentText = doc.splitTextToSize(
      `Comentarios: ${comments}`,
      pageWidth - x - 11,
    );
    newHeight += 4 + textSpacing * commentText.length;
  }

  addPage(doc, newHeight, warning);

  doc.rect(x, (myY += 4), pageWidth - 10, cellHeight);

  addText(
    doc,
    tzNormalizeDate({ date: issueDate, format: DATE_FORMAT }),
    13,
    (myY += textSpacing),
    16,
    'center',
  );
  addText(
    doc,
    creationType ? creationType.substring(0, 3).concat('.') : '-',
    30,
    myY,
    16,
    'center',
  );

  const subCategoryName = subCategory
    ? reduceText(doc, subCategory.name, 28)
    : '-';
  addText(doc, subCategoryName, 53, myY, 28, 'center');

  addText(doc, companyActivityId, 76, myY, 17, 'center');

  addText(doc, reduceText(doc, activityName, 55), 115, myY, 55, 'center');

  doc.setTextColor(
    MAINTENANCE_SERVICE_ORDER_PRIORITY[priority].hexColor || COLOR.black,
  );
  addText(
    doc,
    MAINTENANCE_SERVICE_ORDER_PRIORITY[priority].label || '-',
    152,
    myY,
    16,
    'center',
  );
  doc.setTextColor(COLOR.black);

  const reporterName = reduceText(doc, reporterCustomer.fullName || '-', 28);
  addText(doc, reporterName, 175, myY, 28, 'center');
  addText(doc, id.toString(), 198, myY, 12, 'center');

  if (comments) {
    const initialYOfLine = myY + 2;
    const commentText = doc.splitTextToSize(
      `Comentarios: ${comments}`,
      pageWidth - x - 11,
    );

    commentText.forEach((text, index) => {
      if (index === 0) myY += 2;
      addText(doc, text, x + 1, (myY += textSpacing), pageWidth - 11, 'left');
    });
    doc.line(x, initialYOfLine, x, myY + 2);
    doc.line(pageWidth - x, initialYOfLine, pageWidth - x, myY + 2);
  }

  doc.rect(x, (myY += 2), 139, 19);
  doc.rect(144, myY, 61, 19);
  addText(
    doc,
    'Resultados de Mantenimiento:',
    x + 1,
    (myY += textSpacing),
    138,
    'left',
  );
  addText(doc, 'Realizado por:', 145, myY, 62, 'left');
};

const printMaintenanceServiceOrder = (data) => {
  const doc = createPdfDocument();
  pageWidth = doc.internal.pageSize.getWidth();
  page = 1;
  myY = initialY;

  serviceOrderId = data.id;

  printDocumentHeader(doc, data);
  data.maintenanceWarningList.forEach((warning) => {
    printTableBody(doc, warning);
    myY += 13;
  });

  window.open(doc.output('bloburl'), '_blank');
};

export default printMaintenanceServiceOrder;
