import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ButtonToolbar,
} from 'reactstrap';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  getItinerarySeatMap,
  clearItinerarySeatMap,
  clearItineraryPassengerList,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import SeatMap from '../../../modules/booking/seats/SeatMap';
import SeatSelectionHeader from '../../../modules/booking/seats/SeatSelectionHeader';
import { Table } from '../../../common/Table';
import { getPrintedMergedTickets } from '../../../../actions/itinerary';
import { printPrintedTickets } from '../../../../utils/printers/Ticket';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import { isRequired } from '../../../../utils/validators';
import { padStart } from '../../../../utils/string';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import Select from '../../../common/forms/select/Select';

const selector = formValueSelector('ServiceMergeForm');

export const ServiceMergeForm = ({
  onSubmit,
  handleSubmit,
  ticketsIds,
  serviceMergeId,
  sourceItinerary,
  loadingServiceMerge,
  destinationItinerary,
  loadingSourceItinerary,
  passengerListSourceItinerary,
  loadingDestinationItinerary,
  sourceItinerarySourceLocation,
  passengerListDestinationItinerary,
  sourceItineraryDestinationLocation,
  destinationItinerarySourceLocation,
  destinationItineraryDestinationLocation,
  dispatchChange,
  dispatchGetItinerarySeatMap,

  dispatchClearItinerarySeatMap,
  dispatchGetPrintedMergedTickets,
  dispatchClearItineraryPassengerList,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearItinerarySeatMap({ type: 'source' });
      dispatchClearItinerarySeatMap({ type: '' });
      dispatchClearItineraryPassengerList({ type: 'source' });
      dispatchClearItineraryPassengerList({ type: '' });
    },
    [],
  );

  const [manualMigrate, setManualMigrate] = useState(false);
  const [seatsSelected, setSeatsSelected] = useState(Immutable.Set());
  const [changingSeatSelection, setChangingSeatSelection] = useState(
    Immutable.Set(),
  );
  const [initialSourceItinerary, setInitialSourceItinerary] = useState(null);
  const [initialDestinationItinerary, setInitialDestinationItinerary] =
    useState(null);
  const [sourceLocationOnSourceItinerary, setSourceLocationOnSourceItinerary] =
    useState([]);
  const [
    destinationLocationOnSourceItinerary,
    setDestionationLocationOnSourceItinerary,
  ] = useState([]);
  const [
    sourceLocationOnDestinationItinerary,
    setSourceLocationOnDestinationItinerary,
  ] = useState([]);
  const [
    destinationLocationOnDestinationItinerary,
    setDestionationLocationOnDestinationItinerary,
  ] = useState([]);

  const getUniqueLocations = (
    itinerary,
    getDestinations,
    getOrigins,
    startingLocationId = null,
  ) => {
    const uniqueLocations = [];
    const locationSet = new Set();
    let foundStartingLocation = !startingLocationId;

    itinerary.get('itinerarySegmentList').forEach((segment) => {
      const { sourceLocation, destinationLocation } = segment.segment;

      if (!foundStartingLocation && sourceLocation.id === startingLocationId) {
        foundStartingLocation = true;
      }

      if (getDestinations && foundStartingLocation) {
        if (!locationSet.has(destinationLocation.id)) {
          uniqueLocations.push({
            id: destinationLocation.id,
            name: destinationLocation.name,
          });
          locationSet.add(destinationLocation.id);
        }
      }

      if (getOrigins && foundStartingLocation) {
        if (!locationSet.has(sourceLocation.id)) {
          uniqueLocations.push({
            id: sourceLocation.id,
            name: sourceLocation.name,
          });
          locationSet.add(sourceLocation.id);
        }
      }
    });
    return uniqueLocations;
  };

  useEffect(() => {
    if (!initialSourceItinerary || initialSourceItinerary.isEmpty()) {
      setInitialSourceItinerary(sourceItinerary);
    }
  }, [sourceItinerary]);

  useEffect(() => {
    if (!initialDestinationItinerary || initialDestinationItinerary.isEmpty()) {
      setInitialDestinationItinerary(destinationItinerary);
    }
  }, [destinationItinerary]);

  useEffect(() => {
    if (sourceItinerarySourceLocation) {
      dispatchChange(
        'ServiceMergeForm',
        'sourceItineraryDestinationLocation',
        null,
      );
      const options = getUniqueLocations(
        initialSourceItinerary,
        true,
        false,
        sourceItinerarySourceLocation.value,
      ).map((location) => ({
        value: location.id,
        label: location.name,
      }));
      setDestionationLocationOnSourceItinerary(options);
    }
  }, [sourceItinerarySourceLocation]);

  useEffect(() => {
    if (destinationItinerarySourceLocation) {
      dispatchChange(
        'ServiceMergeForm',
        'destinationItineraryDestinationLocation',
        null,
      );
      const options = getUniqueLocations(
        initialDestinationItinerary,
        true,
        false,
        destinationItinerarySourceLocation.value,
      ).map((location) => ({
        value: location.id,
        label: location.name,
      }));
      setDestionationLocationOnDestinationItinerary(options);
    }
  }, [destinationItinerarySourceLocation]);

  const resetSeatSelection = () => {
    setSeatsSelected(Immutable.Set());
    setChangingSeatSelection(Immutable.Set());
  };

  const handleSubmitSameSeat = (formValues) => {
    onSubmit({ forced: false, ...formValues });
  };

  const handleSubmitForcedMerge = (formValues) => {
    onSubmit({ forced: true, ...formValues });
  };

  const handleManualMigration = (formValues) => {
    if (!seatsSelected.size || !ticketsIds || !ticketsIds.length) {
      toastr.error(
        'Error',
        'Debe seleccionar asientos y tickets para migrar manualmente',
      );
      return;
    }
    if (ticketsIds.length !== seatsSelected.size) {
      toastr.error(
        'Error',
        'Debe seleccionar asientos para todos los tickets seleccionados',
      );
      return;
    }
    if (
      destinationItinerarySourceLocation === null ||
      destinationItineraryDestinationLocation === null
    ) {
      toastr.error(
        'Error',
        'Debe seleccionar un origen y destino del itinerario destino',
      );
      return;
    }
    const payload = {
      seatsIds: seatsSelected.toJS().map((seat) => seat.seatId),
      ticketsIds,
    };
    resetSeatSelection();
    setManualMigrate(false);
    onSubmit({
      manual: true,
      ...payload,
      ...formValues,
      sourceLocationId: destinationItinerarySourceLocation.value,
      destinationLocationId: destinationItineraryDestinationLocation.value,
    });
  };

  const handlePrintingTickets = async () => {
    const printedTickets = await dispatchGetPrintedMergedTickets({
      serviceMergeId,
    });
    if (printedTickets) {
      printPrintedTickets(printedTickets);
    }
  };

  const handleSeatSelection = (seat) => {
    if (!ticketsIds || ticketsIds.length === seatsSelected.size) return;
    const seatReservation = {
      seat: { id: seat.seatId },
      ...seat,
    };
    const seatCode = `${seat.itineraryId}-${seat.seatId}`;
    const newChangingSeatSelection = changingSeatSelection.add(seatCode);
    setChangingSeatSelection(newChangingSeatSelection);
    setSeatsSelected(seatsSelected.add(seatReservation));
    setTimeout(() => {
      setChangingSeatSelection(newChangingSeatSelection.delete(seatCode));
    }, 250);
  };

  const handleSeatDeselection = (seat) => {
    const newSeatSelected = seatsSelected.filter(
      (seatSelected) => seatSelected.seatId !== seat.seatId,
    );
    const seatCode = `${seat.itineraryId}-${seat.seatId}`;
    const newChangingSeatSelection = changingSeatSelection.add(seatCode);
    setChangingSeatSelection(newChangingSeatSelection);
    setSeatsSelected(newSeatSelected);
    setTimeout(() => {
      setChangingSeatSelection(newChangingSeatSelection.delete(seatCode));
    }, 250);
  };

  const handleClearOptionsSourceItinerary = () => {
    setInitialSourceItinerary(null);
    setSourceLocationOnSourceItinerary([]);
    setDestionationLocationOnSourceItinerary([]);
    dispatchChange('ServiceMergeForm', 'sourceItinerarySourceLocation', null);
    dispatchChange(
      'ServiceMergeForm',
      'sourceItineraryDestinationLocation',
      null,
    );
  };

  const handleClearOptionsDestinationItinerary = () => {
    setInitialDestinationItinerary(null);
    setSourceLocationOnDestinationItinerary([]);
    setDestionationLocationOnDestinationItinerary([]);
    dispatchChange(
      'ServiceMergeForm',
      'destinationItinerarySourceLocation',
      null,
    );
    dispatchChange(
      'ServiceMergeForm',
      'destinationItineraryDestinationLocation',
      null,
    );
  };

  const handleClearSourceItinerary = () => {
    setManualMigrate(false);
    resetSeatSelection();
    handleClearOptionsSourceItinerary();
    dispatchClearItineraryPassengerList({ type: 'source' });
    dispatchClearItinerarySeatMap({ type: 'source' });
  };

  const handleClearDestinationItinerary = () => {
    setManualMigrate(false);
    resetSeatSelection();
    handleClearOptionsDestinationItinerary();
    dispatchClearItineraryPassengerList({ type: '' });
    dispatchClearItinerarySeatMap({ type: '' });
  };

  const handleOnClickManualMigrate = () => {
    if (!sourceItinerary.isEmpty()) {
      const options = getUniqueLocations(sourceItinerary, false, true).map(
        (location) => ({
          value: location.id,
          label: location.name,
        }),
      );
      setSourceLocationOnSourceItinerary(options);
    }
    if (!destinationItinerary.isEmpty()) {
      const options = getUniqueLocations(destinationItinerary, false, true).map(
        (location) => ({
          value: location.id,
          label: location.name,
        }),
      );
      setSourceLocationOnDestinationItinerary(options);
    }
    setManualMigrate(true);
  };

  const handleOnClickSearchSourceItineraryWithLocations = () => {
    if (!sourceItinerarySourceLocation || !sourceItineraryDestinationLocation) {
      toastr.error(
        'Error',
        'Debe seleccionar un origen y destino del itinerario origen',
      );
      return;
    }
    dispatchGetItinerarySeatMap({
      itineraryId: sourceItinerary.get('itinerary').id,
      type: 'source',
      sourceLocationId: sourceItinerarySourceLocation.value,
      destinationLocationId: sourceItineraryDestinationLocation.value,
    });
  };

  const handleOnClickSearchDestinationItineraryWithLocations = () => {
    if (
      !destinationItinerarySourceLocation ||
      !destinationItineraryDestinationLocation
    ) {
      toastr.error(
        'Error',
        'Debe seleccionar un origen y destino del itinerario destino',
      );
      return;
    }
    dispatchGetItinerarySeatMap({
      itineraryId: destinationItinerary.get('itinerary').id,
      type: '',
      sourceLocationId: destinationItinerarySourceLocation.value,
      destinationLocationId: destinationItineraryDestinationLocation.value,
    });
  };

  const generatePassengerListData = (passengerList) => {
    const data = [];

    passengerList.forEach((passenger) => {
      if (passenger.ticketStatus === 'ACTIVE') {
        const row = {};
        row.ticketId = passenger.ticketId;
        row.seatNumber = passenger.seatNumber;
        row.ticket = `${passenger.documentSeries}-${padStart(
          passenger.documentCode.toString(),
          7,
        )}`;
        row.customerOrderId = passenger.customerOrderId;
        row.customerFullName = passenger.customer.fullName;
        row.sourceLocationName = passenger.sourceLocationName;
        row.destinationLocationName = passenger.destinationLocationName;
        data.push(row);
      }
    });

    return data;
  };

  const renderPassengerTable = (title, data, loading, isOrigin) => {
    if (loading) return <Loader />;
    if (data.isEmpty()) return null;

    const passengerListData = generatePassengerListData(data);

    return (
      <div>
        <h4 className="text-center">{title}</h4>
        {!manualMigrate || !isOrigin ? (
          <Table
            columns={[
              {
                Header: 'Asiento',
                accessor: 'seatNumber',
                className: 'text-center',
                width: 80,
              },
              {
                Header: 'Boleto',
                accessor: 'ticket',
                className: 'text-center',
                width: 150,
              },
              {
                Header: 'Customer Order',
                accessor: 'customerOrderId',
                className: 'text-center',
              },
              {
                Header: 'Pasajero',
                accessor: 'customerFullName',
                className: 'text-center',
              },
              {
                Header: 'Origen',
                accessor: 'sourceLocationName',
                className: 'text-center',
              },
              {
                Header: 'Destino',
                accessor: 'destinationLocationName',
                className: 'text-center',
              },
            ]}
            data={passengerListData}
            defaultPageSize={passengerListData.length}
            showPagination={false}
          />
        ) : (
          <SelectionableTable
            columns={[
              {
                Header: 'Asiento',
                accessor: 'seatNumber',
                className: 'text-center',
                width: 80,
              },
              {
                Header: 'Boleto',
                accessor: 'ticket',
                className: 'text-center',
                width: 150,
              },
              {
                Header: 'Customer Order',
                accessor: 'customerOrderId',
                className: 'text-center',
              },
              {
                Header: 'Pasajero',
                accessor: 'customerFullName',
                className: 'text-center',
              },
              {
                Header: 'Origen',
                accessor: 'sourceLocationName',
                className: 'text-center',
              },
              {
                Header: 'Destino',
                accessor: 'destinationLocationName',
                className: 'text-center',
              },
            ]}
            data={passengerListData}
            keyField="ticketId"
            name="ticketsIds"
            defaultPageSize={passengerListData.length}
            returnOnlySelectedItems
            form="ServiceMergeForm"
            manual
          />
        )}
      </div>
    );
  };

  const renderPassengerList = () => (
    <Container fluid className="mt-4">
      <Row>
        <Col md={6} sm={12} xs={12}>
          {renderPassengerTable(
            'Pasajeros Itinerario Inicial',
            passengerListSourceItinerary,
            loadingSourceItinerary,
            true,
          )}
        </Col>
        <Col md={6} sm={12} xs={12}>
          {renderPassengerTable(
            'Pasajeros Itinerario Final',
            passengerListDestinationItinerary,
            loadingDestinationItinerary,
            false,
          )}
        </Col>
      </Row>
    </Container>
  );

  const renderSeatMap = (title, data, loading, isDestination) => {
    if (loading) return <Loader />;
    if (data.isEmpty()) return null;

    const destinationLocationId = data.get('destination').id;
    const sourceLocationId = data.get('origin').id;
    const itineraryId = data.get('itinerary').id;
    const { floors } = data.get('seatMapDTO');
    const numPassengers = data.get('availableSeats');

    const itinerarySearchResult = {
      seatMapDisplayName: data.get('seatMapDisplayName'),
      arrivalTime: data.get('arrivalTime'),
      departureTime: data.get('departureTime'),
      origin: data.get('origin'),
      destination: data.get('destination'),
    };

    return (
      <div>
        <Card>
          <CardBody>
            <CardTitle tag="h4" className="text-center">
              {title}
            </CardTitle>
            <CardSubtitle>
              <SeatSelectionHeader
                itinerarySearchResult={itinerarySearchResult}
              />
            </CardSubtitle>
          </CardBody>
          <CardBody>
            <SeatMap
              destinationLocationId={destinationLocationId}
              sourceLocationId={sourceLocationId}
              itineraryId={itineraryId}
              floors={floors}
              handleSeatSelection={
                manualMigrate && isDestination ? handleSeatSelection : () => {}
              }
              handleSeatDeselection={
                manualMigrate && isDestination
                  ? handleSeatDeselection
                  : () => {}
              }
              seatReservations={
                manualMigrate && isDestination ? seatsSelected : Immutable.Set()
              }
              seatSelectionChangesInProgress={
                manualMigrate && isDestination
                  ? changingSeatSelection
                  : Immutable.Set()
              }
              numPassengers={numPassengers}
            />
          </CardBody>
        </Card>
      </div>
    );
  };

  const renderSeatSelection = () => (
    <>
      <Container fluid>
        <Row>
          <Col md={6} sm={12} xs={12}>
            {manualMigrate && (
              <Row>
                <Col>
                  <Field
                    name="sourceItinerarySourceLocation"
                    component={Select}
                    placeholder="Desde"
                    options={sourceLocationOnSourceItinerary}
                  />
                </Col>
                <Col>
                  {sourceItinerarySourceLocation && (
                    <Field
                      name="sourceItineraryDestinationLocation"
                      component={Select}
                      placeholder="Hasta"
                      options={destinationLocationOnSourceItinerary}
                    />
                  )}
                </Col>
                <Button
                  type="button"
                  onClick={() =>
                    handleOnClickSearchSourceItineraryWithLocations()
                  }
                  color="primary"
                >
                  Buscar
                </Button>
              </Row>
            )}
            {renderSeatMap(
              'Mapa Asientos Itinerario Inicial',
              sourceItinerary,
              loadingSourceItinerary,
              false,
            )}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {manualMigrate && (
              <Row>
                <Col>
                  <Field
                    name="destinationItinerarySourceLocation"
                    component={Select}
                    placeholder="Desde"
                    options={sourceLocationOnDestinationItinerary}
                  />
                </Col>
                <Col>
                  {destinationItinerarySourceLocation && (
                    <Field
                      name="destinationItineraryDestinationLocation"
                      component={Select}
                      placeholder="Hasta"
                      options={destinationLocationOnDestinationItinerary}
                    />
                  )}
                </Col>
                <Button
                  type="button"
                  onClick={() =>
                    handleOnClickSearchDestinationItineraryWithLocations()
                  }
                  color="primary"
                >
                  Buscar
                </Button>
              </Row>
            )}
            {renderSeatMap(
              'Mapa Asientos Itinerario Final',
              destinationItinerary,
              loadingDestinationItinerary,
              true,
            )}
          </Col>
        </Row>
      </Container>
    </>
  );

  const renderPrintingTicketsButton = () => {
    if (serviceMergeId) {
      return (
        <Button type="button" onClick={handlePrintingTickets} color="success">
          Imprimir Tickets Migrados
        </Button>
      );
    }
    return null;
  };

  if (loadingServiceMerge) return <Loader />;

  const seatSelection = renderSeatSelection();
  const passengerList = renderPassengerList();
  const printingTicketsButton = renderPrintingTicketsButton();

  return (
    <>
      <Form>
        <FormGroup row>
          <FormItem label="Itinerario Origen" required>
            <Field
              name="sourceItineraryId"
              component={ItinerarySearchInput}
              placeholder="ID Itinerario Origen"
              handleSelectItinerary={({ itineraryId }) => {
                handleClearSourceItinerary();
                dispatchGetItinerarySeatMap({ itineraryId, type: 'source' });
              }}
              handleClear={handleClearSourceItinerary}
              validate={[isRequired]}
              props={{
                form: 'ServiceMergeForm',
              }}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Itinerario Destino" required>
            <Field
              name="destinationItineraryId"
              component={ItinerarySearchInput}
              placeholder="ID Itinerario Destino"
              handleSelectItinerary={({ itineraryId }) => {
                handleClearDestinationItinerary();
                dispatchGetItinerarySeatMap({
                  itineraryId,
                  type: 'destination',
                });
              }}
              handleClear={handleClearDestinationItinerary}
              validate={[isRequired]}
              props={{
                form: 'ServiceMergeForm',
              }}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Comentarios ">
            <Field name="comment" component={TextInput} type="textarea" />
          </FormItem>
        </FormGroup>
        <ButtonToolbar className="flex row-reverse mb-4">
          <div>
            {!manualMigrate ? (
              <>
                <Button
                  type="button"
                  onClick={handleSubmit(handleSubmitSameSeat)}
                  color="primary"
                >
                  Migrar Mismos Asientos
                </Button>{' '}
                <Button
                  type="button"
                  onClick={handleSubmit(handleSubmitForcedMerge)}
                  color="primary"
                >
                  Migrar Forzar
                </Button>{' '}
                <Button
                  type="button"
                  color="primary"
                  onClick={() => handleOnClickManualMigrate()}
                >
                  Migrar manual
                </Button>{' '}
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="success"
                  onClick={handleSubmit(handleManualMigration)}
                >
                  Migrar
                </Button>{' '}
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    setManualMigrate(false);
                  }}
                >
                  Cancelar
                </Button>
              </>
            )}
            {printingTicketsButton}
          </div>
        </ButtonToolbar>
        {seatSelection}
        {passengerList}
      </Form>
    </>
  );
};

ServiceMergeForm.propTypes = {
  dispatchGetItinerarySeatMap: PropTypes.func.isRequired,
  dispatchClearItinerarySeatMap: PropTypes.func.isRequired,
  dispatchClearItineraryPassengerList: PropTypes.func.isRequired,
  dispatchGetPrintedMergedTickets: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loadingServiceMerge: PropTypes.bool.isRequired,
  serviceMergeId: PropTypes.number,
  loadingSourceItinerary: PropTypes.bool.isRequired,
  sourceItinerary: PropTypes.instanceOf(Immutable.Map),
  passengerListSourceItinerary: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingDestinationItinerary: PropTypes.bool.isRequired,
  destinationItinerary: PropTypes.instanceOf(Immutable.Map),
  passengerListDestinationItinerary: PropTypes.instanceOf(Immutable.Set)
    .isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ticketsIds: PropTypes.arrayOf(PropTypes.number),
  sourceItinerarySourceLocation: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  sourceItineraryDestinationLocation: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  destinationItinerarySourceLocation: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  destinationItineraryDestinationLocation: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

ServiceMergeForm.defaultProps = {
  sourceItinerary: null,
  destinationItinerary: null,
  serviceMergeId: null,
  ticketsIds: [],
  sourceItinerarySourceLocation: null,
  sourceItineraryDestinationLocation: null,
  destinationItinerarySourceLocation: null,
  destinationItineraryDestinationLocation: null,
};

const mapStateToProps = (state) => ({
  loadingServiceMerge: !state.ItineraryUnit.ServiceMerge.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  serviceMergeId: state.ItineraryUnit.ServiceMerge.getIn(['current', 'id']),
  loadingSourceItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'loading',
  ]),
  sourceItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'seatMap',
  ]),
  passengerListSourceItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'sourceItinerary',
    'passengerList',
  ]),
  loadingDestinationItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'loading',
  ]),
  destinationItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'seatMap',
  ]),
  passengerListDestinationItinerary: state.ItineraryUnit.ServiceMerge.getIn([
    'destinationItinerary',
    'passengerList',
  ]),
  ticketsIds: selector(state, 'ticketsIds'),
  sourceItinerarySourceLocation: selector(
    state,
    'sourceItinerarySourceLocation',
  ),
  sourceItineraryDestinationLocation: selector(
    state,
    'sourceItineraryDestinationLocation',
  ),
  destinationItinerarySourceLocation: selector(
    state,
    'destinationItinerarySourceLocation',
  ),
  destinationItineraryDestinationLocation: selector(
    state,
    'destinationItineraryDestinationLocation',
  ),
});

const mapDispatchToProps = {
  dispatchGetItinerarySeatMap: getItinerarySeatMap,
  dispatchClearItinerarySeatMap: clearItinerarySeatMap,
  dispatchClearItineraryPassengerList: clearItineraryPassengerList,
  dispatchGetPrintedMergedTickets: getPrintedMergedTickets,
  dispatchChange: change,
};

export default reduxForm({
  form: 'ServiceMergeForm',
})(connect(mapStateToProps, mapDispatchToProps)(ServiceMergeForm));
