import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { ACTIVITY_PROJECTION_COLUMNS } from '../../../../config/columns';
import { clearPreventiveMaintenanceReport } from '../../../../actions';
import Table from '../../../common/Table';
import { COLOR, ACTIVITY_STATUS } from '../../../../config/constants';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../config/messages';
import { orderBy } from '../../../../utils/array';
import DownloadButton from '../../../common/button/DownloadButton';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import { formatMaintenanceReportData } from '../preventive-maintenance-report/PreventiveMaintenanceReport';

import './ActivityProjectionSearchResult.css';

const generateStyle = (row) => {
  let background = '';

  if (row) {
    const {
      original: { status },
    } = row;

    background = status === ACTIVITY_STATUS.PENDING.value && COLOR.danger;
  }

  return { background };
};

export const ActivityProjectionSearchResult = ({
  dispatchClearPreventiveMaintenanceReport,
  loading,
  activities,
}) => {
  useLayoutEffect(() => () => dispatchClearPreventiveMaintenanceReport(), []);

  const data = activities.get('content');
  const dataFormatted = formatMaintenanceReportData(data);
  let table = <Alert message={THERE_ARE_NOT_DATA_TO_SHOW} />;
  if (loading) table = <Loader />;
  if (!loading && dataFormatted.length > 0) {
    // Sorting data
    const sortedData = orderBy(dataFormatted, ['factorQuantity'], ['desc']);

    const downloadButton = dataFormatted && (
      <div className="mb-1 text-right">
        <DownloadButton
          data={sortedData}
          csvName={`Proyeccion de Actividades al ${tzNormalizeDate({
            format: INT_DATE_FORMAT,
          })}.csv`}
        />
      </div>
    );
    table = (
      <>
        {downloadButton}
        <Table
          manual={false}
          columns={ACTIVITY_PROJECTION_COLUMNS}
          data={sortedData}
          loading={loading}
          generateStyle={generateStyle}
          defaultPageSize={dataFormatted.length}
          defaultFilterMethod={filterMethodCaseInsensitive}
          filterable
          showPagination={false}
          getTbodyProps={() => ({ className: 'body-scrollable' })}
        />
      </>
    );
  }
  return table;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: MechanicalMaintenanceUnit.PreventiveMaintenanceReport.getIn([
    'all',
    'loading',
  ]),
  activities: MechanicalMaintenanceUnit.PreventiveMaintenanceReport.getIn([
    'all',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchClearPreventiveMaintenanceReport: clearPreventiveMaintenanceReport,
};

ActivityProjectionSearchResult.propTypes = {
  activities: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearPreventiveMaintenanceReport: PropTypes.func.isRequired,
};

ActivityProjectionSearchResult.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'ActivityProjectionSearchResult',
})(ActivityProjectionSearchResult);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
