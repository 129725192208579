import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import withEndpointAuthorization from '../../../authorization/withEndPointAuthorization';
import { deleteTripFromItinerary } from '../../../../../actions';
import { generateDeleteTripFromItineraryEndpoint } from '../../../../../config/endpoints';
import { DELETE } from '../../../../../config/permissions';

export const DeleteTripButton = ({
  itineraryId,
  dispatchDeleteTripFromItinerary,
}) => (
  <Button
    color="danger"
    className="border"
    onClick={() => dispatchDeleteTripFromItinerary({ itineraryId })}
  >
    <i className="fa fa-times" /> Liberar bus
  </Button>
);

DeleteTripButton.propTypes = {
  itineraryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  dispatchDeleteTripFromItinerary: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteTripFromItinerary: deleteTripFromItinerary,
};

const connectedComponent = connect(null, mapDispatchToProps)(DeleteTripButton);

export default withEndpointAuthorization({
  url: generateDeleteTripFromItineraryEndpoint(),
  mapUrlParamsToProps: { itineraryId: 'itineraryId' },
  permissionType: DELETE,
})(connectedComponent);
