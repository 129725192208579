import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_MAINTENANCE_PLANNING,
  CLEAR_MAINTENANCE_PLANNINGS,
  FLAG_GETTING_MAINTENANCE_PLANNINGS,
  FLAG_MAINTENANCE_PLANNING_ACTIVITY,
  GET_MAINTENANCE_PLANNING,
  GET_MAINTENANCE_PLANNINGS,
} from '../types/mechanical-maintenance';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  generateEndpointDesactiveMaintenancePlanningResources,
  MAINTENANCE_PLANNING_ENDPOINT,
} from '../../config/endpoints';
import { MAINTENANCE_PLANNING_PATH } from '../../config/paths';

const flagMaintenancePlannings = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_PLANNINGS,
    payload: flag,
  });

const getMaintenancePlannings = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagMaintenancePlannings(true));
    const query = tableFilters;
    const url = `${MAINTENANCE_PLANNING_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_MAINTENANCE_PLANNINGS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagMaintenancePlannings(false));
  }
};

const clearMaintenancePlannings = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_PLANNINGS,
  });

const flagMaintenancePlanningActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_PLANNING_ACTIVITY,
    payload: flag,
  });

const getMaintenancePlanning =
  async ({ maintenancePlanningId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlanningActivity(true));
      const url = `${MAINTENANCE_PLANNING_ENDPOINT}/${maintenancePlanningId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_PLANNING,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlanningActivity(false));
    }
  };

const clearMaintenancePlanning = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_PLANNING,
  });

const postMaintenancePlanning =
  async ({
    description,
    planningType,
    factorType,
    companyAreaId,
    resetFactor,
    maintenancePlanningActivityOrderList,
    maintenancePlanningResourceList,
    percentageDeviation,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlanningActivity(true));
      const payload = {
        description,
        planningType,
        factorType,
        companyAreaId,
        resetFactor,
        maintenancePlanningActivityOrderList,
        maintenancePlanningResourceList,
        percentageDeviation,
      };
      const url = MAINTENANCE_PLANNING_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const planning = await promise.json();
      if (planning) {
        dispatch(push(`${MAINTENANCE_PLANNING_PATH}/${planning.id}`));
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlanningActivity(false));
    }
  };

const putMaintenancePlanning =
  async (
    maintenancePlanningId,
    {
      description,
      planningType,
      factorType,
      companyAreaId,
      resetFactor,
      maintenancePlanningActivityOrderList,
      maintenancePlanningResourceList,
      percentageDeviation,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlanningActivity(true));
      const payload = {
        id: maintenancePlanningId,
        description,
        planningType,
        factorType,
        companyAreaId,
        resetFactor,
        maintenancePlanningActivityOrderList,
        maintenancePlanningResourceList,
        percentageDeviation,
      };
      const url = `${MAINTENANCE_PLANNING_ENDPOINT}/${maintenancePlanningId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const planning = await promise.json();
      if (planning) {
        dispatch(push(`${MAINTENANCE_PLANNING_PATH}/${planning.id}`));
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlanningActivity(false));
    }
  };

const getMaintenancePlanningActivities =
  async ({ maintenancePlanningId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlanningActivity(true));
      const url = `${MAINTENANCE_PLANNING_ENDPOINT}/activities/${maintenancePlanningId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlanningActivity(false));
    }
    return null;
  };

const getMaintenancePlanningsByRegisteredBus =
  async ({ registeredBusId }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlannings(true));
      const query = tableFilters;
      const url = `${MAINTENANCE_PLANNING_ENDPOINT}/resource/bus/${registeredBusId}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_PLANNINGS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlannings(false));
    }
  };

const putDesactiveMaintenancePlanningResources =
  async (maintenancePlanningId, { maintenanceBusGroupIds, registeredBusIds }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlanningActivity(true));
      const payload = {
        maintenanceBusGroupIds,
        registeredBusIds,
      };
      const url = generateEndpointDesactiveMaintenancePlanningResources(
        maintenancePlanningId,
      );
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const resources = await promise.json();

      toastr.success('Confirmación', 'Desactivación exitosa');
      return resources;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlanningActivity(false));
    }
    return null;
  };

const getMaintenancePlanningsByCompanyResource =
  async ({ companyResourceId }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenancePlannings(true));
      const query = tableFilters;
      const url = `${MAINTENANCE_PLANNING_ENDPOINT}/resource/company-resource/${companyResourceId}?${QueryString.stringify(
        query,
      )}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_PLANNINGS,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenancePlannings(false));
    }
  };
export {
  getMaintenancePlannings,
  clearMaintenancePlannings,
  getMaintenancePlanning,
  clearMaintenancePlanning,
  postMaintenancePlanning,
  putMaintenancePlanning,
  getMaintenancePlanningActivities,
  getMaintenancePlanningsByRegisteredBus,
  putDesactiveMaintenancePlanningResources,
  getMaintenancePlanningsByCompanyResource,
};
