import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, change, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import {
  clearOrganizationCharts,
  getOrganizationCharts,
} from '../../../../actions';
import OrganizationChartSelect from '../../../common/forms/select/OrganizationChartSelect';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import MaintenanceBusGroupSelect from '../../../common/forms/select/MaintenanceBusGroupSelect';
import DynamicForm from '../../../common/forms/DynamicForm';
import CompanyResourceSelect from '../../../common/forms/select/CompanyResourceSelect';
import Select from '../../../common/forms/select/Select';
import {
  MAINTENANCE_FACTOR_TYPE,
  MAINTENANCE_PLANNING_TYPE,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import { MAINTENANCE_PLANNING_COLUMNS } from '../../../../config/dynamicFormFields';

const validateBusOrCompanyResourceOrFuelGroup = (value, allValues) => {
  const { registeredBusId, companyResourceId, maintenanceBusGroupId } =
    allValues;

  const isNotEmpty = (val) => {
    if (val === null || val === undefined) {
      return false;
    }
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    if (typeof val === 'object') {
      return Object.keys(val).length > 0;
    }
    return val !== '';
  };

  const validFields = [
    registeredBusId,
    companyResourceId,
    maintenanceBusGroupId,
  ].filter(isNotEmpty);

  if (validFields.length === 0) {
    return 'Debe seleccionar al menos uno: Bus, Recurso de la Compañía o Grupo de Buses.';
  }

  if (validFields.length > 1) {
    return 'Solo debe seleccionar Bus, Recurso de la Compañía o Grupo de Buses.';
  }

  return undefined;
};

export const MaintenancePlanningForm = ({
  handleSubmit,
  loading,
  planningType,
}) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Descripción" required>
          <Field
            name="description"
            component={TextInput}
            type="text"
            placeholder="Descripción"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Rutina">
          <Field
            name="planningType"
            id="planningType"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_PLANNING_TYPE)}
            showToolTip
            placeholder="Tipo de Plan"
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {planningType === MAINTENANCE_PLANNING_TYPE.CICLICAL.value && (
        <FormGroup row>
          <FormItem label="Factor de Reseteo" required>
            <Field
              name="resetFactor"
              component={TextInput}
              type="number"
              placeholder="Reset Factor"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      )}
      <FormGroup row>
        <FormItem label="Desviación" required>
          <Field
            name="percentageDeviation"
            component={TextInput}
            type="number"
            placeholder="Desviación"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Factor">
          <Field
            name="factorType"
            id="factorType"
            component={Select}
            options={enumToSelectOptions(MAINTENANCE_FACTOR_TYPE)}
            showToolTip
            placeholder="Tipo de Factor"
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Organigrama" required>
          <Field
            name="companyArea"
            component={OrganizationChartSelect}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Bus" required>
          <Field
            name="registeredBusId"
            component={RegisteredBusSelect}
            isClearable
            isMulti
            validate={[validateBusOrCompanyResourceOrFuelGroup]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Recurso de la Compañia" required>
          <Field
            name="companyResourceId"
            component={CompanyResourceSelect}
            isClearable
            isMulti
            validate={[validateBusOrCompanyResourceOrFuelGroup]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Grupo de Buses" required>
          <Field
            name="maintenanceBusGroupId"
            component={MaintenanceBusGroupSelect}
            isClearable
            isMulti
            validate={[validateBusOrCompanyResourceOrFuelGroup]}
          />
        </FormItem>
      </FormGroup>
      <h3>Actividades</h3>
      <DynamicForm
        name="maintenancePlanningActivityOrderList"
        columns={MAINTENANCE_PLANNING_COLUMNS}
      />

      <FormFooter />
    </Form>
  );
};

MaintenancePlanningForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  planningType: PropTypes.string,
};

MaintenancePlanningForm.defaultProps = {
  loading: false,
  planningType: null,
};

const mapStateToProps = (state) => ({
  loading: !state.MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  planningType:
    state.form.MaintenancePlanningForm &&
    state.form.MaintenancePlanningForm.values &&
    state.form.MaintenancePlanningForm.values.planningType &&
    state.form.MaintenancePlanningForm.values.planningType.value,
});

const mapDispatchToProps = {
  dispatchGetOrganizationCharts: getOrganizationCharts,
  dispatchClearOrganizationCharts: clearOrganizationCharts,
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'MaintenancePlanningForm',
})(MaintenancePlanningForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
