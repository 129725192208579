import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MAINTENANCE_TASK_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import MaintenanceTaskForm from './MaintenanceTaskForm';
import {
  clearMaintenanceTasks,
  getMaintenanceTask,
  putMaintenanceTask,
} from '../../../../actions/mechanical-maintenance/MaintenanceTask';

const EditMaintenanceTask = ({
  breadcrumbs,
  dispatchGetMaintenanceTask,
  dispatchClearMaintenanceTasks,
  dispatchPutMaintenanceTask,
  match: {
    params: { id: maintenanceTaskId },
  },
  loading,
  maintenanceTask,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceTask({ maintenanceTaskId });

    return () => dispatchClearMaintenanceTasks();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = { ...formValues };
    dispatchPutMaintenanceTask(maintenanceTaskId, newFormValues);
  };

  const generateInitialValues = (maintanenceTaskJson) => ({
    description: maintanenceTaskJson.description,
    companyMaintenanceTaskId: maintanenceTaskJson.companyMaintenanceTaskId,
    workingHours: maintanenceTaskJson.workingHours,
    active: maintanenceTaskJson.active,
  });

  let content;

  if (loading) content = <Loader />;
  else if (maintenanceTask.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_TASK_PATH} />;
  else
    content = (
      <MaintenanceTaskForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(maintenanceTask.toJS())}
        edit
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Tarea"
      subtitle="Editar tarea"
      content={content}
    />
  );
};

EditMaintenanceTask.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaintenanceTasks: PropTypes.func.isRequired,
  dispatchGetMaintenanceTask: PropTypes.func.isRequired,
  dispatchPutMaintenanceTask: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  maintenanceTask: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditMaintenanceTask.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaintenanceTasks: clearMaintenanceTasks,
  dispatchGetMaintenanceTask: getMaintenanceTask,
  dispatchPutMaintenanceTask: putMaintenanceTask,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tareas',
      href: MAINTENANCE_TASK_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_TASK_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenanceTask: MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceTask);
