import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { tzNormalizeDate } from '../../../../../utils/date';
import { ACTIVITY_STATUS } from '../../../../../config/constants';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../../config/paths';
import Loader from '../../../../common/Loader';
import NoDataResource from '../../../../common/resource/NoDataResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from '../resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';
import MaintenanceServiceOrderBasicInformation from '../resource/MaintenanceServiceOrderBasicInformationResource';
import Content from '../../../../layout/Content';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import {
  clearMaintenanceServiceOrder,
  getMaintenanceServiceOrder,
} from '../../../../../actions';
import {
  clearServiceOrderExecution,
  getServiceOrderExecution,
  putServiceOrderExecution,
} from '../../../../../actions/mechanical-maintenance/MaintenancePlanningActivityExecution';
import ServiceOrderExecutionForm from './ServiceOrderExecutionForm';

const EditMaintenanceServiceOrderMaintenancePlanningActivityExecution = ({
  breadcrumbs,
  dispatchPutServiceOrderExecution,
  dispatchGetMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder,
  maintenanceServiceOrder,
  loading,
  match: {
    params: { id: maintenanceServiceOrderId, abeId: serviceOrderExecutionId },
  },
  dispatchGetServiceOrderExecution,
  dispatchClearExecutionRegistration,
  loadingServiceOrderExecution,
  serviceOrderExecution,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenanceServiceOrder({ maintenanceServiceOrderId });
    dispatchGetServiceOrderExecution({ serviceOrderExecutionId });

    return () => {
      dispatchClearMaintenanceServiceOrder();
      dispatchClearExecutionRegistration();
    };
  }, []);

  const onSubmit = (formValues) => {
    const serviceOrderExecutionJson = serviceOrderExecution.toJS();

    const newServiceOrderExecutionPayload = {
      maintenancePlanningActivityResourceId:
        serviceOrderExecutionJson.maintenancePlanningActivityResourceId,
      status: formValues.status.value,
      startDate: formValues.startDate
        ? tzNormalizeDate({ date: formValues.startDate })
        : null,
      endDate: formValues.endDate
        ? tzNormalizeDate({ date: formValues.endDate })
        : null,
      workingHours: formValues.workingHour,
      extensionFactor: formValues.extensionFactor,
      comment: formValues.comment,
      baseLocationId: formValues.baseLocationId.value,
      maintenancePlanningActivityExecutionEmployeeList:
        formValues.mechanics.map((mechanic) => ({
          employeeId: mechanic.value,
        })),

      serviceOrderId: serviceOrderExecutionJson.serviceOrderId,
      maintenancePlanningId: serviceOrderExecutionJson.maintenancePlanningId,
    };

    dispatchPutServiceOrderExecution(
      serviceOrderExecutionId,
      newServiceOrderExecutionPayload,
    );
  };

  const generateInitialValues = (serviceOrderExecutionJson) => {
    const {
      baseLocation,
      extensionFactor,
      status,
      workingHours,
      comment,
      startDate,
      endDate,
      activityId,
      maintenancePlanningActivityExecutionEmployeeList,
    } = serviceOrderExecutionJson;

    const newStartDate = startDate
      ? tzNormalizeDate({ date: startDate })
      : tzNormalizeDate();
    const newEndDate = endDate
      ? tzNormalizeDate({ date: endDate })
      : tzNormalizeDate({ addTime: { amount: 1, unit: 'h' } });

    let baseLocationId;
    if (baseLocation) {
      baseLocationId = { value: baseLocation.id, label: baseLocation.name };
    }

    return {
      status: ACTIVITY_STATUS[status] || ACTIVITY_STATUS.PENDING,
      extensionFactor,
      workingHour: workingHours || 1,
      startDate: newStartDate.slice(0, 16),
      endDate: newEndDate.slice(0, 16),
      baseLocationId,
      comment,
      activityId,
      mechanics: maintenancePlanningActivityExecutionEmployeeList.map(
        (employee) => ({
          value: employee.employeeId,
          label: employee.employee.customer.fullName,
        }),
      ),
    };
  };

  let content;

  if (loading || loadingServiceOrderExecution) content = <Loader />;
  else if (
    maintenanceServiceOrder.isEmpty() ||
    serviceOrderExecution.isEmpty()
  ) {
    content = (
      <NoDataResource
        returnPage={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/execution`}
      />
    );
  } else {
    const {
      registeredBus,
      deadline,
      priority,
      description,
      status,
      maintenanceWarningList,
    } = maintenanceServiceOrder.toJS();

    const serviceOrderExecutionJson = serviceOrderExecution.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={maintenanceWarningList}
      />
    );

    content = (
      <>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              deadline={deadline}
              priority={priority}
              description={description}
              status={status}
              registeredBus={registeredBus}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <h3>
          Actividad:{' '}
          {
            serviceOrderExecutionJson.maintenancePlanningActivityResource
              .maintenancePlanningActivityOrder.activity.companyActivityId
          }{' '}
          -{' '}
          {
            serviceOrderExecutionJson.maintenancePlanningActivityResource
              .maintenancePlanningActivityOrder.activity.name
          }
        </h3>
        <ServiceOrderExecutionForm
          onSubmit={onSubmit}
          initialValues={generateInitialValues(serviceOrderExecutionJson)}
        />
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Ejecución de Actividad"
      subtitle="Editar ejecución de actividad"
      content={content}
    />
  );
};

EditMaintenanceServiceOrderMaintenancePlanningActivityExecution.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes,
  dispatchPutServiceOrderExecution: PropTypes.func.isRequired,
  dispatchGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispatchClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetServiceOrderExecution: PropTypes.func.isRequired,
  dispatchClearExecutionRegistration: PropTypes.func.isRequired,
  serviceOrderExecution: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingServiceOrderExecution: PropTypes.bool.isRequired,
};

EditMaintenanceServiceOrderMaintenancePlanningActivityExecution.defaultProps = {
  match: null,
};

const mapDispatchToProps = {
  dispatchPutServiceOrderExecution: putServiceOrderExecution,
  dispatchGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispatchClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
  dispatchGetServiceOrderExecution: getServiceOrderExecution,
  dispatchClearExecutionRegistration: clearServiceOrderExecution,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Ejecuciones',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}/execution`,
    },
    {
      text: 'Editar Ejecución de Actividad',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  serviceOrderExecution: MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'content',
  ]),
  loadingServiceOrderExecution:
    !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
      'current',
      'activity',
    ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceServiceOrderMaintenancePlanningActivityExecution);
