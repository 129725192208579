import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_BUS_GROUP_PATH } from '../../../../config/paths';
import MaintenanceBusGroupDeleteButton from './MaintenanceBusGroupDeleteButton';

const MaintenanceBusGroupToolbar = ({ maintenanceBusGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${MAINTENANCE_BUS_GROUP_PATH}/${maintenanceBusGroupId}/edit`}
      />{' '}
      <MaintenanceBusGroupDeleteButton
        maintenanceBusGroupId={maintenanceBusGroupId}
      />
    </div>
  </ButtonToolbar>
);

MaintenanceBusGroupToolbar.propTypes = {
  maintenanceBusGroupId: PropTypes.number.isRequired,
};

export default MaintenanceBusGroupToolbar;
