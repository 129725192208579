import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { WORKSTATION_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import WorkstationForm from './WorkstationForm';
import Loader from '../../../common/Loader';
import {
  clearWorkstation,
  getWorkstation,
  putWorkstation,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class EditWorkstation extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: workstationId },
      },
      dispatchGetWorkstation,
    } = this.props;

    dispatchGetWorkstation({ workstationId });
  }

  componentWillUnmount() {
    const { dispatchClearWorkstation } = this.props;

    dispatchClearWorkstation();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutWorkstation,
      match: {
        params: { id },
      },
      workstation,
    } = this.props;

    const newFormValues = {
      ...formValues,
      agencyId: workstation.get('agencyId'),
      virtualPrintStationAssignment: workstation.get(
        'virtualPrintStationAssignment',
      ),
    };

    if (formValues.printStationList) {
      newFormValues.printStationList = formValues.printStationList.map(
        (printStation) => printStation,
      );
    }

    dispatchPutWorkstation(id, newFormValues);
  };

  generateInitialValues = (workstation) => ({
    serialNumber: workstation.get('serialNumber'),
    name: workstation.get('name'),
    description: workstation.get('description'),
    printStationList: workstation
      .get('printStationList')
      .map((printStation) => ({
        ...printStation,
        value: printStation.id,
        label: `${printStation.name} (${printStation.serialNumber})`,
      })),
    contingency: workstation.get('contingency'),
  });

  render() {
    const { breadcrumbs, workstation, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (workstation.isEmpty()) {
      content = <NoDataResource returnPage={WORKSTATION_PATH} />;
    } else {
      content = (
        <WorkstationForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(workstation)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Caja"
        subtitle="Editar caja"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { HumanResourcesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...HumanResourcesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Cajas',
      href: WORKSTATION_PATH,
    },
    {
      text: 'Ver',
      href: `${WORKSTATION_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  workstation: HumanResourcesUnit.Workstation.getIn(['current', 'content']),
  loading: !HumanResourcesUnit.Workstation.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetWorkstation: getWorkstation,
  dispatchClearWorkstation: clearWorkstation,
  dispatchPutWorkstation: putWorkstation,
};

EditWorkstation.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutWorkstation: PropTypes.func.isRequired,
  dispatchGetWorkstation: PropTypes.func.isRequired,
  dispatchClearWorkstation: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  workstation: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditWorkstation.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkstation);
