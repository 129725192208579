import {
  string,
  shape,
  number,
  bool,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';

const companyAreaTypePropTypes = {
  id: number.isRequired,
  name: string.isRequired,
};

const parentCompanyAreaPropTypes = {
  parentCompanyArea: shape({
    id: number,
    name: string,
    description: string,
    companyId: number,
    companyAreaTypeId: number,
    companyAreaType: shape(companyAreaTypePropTypes),
    parentCompanyAreaId: number,
  }),
};

const materialPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  externalId: string,
  measurementUnit: string.isRequired,
};

const maintenanceTaskPropTypes = {
  id: number.isRequired,
  description: string.isRequired,
  workingHours: number.isRequired,
  companyAreaId: number.isRequired,
  active: bool.isRequired,
  companyArea: shape({
    id: number.isRequired,
    name: string.isRequired,
    description: string,
    companyId: number.isRequired,
    companyAreaTypeId: number.isRequired,
    companyAreaType: shape(companyAreaTypePropTypes).isRequired,
    ...parentCompanyAreaPropTypes,
  }).isRequired,
  materialList: arrayOf(shape(materialPropTypes)).isRequired,
};

const maintenanceTaskDefaultProps = {
  description: null,
  companyArea: null,
  materialList: [],
};

const customerPropTypes = {
  id: number.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  fullName: string,
};

const userPropTypes = {
  id: number.isRequired,
  username: string.isRequired,
  customer: shape(customerPropTypes).isRequired,
  locale: string,
};

const activityPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  companyActivityId: string.isRequired,
};

const maintenancePlanningActivityOrderPropTypes = {
  id: number.isRequired,
  activity: shape(activityPropTypes).isRequired,
  factor: number.isRequired,
  activityOrder: number.isRequired,
};

const companyAreaPropTypes = {
  name: string.isRequired,
};

const registeredBusPropTypes = {
  companyBusId: string.isRequired,
};

const busFuelGroupPropTypes = {
  name: string.isRequired,
};

const companyResourceTypePropTypes = {
  id: number.isRequired,
  name: string.isRequired,
};

const companyResourcePropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  companyResourceTypeId: number,
  companyResourceType: shape(companyResourceTypePropTypes),
};

const maintenancePlanningPropTypes = {
  id: number.isRequired,
  description: string.isRequired,
  planningType: string.isRequired,
  factorType: string.isRequired,
  companyArea: shape(companyAreaPropTypes).isRequired,
  companyResource: shape(companyResourcePropTypes),
  registeredBus: shape(registeredBusPropTypes),
  busFuelGroup: shape(busFuelGroupPropTypes),
  resetFactor: number,
  user: shape(userPropTypes).isRequired,
  createDate: oneOfType([number, instanceOf(Date)]).isRequired,
  maintenancePlanningActivityOrder: arrayOf(
    shape(maintenancePlanningActivityOrderPropTypes),
  ).isRequired,
};

const maintenancePlanningDefaultProps = {
  companyResource: null,
  busFuelGroup: null,
  maintenancePlanningActivityOrder: [],
  resetFactor: 0,
};

export {
  maintenanceTaskPropTypes,
  maintenanceTaskDefaultProps,
  parentCompanyAreaPropTypes,
  companyAreaTypePropTypes,
  materialPropTypes,
  customerPropTypes,
  userPropTypes,
  activityPropTypes,
  maintenancePlanningActivityOrderPropTypes,
  companyAreaPropTypes,
  registeredBusPropTypes,
  busFuelGroupPropTypes,
  companyResourceTypePropTypes,
  companyResourcePropTypes,
  maintenancePlanningPropTypes,
  maintenancePlanningDefaultProps,
};
