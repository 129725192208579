import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';
import Select from '../../../common/forms/select/Select';

const validateBusMaintenanceBusGroup = (value, allValues) => {
  const { registeredBusId, maintenanceBusGroupId } = allValues;

  const isNotEmpty = (val) => {
    if (val === null || val === undefined) {
      return false;
    }
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    if (typeof val === 'object') {
      return Object.keys(val).length > 0;
    }
    return val !== '';
  };

  const validFields = [registeredBusId, maintenanceBusGroupId].filter(
    isNotEmpty,
  );

  if (validFields.length === 0) {
    return 'Debe seleccionar al menos uno: Bus o Grupo de Buses.';
  }

  if (validFields.length > 1) {
    return 'Solo debe seleccionar Bus o Grupo de Buses.';
  }

  return undefined;
};

const EndMaintenacePlanningForm = ({
  handleSubmit,
  handleProcess,
  onCancel,
  busGroupOptions,
  busOptions,
}) => (
  <Form onSubmit={handleSubmit(handleProcess)}>
    <p>
      Seleccione los Recursos que desea dar de baja del plan de mantenimiento
    </p>
    <hr />
    <FormGroup row className="mt-4">
      <FormItem label="Bus">
        <Field
          name="registeredBusId"
          component={Select}
          type="text"
          placeholder="Buses"
          options={busOptions}
          validate={[validateBusMaintenanceBusGroup]}
          isMulti
        />
      </FormItem>
    </FormGroup>
    <FormGroup row className="mt-4">
      <FormItem label="Grupo de Buses">
        <Field
          name="maintenanceBusGroupId"
          component={Select}
          type="text"
          placeholder="Grupo de Buses"
          validate={[validateBusMaintenanceBusGroup]}
          options={busGroupOptions}
          isMulti
        />
      </FormItem>
    </FormGroup>
    <FormFooter
      saveButtonColor="warning"
      saveButtonIcon="fa fa-check-circle"
      saveButtonText="Culminar"
    >
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

EndMaintenacePlanningForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  busGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  busOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'EndMaintenacePlanningForm',
})(EndMaintenacePlanningForm);

export default connect(null, mapDispatchToProps)(formComponent);
