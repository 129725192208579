import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearDocumentTypes,
  getDocumentTypes,
} from '../../../../actions/bus/DocumentType';

const DocumentTypeSelect = ({
  dispatchGetDocumentTypes,
  dispatchClearDocumentTypes,
  cleanWhenUnmount,
  loading,
  cargoCategoryOptions,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetDocumentTypes(DEFAULT_QUERY_GET_ALL);

    return () => {
      if (cleanWhenUnmount) {
        dispatchClearDocumentTypes();
      }
    };
  }, []);

  return (
    <Select
      isLoading={loading}
      options={cargoCategoryOptions}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

DocumentTypeSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetDocumentTypes: PropTypes.func.isRequired,
  dispatchClearDocumentTypes: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  cargoCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ).isRequired,
  isClearable: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  cleanWhenUnmount: PropTypes.bool,
};

DocumentTypeSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  isClearable: false,
  onValueChange: undefined,
  value: {},
  cleanWhenUnmount: true,
};

const mapStateToProps = ({ BusUnit }) => ({
  cargoCategoryOptions: BusUnit.DocumentType.getIn([
    'all',
    'content',
    'content',
  ]).map((documentType) => ({
    value: documentType.id,
    label: documentType.name,
  })),
  loading: BusUnit.DocumentType.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDocumentTypes: getDocumentTypes,
  dispatchClearDocumentTypes: clearDocumentTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeSelect);
