import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  BUS_FUEL_GROUP_PATH,
  MAINTENANCE_BUS_GROUP_PATH,
} from '../../../../config/paths';

import Loader from '../../../common/Loader';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import MaintenanceBusGroupForm from './MaintenanceBusGroupForm';
import {
  clearMaintenanceBusGroup,
  getMaintenanceBusGroup,
  putMaintenanceBusGroup,
} from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';

export class EditMaintenanceBusGroup extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.props.dispatchGetMaintenanceBusGroup({ maintenanceBusGroupId: id });
  }

  componentWillUnmount() {
    this.props.dispatchClearMaintenanceBusGroup();
  }

  onSubmit = (formValues) => {
    const maintenanceBusGroupId = parseInt(this.props.match.params.id, 10);
    const registeredBusList = [];
    if (formValues.registeredBus.indexOf(',') >= 0) {
      formValues.registeredBus.split(',').forEach((value) => {
        registeredBusList.push({ id: value });
      });
    } else {
      registeredBusList.push({ id: parseInt(formValues.registeredBus, 10) });
    }
    const putBusFuelGroupFormValues = {
      name: formValues.name,
      description: formValues.description,
      registeredBusList,
    };
    this.props.dispatchPutMaintenanceBusGroup(
      maintenanceBusGroupId,
      putBusFuelGroupFormValues,
    );
  };

  render() {
    const { maintenanceBusGroup, loading } = this.props;
    let content;
    if (loading) {
      content = <Loader />;
    } else if (maintenanceBusGroup.isEmpty()) {
      content = <NoDataResource returnPage={BUS_FUEL_GROUP_PATH} />;
    } else {
      const registeredBus = maintenanceBusGroup
        .get('registeredBusList')
        .map((bus) => bus.id);
      const initialValues = {
        name: maintenanceBusGroup.get('name'),
        description: maintenanceBusGroup.get('description'),
        registeredBus,
      };
      content = (
        <MaintenanceBusGroupForm
          maintenanceBusGroupId={this.props.match.params.id}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Editar Bus por Consumo de Combustible"
        subtitle="Editar un bus por comsumo de combustible"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupo de Buses para Mantenimiento',
      href: MAINTENANCE_BUS_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_BUS_GROUP_PATH}/${id}`,
    },
    { text: 'Editar', href: '' },
  ],
  maintenanceBusGroup: MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetMaintenanceBusGroup: getMaintenanceBusGroup,
  dispatchClearMaintenanceBusGroup: clearMaintenanceBusGroup,
  dispatchPutMaintenanceBusGroup: putMaintenanceBusGroup,
};

EditMaintenanceBusGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  maintenanceBusGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetMaintenanceBusGroup: PropTypes.func.isRequired,
  dispatchClearMaintenanceBusGroup: PropTypes.func.isRequired,
  dispatchPutMaintenanceBusGroup: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenanceBusGroup);
