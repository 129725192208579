import { string, shape, number, bool } from 'prop-types';

const CompanyResourceBasicInformationResourceProptypes = {
  id: number.isRequired,
  name: string.isRequired,
  companyResourceTypeId: number.isRequired,
  companyResourceType: shape({
    id: number,
    description: string,
  }),
  active: bool.isRequired,
};

export default CompanyResourceBasicInformationResourceProptypes;
