import React from 'react';
import {
  maintenanceServiceOrderActivityBusExecutionListDefaultProps,
  maintenanceServiceOrderInternalInformationPropTypes,
} from '../prop-types/MaintenanceServiceOrderPropTypes';
import Alert from '../../../../common/informative/Alert';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { MAINTENANCE_SERVICE_ORDER_ACTIVITY_BUS_EXECUTION_LIST_COLUMNS } from '../../../../../config/columns';
import { SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE } from '../../../../../config/messages';
import { ACTIVITY_STATUS } from '../../../../../config/constants';

const MaintenanceServiceOrderActivityBusExecutionListInformation = ({
  maintenancePlanningActivityExecutionList,
}) => {
  const data = maintenancePlanningActivityExecutionList.map(
    ({ maintenancePlanningActivityResource, employeeList, status }) => {
      const activityName =
        maintenancePlanningActivityResource &&
        maintenancePlanningActivityResource.maintenancePlanningActivityOrder
          .activity
          ? maintenancePlanningActivityResource.maintenancePlanningActivityOrder
              .activity.name
          : '-';

      const employees = employeeList
        ? employeeList.map((emp) => ({ name: emp.fullName }))
        : [];

      const materials =
        maintenancePlanningActivityResource &&
        maintenancePlanningActivityResource.maintenancePlanningActivityOrder
          .activity.materialList
          ? maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.materialList.map(
              (material) => ({ name: material.name }),
            )
          : [];

      const tasks =
        maintenancePlanningActivityResource &&
        maintenancePlanningActivityResource.maintenancePlanningActivityOrder
          .activity.activityMaintenanceTaskList
          ? maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.activityMaintenanceTaskList.map(
              (task) => ({ name: task.description }),
            )
          : [];

      const activityStatus =
        status && ACTIVITY_STATUS[status] ? ACTIVITY_STATUS[status].label : '-';

      return {
        activityName,
        employeeList: employees,
        materialList: materials,
        taskList: tasks,
        status: activityStatus,
        newBlock: true,
      };
    },
  );

  if (maintenancePlanningActivityExecutionList.length === 0)
    return (
      <Alert
        type="danger"
        message={SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE}
      />
    );

  return (
    <Table
      columns={MAINTENANCE_SERVICE_ORDER_ACTIVITY_BUS_EXECUTION_LIST_COLUMNS}
      data={data}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
  );
};

MaintenanceServiceOrderActivityBusExecutionListInformation.propTypes =
  maintenanceServiceOrderInternalInformationPropTypes;

MaintenanceServiceOrderActivityBusExecutionListInformation.defaultProps =
  maintenanceServiceOrderActivityBusExecutionListDefaultProps;

export default MaintenanceServiceOrderActivityBusExecutionListInformation;
