import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_EXECUTION_REGISTRATION,
  FLAG_EXECUTION_REGISTRATION_ACTIVITY,
  GET_EXECUTION_REGISTRATION,
} from '../types';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { MAINTENANCE_PLANNING_EXECUTION_ENDPOINT } from '../../config/endpoints';
import {
  generateEditMaintenanceServiceOrderStep3,
  MAINTENANCE_SERVICE_ORDER_PATH,
} from '../../config/paths';

const flagServiceOrderExecutionActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXECUTION_REGISTRATION_ACTIVITY,
    payload: flag,
  });

const clearServiceOrderExecution = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXECUTION_REGISTRATION,
  });

const getServiceOrderExecution =
  async ({ serviceOrderExecutionId }) =>
  async (dispatch) => {
    try {
      dispatch(flagServiceOrderExecutionActivity(true));
      const url = `${MAINTENANCE_PLANNING_EXECUTION_ENDPOINT}/${serviceOrderExecutionId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_EXECUTION_REGISTRATION,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagServiceOrderExecutionActivity(false));
    }
  };

const postAssignActivitiesServiceOrder =
  async (maintenanceServiceOrderId, maintenancePlanningActivityExecutionList) =>
  async (dispatch) => {
    try {
      dispatch(flagServiceOrderExecutionActivity(true));
      const url = `${MAINTENANCE_PLANNING_EXECUTION_ENDPOINT}/${maintenanceServiceOrderId}`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(maintenancePlanningActivityExecutionList),
      });
      await isErrorResponse(promise);
      const response = await promise.json();

      dispatch(
        push(
          generateEditMaintenanceServiceOrderStep3(maintenanceServiceOrderId),
        ),
      );

      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagServiceOrderExecutionActivity(false));
    }
  };

const putServiceOrderExecution =
  async (
    serviceOrderExecutionId,
    {
      maintenancePlanningActivityResourceId,
      status,
      startDate,
      endDate,
      workingHours,
      extensionFactor,
      comment,
      maintenancePlanningActivityExecutionEmployeeList,
      maintenancePlanningActivityTaskExecutionList,
      serviceOrderId,
      maintenancePlanningId,
      baseLocationId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagServiceOrderExecutionActivity(true));
      const payload = {
        id: serviceOrderExecutionId,
        maintenancePlanningActivityResourceId,
        status,
        startDate,
        endDate,
        workingHours,
        extensionFactor,
        comment,
        maintenancePlanningActivityExecutionEmployeeList,
        maintenancePlanningActivityTaskExecutionList,
        serviceOrderId,
        baseLocationId,
        maintenancePlanningId,
      };
      const url = `${MAINTENANCE_PLANNING_EXECUTION_ENDPOINT}/${serviceOrderExecutionId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const response = await promise.json();

      dispatch(
        push(`${MAINTENANCE_SERVICE_ORDER_PATH}/${serviceOrderId}/execution`),
      );
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagServiceOrderExecutionActivity(false));
    }
  };

export {
  flagServiceOrderExecutionActivity,
  getServiceOrderExecution,
  clearServiceOrderExecution,
  putServiceOrderExecution,
  postAssignActivitiesServiceOrder,
};
