import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteActivity } from '../../../../actions/mechanical-maintenance/Activity';
import { ACTIVITY_PATH } from '../../../../config/paths';

const CloneActivityButton = ({ activityId }) => (
  <>
    <Link className="btn btn-info" to={`${ACTIVITY_PATH}/${activityId}/clone`}>
      <i className="fa fa-clone" /> Clonar
    </Link>
  </>
);

CloneActivityButton.propTypes = {
  activityId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchDeleteActivity: deleteActivity,
};

export default connect(null, mapDispatchToProps)(CloneActivityButton);
