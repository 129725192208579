import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { COMPANY_RESOURCE_PATH } from '../../../../config/paths';

const CompanyResourceToolbar = ({ companyResourceId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${COMPANY_RESOURCE_PATH}/${companyResourceId}/edit`} />
    </div>
  </ButtonToolbar>
);

CompanyResourceToolbar.propTypes = {
  companyResourceId: PropTypes.number.isRequired,
};

export default CompanyResourceToolbar;
