import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearCompanyResourceTypes,
  getCompanyResourceTypes,
} from '../../../../actions/mechanical-maintenance/CompanyResource';

export const CompanyResourceTypeSelect = ({
  loading,
  companyResourceTypeOptions,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchGetCompanyResourceTypes,
  dispatchClearCompanyResourceTypes,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearCompanyResourceTypes(), []);

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    if (inputValueLength > 4) {
      const query = [];

      if (inputValueLength && inputValueLength >= 4)
        query.push(`name:${inputValue}`);

      dispatchGetCompanyResourceTypes({
        ...DEFAULT_QUERY_GET_ALL,
        query,
      });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={companyResourceTypeOptions}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

CompanyResourceTypeSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCompanyResourceTypes: PropTypes.func.isRequired,
  dispatchClearCompanyResourceTypes: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  companyResourceTypeOptions: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

CompanyResourceTypeSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  companyResourceTypeOptions: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por nombre',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => {
  const companyResourceTypeOptions =
    MechanicalMaintenanceUnit.CompanyResource.getIn([
      'types',
      'content',
      'content',
    ]).map((companyResourceType) => ({
      value: companyResourceType.id,
      label: companyResourceType.description,
    }));

  return {
    companyResourceTypeOptions,
    loading: MechanicalMaintenanceUnit.CompanyResource.getIn([
      'all',
      'loading',
    ]),
  };
};
const mapDispatchToProps = {
  dispatchGetCompanyResourceTypes: getCompanyResourceTypes,
  dispatchClearCompanyResourceTypes: clearCompanyResourceTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyResourceTypeSelect);
