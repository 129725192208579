import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import {
  clearCompanyResources,
  getCompanyResources,
} from '../../../../actions/mechanical-maintenance/CompanyResource';

export const CompanyResourceSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearCompanyResources,
  dispatchGetCompanyResources,
  listAll,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearCompanyResources(), []);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      if (listAll) {
        dispatchGetCompanyResources({ query: `name:${inputValue}` });
      } else {
        const query = ['active:true'];

        query.push(`name:${inputValue}`);

        dispatchGetCompanyResources({ query });
      }
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

CompanyResourceSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCompanyResources: PropTypes.func.isRequired,
  dispatchClearCompanyResources: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  listAll: PropTypes.bool,
};

CompanyResourceSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  listAll: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.CompanyResource.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name }) => ({
    value: id,
    label: name,
  })),
  loading: MechanicalMaintenanceUnit.CompanyResource.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCompanyResources: getCompanyResources,
  dispatchClearCompanyResources: clearCompanyResources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyResourceSelect);
