import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { registeredBusBasicInformationPropTypes } from '../proptypes/RegisteredBusPropTypes';
import Table from '../../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { NO_DOCUMENTS_IN_REGISTERED_BUS } from '../../../../../config/messages';
import Alert from '../../../../common/informative/Alert';
import { BUS_DOCUMENTS_COLUMNS } from '../../../../../config/columns';
import { tzNormalizeDate } from '../../../../../utils/date';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import Checkbox from '../../../../common/forms/Checkbox';

const RegisteredBusBasicInformationResource = ({
  id,
  companyBusId,
  licensePlate,
  busChassis,
  homeBaseLocation,
  busBodywork,
  chassisSerialNumber,
  bodyworkSerialNumber,
  seatMap,
  year,
  documentSet,
  lastUpdate,
  active,
}) => {
  const RegisteredBusInformationResource = (
    <>
      <ResourceProperty label="Número de Bus:">
        {companyBusId || '-'}
      </ResourceProperty>
      <ResourceProperty label="Matrícula:">
        {licensePlate || '-'}
      </ResourceProperty>
      <ResourceProperty label="Base:">
        {homeBaseLocation ? homeBaseLocation.name : '-'}
      </ResourceProperty>
      <ResourceProperty label="Chasís:">
        {busChassis
          ? `${busChassis.make} | ${busChassis.model} | ${busChassis.year}`
          : '-'}
      </ResourceProperty>
      <ResourceProperty label="Chasis Número de serie:">
        {chassisSerialNumber || '-'}
      </ResourceProperty>
      <ResourceProperty label="Carrocería:">
        {busBodywork
          ? `${busBodywork.make} | ${busBodywork.model} | ${busBodywork.year}`
          : '-'}
      </ResourceProperty>
      <ResourceProperty label="Carrocería Número de serie:">
        {bodyworkSerialNumber || '-'}
      </ResourceProperty>
      <ResourceProperty label="Mapa de Asientos:">
        {seatMap ? seatMap.name : '-'}
      </ResourceProperty>
      <ResourceProperty label="Año:">{year || '-'}</ResourceProperty>
    </>
  );

  const RegisteredBusInternalResource = (
    <>
      <h3>Información Interna</h3>
      <ResourceProperty label="ID:">{id}</ResourceProperty>
      <ResourceProperty label="Fecha de Actualización:">
        {lastUpdate
          ? tzNormalizeDate({ date: lastUpdate, format: FULL_DATE_TIME_FORMAT })
          : '-'}{' '}
      </ResourceProperty>
      <div>
        <Checkbox checked={active} /> Activo
      </div>
    </>
  );

  const tableDocumentSet =
    documentSet.length !== 0 ? (
      <Table
        columns={BUS_DOCUMENTS_COLUMNS}
        data={documentSet}
        showPagination={documentSet.length > 10}
        defaultPageSize={
          documentSet.length > 10 ? DEFAULT_PAGE_SIZE : documentSet.length
        }
      />
    ) : (
      <Alert message={NO_DOCUMENTS_IN_REGISTERED_BUS} type="warning" />
    );

  return (
    <>
      <Row>
        <Col md={6}>{RegisteredBusInformationResource}</Col>
        <Col md={6}>{RegisteredBusInternalResource}</Col>
      </Row>
      <h3>Documentos</h3>
      {tableDocumentSet}
    </>
  );
};

RegisteredBusBasicInformationResource.propTypes =
  registeredBusBasicInformationPropTypes;

export default RegisteredBusBasicInformationResource;
