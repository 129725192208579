import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import EndMaintenacePlanningForm from './EndMaintenacePlanningForm';
import { MAINTENANCE_PLANNING_STATUS_STARTED } from '../../../../config/constants';
import {
  getMaintenancePlanning,
  putDesactiveMaintenancePlanningResources,
} from '../../../../actions';

export const EndMaintenancePlanningButton = ({
  maintenancePlanning,
  dispatchPutDesactiveMaintenancePlanningResources,
  dispatchGetMaintenancePlanning,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const onModalSubmit = async (formValues) => {
    const newFormValues = {
      maintenanceBusGroupIds: [],
      registeredBusIds: [],
    };

    if (
      formValues.maintenanceBusGroupId &&
      formValues.maintenanceBusGroupId.length > 0
    ) {
      newFormValues.maintenanceBusGroupIds =
        formValues.maintenanceBusGroupId.map((option) => option.value);
    }

    if (formValues.registeredBusId && formValues.registeredBusId.length > 0) {
      newFormValues.registeredBusIds = formValues.registeredBusId.map(
        (option) => option.value,
      );
    }

    await dispatchPutDesactiveMaintenancePlanningResources(
      maintenancePlanning.id,
      newFormValues,
    );

    dispatchGetMaintenancePlanning({
      maintenancePlanningId: maintenancePlanning.id,
    });

    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const {
    busOptions: processedBusOptions,
    busGroupOptions: processedBusGroupOptions,
  } = useMemo(() => {
    const busOptions = maintenancePlanning.maintenancePlanningResourceList
      .filter(
        (item) =>
          item.registeredBus &&
          item.status === MAINTENANCE_PLANNING_STATUS_STARTED,
      )
      .map((item) => ({
        value: item.registeredBus.id,
        label: item.registeredBus.companyBusId,
      }));

    const busGroupOptions = Array.from(
      new Map(
        maintenancePlanning.maintenancePlanningResourceList
          .filter(
            (item) =>
              item.maintenanceBusGroup &&
              item.status === MAINTENANCE_PLANNING_STATUS_STARTED,
          )
          .map((item) => [
            item.maintenanceBusGroup.id,
            {
              value: item.maintenanceBusGroup.id,
              label: item.maintenanceBusGroup.name,
            },
          ]),
      ).values(),
    );

    return { busOptions, busGroupOptions };
  }, [maintenancePlanning]);

  const onShowModal = () => {
    const newModalBody = (
      <EndMaintenacePlanningForm
        onCancel={onCancel}
        handleProcess={onModalSubmit}
        busGroupOptions={processedBusGroupOptions}
        busOptions={processedBusOptions}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Button color="warning" onClick={onShowModal}>
        <i className="fa fa-check-circle" /> Culminar Plan de Mantenimiento
      </Button>
      <Modal
        show={showModal}
        title="Terminar Plan de Mantenimiento"
        body={modalBody}
        size="lg"
      />
    </>
  );
};

EndMaintenancePlanningButton.propTypes = {
  maintenancePlanning: PropTypes.instanceOf(Object).isRequired,
  dispatchPutDesactiveMaintenancePlanningResources: PropTypes.func.isRequired,
  dispatchGetMaintenancePlanning: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPutDesactiveMaintenancePlanningResources:
    putDesactiveMaintenancePlanningResources,
  dispatchGetMaintenancePlanning: getMaintenancePlanning,
};

export default connect(null, mapDispatchToProps)(EndMaintenancePlanningButton);
