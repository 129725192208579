import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_PREVENTIVE_REPORT,
  CLEAR_PREVENTIVE_REPORT,
  GET_PREVENTIVE_REPORT,
} from '../types';
import { PREVENTIVE_MAINTENANCE_REPORT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_POST_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingPreventiveMaintenanceReport = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PREVENTIVE_REPORT,
    payload: flag,
  });

const clearPreventiveMaintenanceReport = () => (dispatch) =>
  dispatch({
    type: CLEAR_PREVENTIVE_REPORT,
  });

const postPreventiveMaintenanceReport =
  async ({
    page,
    size,
    order,
    sort,
    registeredBusIdList,
    homeBaseLocationId,
    companyResourceIdList,
    daily,
    dateTo,
    dateFrom,
    simulationDays,
    simulationKilometers,
    force,
    dispatchData = true,
    activityId,
  }) =>
  async (dispatch) => {
    try {
      const params = {
        page,
        size,
        order,
        sort,
      };
      const payload = {
        registeredBusIdList,
        homeBaseLocationId,
        companyResourceIdList,
        daily,
        dateTo,
        simulationDays,
        simulationKilometers,
        dateFrom,
        force,
        activityId,
      };
      dispatch(flagGettingPreventiveMaintenanceReport(true));
      const url = `${PREVENTIVE_MAINTENANCE_REPORT_ENDPOINT}?${QueryString.stringify(
        params,
      )}`;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      if (dispatchData)
        dispatch({ type: GET_PREVENTIVE_REPORT, payload: response });
      return response;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagGettingPreventiveMaintenanceReport(false));
    }
  };

export { clearPreventiveMaintenanceReport, postPreventiveMaintenanceReport };
