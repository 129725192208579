import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ACTIVITY_PATH } from '../../../../config/paths';
import {
  clearActivity,
  getActivity,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ActivityBasicInformationResource from './resource/ActivityBasicInformationResource';
import ActivityToolbar from './ActivityToolbar';
import ActivityMaintenanceTaskBasicInformationResource from './resource/ActivityMaintenanceTaskBasicInformationResource';
import ActivityMaterialInformationResource from './resource/ActivityMaterialInformationResource';

export const Activity = ({
  breadcrumbs,
  dispatchGetActivity,
  dispatchClearActivity,
  match: {
    params: { id: activityId },
  },
  loading,
  activity,
}) => {
  useLayoutEffect(() => {
    dispatchGetActivity({ activityId });

    return () => dispatchClearActivity();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (activity.isEmpty())
    content = <NoDataResource returnPage={ACTIVITY_PATH} />;
  else {
    const activityJson = activity.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <ActivityBasicInformationResource {...activityJson} />
        <div className="mb-3">
          <h3>Materiales Asignados</h3>
          <ActivityMaterialInformationResource {...activityJson} />
        </div>
        <h3>Tareas Asignadas</h3>
        <ActivityMaintenanceTaskBasicInformationResource {...activityJson} />
      </>
    );

    toolbar = <ActivityToolbar activityId={activityJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Actividad"
      content={content}
    />
  );
};

Activity.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearActivity: PropTypes.func.isRequired,
  dispatchGetActivity: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  activity: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Activity.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearActivity: clearActivity,
  dispatchGetActivity: getActivity,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Actividades',
      href: ACTIVITY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  activity: MechanicalMaintenanceUnit.Activity.getIn(['current', 'content']),
  loading: !MechanicalMaintenanceUnit.Activity.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
