import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { MAINTENANCE_ACTIVITY_HISTORY_COLUMNS } from '../../../../config/columns';
import {
  clearBusActivityHistory,
  getBusActivityHistory,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../config/messages';
import DownloadButton from '../../../common/button/DownloadButton';
import { ACTIVITY_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_TIME_FORMAT } from '../../../../config/locale';
import Table from '../../../common/Table';

const convertDataToCsv = (response) =>
  response.map(
    ({
      activityName,
      companyActivityId,
      factor,
      virtualOdometer,
      status,
      employeeList,
      startDate,
      endDate,
      workingHours,
      comment,
      baseLocationName,
    }) => ({
      activityName,
      companyActivityId,
      factor,
      virtualOdometer,
      status: ACTIVITY_STATUS[status].label,
      employees:
        employeeList.map(({ fullName }) => fullName).join('-') ||
        'Sin empleados',
      startDate: tzNormalizeDate({
        date: startDate,
        format: INT_DATE_TIME_FORMAT,
      }),
      endDate: tzNormalizeDate({
        date: endDate,
        format: INT_DATE_TIME_FORMAT,
      }),
      workingHours,
      comment,
      baseLocationName,
    }),
  );

export const BusActivityHistoryResult = ({
  dispatchClearBusActivityHistory,
  loading,
  content,
  handleSubmit,
}) => {
  useLayoutEffect(() => () => dispatchClearBusActivityHistory(), []);

  const data = content.get('content') || [];

  let table = <Alert message={THERE_ARE_NOT_DATA_TO_SHOW} />;
  if (loading) table = <Loader />;
  if (!loading && data.length > 0) {
    const downloadButton = data && (
      <div className="mb-1 text-right">
        <DownloadButton
          data={convertDataToCsv(data)}
          csvName="Historial de Actividades por Bus.csv"
        />
      </div>
    );
    table = (
      <>
        {downloadButton}
        <Form onSubmit={handleSubmit}>
          <Table
            columns={MAINTENANCE_ACTIVITY_HISTORY_COLUMNS}
            data={data}
            defaultPageSize={10}
            loading={loading}
            required
            filterable
          />
        </Form>
      </>
    );
  }

  return table;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: MechanicalMaintenanceUnit.BusActivityHistory.getIn([
    'all',
    'loading',
  ]),
  content: MechanicalMaintenanceUnit.BusActivityHistory.getIn([
    'all',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetBusActivityHistory: getBusActivityHistory,
  dispatchClearBusActivityHistory: clearBusActivityHistory,
};

BusActivityHistoryResult.propTypes = {
  content: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearBusActivityHistory: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

BusActivityHistoryResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusActivityHistoryResult);
