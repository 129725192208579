import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MAINTENANCE_PLANNING_PATH } from '../../../../config/paths';
import { postMaintenancePlanning } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenancePlanningForm from './MaintenancePlanningForm';

const NewMaintenancePlanning = ({
  breadcrumbs,
  dispatchPostMaintenancePlanning,
}) => {
  const onSubmit = (formValues) => {
    const maintenancePlanningResourceList = [];

    if (
      Array.isArray(formValues.registeredBusId) &&
      formValues.registeredBusId.length > 0
    ) {
      formValues.registeredBusId.forEach((bus) => {
        maintenancePlanningResourceList.push({
          registeredBusId: bus.value,
          companyResourceId: null,
          maintenanceBusGroupId: null,
        });
      });
    } else if (
      Array.isArray(formValues.companyResourceId) &&
      formValues.companyResourceId.length > 0
    ) {
      formValues.companyResourceId.forEach((resource) => {
        maintenancePlanningResourceList.push({
          registeredBusId: null,
          companyResourceId: resource.value,
          maintenanceBusGroupId: null,
        });
      });
    } else if (
      Array.isArray(formValues.maintenanceBusGroupId) &&
      formValues.maintenanceBusGroupId.length > 0
    ) {
      formValues.maintenanceBusGroupId.forEach((maintenanceGroup) => {
        maintenancePlanningResourceList.push({
          registeredBusId: null,
          companyResourceId: null,
          maintenanceBusGroupId: maintenanceGroup.value,
        });
      });
    }

    const newFormValues = { ...formValues };
    newFormValues.planningType = formValues.planningType.value;
    newFormValues.factorType = formValues.factorType.value;
    newFormValues.companyAreaId = formValues.companyArea.value;
    newFormValues.maintenancePlanningActivityOrderList = Array.isArray(
      formValues.maintenancePlanningActivityOrderList,
    )
      ? formValues.maintenancePlanningActivityOrderList.map((activity) => ({
          activityId: activity.activity.value,
          factor: activity.factor,
          activityOrder: activity.activityOrder,
        }))
      : [];
    newFormValues.maintenancePlanningResourceList =
      maintenancePlanningResourceList;

    dispatchPostMaintenancePlanning(newFormValues);
  };

  const content = <MaintenancePlanningForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Plan de Mantenimiento"
      subtitle="Crear plan de mantenimiento"
      content={content}
    />
  );
};

NewMaintenancePlanning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenancePlanning: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaintenancePlanning: postMaintenancePlanning,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Planes de Mantenimiento',
      href: MAINTENANCE_PLANNING_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMaintenancePlanning);
