import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  clearCompanyResource,
  getCompanyResource,
} from '../../../../actions/mechanical-maintenance/CompanyResource';
import CompanyResourceBasicInformationResource from './resource/CompanyResourceBasicInformationResource';
import CompanyResourceToolbar from './CompanyResourceToolbar';

export const CompanyResource = ({
  breadcrumbs,
  dispatchClearCompanyResource,
  dispatchGetCompanyResource,
  match: {
    params: { id: companyResourceId },
  },
  loading,
  companyResource,
}) => {
  useLayoutEffect(() => {
    dispatchGetCompanyResource({ companyResourceId });

    return () => dispatchClearCompanyResource();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (companyResource.isEmpty())
    content = <NoDataResource returnPage={COMPANY_RESOURCE_PATH} />;
  else {
    const companyResourceJson = companyResource.toJS();

    content = (
      <>
        <CompanyResourceBasicInformationResource {...companyResourceJson} />
      </>
    );

    toolbar = (
      <CompanyResourceToolbar companyResourceId={companyResourceJson.id} />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Recurso de la Compañia"
      content={content}
    />
  );
};

CompanyResource.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCompanyResource: PropTypes.func.isRequired,
  dispatchGetCompanyResource: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyResource: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CompanyResource.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCompanyResource: clearCompanyResource,
  dispatchGetCompanyResource: getCompanyResource,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Recursos de la Compañia',
      href: COMPANY_RESOURCE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  companyResource: MechanicalMaintenanceUnit.CompanyResource.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.CompanyResource.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyResource);
