import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { reduxForm } from 'redux-form';
import Loader from '../../../common/Loader';
import FileDetailResource from '../../../common/resource/FileDetailResource';
import FormFooter from '../../../common/forms/FormFooter';
import FormItem from '../../../common/forms/FormItem';
import { getCommonProductExcelTemplate } from '../../../../actions';

const AddCommonProductPriceForm = ({
  handleSubmit,
  loading,
  onCancel,
  dispatchTemplate,
  commonProductId,
  onSubmit,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const isExcelFileTypeValid = (uploadedFile) => {
    const validExcelFileExtensions = ['.xlsx'];
    const uploadedFileNameInLowerCase = uploadedFile.name.toLowerCase();
    return validExcelFileExtensions.some((extension) =>
      uploadedFileNameInLowerCase.endsWith(extension),
    );
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file && isExcelFileTypeValid(file)) {
      setSelectedFile(file);
    } else {
      toastr.error('Error', 'Sólo se permiten archivos con extensión .xlsx');
      e.target.value = null;
      setSelectedFile(null);
    }
  };

  const downloadTemplate = () => {
    dispatchTemplate({ commonProductId });
  };

  const submitForm = () => {
    if (!selectedFile) {
      toastr.error('Error', 'Debe seleccionar un archivo .xlsx');
      return;
    }

    if (!isExcelFileTypeValid(selectedFile)) {
      toastr.error(
        'Error',
        'El archivo seleccionado no es un archivo .xlsx válido',
      );
      return;
    }

    const newFormValues = {
      file: selectedFile,
    };

    onSubmit(newFormValues);
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <FormGroup row>
        <FormItem label="">
          <Button type="button" onClick={downloadTemplate}>
            Descargar Plantilla
          </Button>
        </FormItem>
      </FormGroup>

      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>Tarifario</Label>
        </Col>
        <Col sm={9} xs={12}>
          <Input
            name="file"
            type="file"
            accept=".xlsx"
            onChange={onChangeFile}
          />
          {selectedFile && <FileDetailResource file={selectedFile} />}
        </Col>
      </FormGroup>
      <FormFooter>
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancelar
        </button>
      </FormFooter>
    </Form>
  );
};

AddCommonProductPriceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchTemplate: PropTypes.func.isRequired,
  commonProductId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.CommonProduct.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchTemplate: getCommonProductExcelTemplate,
};

const formComponent = reduxForm({
  form: 'AddCommonProductPriceForm',
})(AddCommonProductPriceForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
