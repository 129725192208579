import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import CompanyResourceForm from './CompanyResourceForm';
import { postCompanyResource } from '../../../../actions/mechanical-maintenance/CompanyResource';

const NewCompanyResource = ({ breadcrumbs, dispatchPostCompanyResource }) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      companyResourceTypeId: formValues.companyResourceTypeId.value,
      active: true,
    };
    dispatchPostCompanyResource(newFormValues);
  };

  const content = <CompanyResourceForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Recurso de la Compañia"
      subtitle="Crear recurso de la compañia"
      content={content}
    />
  );
};

NewCompanyResource.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCompanyResource: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCompanyResource: postCompanyResource,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Recursos de la Compañia',
      href: COMPANY_RESOURCE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCompanyResource);
