import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  MAINTENANCE_TASK_PATH,
  NEW_MAINTENANCE_TASK_PATH,
} from '../../../../config/paths';
import { MAINTENANCE_TASK_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearMaintenanceTasks,
  getMaintenanceTasks,
} from '../../../../actions/mechanical-maintenance/MaintenanceTask';

export const MaintenanceTasks = ({
  breadcrumbs,
  maintenanceTasks,
  loading,
  dispatchGetMaintenenceTasks,
  dispatchClearMaintenenceTasks,
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenenceTasks(), []);

  const data = maintenanceTasks.get('content') || [];
  const pages = maintenanceTasks.get('totalPages') || null;
  const defaultPageSize = maintenanceTasks.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Tareas"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MAINTENANCE_TASK_PATH}
      tableStructure={{
        columns: MAINTENANCE_TASK_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaintenenceTasks,
        modelPath: MAINTENANCE_TASK_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaintenenceTasks: getMaintenanceTasks,
  dispatchClearMaintenenceTasks: clearMaintenanceTasks,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Tareas',
      href: MAINTENANCE_TASK_PATH,
    },
  ],
  maintenanceTasks: MechanicalMaintenanceUnit.MaintenanceTask.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenanceTask.getIn(['all', 'loading']),
});

MaintenanceTasks.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  maintenanceTasks: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaintenenceTasks: PropTypes.func.isRequired,
  dispatchClearMaintenenceTasks: PropTypes.func.isRequired,
};

MaintenanceTasks.defaultProps = {
  maintenanceTasks: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTasks);
