import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Form, FormGroup, Col, Label } from 'reactstrap';
import Immutable from 'immutable';
import Alert from '../../../common/informative/Alert';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateLength,
  validateNumber,
  validateRuc,
} from '../../../../utils/validators';
import {
  getDriverBusAssignments,
  getInternalParcelByCorrelation,
  postBusiness,
  postCustomer,
} from '../../../../actions';
import Select from '../../../common/forms/select/Select';
import TextInput from '../../../common/forms/input/TextInput';
import CargoLocationSelect from '../../../common/forms/select/CargoLocationSelect';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateManualGrrColumns } from '../../../../config/dynamicFormFields';
import FormFooter from '../../../common/forms/FormFooter';
import DatePicker from '../../../common/forms/input/DatePicker';
import Loader from '../../../common/Loader';
import {
  GRR_MOTIVE_BUSINESS_TRANSFER,
  GRR_MOTIVE_INTERNAL_TRANSFER,
  GRR_MOTIVE_OTHERS,
  GRR_MOTIVE_SALE,
  MANUAL_GRR_MOTIVE_OPTIONS,
  MANUAL_GRR_VOUCHER_TYPES_OPTIONS,
  MAX_LENGTH_TEXTAREA_FOR_INTERNAL_PARCEL,
} from '../../../../config/constants';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import Modal from '../../../common/modal/Modal';
import InternalParcelSearchForm from './internal-parcel-search/InternalParcelSearchForm';

const validateLength11 = validateLength(11, 'dígitos');

const validateFields = (values) => {
  const errors = {};

  if (!values.cargoItems || !values.cargoItems.length) {
    errors.itemsError = 'Debe ingresar un item';
  }

  return errors;
};

export const ManualSenderRemissionGuideForm = ({
  handleSubmit,
  dispatchGetDriverBusAssignments,
  onSubmit,
  drivers,
  loading,
  dispatchGetInternalParcelByCorrelation,
  dispatchChange,
}) => {
  const [motiveId, setMotiveId] = useState('');
  const [sourceLocation, setSourceLocation] = useState(0);
  const [destinationLocation, setDestinationLocation] = useState(0);
  const [refDocumentRequired, setRefDocumentRequired] = useState(false);
  const [business, setBusiness] = useState(false);
  const [naturalPerson, setNaturalPerson] = useState(true);
  const [showInternalParcelModal, setShowInternalParcelModal] = useState(false);
  const [internalParcelRequired, setInternalParcelRequired] = useState(false);
  const [internalParcelModalBody, setInternalParcelModalBody] = useState(null);
  const [internalParcelId, setInternalParcelId] = useState(0);

  useEffect(() => {
    if (
      motiveId === GRR_MOTIVE_BUSINESS_TRANSFER ||
      motiveId === GRR_MOTIVE_SALE
    ) {
      setBusiness(true);
    }
    dispatchChange('ManualSenderRemissionGuideForm', 'cargoItems', []);
    setInternalParcelRequired(false);
  }, [motiveId]);

  const onHandleSubmit = (formValues) => {
    onSubmit(
      formValues,
      drivers.get('content'),
      refDocumentRequired,
      business,
      internalParcelRequired,
      internalParcelId,
    );
  };

  const handleMotiveId = (motive) => {
    setBusiness(false);

    setMotiveId(motive.value);
  };

  const handleLocationChange = (location, name) => {
    if (name === 'sourceLocation') {
      setSourceLocation({ sourceCityId: location.city ? location.city.id : 0 });
    }
    if (name === 'destinationLocation') {
      setDestinationLocation({
        destinationCityId: location.city ? location.city.id : 0,
      });
    }
  };

  const handleRefDocumentRequired = (event) => {
    setRefDocumentRequired(event.target.checked);
  };

  const onInternalParcelSearch = async (formValues) => {
    const newFormValues = {
      documentSeries: formValues.internalParcelDocumentSerie,
      documentCode: formValues.internalParcelDocumentCorrelation,
    };
    const internalParcel = await dispatchGetInternalParcelByCorrelation(
      newFormValues,
    );

    if (internalParcel) {
      setSourceLocation(internalParcel.sourceLocationId);
      dispatchChange('ManualSenderRemissionGuideForm', 'sourceLocation', {
        value: internalParcel.sourceLocationId,
        label: internalParcel.sourceLocationName,
      });
      dispatchChange(
        'ManualSenderRemissionGuideForm',
        'cargoItems',
        internalParcel.internalCargoItemList,
      );
      dispatchChange(
        'ManualSenderRemissionGuideForm',
        'comments',
        internalParcel.note,
      );
      setInternalParcelId(internalParcel.id);
      setShowInternalParcelModal(false);
    }
  };

  const handleCloseInternalParcelRequired = () => {
    setInternalParcelRequired(false);
    setInternalParcelModalBody(null);
    setShowInternalParcelModal(false);
  };

  const handleInternalParcelRequired = (event) => {
    setShowInternalParcelModal(event.target.checked);
    setInternalParcelRequired(event.target.checked);
    setInternalParcelModalBody(
      <InternalParcelSearchForm
        onCancel={handleCloseInternalParcelRequired}
        onSubmit={onInternalParcelSearch}
      />,
    );
  };

  const handleReceiver = (event) => {
    setBusiness(event.target.checked);
    setNaturalPerson(false);

    if (event.target.checked === false) {
      setNaturalPerson(true);
    }
  };

  const renderConsigneeField = () => {
    if (motiveId === '') {
      return <Alert message="Seleccione un Motivo de Traslado" />;
    }
    if (motiveId === GRR_MOTIVE_INTERNAL_TRANSFER) {
      return <Alert message="El destinatario es Turismo Civa SAC" />;
    }

    if (
      motiveId === GRR_MOTIVE_BUSINESS_TRANSFER ||
      motiveId === GRR_MOTIVE_SALE
    ) {
      return (
        <BusinessInputGroup
          label="Proveedor"
          labelRequired
          name="consigneeBusiness"
          form="ManualSenderRemissionGuideForm"
          validate={business ? [isRequired] : []}
          showDetails
        />
      );
    }

    return (
      <>
        <Label>
          <Field
            name="business"
            component="input"
            type="checkbox"
            onChange={(option) => handleReceiver(option)}
          />
          {'  '}
          Empresa
        </Label>
      </>
    );
  };

  const onRegisteredBusChange = (bus) => {
    if (bus.value) {
      dispatchGetDriverBusAssignments({ query: `companyBusId:${bus.label}` });
    }
  };

  const renderDynamicForm = () => {
    if (sourceLocation === 0 || destinationLocation === 0) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    return (
      <>
        <DynamicForm
          name="cargoItems"
          columns={generateManualGrrColumns(
            motiveId === GRR_MOTIVE_INTERNAL_TRANSFER,
          )}
          showAddButton={motiveId !== GRR_MOTIVE_INTERNAL_TRANSFER}
          showRemoveButton={motiveId !== GRR_MOTIVE_INTERNAL_TRANSFER}
        />
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Motivo de Traslado" required>
            <Field
              name="motiveId"
              component={Select}
              type="text"
              placeholder="Seleccione un Motivo"
              validate={[isRequired]}
              options={MANUAL_GRR_MOTIVE_OPTIONS}
              onChange={(option) => handleMotiveId(option)}
            />
          </FormItem>
        </FormGroup>
        {motiveId === GRR_MOTIVE_INTERNAL_TRANSFER && (
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="internalParcelRequired"
                  component="input"
                  type="checkbox"
                  onChange={handleInternalParcelRequired}
                  checked={internalParcelRequired}
                />{' '}
                Adjuntar Translado Interno
              </Label>
            </FormItem>
          </FormGroup>
        )}
        <FormGroup row>
          <FormItem label="Observaciones del Traslado" required>
            <Field
              name="comments"
              type="textarea"
              component={TextInput}
              placeholder="Observaciones del Traslado"
              validate={[isRequired]}
              maxLength={MAX_LENGTH_TEXTAREA_FOR_INTERNAL_PARCEL}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Instrucciones del Traslado" required>
            <Field
              name="motiveDescription"
              type="textarea"
              component={TextInput}
              placeholder="Instrucciones del Traslado"
              validate={[isRequired]}
              maxLength={MAX_LENGTH_TEXTAREA_FOR_INTERNAL_PARCEL}
            />
          </FormItem>
        </FormGroup>

        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={CargoLocationSelect}
              onChange={(option) =>
                handleLocationChange(option, 'sourceLocation')
              }
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={CargoLocationSelect}
              onChange={(option) =>
                handleLocationChange(option, 'destinationLocation')
              }
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha de Salida" required>
            <Field
              name="itineraryDepartureDate"
              component={DatePicker}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <h3>Documento Adjunto</h3>
        <Label>
          <Field
            name="refDocumentRequired"
            component="input"
            type="checkbox"
            onChange={handleRefDocumentRequired}
          />{' '}
          Se va adjuntar un documento?
        </Label>
        {refDocumentRequired && (
          <FormGroup row className="d-flex align-items-center flex-wrap">
            <Col xs="12" sm="auto">
              <Label for="voucherType" className="mb-0">
                Tipo de Documento:
              </Label>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="3"
              xl="2"
              className="input-container"
            >
              <Field
                name="voucherType"
                type="text"
                component={Select}
                options={MANUAL_GRR_VOUCHER_TYPES_OPTIONS}
                validate={refDocumentRequired ? [isRequired] : []}
              />
            </Col>
            <Col xs="12" sm="auto">
              <Label for="documentSeries" className="mb-0">
                Código del Documento:
              </Label>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="2"
              xl="1"
              className="input-container"
            >
              <Field
                name="documentSeries"
                type="text"
                component={TextInput}
                validate={refDocumentRequired ? [isRequired] : []}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              -
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="2"
              xl="1"
              className="input-container"
            >
              <Field
                name="documentCode"
                type="text"
                component={TextInput}
                validate={refDocumentRequired ? [isRequired] : []}
              />
            </Col>
            <Col xs="12" sm="auto">
              <Label for="issuerDocumentNumber" className="mb-0">
                Número Documento del Emisor:
              </Label>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="3"
              xl="2"
              className="input-container"
            >
              <Field
                name="issuerDocumentNumber"
                type="text"
                component={TextInput}
                validate={
                  refDocumentRequired
                    ? [
                        isRequired,
                        validateLength11,
                        validateNumber,
                        validateRuc,
                      ]
                    : []
                }
              />
            </Col>
          </FormGroup>
        )}

        <h3>Destinatario</h3>
        {renderConsigneeField()}
        {naturalPerson && motiveId === GRR_MOTIVE_OTHERS && (
          <CustomerInputGroup
            label="Cliente"
            labelRequired
            name="consigneeCustomer"
            form="ManualSenderRemissionGuideForm"
            validate={
              naturalPerson && motiveId === GRR_MOTIVE_OTHERS
                ? [isRequired]
                : []
            }
          />
        )}
        {business && motiveId === GRR_MOTIVE_OTHERS && (
          <BusinessInputGroup
            label="Proveedor"
            labelRequired
            name="consigneeBusiness"
            form="ManualSenderRemissionGuideForm"
            validate={
              business && motiveId === GRR_MOTIVE_OTHERS ? [isRequired] : []
            }
            showDetails
          />
        )}
        <h3>Datos del Vehículo</h3>
        <FormGroup row>
          <FormItem label="Número de Bus" required>
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              onChange={onRegisteredBusChange}
              validate={[isRequired]}
              onlyActive
            />
          </FormItem>
        </FormGroup>
        <h3>Datos de la Encomienda</h3>
        <Field name="itemsError" component={TextInput} type="hidden" />
        {renderDynamicForm()}
        <FormFooter />
      </Form>
      <Modal
        show={showInternalParcelModal}
        title="Buscar Traslado Interno"
        onClickClose={handleCloseInternalParcelRequired}
        body={internalParcelModalBody}
        size="lg"
      />
    </>
  );
};

ManualSenderRemissionGuideForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetDriverBusAssignments: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetInternalParcelByCorrelation: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  drivers: state.TrafficUnit.DriverBusAssignment.getIn(['all', 'content']),
  loading: !state.CargoUnit.ManualSenderRemissionGuide.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  internalParcel: state.CargoUnit.InternalTransfer.getIn([
    'current',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchPostCustomer: postCustomer,
  dispatchPostBusiness: postBusiness,
  dispatchGetDriverBusAssignments: getDriverBusAssignments,
  dispatchChange: change,
  dispatchGetInternalParcelByCorrelation: getInternalParcelByCorrelation,
};

const formComponent = reduxForm({
  form: 'ManualSenderRemissionGuideForm',
  validate: validateFields,
})(ManualSenderRemissionGuideForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
