import React, { useState, createElement } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { generateSalesSessionDownloadEndpoint } from '../../config/endpoints';

const ContentComponent = ({ contentComponent }) => {
  if (!contentComponent) {
    return null;
  }

  return contentComponent;
};

ContentComponent.propTypes = {
  contentComponent: PropTypes.node,
};

ContentComponent.defaultProps = {
  contentComponent: undefined,
};

const CollapsibleHeader = (props) => {
  const [showContent, setShowContent] = useState(false);

  const { headerFont, headerText, component, downloadButton, salesSessionId } =
    props;

  const toggle = () => setShowContent(!showContent);

  let toolbar = null;

  if (downloadButton && salesSessionId)
    toolbar = (
      <ButtonToolbar className="pull-right">
        <a
          className="btn btn-secondary"
          href={generateSalesSessionDownloadEndpoint(salesSessionId)}
        >
          Descargar boletos
        </a>
      </ButtonToolbar>
    );

  let iconType = 'plus';

  let contentComponent;

  if (showContent) {
    contentComponent = createElement(component, props);
    iconType = 'minus';
  }

  const icon = (
    <i
      key={headerText}
      onClick={toggle}
      onKeyPress={toggle}
      role="button"
      tabIndex={0}
      className={`fa fa-${iconType}-square-o text-primary clickable`}
    />
  );

  return (
    <>
      {toolbar}
      <div className="flex">
        <h2 className={`${headerFont}`}>
          {headerText} {icon}
        </h2>
      </div>
      <ContentComponent contentComponent={contentComponent} />
    </>
  );
};

CollapsibleHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  headerFont: PropTypes.string,
  downloadButton: PropTypes.bool,
  salesSessionId: PropTypes.number,
};

CollapsibleHeader.defaultProps = {
  downloadButton: false,
  salesSessionId: null,
  headerFont: 'h2',
};

export default CollapsibleHeader;
