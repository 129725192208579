import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postRegisteredBus } from '../../../../actions';
import { BUS_PATH } from '../../../../config/paths';
import RegisteredBusForm from './RegisteredBusForm';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';

const NewRegisteredBus = ({ breadcrumbs, dispatchPostRegisteredBus }) => {
  const onSubmit = (formValues) => {
    const { homeBaseLocation, busChassis, busBodywork, seatMap, documentList } =
      formValues;

    const newFormValues = {
      ...formValues,
      busBodyworkId: busBodywork.value,
      busChassisId: busChassis.value,
      homeBaseLocationId: homeBaseLocation.value,
      seatMapId: seatMap.value,
    };

    if (documentList) {
      newFormValues.documentSet = formValues.documentList.map((document) => ({
        documentTypeId: document.documentType.value,
        effectiveDate: tzNormalizeDate({
          date: document.effectiveDate,
          format: TIMESTAMP_FORMAT,
        }),
        serialNumber: document.serialNumber,
        expirationDate: tzNormalizeDate({
          date: document.expirationDate,
          format: TIMESTAMP_FORMAT,
        }),
      }));
    }

    dispatchPostRegisteredBus(newFormValues);
  };

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Registrar Bus"
      subtitle="Registar bus"
      content={<RegisteredBusForm onSubmit={onSubmit} isPost />}
    />
  );
};

const mapStateToProps = ({ BusUnit }) => ({
  breadcrumbs: [
    ...BusUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Buses',
      href: BUS_PATH,
    },
    {
      text: 'Crear',
      href: '',
    },
  ],
});

const mapDispatchToProps = { dispatchPostRegisteredBus: postRegisteredBus };

NewRegisteredBus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostRegisteredBus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRegisteredBus);
