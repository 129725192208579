import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteMaintenanceBusGroup } from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';

const MaintenanceBusGroupDeleteButton = ({
  dispatchDeleteMaintenanceBusGroup,
  maintenanceBusGroupId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteMaintenanceBusGroup({ maintenanceBusGroupId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

MaintenanceBusGroupDeleteButton.propTypes = {
  dispatchDeleteMaintenanceBusGroup: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maintenanceBusGroupId: PropTypes.number.isRequired,
};

MaintenanceBusGroupDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteMaintenanceBusGroup: deleteMaintenanceBusGroup,
};

export default connect(
  null,
  mapDispatchToProps,
)(MaintenanceBusGroupDeleteButton);
