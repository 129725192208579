import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_BUSES_BODY_WORKS,
  FLAG_GETTING_BUSES_BODY_WORKS,
  GET_BUSES_BODY_WORKS,
} from '../types';
import { BUS_BODYWORK_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';

const flagGettingBusesBodyWorks = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_BUSES_BODY_WORKS,
  });

const getBusesBodyWorks = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingBusesBodyWorks(true));
    const query = tableFilters;
    const url = `${BUS_BODYWORK_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_BUSES_BODY_WORKS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingBusesBodyWorks(false));
  }
};

const clearBusBodyWorks = () => (dispatch) =>
  dispatch({ type: CLEAR_BUSES_BODY_WORKS });

export { clearBusBodyWorks, getBusesBodyWorks };
