import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_TASK_PATH } from '../../../../config/paths';

const MaintenanceTaskToolbar = ({ maintenanceTaskId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton path={`${MAINTENANCE_TASK_PATH}/${maintenanceTaskId}/edit`} />
    </div>
  </ButtonToolbar>
);

MaintenanceTaskToolbar.propTypes = {
  maintenanceTaskId: PropTypes.number.isRequired,
};

export default MaintenanceTaskToolbar;
