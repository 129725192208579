import React, { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { postParcelVoidPayment } from '../../../../actions';

const VoidPaymentButton = ({ dispatchPostParcelVoid, parcelId }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleParcelVoid = () => {
    dispatchPostParcelVoid({ parcelId });
  };

  return (
    <>
      <Button color="danger" onClick={openConfirmationModal}>
        Anular Pago
      </Button>
      <ConfirmationModal
        show={showConfirmationModal}
        onClickConfirm={handleParcelVoid}
        onClickCancel={closeConfirmationModal}
      />
    </>
  );
};

VoidPaymentButton.propTypes = {
  dispatchPostParcelVoid: PropTypes.func.isRequired,
  parcelId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  dispatchPostParcelVoid: postParcelVoidPayment,
};

export default connect(null, mapDispatchToProps)(VoidPaymentButton);
