import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import InternalParcelForm from './InternalParcelForm';
import { postInternalParcel } from '../../../../actions';
import { INTERNAL_TRANSFER_PATH } from '../../../../config/paths';
import Content from '../../../layout/Content';

export class NewInternalParcel extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      voucherTypeId: 3,
      sourceCityId: formValues.sourceLocation.city.id,
      sourceLocationId: formValues.sourceLocation.value,
      destinationCityId: formValues.destinationLocation.city.id,
      destinationLocationId: formValues.destinationLocation.value,
      senderCustomerId: formValues.senderCustomer.value,
      consigneeCustomerId: formValues.consigneeCustomer.value,
      comments: formValues.note,
      internalCargoItemList: formValues.internalCargoItems.map((item) => ({
        cargoCategoryId: item.cargoCategoryId
          ? item.cargoCategoryId.value
          : null,
        commonProductId: item.commonProductId
          ? item.commonProductId.value
          : null,
        description: item.description,
        quantity: item.quantity,
        weight: item.weight,
      })),
      sapCode: formValues.sapCode,
    };
    this.props.dispatchPostInternalParcel(newFormValues);
  };

  render() {
    const { breadcrumbs } = this.props;
    const content = <InternalParcelForm onSubmit={this.onSubmit} />;
    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Recepción de Translado Interno"
        subtitle="Crear nueva Recepción de Translado Interno."
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Translados Internos',
      href: INTERNAL_TRANSFER_PATH,
    },
    {
      text: 'Recepción de Translado Interno',
      href: '',
    },
  ],
});

const mapDispatchToProps = { dispatchPostInternalParcel: postInternalParcel };

NewInternalParcel.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostInternalParcel: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInternalParcel);
