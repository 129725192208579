import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  MAINTENANCE_PLANNING_PATH,
  NEW_MAINTENANCE_PLANNING_PATH,
} from '../../../../config/paths';
import { MAINTENANCE_PLANNINGS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearMaintenancePlannings,
  getMaintenancePlannings,
} from '../../../../actions';

export const MaintenancePlannings = ({
  breadcrumbs,
  maintenancePlannings,
  loading,
  dispatchGetMaintenencePlannings,
  dispatchClearMaintenencePlannings,
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenencePlannings(), []);

  const data = maintenancePlannings.get('content') || [];
  const pages = maintenancePlannings.get('totalPages') || null;
  const defaultPageSize = maintenancePlannings.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Planes de Mantenimiento"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MAINTENANCE_PLANNING_PATH}
      tableStructure={{
        columns: MAINTENANCE_PLANNINGS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaintenencePlannings,
        modelPath: MAINTENANCE_PLANNING_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaintenencePlannings: getMaintenancePlannings,
  dispatchClearMaintenencePlannings: clearMaintenancePlannings,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Planes de Mantenimiento',
      href: MAINTENANCE_PLANNING_PATH,
    },
  ],
  maintenancePlannings: MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'all',
    'loading',
  ]),
});

MaintenancePlannings.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  maintenancePlannings: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaintenencePlannings: PropTypes.func.isRequired,
  dispatchClearMaintenencePlannings: PropTypes.func.isRequired,
};

MaintenancePlannings.defaultProps = {
  maintenancePlannings: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenancePlannings);
