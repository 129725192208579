import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  ITINERARY_PATH,
  NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
} from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ExtraordinaryMovementForm from './ExtraordinaryMovementForm';
import {
  postRescueItinerary,
  getItinerary,
  clearItinerary,
} from '../../../../actions/itinerary';
import Loader from '../../../common/Loader';
import { EXTRAORDINARY_MOVEMENT_TYPE } from '../../../../config/constants';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';

export class NewRescueItinerary extends Component {
  componentDidMount() {
    const {
      dispatchGetItinerary,
      match: {
        params: { id },
      },
    } = this.props;

    dispatchGetItinerary({ itineraryId: id });
  }

  componentWillUnmount() {
    const { dispatchClearItinerary } = this.props;

    dispatchClearItinerary();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPostRescueItinerary,
      match: {
        params: { id },
      },
    } = this.props;

    const newFormValues = {
      extraordinaryMovementType: EXTRAORDINARY_MOVEMENT_TYPE.OTHER.value,
      sourceLocation: formValues.sourceLocation,
      busId: formValues.busId.value,
      destinationLocation: formValues.destinationLocation,
      cabinCrewCommissionAmount: formValues.cabinCrewCommissionAmount || 0,
      driverCommissionAmount: formValues.driverCommissionAmount || 0,
      tollExpenseAmount: formValues.tollExpenseAmount || 0,
      otherExpenseAmount: formValues.otherExpenseAmount || 0,
      distance: formValues.distance || 0,
      fuelGallons: formValues.fuelGallons || 0,
      adBlueLiters: formValues.adBlueLiters || 0,
      comment: formValues.comment || null,
      itineraryId: id,
    };

    dispatchPostRescueItinerary(newFormValues);
  };

  generateInitialValues = (itinerary) => ({
    sourceLocation: itinerary.get('route').sourceLocationName,
    destinationLocation: itinerary.get('route').destinationLocationName,
    extraordinaryMovementType: {
      value: EXTRAORDINARY_MOVEMENT_TYPE.OTHER.value,
      label: EXTRAORDINARY_MOVEMENT_TYPE.OTHER.label,
    },
  });

  render() {
    const { breadcrumbs, loading, itinerary } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (itinerary.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_PATH} />;
    } else {
      content = (
        <ExtraordinaryMovementForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(itinerary)}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Movimiento Extraordinario"
        subtitle="Movimiento Extraordinario - Rescate de Itinerario"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    // TODO link to list of extraordinary movement goes here
    {
      text: 'Movimiento Extraordinario',
      href: NEW_ITINERARY_EXTRAORDINARY_MOVEMENT_PATH,
    },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
  dispatchPostRescueItinerary: postRescueItinerary,
};

NewRescueItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchPostRescueItinerary: PropTypes.func.isRequired,
};

NewRescueItinerary.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRescueItinerary);
