import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { DOCUMENT_TYPE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import {
  CLEAR_DOCUMENT_TYPES,
  FLAG_GETTING_DOCUMENT_TYPES,
  GET_DOCUMENT_TYPES,
} from '../types';

const flagGettingDocumentTypes = (flag) => (dispatch) =>
  dispatch({
    payload: flag,
    type: FLAG_GETTING_DOCUMENT_TYPES,
  });

const getDocumentTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDocumentTypes(true));
    const query = tableFilters;
    const url = `${DOCUMENT_TYPE_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_DOCUMENT_TYPES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDocumentTypes(false));
  }
};

const clearDocumentTypes = () => (dispatch) =>
  dispatch({ type: CLEAR_DOCUMENT_TYPES });

export { clearDocumentTypes, getDocumentTypes };
