import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import MaintenanceServiceOrderEditButton from './MaintenanceServiceOrderEditButton';
import MaintenanceServiceOrderAssignExecutionButton from './MaintenanceServiceOrderAssignExecutionButton';
import MaintenanceServiceOrderPrintButton from './MaintenanceServiceOrderPrintButton';
import MaintenanceServiceOrderDeleteButton from './MaintenanceServiceOrderDeleteButton';

const MaintenanceServiceOrderToolbar = ({ maintenanceServiceOrderId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <MaintenanceServiceOrderAssignExecutionButton
        maintenanceServiceOrderId={maintenanceServiceOrderId}
      />{' '}
      <MaintenanceServiceOrderPrintButton />{' '}
      <MaintenanceServiceOrderEditButton
        maintenanceServiceOrderId={maintenanceServiceOrderId}
      />{' '}
      <MaintenanceServiceOrderDeleteButton
        maintenanceServiceOrderId={maintenanceServiceOrderId}
      />
    </div>
  </ButtonToolbar>
);

MaintenanceServiceOrderToolbar.propTypes = {
  maintenanceServiceOrderId: PropTypes.number.isRequired,
};

export default MaintenanceServiceOrderToolbar;
