import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteMaintenanceWarning } from '../../../../actions';

const DeleteMaintenanceWarningButton = ({
  dispatchDeleteMaintenanceWarning,
  maintenanceWarningId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteMaintenanceWarning({ maintenanceWarningId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

DeleteMaintenanceWarningButton.propTypes = {
  dispatchDeleteMaintenanceWarning: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maintenanceWarningId: PropTypes.number.isRequired,
};

DeleteMaintenanceWarningButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteMaintenanceWarning: deleteMaintenanceWarning,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeleteMaintenanceWarningButton);
