import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import TreeSelect from '../../../common/forms/select/TreeSelect';
import {
  clearRegisteredBuses,
  getRegisteredBusesV2,
} from '../../../../actions';
import {
  getChassisBodyworks,
  clearChassisBodyworks,
} from '../../../../actions/traffic/ChassisBodywork';
import Loader from '../../../common/Loader';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import FormFooter from '../../../common/forms/FormFooter';

const MaintenanceBusGroupForm = ({
  handleSubmit,
  maintenanceBusGroupId,
  loading,
  chassisBodyworks,
  loadingChassisBodyworks,
  registeredBus,
  loadingRegisteredBus,
  dispatchGetChassisBodyworks,
  dispatchGetRegisteredBuses,
  dispatchClearChassisBodyworks,
  dispatchClearRegisteredBuses,
  dispatchChange,
}) => {
  useEffect(() => {
    dispatchGetChassisBodyworks();
    dispatchGetRegisteredBuses(DEFAULT_QUERY_GET_ALL);

    return () => {
      dispatchClearChassisBodyworks();
      dispatchClearRegisteredBuses();
    };
  }, []);
  const onChange = (currentNode, selectedNodes) => {
    const buses = [];
    for (let index = 0; index < selectedNodes.length; index += 1) {
      if (typeof selectedNodes[index].value === 'object') {
        selectedNodes[index].value.forEach((value) => {
          buses.push(value);
        });
      } else {
        buses.push(selectedNodes[index].value);
      }
    }
    dispatchChange(
      'MaintenanceBusGroupForm',
      'registeredBus',
      buses.toString(),
    );
  };

  const createTreeData = (chassis, registeredBuses, busGroupId) => {
    const objectBus = [];
    chassis.forEach((value) => {
      const arrayValue = [];
      const childrenNodes = [];
      registeredBuses.get('content').forEach((bus) => {
        if (
          value.busBodyworkId === bus.busBodyworkId &&
          value.busChassisId === bus.busChassisId
        ) {
          if (
            !bus.maintenanceBusGroupId ||
            bus.maintenanceBusGroupId === parseInt(busGroupId, 10)
          ) {
            arrayValue.push(bus.id);
            childrenNodes.push({
              label: `${bus.companyBusId}`,
              checked: bus.maintenanceBusGroupId === parseInt(busGroupId, 10),
              value: bus.id,
            });
          }
        }
      });

      if (childrenNodes.length > 0) {
        const parentNodes = {
          label: `${value.busChassis.make} ${value.busChassis.model} ${value.busBodywork.model}`,
          value: arrayValue,
          children: childrenNodes,
        };
        objectBus.push(parentNodes);
      }
    });

    return objectBus;
  };

  if (loading || loadingChassisBodyworks || loadingRegisteredBus) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre de Grupo" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre de Grupo"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Bus" required>
          <Field
            data={createTreeData(
              chassisBodyworks,
              registeredBus,
              maintenanceBusGroupId,
            )}
            component={TreeSelect}
            name="tree"
            keepTreeOnSearch
            keepChildrenOnSearch
            keepOpenOnSelect
            showPartiallySelected
            onInputChange={onChange}
          />
          <Field
            name="registeredBus"
            component={TextInput}
            type="hidden"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

MaintenanceBusGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  maintenanceBusGroupId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  chassisBodyworks: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingChassisBodyworks: PropTypes.bool.isRequired,
  registeredBus: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingRegisteredBus: PropTypes.bool.isRequired,
  dispatchGetChassisBodyworks: PropTypes.func.isRequired,
  dispatchGetRegisteredBuses: PropTypes.func.isRequired,
  dispatchClearChassisBodyworks: PropTypes.func.isRequired,
  dispatchClearRegisteredBuses: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

MaintenanceBusGroupForm.defaultProps = {
  maintenanceBusGroupId: '',
};

const mapStateToProps = (state) => ({
  loading: !state.TrafficUnit.BusFuelGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  chassisBodyworks: state.TrafficUnit.ChassisBodywork.getIn(['all', 'content']),
  loadingChassisBodyworks: state.TrafficUnit.ChassisBodywork.getIn([
    'all',
    'loading',
  ]),
  registeredBus: state.BusUnit.RegisteredBus.getIn(['all', 'content']),
  loadingRegisteredBus: state.BusUnit.RegisteredBus.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetChassisBodyworks: getChassisBodyworks,
  dispatchGetRegisteredBuses: getRegisteredBusesV2,
  dispatchClearChassisBodyworks: clearChassisBodyworks,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
  dispatchChange: change,
};

export default reduxForm({
  form: 'MaintenanceBusGroupForm',
})(connect(mapStateToProps, mapDispatchToProps)(MaintenanceBusGroupForm));
