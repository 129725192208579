export const GET_SERVICE_MERGES = 'GET_SERVICE_MERGES';
export const FLAG_GETTING_SERVICE_MERGES = 'FLAG_GETTING_SERVICE_MERGES';
export const CLEAR_SERVICE_MERGES = 'CLEAR_SERVICE_MERGES';
export const GET_SERVICE_MERGE = 'GET_SERVICE_MERGE';
export const FLAG_SERVICE_MERGE_ACTIVITY = 'FLAG_SERVICE_MERGE_ACTIVITY';
export const CLEAR_SERVICE_MERGE = 'CLEAR_SERVICE_MERGE';
export const GET_PRINTED_TICKETS = 'GET_PRINTED_TICKETS';
export const CLEAR_PRINTED_TICKETS = 'CLEAR_PRINTED_TICKETS';

export const GET_SOURCE_ITINERARY_SEATMAP = 'GET_SOURCE_ITINERARY_SEATMAP';
export const FLAG_GETTING_SOURCE_ITINERARY_SEATMAP =
  'FLAG_GETTING_SOURCE_ITINERARY_SEATMAP';
export const CLEAR_SOURCE_ITINERARY_SEATMAP = 'CLEAR_SOURCE_ITINERARY_SEATMAP';
export const GET_SOURCE_ITINERARY_PASSENGER_LIST =
  'GET_SOURCE_ITINERARY_PASSENGER_LIST';
export const CLEAR_SOURCE_ITINERARY_PASSENGER_LIST =
  'CLEAR_SOURCE_ITINERARY_PASSENGER_LIST';

export const GET_DESTINATION_ITINERARY_SEATMAP =
  'GET_DESTINATION_ITINERARY_SEATMAP';
export const FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP =
  'FLAG_GETTING_DESTINATION_ITINERARY_SEATMAP';
export const CLEAR_DESTINATION_ITINERARY_SEATMAP =
  'CLEAR_DESTINATION_ITINERARY_SEATMAP';
export const GET_DESTINATION_ITINERARY_PASSENGER_LIST =
  'GET_DESTINATION_ITINERARY_PASSENGER_LIST';
export const CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST =
  'CLEAR_DESTINATION_ITINERARY_PASSENGER_LIST';
