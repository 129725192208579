import React from 'react';
import Table from '../../../../common/Table';
import Alert from '../../../../common/informative/Alert';
import {
  activityMaterialInformationDefaultProps,
  activityMaterialInformationPropTypes,
} from '../proptypes/ActivityPropTypes';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { ACTIVITY_MAINTENANCE_TASK_COLUMNS } from '../../../../../config/columns';

const ActivityMaintenanceTaskBasicInformationResource = ({
  activityMaintenanceTaskList,
  maintenanceTaskMaterialActivityList,
}) => {
  if (activityMaintenanceTaskList.length === 0)
    return (
      <Alert type="warning" message="Actividad no tiene tareas asignadas." />
    );

  const data = activityMaintenanceTaskList.map((task) => {
    const taskName = task.maintenanceTask.description || '-';
    const workingHours = task.maintenanceTask.workingHours || 0;

    const materials = maintenanceTaskMaterialActivityList
      .filter(
        (relation) => relation.maintenanceTaskId === task.maintenanceTask.id,
      )
      .map((material) => `${material.material.name}`)
      .join(', ');

    return {
      taskName,
      workingHours,
      materials: materials || '-',
    };
  });

  return (
    <Table
      columns={ACTIVITY_MAINTENANCE_TASK_COLUMNS}
      data={data}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
      defaultPageSize={
        activityMaintenanceTaskList.length > 10
          ? DEFAULT_PAGE_SIZE
          : activityMaintenanceTaskList.length
      }
      showPagination={activityMaintenanceTaskList.length > 10}
    />
  );
};

ActivityMaintenanceTaskBasicInformationResource.propTypes =
  activityMaterialInformationPropTypes;

ActivityMaintenanceTaskBasicInformationResource.defaultProps =
  activityMaterialInformationDefaultProps;

export default ActivityMaintenanceTaskBasicInformationResource;
