import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ActivityFormFields from './ActivityFormFields';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';

const selector = formValueSelector('ActivityForm');

export const ActivityForm = ({
  handleSubmit,
  loading,
  initialValues,
  materialListValue,
}) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <ActivityFormFields
        initialValues={initialValues}
        materialList={materialListValue}
      />
      <FormFooter />
    </Form>
  );
};

ActivityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  materialListValue: PropTypes.arrayOf(
    PropTypes.shape({
      materialId: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
      quantity: PropTypes.number,
    }),
  ),
};

ActivityForm.defaultProps = {
  initialValues: null,
  materialListValue: [],
};

const mapStateToProps = (state) => ({
  loading: !state.MechanicalMaintenanceUnit.Activity.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  materialListValue: selector(state, 'materialList'),
});

const formComponent = reduxForm({
  form: 'ActivityForm',
})(ActivityForm);

export default connect(mapStateToProps)(formComponent);
