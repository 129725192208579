import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { isIntegerNumber } from '../../../../utils/validators';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  clearMaintenancePlanningActivitiesResources,
  getMaintenancePlanningActivitiesResources,
} from '../../../../actions';

export const ActivityMaintenancePlanningSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchGetMaintenancePlanningActivitiesResources,
  dispatchClearMaintenancePlanningActivitiesResources,
  registeredBusId,
  ...rest
}) => {
  useLayoutEffect(
    () => () => dispatchClearMaintenancePlanningActivitiesResources(),
    [],
  );

  const onInputChangeActivity = (inputValue) => {
    const inputValueLength = inputValue.trim().length;
    if (inputValueLength > 0) {
      const inputValueIsNumber =
        isIntegerNumber(inputValue.trim()) && inputValueLength;

      const query = [
        `maintenancePlanningResource.registeredBusId:${registeredBusId}`,
      ];

      if (inputValueIsNumber)
        query.push(
          `maintenancePlanningActivityOrder.activity.companyActivityId:${inputValue}`,
        );
      else if (inputValueLength && inputValueLength >= 4)
        query.push(
          `maintenancePlanningActivityOrder.activity.name:${inputValue}`,
        );

      dispatchGetMaintenancePlanningActivitiesResources({
        ...DEFAULT_QUERY_GET_ALL,
        query,
      });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChangeActivity}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

ActivityMaintenancePlanningSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetMaintenancePlanningActivitiesResources: PropTypes.func.isRequired,
  dispatchClearMaintenancePlanningActivitiesResources:
    PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  registeredBusId: PropTypes.number.isRequired,
};

ActivityMaintenancePlanningSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por código o nombre de actividad',
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.MaintenancePlanningActivityResource.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, maintenancePlanningActivityOrder }) => ({
    value: id,
    label: `${maintenancePlanningActivityOrder.activity.companyActivityId} - ${maintenancePlanningActivityOrder.activity.name}`,
  })),
  loading: MechanicalMaintenanceUnit.MaintenancePlanningActivityResource.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetMaintenancePlanningActivitiesResources:
    getMaintenancePlanningActivitiesResources,
  dispatchClearMaintenancePlanningActivitiesResources:
    clearMaintenancePlanningActivitiesResources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityMaintenancePlanningSelect);
