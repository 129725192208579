import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  maintenancePlanningDefaultProps,
  maintenancePlanningPropTypes,
} from './MaintenancePlanningBasicInformationPropTypes';
import EnumStatus from '../../../../common/EnumStatus';
import {
  MAINTENANCE_FACTOR_TYPE_LABEL,
  MAINTENANCE_PLANNING_STATUS,
  MAINTENANCE_PLANNING_TYPE_LABEL,
} from '../../../../../config/constants';

const MaintenancePlanningBasicInformationResource = ({
  description,
  planningType,
  companyArea,
  factorType,
  status,
}) => (
  <>
    <ResourceProperty label="Descripción:">
      {description || '-'}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Plan:">
      <EnumStatus
        enumObject={MAINTENANCE_PLANNING_TYPE_LABEL}
        enumValue={planningType}
      />
    </ResourceProperty>
    <ResourceProperty label="Tipo de Factor:">
      <EnumStatus
        enumObject={MAINTENANCE_FACTOR_TYPE_LABEL}
        enumValue={factorType}
      />
    </ResourceProperty>
    <ResourceProperty label="Organigrama:">
      {companyArea ? companyArea.name : '-'}
    </ResourceProperty>
    <ResourceProperty label="Estado:">
      <EnumStatus enumObject={MAINTENANCE_PLANNING_STATUS} enumValue={status} />
    </ResourceProperty>
  </>
);

MaintenancePlanningBasicInformationResource.propTypes =
  maintenancePlanningPropTypes;

MaintenancePlanningBasicInformationResource.defaultProps =
  maintenancePlanningDefaultProps;

export default MaintenancePlanningBasicInformationResource;
