import React from 'react';
import {
  maintenanceServiceOrderMaintenanceWarningListDefaultProps,
  maintenanceServiceOrderMaintenanceWarningListPropTypes,
} from '../prop-types/MaintenanceServiceOrderPropTypes';
import Alert from '../../../../common/informative/Alert';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { FORMATTED_MAINTENANCE_WARNING_COLUMNS } from '../../../../../config/columns';
import { DATE_FORMAT } from '../../../../../config/locale';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_STATUS,
  MAINTENANCE_WARNING_TYPE,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import { MAINTENANCE_WARNING_PATH } from '../../../../../config/paths';

const MaintenanceServiceOrderMaintenanceWarningListInformation = ({
  maintenanceWarningList,
}) => {
  const formattedData = maintenanceWarningList.map(
    ({
      issueDate,
      registeredBus,
      category,
      subCategory,
      maintenanceFailMode,
      maintenanceWarningType,
      priority,
      comments,
      reporterCustomer,
      status,
      statusComment,
      id,
      creationType,
      activityBus,
      maintenancePlanningActivityResource,
    }) => {
      let failModeText =
        maintenanceFailMode && maintenanceFailMode.description
          ? maintenanceFailMode.description
          : '-';

      if (
        creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value &&
        activityBus
      ) {
        failModeText = `${activityBus.activity.companyActivityId} - ${activityBus.activity.name}`;
      }

      if (
        creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value &&
        maintenancePlanningActivityResource.maintenancePlanningActivityOrder
      ) {
        failModeText = `${maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.companyActivityId} - ${maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.name}`;
      }

      return {
        issueDate: issueDate
          ? tzNormalizeDate({ date: issueDate, format: DATE_FORMAT })
          : '-',
        companyBusId: registeredBus ? registeredBus.companyBusId : '-',
        category: category ? category.name : '-',
        subCategory: subCategory ? subCategory.name : '-',
        maintenanceFailMode: failModeText,
        maintenanceWarningType: maintenanceWarningType
          ? MAINTENANCE_WARNING_TYPE[maintenanceWarningType].label
          : '-',
        priority: MAINTENANCE_WARNING_PRIORITY[priority].label,
        priorityValue: priority,
        comments,
        reporterCustomer: reporterCustomer ? reporterCustomer.fullName : '-',
        status: MAINTENANCE_WARNING_STATUS[status].label,
        statusComment,
        id,
      };
    },
  );

  return maintenanceWarningList.length === 0 ? (
    <Alert
      type="danger"
      message="Esta orden de servicio no tiene reportes de ocurrencia."
    />
  ) : (
    <Table
      columns={FORMATTED_MAINTENANCE_WARNING_COLUMNS}
      data={formattedData}
      filterable
      manual={false}
      defaultFilterMethod={filterMethodCaseInsensitive}
      modelPath={MAINTENANCE_WARNING_PATH}
      openPathInNewTab
      navigateToModelOnRowClick
    />
  );
};

MaintenanceServiceOrderMaintenanceWarningListInformation.propTypes =
  maintenanceServiceOrderMaintenanceWarningListPropTypes;

MaintenanceServiceOrderMaintenanceWarningListInformation.defaultProps =
  maintenanceServiceOrderMaintenanceWarningListDefaultProps;

export default MaintenanceServiceOrderMaintenanceWarningListInformation;
