import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Form, FormGroup, Button, Label } from 'reactstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import {
  postCargoPrice,
  getActiveSalesSession,
  getDetractionAmountBySalePrice,
  clearCargoPrices,
  getCommonProductPrice,
  getParcelPriceConfigurations,
} from '../../../../actions';
import {
  isRequired,
  validateNumber,
  validateMaxLength,
  isValidNumber,
} from '../../../../utils/validators';
import { CARGO_PATH } from '../../../../config/paths';
import { generateCargoItemColumns } from '../../../../config/dynamicFormFields';
import {
  PARCEL_CATEGORY_ENVELOPE,
  PARCEL_PRICE_CONFIGURATION,
  VOUCHER_TYPE_BUSINESS_INVOICE_ID,
} from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import DynamicForm from '../../../common/forms/DynamicForm';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import CargoLocationSelect from '../../../common/forms/select/CargoLocationSelect';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import './ParcelForm.css';
import {
  DEFAULT_PARCEL_SERVICE_TYPE_OPTION,
  DEFAULT_VOUCHER_TYPE_OPTION,
} from '../../../../config/defaults';
import ParcelServiceTypeSelect from '../../../common/forms/select/ParcelServiceTypeSelect';
import { isNumber } from '../../../../utils/number';

const selector = formValueSelector('ParcelForm');
const validataMaxLength10 = validateMaxLength(10);
const validateSecretCode = (confirmSecretCode, { secretCode }) => {
  if (!confirmSecretCode) return undefined;

  return confirmSecretCode !== secretCode
    ? 'Las claves secretas no coinciden'
    : undefined;
};

const isInvalidNumberValue = (item, value) =>
  !item[value] || !isNumber(item[value], 10) || item[value] <= 0;

export const ParcelForm = ({
  prices,
  cargaItemsValues,
  selectedVoucherType,
  senderCustomerValue,
  onSubmit,
  handleSubmit,
  dispatchChange,
  loading,
  commonProducts,
  activeSalesSession,
  gettingActiveSalesSession,
  dispatchPostCargoPrice,
  dispatchClearCargoPrices,
  dispatchGetActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice,
  discountCode,
  dispatchPush,
  cargoCategories,
  parcelServiceTypes,
  customerValue,
  businessValue,
  dispatchGetCommonProductPrice,
  commonProductPrices,
  dispatchGetParcelPriceConfigurations,
  parcelPriceConfigurations,
}) => {
  const [showDeliveryFields, setShowDeliveryFields] = useState(false);
  const [sourceLocationId, setSourceLocationId] = useState(0);
  const [destinationLocationId, setDestinationLocationId] = useState(0);
  const [parcelServiceTypeId, setParcelServiceTypeId] = useState(0);
  const [showDetraction, setShowDetraction] = useState(false);
  const [detraction, setDetraction] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isCopyClientChecked, setIsCopyClientChecked] = useState(false);
  const [isDiscountCodeChecked, setIsDiscountCodeChecked] = useState(false);
  const [showSecondaryConsigneeField, SetShowSecondaryConsigneeField] =
    useState(false);
  const [businessId, setBusinessId] = useState(0);
  const [totaListPrice, setTotaListPrice] = useState(0);

  useLayoutEffect(() => {
    dispatchGetActiveSalesSession({ source: CARGO_PATH });
    return () => dispatchClearCargoPrices();
  }, []);

  const clearSalePrice = async () => {
    dispatchChange('ParcelForm', 'listPrice', '');
    dispatchChange('ParcelForm', 'salePrice', '');
    dispatchChange('ParcelForm', 'discountPrice', '');
    dispatchChange('ParcelForm', 'servicePrice', '');
  };

  useEffect(() => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value !== VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      setBusinessId(0);
      setDetraction(0);
      setShowDetraction(false);
    }
    clearSalePrice();
  }, [selectedVoucherType]);

  useEffect(() => {
    clearSalePrice();
  }, [customerValue, businessValue]);

  useEffect(() => {
    setShowLoader(gettingActiveSalesSession || activeSalesSession.isEmpty());
    if (!activeSalesSession.isEmpty()) {
      const {
        locationId,
        location: {
          name,
          address: { city },
          receiveCargoPoint,
        },
      } = activeSalesSession.get('agency');
      if (!receiveCargoPoint) {
        toastr.error('Error', 'La agencia no puede recibir carga.');
        dispatchPush(CARGO_PATH);
        return;
      }
      const sourceLocation = { value: locationId, label: name, city };
      setSourceLocationId(locationId);
      dispatchChange('ParcelForm', 'sourceLocation', sourceLocation);
    }
  }, [gettingActiveSalesSession, activeSalesSession]);

  useEffect(() => {
    if (!prices.isEmpty()) {
      const salePriceWithDetraction = prices.get('finalPrice') - detraction;

      dispatchChange('ParcelForm', 'detraction', detraction.toFixed(2));
      dispatchChange(
        'ParcelForm',
        'salePriceWithDetraction',
        salePriceWithDetraction.toFixed(2),
      );
      dispatchChange('ParcelForm', 'servicePrice', prices.get('servicePrice'));
    }
  }, [detraction, prices]);

  useEffect(() => {
    if (parcelServiceTypes) {
      dispatchChange(
        'ParcelForm',
        'parcelServiceType',
        DEFAULT_PARCEL_SERVICE_TYPE_OPTION,
      );
      setParcelServiceTypeId(DEFAULT_PARCEL_SERVICE_TYPE_OPTION.value);
    }
  }, [parcelServiceTypes]);

  useEffect(() => {
    if (sourceLocationId && destinationLocationId) {
      dispatchGetCommonProductPrice({
        sourceLocationId,
        destinationLocationId,
      });
      dispatchGetParcelPriceConfigurations();
      dispatchChange('ParcelForm', 'cargaItems', []);
      clearSalePrice();
    }
  }, [sourceLocationId, destinationLocationId]);

  const calculateTotalListPrice = () => {
    let total = 0;

    prices.get('pricesList').forEach(({ price: { listPrice } }) => {
      total += listPrice;
    });

    dispatchChange('ParcelForm', 'listPrice', total.toFixed(2));
  };

  const calculateTotalSalePrice = async () => {
    const total = prices.get('totalPrice');
    const finalPrice = prices.get('finalPrice');
    const corporateCreditAmount = prices.get('corporateCreditAmount');
    const listPrice = prices.get('listPrice');

    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      const updatedDetraction = await dispatchGetDetractionAmountBySalePrice({
        salePrice: total,
      });
      setDetraction(updatedDetraction);
      setShowDetraction(updatedDetraction !== 0);
    }

    dispatchChange(
      'ParcelForm',
      'corporateCreditAmount',
      corporateCreditAmount,
    );

    dispatchChange('ParcelForm', 'salePrice', finalPrice.toFixed(2));
    setTotaListPrice(listPrice);

    if (isDiscountCodeChecked) {
      dispatchChange('ParcelForm', 'salePrice', total.toFixed(2));
      dispatchChange(
        'ParcelForm',
        'discountPrice',
        prices.get('finalPrice').toFixed(2),
      );
    }
    if (corporateCreditAmount) {
      dispatchChange('ParcelForm', 'discountPrice', finalPrice.toFixed(2));
    }
  };

  const showItemListPrice = () => {
    prices
      .get('pricesList')
      .forEach(({ index, price: { listPrice, salePrice } }) => {
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].listPrice`,
          listPrice.toFixed(2),
        );
        dispatchChange(
          'ParcelForm',
          `cargaItems[${index}].salePrice`,
          salePrice,
        );
      });

    calculateTotalListPrice();
    calculateTotalSalePrice();
  };

  useEffect(() => {
    if (!prices.isEmpty()) {
      showItemListPrice();
    }
  }, [prices]);

  const reinicializeItemPrices = (index) => {
    dispatchChange('ParcelForm', `cargaItems[${index}].listPrice`, '');
    dispatchChange('ParcelForm', `cargaItems[${index}].salePrice`, '');
    dispatchClearCargoPrices();
  };

  const onClickRemove = (index, fields) => {
    fields.remove(index);
    clearSalePrice();
  };

  const onChangeCategory = (index, payload) => {
    const actualCategory =
      typeof payload === 'object'
        ? payload
        : cargaItemsValues[index].cargoCategoryId;

    if (!actualCategory || !actualCategory.value) return;

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].cargoCategoryId`,
      actualCategory,
    );

    const actualCommonProduct = cargaItemsValues[index].commonProductId;

    if (!actualCommonProduct) {
      dispatchChange('ParcelForm', `cargaItems[${index}].width`, '');
      dispatchChange('ParcelForm', `cargaItems[${index}].height`, '');
      dispatchChange('ParcelForm', `cargaItems[${index}].length`, '');
    }

    const categorySelected = cargoCategories.find(
      (category) => category.id === actualCategory.value,
    );

    if (
      categorySelected.name.toLocaleLowerCase() ===
      PARCEL_CATEGORY_ENVELOPE.toLocaleLowerCase()
    ) {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, true);

      if (parcelPriceConfigurations) {
        const parcelPriceConfiguration = parcelPriceConfigurations.find(
          (config) =>
            config.configurationType ===
            PARCEL_PRICE_CONFIGURATION.MAX_ENVELOPE_WEIGHT.value,
        );

        if (parcelPriceConfiguration) {
          dispatchChange(
            'ParcelForm',
            `cargaItems[${index}].weight`,
            parcelPriceConfiguration.value,
          );
        }
      }
    } else {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, false);
    }
    reinicializeItemPrices(index);
  };

  const onQuantityInputChange = (index) => {
    reinicializeItemPrices(index);
  };

  const onChangeCommonProduct = (index, payload) => {
    const actualCommonProduct =
      typeof payload === 'object'
        ? payload
        : cargaItemsValues[index].commonProductId;

    if (!actualCommonProduct || !actualCommonProduct.value) return;

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].commonProductId`,
      actualCommonProduct,
    );

    dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, false);

    const {
      weight,
      width,
      height,
      length,
      cargoCategoryId,
      productDescription,
    } = commonProducts.find(
      (product) => product.id === actualCommonProduct.value,
    );

    dispatchChange('ParcelForm', `cargaItems[${index}].weight`, weight);
    dispatchChange('ParcelForm', `cargaItems[${index}].width`, width);
    dispatchChange('ParcelForm', `cargaItems[${index}].height`, height);
    dispatchChange('ParcelForm', `cargaItems[${index}].length`, length);
    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].description`,
      productDescription,
    );

    const categoryCommonProduct = cargoCategories.find(
      (category) => category.id === cargoCategoryId,
    );

    dispatchChange('ParcelForm', `cargaItems[${index}].cargoCategoryId`, {
      value: categoryCommonProduct.id,
      label: categoryCommonProduct.name,
    });

    if (
      categoryCommonProduct.name.toLocaleLowerCase() ===
      PARCEL_CATEGORY_ENVELOPE.toLocaleLowerCase()
    ) {
      dispatchChange('ParcelForm', `cargaItems[${index}].isEnvelope`, true);
    }

    dispatchChange(
      'ParcelForm',
      `cargaItems[${index}].isCommonProductSelected`,
      true,
    );

    const commonProductPrice = commonProductPrices.find(
      (commonProductPriceItem) =>
        commonProductPriceItem.id === actualCommonProduct.value,
    );

    if (commonProductPrice && commonProductPrice.price) {
      dispatchChange(
        'ParcelForm',
        `cargaItems[${index}].salePrice`,
        commonProductPrice.price,
      );
      if (
        cargaItemsValues[index].quantity &&
        isValidNumber(cargaItemsValues[index].quantity) &&
        cargaItemsValues[index].quantity > 0
      ) {
        const total =
          commonProductPrice.price * cargaItemsValues[index].quantity;
        dispatchChange('ParcelForm', `cargaItems[${index}].salePrice`, total);
      }
    }
  };

  const onCommonProductChange = (index, e) => {
    clearSalePrice();
    reinicializeItemPrices(index);
    onChangeCommonProduct(index, e);
  };

  const onWeightChange = (index) => {
    if (cargaItemsValues[index].commonProductId) return;
    reinicializeItemPrices(index);
  };

  const validateItemValues = (itemsValues) => {
    let isValid = true;
    if (itemsValues.length) {
      const isInvalidData = itemsValues.some((itemValue) => {
        const isEnvelop = !!itemValue.isEnvelope;

        if (isInvalidNumberValue(itemValue, 'quantity')) {
          toastr.error('Error', 'Ingrese una cantidad válida.');
          return true;
        }

        if (!itemValue.cargoCategoryId) {
          toastr.error('Error', 'Seleccione una categoría válida');
          return true;
        }

        if (!itemValue.description) {
          toastr.error('Error', 'Ingrese una descripción válida');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'weight')) {
          toastr.error('Error', 'Ingrese un peso válido.');
          return true;
        }

        if (isEnvelop) {
          return false;
        }

        if (isInvalidNumberValue(itemValue, 'length')) {
          toastr.error('Error', 'Ingrese un largo válido.');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'width')) {
          toastr.error('Error', 'Ingrese un ancho válido.');
          return true;
        }

        if (isInvalidNumberValue(itemValue, 'height')) {
          toastr.error('Error', 'Ingrese un alto válido.');
          return true;
        }

        return false;
      });
      isValid = !isInvalidData;
    } else {
      toastr.error('Error', 'Ingrese al menos un item al detalle de carga.');
      isValid = false;
    }
    return isValid;
  };

  const calculateItemListPrice = async () => {
    if (validateItemValues(cargaItemsValues)) {
      dispatchClearCargoPrices();
      const indexes = [];
      const cargoItemList = [];
      cargaItemsValues.forEach((item, index) => {
        const itemToList = {
          quantity: item.quantity,
          weight: item.weight,
          height: item.height,
          width: item.width,
          length: item.length,
          price: item.salePrice,
        };

        if (item.commonProductId) {
          itemToList.commonProductId = item.commonProductId.value;
        }

        if (item.cargoCategoryId) {
          itemToList.categoryId = item.cargoCategoryId.value;
        }

        indexes.push(index);
        cargoItemList.push(itemToList);
      });

      const request = {
        sourceLocationId,
        destinationLocationId,
        cargoItemList,
        parcelServiceTypeId,
        businessId: businessId > 0 ? businessId : null,
        discountCodeString: isDiscountCodeChecked ? discountCode : null,
      };

      const response = await dispatchPostCargoPrice(indexes, request);

      if (response === null) {
        clearSalePrice();
      }
    }
  };

  const copySenderToConsignee = () => {
    dispatchChange('ParcelForm', 'consigneeCustomer', senderCustomerValue);
  };
  const copySenderToClient = () => {
    dispatchChange('ParcelForm', 'customer', senderCustomerValue);
  };

  const handleLocationChange = (location) => {
    setDestinationLocationId(location.value || 0);
    clearSalePrice();
  };

  const handleBusinessChange = (business) => {
    setBusinessId(business.value || 0);
  };

  const handleParcelServiceTypeChange = (parcelServiceType) => {
    setParcelServiceTypeId(parcelServiceType.value || 0);
    clearSalePrice();
  };

  const renderDeliveryFields = () =>
    !showDeliveryFields ? null : (
      <>
        <FormGroup row>
          <FormItem label="Distritos Autorizados" required>
            <Field
              name="district"
              component={Select}
              options={[{ value: 1, label: 'Lima' }]}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Dirección" required>
            <Field
              name="address"
              component={TextInput}
              type="text"
              placeholder="Dirección"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

  const renderDynamicForm = () => {
    if (sourceLocationId === 0 || destinationLocationId === 0) {
      return <Alert message="Seleccione Origen y Destino" />;
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <DynamicForm
        name="cargaItems"
        columns={generateCargoItemColumns(
          onQuantityInputChange,
          onCommonProductChange,
          onWeightChange,
          onChangeCategory,
          onChangeCommonProduct,
        )}
        onClickRemove={onClickRemove}
      />
    );
  };

  const renderCalculatePriceButton = () => {
    if (
      sourceLocationId === 0 ||
      destinationLocationId === 0 ||
      cargaItemsValues.length === 0
    ) {
      return null;
    }
    return (
      <Button
        type="button"
        outline
        color="primary"
        onClick={() => calculateItemListPrice()}
      >
        Calcular precio
      </Button>
    );
  };

  const renderDetraction = () => {
    const detractionField = showDetraction ? (
      <>
        <FormGroup row>
          <FormItem label="Detracción">
            <Field
              name="detraction"
              component={TextInput}
              type="text"
              placeholder="Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Precio Total de Venta con Detracción">
            <Field
              name="salePriceWithDetraction"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta con Detracción"
              disabled
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
      </>
    ) : null;
    return detractionField;
  };

  const renderClient = () => {
    if (
      selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID
    ) {
      dispatchChange('ParcelForm', 'customer', '');
      return (
        <BusinessInputGroup
          label="Empresa"
          labelRequired
          name="business"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
          onChange={(option) => handleBusinessChange(option)}
        />
      );
    }
    dispatchChange('ParcelForm', 'business', '');
    return (
      <CustomerInputGroup
        label="Cliente"
        labelRequired
        name="customer"
        form="ParcelForm"
        validate={[isRequired]}
        showDetails
        disabled={isCopyClientChecked}
      />
    );
  };

  const handleClientSelectChange = (isChecked) => {
    if (isChecked) {
      copySenderToClient();
      setIsCopyClientChecked(true);
      return;
    }
    setIsCopyClientChecked(false);
    dispatchChange('ParcelForm', 'customer', '');
  };

  const handleDiscountCodeChange = (isChecked) => {
    clearSalePrice();
    if (isChecked) {
      setIsDiscountCodeChecked(true);
      return;
    }
    setIsDiscountCodeChecked(false);
    dispatchChange('ParcelForm', 'discountCode', '');
  };

  const renderCopySenderToButton = () => {
    if (!senderCustomerValue) {
      return null;
    }
    const copyButton = (action, className) =>
      action === copySenderToConsignee ? (
        <Button
          type="button"
          className={className}
          outline
          color="primary"
          onClick={action}
        >
          Copiar Consignado
        </Button>
      ) : (
        <FormItem>
          <Label>
            <Field
              name="client"
              id="client"
              component="input"
              type="checkbox"
              onChange={(e) => handleClientSelectChange(e.target.checked)}
            />{' '}
            Copiar Cliente
          </Label>
        </FormItem>
      );
    return selectedVoucherType &&
      selectedVoucherType.value === VOUCHER_TYPE_BUSINESS_INVOICE_ID ? (
      <FormGroup row>
        <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
      </FormGroup>
    ) : (
      <>
        <FormGroup row>
          <FormItem>{copyButton(copySenderToConsignee)}</FormItem>
        </FormGroup>
        <FormGroup row>{copyButton(copySenderToClient)}</FormGroup>
      </>
    );
  };

  const onHandleSubmit = (formValues) => {
    if (validateItemValues(cargaItemsValues)) {
      onSubmit(formValues, isDiscountCodeChecked, totaListPrice);
    }
  };

  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const renderSecondaryConsigneeFields = () =>
    !showSecondaryConsigneeField ? null : (
      <>
        <CustomerInputGroup
          label="Consignado Secundario"
          labelRequired
          name="secondaryConsigneeCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
      </>
    );

  const renderContingencyFields = () => {
    let isContingencySalesSession = false;

    if (!activeSalesSession.isEmpty()) {
      isContingencySalesSession =
        activeSalesSession.get('workstation').contingency;
    }
    const contingencyField = isContingencySalesSession ? (
      <>
        <h5>Datos de Contingencia</h5>
        <FormGroup row>
          <FormItem label="Serie de Contingencia">
            <Field
              name="contingencyDocumentSeries"
              component={TextInput}
              type="text"
              placeholder="Serie de Contingencia"
              validate={isContingencySalesSession ? [isRequired] : []}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Código de Contingencia">
            <Field
              name="contingencyDocumentCode"
              component={TextInput}
              type="text"
              placeholder="Código de Contingencia"
              validate={
                isContingencySalesSession ? [isRequired, validateNumber] : []
              }
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Motivo de Contingencia">
            <Field
              name="contingencyMotive"
              component={TextInput}
              type="text"
              placeholder="Motivo de Contingencia"
              validate={isContingencySalesSession ? [isRequired] : []}
            />
          </FormItem>
        </FormGroup>
      </>
    ) : null;
    return contingencyField;
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Tipo de Comprobante" required>
            <Field
              name="voucherType"
              component={VoucherTypeSelect}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Servicio" required>
            <Field
              name="parcelServiceType"
              component={ParcelServiceTypeSelect}
              validate={[isRequired]}
              onChange={(option) => handleParcelServiceTypeChange(option)}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Origen" required>
            <Field
              name="sourceLocation"
              component={CargoLocationSelect}
              isDisabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Destino" required>
            <Field
              name="destinationLocation"
              component={CargoLocationSelect}
              onChange={(option) => handleLocationChange(option)}
              validate={[isRequired]}
              forPickupCargo
            />
          </FormItem>
        </FormGroup>
        <CustomerInputGroup
          label="Remitente"
          labelRequired
          name="senderCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
        {renderCopySenderToButton()}
        <CustomerInputGroup
          label="Consignado"
          labelRequired
          name="consigneeCustomer"
          form="ParcelForm"
          validate={[isRequired]}
          showDetails
        />
        {renderClient()}
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="secondaryConsignee"
                id="secondaryConsignee"
                component="input"
                type="checkbox"
                onChange={(e) =>
                  SetShowSecondaryConsigneeField(e.target.checked)
                }
              />{' '}
              Agregar Consignado Secundario
            </Label>
          </FormItem>
        </FormGroup>
        {renderSecondaryConsigneeFields()}
        <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="delivery"
                id="delivery"
                component="input"
                type="checkbox"
                onChange={(e) => setShowDeliveryFields(e.target.checked)}
              />{' '}
              Entrega a Domicilio
            </Label>
          </FormItem>
        </FormGroup>
        {renderDeliveryFields()}
        <FormGroup row>
          <FormItem label="Clave Secreta" required>
            <Field
              className="password"
              name="secretCode"
              component={TextInput}
              type="text"
              placeholder="Clave Secreta"
              validate={[isRequired, validateNumber, validataMaxLength10]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Confirmar Clave Secreta" required>
            <Field
              className="password"
              name="confirmSecretCode"
              component={TextInput}
              type="text"
              placeholder="Confirmar Clave Secreta"
              validate={[
                isRequired,
                validateNumber,
                validataMaxLength10,
                validateSecretCode,
              ]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Documento Relacionado">
            <Field
              name="attachedDocument"
              component={TextInput}
              type="text"
              placeholder="Guia Remisión y/o Factura"
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Nota">
            <Field
              name="note"
              component={TextInput}
              type="textarea"
              placeholder="Nota"
            />
          </FormItem>
        </FormGroup>
        {renderContingencyFields()}
        <h5>Detalle de Carga</h5>
        {renderDynamicForm()}
        {cargaItemsValues.length > 0 && (
          <>
            <FormGroup row>
              <FormItem>
                <Label>
                  <Field
                    name="discount"
                    id="discount"
                    component="input"
                    type="checkbox"
                    onChange={(e) => handleDiscountCodeChange(e.target.checked)}
                  />{' '}
                  Aplicar Descuento
                </Label>
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Código de Descuento">
                <Field
                  name="discountCode"
                  component={TextInput}
                  type="text"
                  placeholder="Código de Descuento"
                  disabled={!isDiscountCodeChecked}
                  validate={isDiscountCodeChecked ? [isRequired] : []}
                />
              </FormItem>
            </FormGroup>
            <FormGroup row>
              <FormItem label="Servicio de Carga">
                <Field
                  name="servicePrice"
                  component={TextInput}
                  type="text"
                  placeholder="Precio por Servicio de Carga"
                  disabled
                  validate={[validateNumber]}
                />
              </FormItem>
            </FormGroup>
          </>
        )}

        <FormGroup row>
          <FormItem label="Precio Total de Venta">
            <Field
              name="salePrice"
              component={TextInput}
              type="text"
              placeholder="Precio Total de Venta"
              disabled
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        {isDiscountCodeChecked && (
          <>
            <FormGroup row>
              <FormItem label="Precio Total con Descuento">
                <Field
                  name="discountPrice"
                  component={TextInput}
                  type="text"
                  placeholder="Precio Total con Descuento"
                  disabled
                  validate={[isRequired, validateNumber]}
                />
              </FormItem>
            </FormGroup>
          </>
        )}

        {renderDetraction()}
        <FormFooter
          saveButtonDisabled={loading}
          saveButtonIcon={loading ? 'fa fa-spinner fa-spin' : 'fa fa-save'}
        >
          {renderCalculatePriceButton()}
        </FormFooter>
      </Form>
    </>
  );
};

ParcelForm.propTypes = {
  prices: PropTypes.instanceOf(Immutable.Map).isRequired,
  cargaItemsValues: PropTypes.instanceOf(Array),
  selectedVoucherType: PropTypes.instanceOf(Object),
  senderCustomerValue: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  commonProducts: PropTypes.instanceOf(Array).isRequired,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  gettingActiveSalesSession: PropTypes.bool.isRequired,
  dispatchPostCargoPrice: PropTypes.func.isRequired,
  dispatchClearCargoPrices: PropTypes.func.isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  dispatchGetDetractionAmountBySalePrice: PropTypes.func.isRequired,
  discountCode: PropTypes.string,
  dispatchPush: PropTypes.func.isRequired,
  cargoCategories: PropTypes.instanceOf(Immutable.Map).isRequired,
  parcelServiceTypes: PropTypes.instanceOf(Array).isRequired,
  customerValue: PropTypes.string,
  businessValue: PropTypes.string,
  dispatchGetCommonProductPrice: PropTypes.func.isRequired,
  commonProductPrices: PropTypes.instanceOf(Array).isRequired,
  dispatchGetParcelPriceConfigurations: PropTypes.func.isRequired,
  parcelPriceConfigurations: PropTypes.instanceOf(Array).isRequired,
};

ParcelForm.defaultProps = {
  cargaItemsValues: [],
  selectedVoucherType: null,
  senderCustomerValue: null,
  loading: false,
  discountCode: null,
  customerValue: null,
  businessValue: null,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
  loadingCustomer: !state.UserUnit.Customer.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  cargaItemsValues: selector(state, 'cargaItems'),
  salePriceValue: selector(state, 'salePrice'),
  selectedVoucherType: selector(state, 'voucherType'),
  senderCustomerValue: selector(state, 'senderCustomer'),
  consigneeCustomerValue: selector(state, 'consigneeCustomer'),
  customerValue: selector(state, 'customer'),
  businessValue: selector(state, 'business'),
  prices: state.CargoUnit.Cargo.getIn(['current', 'price']),
  gettingActiveSalesSession: state.SalesUnit.SalesSession.getIn([
    'active',
    'loading',
  ]),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
  commonProducts: state.CargoUnit.CommonProduct.getIn([
    'all',
    'content',
    'content',
  ]),
  discountCode: selector(state, 'discountCode'),
  cargoCategories: state.CargoUnit.CargoCategory.getIn([
    'all',
    'content',
    'content',
  ]),
  parcelServiceTypes: state.CargoUnit.ParcelServiceType.getIn([
    'all',
    'content',
    'content',
  ]),
  commonProductPrices: state.CargoUnit.CommonProduct.getIn([
    'current',
    'price',
  ]).toJS(),
  parcelPriceConfigurations: state.CargoUnit.ParcelPriceConfiguration.getIn([
    'all',
    'content',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchPostCargoPrice: postCargoPrice,
  dispatchClearCargoPrices: clearCargoPrices,
  dispatchGetActiveSalesSession: getActiveSalesSession,
  dispatchGetDetractionAmountBySalePrice: getDetractionAmountBySalePrice,
  dispatchPush: push,
  dispatchGetCommonProductPrice: getCommonProductPrice,
  dispatchGetParcelPriceConfigurations: getParcelPriceConfigurations,
};

const formComponent = reduxForm({
  form: 'ParcelForm',
  initialValues: {
    voucherType: DEFAULT_VOUCHER_TYPE_OPTION,
  },
})(ParcelForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
