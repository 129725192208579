import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BUS_PATH, BUS_UNIT_PATH, NEW_BUS_PATH } from '../../../config/paths';
import BusHome from './BusHome';
import RegisteredBusses from './registered-bus/RegisteredBusses';
import RegisteredBus from './registered-bus/RegisteredBus';
import NewRegisteredBus from './registered-bus/NewRegisteredBus';
import EditRegisteredBus from './registered-bus/EditRegisteredBus';

const BusUnit = () => (
  <Switch>
    <Route exact path={BUS_UNIT_PATH} component={BusHome} />
    <Route exact path={BUS_PATH} component={RegisteredBusses} />
    <Route exact path={NEW_BUS_PATH} component={NewRegisteredBus} />
    <Route exact path={`${BUS_PATH}/:id`} component={RegisteredBus} />
    <Route exact path={`${BUS_PATH}/:id/edit`} component={EditRegisteredBus} />
  </Switch>
);

export default BusUnit;
