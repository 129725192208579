import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { MAINTENANCE_TASK_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { MAINTENANCE_TASK_PATH } from '../../config/paths';
import {
  CLEAR_MAINTENANCE_TASK,
  CLEAR_MAINTENANCE_TASKS,
  FLAG_GETTING_MAINTENANCE_TASKS,
  FLAG_MAINTENANCE_TASK_ACTIVITY,
  GET_MAINTENANCE_TASK,
  GET_MAINTENANCE_TASKS,
} from '../types';

const flagGettingMaintenanceTasks = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_MAINTENANCE_TASKS,
    payload: flag,
  });

const getMaintenanceTasks = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingMaintenanceTasks(true));
    const query = tableFilters;
    const url = `${MAINTENANCE_TASK_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();
    dispatch({
      type: GET_MAINTENANCE_TASKS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingMaintenanceTasks(false));
  }
};

const clearMaintenanceTasks = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_TASKS,
  });

const flagMaintenanceTaskActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_MAINTENANCE_TASK_ACTIVITY,
    payload: flag,
  });

const getMaintenanceTask =
  async ({ maintenanceTaskId }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceTaskActivity(true));
      const url = `${MAINTENANCE_TASK_ENDPOINT}/${maintenanceTaskId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_MAINTENANCE_TASK,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceTaskActivity(false));
    }
  };

const clearMaintenanceTask = () => (dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_TASK,
  });

const postMaintenanceTask =
  async ({
    description,
    workingHours,
    companyAreaId,
    materialList,
    companyMaintenanceTaskId,
    active,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceTaskActivity(true));
      const payload = {
        description,
        workingHours,
        companyAreaId,
        materialList,
        companyMaintenanceTaskId,
        active,
      };
      const url = MAINTENANCE_TASK_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_TASK_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceTaskActivity(false));
    }
  };

const putMaintenanceTask =
  async (
    maintenanceTaskId,
    {
      description,
      workingHours,
      companyAreaId,
      companyMaintenanceTaskId,
      materialList,
      active,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagMaintenanceTaskActivity(true));
      const payload = {
        id: maintenanceTaskId,
        description,
        workingHours,
        companyMaintenanceTaskId,
        companyAreaId,
        materialList,
        active,
      };
      const url = `${MAINTENANCE_TASK_ENDPOINT}/${maintenanceTaskId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(MAINTENANCE_TASK_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagMaintenanceTaskActivity(false));
    }
  };

export {
  getMaintenanceTasks,
  getMaintenanceTask,
  postMaintenanceTask,
  putMaintenanceTask,
  clearMaintenanceTasks,
  clearMaintenanceTask,
};
