import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_INTERNAL_PARCELS,
  GET_INTERNAL_PARCELS,
  CLEAR_INTERNAL_PARCEL,
  FLAG_INTERNAL_PARCEL_ACTIVITY,
  GET_INTERNAL_PARCEL,
  CLEAR_INTERNAL_PARCELS,
} from '../types/cargo';
import {
  generatePutInternalParcelPickup,
  INTERNAL_PARCEL_BY_CORRELATION_ENDPOINT,
  INTERNAL_PARCEL_ENDPOINT,
  POST_PARCEL_COMMENT_ENDPOINT,
} from '../../config/endpoints';
import {
  DEFAULT_POST_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { INTERNAL_TRANSFER_PATH } from '../../config/paths';

const flagInternalParcelActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_INTERNAL_PARCEL_ACTIVITY,
    payload: flag,
  });

const flagGettingParcels = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_INTERNAL_PARCELS,
    payload: flag,
  });

const clearInternalParcels = () => (dispatch) =>
  dispatch({
    type: CLEAR_INTERNAL_PARCELS,
  });

const getInternalParcels = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingParcels(true));
    const query = tableFilters;
    const url = `${INTERNAL_PARCEL_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const parcels = await response.json();
    dispatch({
      type: GET_INTERNAL_PARCELS,
      payload: parcels,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingParcels(false));
  }
};

const postInternalParcel =
  async ({
    voucherTypeId,
    sourceCityId,
    sourceLocationId,
    destinationCityId,
    destinationLocationId,
    senderCustomerId,
    consigneeCustomerId,
    comments,
    internalCargoItemList,
    sapCode,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const payload = {
        voucherTypeId,
        sourceCityId,
        sourceLocationId,
        destinationCityId,
        destinationLocationId,
        senderCustomerId,
        consigneeCustomerId,
        comments,
        internalCargoItemList,
        sapCode,
      };
      const url = INTERNAL_PARCEL_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const parcel = await response.json();
      dispatch(push(`${INTERNAL_TRANSFER_PATH}/${parcel.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
  };

const getInternalParcel =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const url = `${INTERNAL_PARCEL_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const parcel = await response.json();
      dispatch({
        type: GET_INTERNAL_PARCEL,
        payload: parcel,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
  };

const getInternalParcelByCorrelation =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const query = tableFilters;
      const url = `${INTERNAL_PARCEL_BY_CORRELATION_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const parcel = await response.json();
      return parcel;
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
    return null;
  };

const clearInternalParcel = () => (dispatch) =>
  dispatch({
    type: CLEAR_INTERNAL_PARCEL,
  });

const fetchInternalParcel = async (internalParcelId, dispatch) => {
  const urlGet = `${INTERNAL_PARCEL_ENDPOINT}/${internalParcelId}`;
  const responseGet = await fetch(urlGet, DEFAULT_GET_CONFIG);
  await isErrorResponse(responseGet);
  const item = await responseGet.json();
  dispatch({
    type: GET_INTERNAL_PARCEL,
    payload: item,
  });
};

const postInternalParcelComment =
  ({ internalParcelId, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const payload = {
        internalParcelId,
        description,
      };
      const url = POST_PARCEL_COMMENT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await fetchInternalParcel(internalParcelId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
    return null;
  };

const putInternalParcelPickup =
  ({ internalParcelId }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const url = generatePutInternalParcelPickup(internalParcelId);
      const response = await fetch(url, { ...DEFAULT_PUT_CONFIG });
      await isErrorResponse(response, null, dispatch);
      await fetchInternalParcel(internalParcelId, dispatch);
      const parcel = await response.json();
      push(`${INTERNAL_TRANSFER_PATH}/${parcel.id}`);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
  };

export {
  postInternalParcel,
  getInternalParcels,
  clearInternalParcels,
  flagInternalParcelActivity,
  getInternalParcel,
  clearInternalParcel,
  postInternalParcelComment,
  getInternalParcelByCorrelation,
  putInternalParcelPickup,
};
