export const FLAG_GETTING_COMPANY_RESOURCES = 'FLAG_GETTING_COMPANY_RESOURCES';
export const GET_COMPANY_RESOURCES = 'GET_COMPANY_RESOURCES';
export const CLEAR_COMPANY_RESOURCES = 'CLEAR_COMPANY_RESOURCES';
export const FLAG_COMPANY_RESOURCE_ACTIVITY = 'FLAG_COMPANY_RESOURCE_ACTIVITY';
export const GET_COMPANY_RESOURCE = 'GET_COMPANY_RESOURCE';
export const CLEAR_COMPANY_RESOURCE = 'CLEAR_COMPANY_RESOURCE';
export const FLAG_GETTING_COMPANY_RESOURCE_TYPES =
  'FLAG_GETTING_COMPANY_RESOURCE_TYPES';
export const GET_COMPANY_RESOURCE_TYPES = 'GET_COMPANY_RESOURCE_TYPES';
export const CLEAR_COMPANY_RESOURCE_TYPES = 'CLEAR_COMPANY_RESOURCE_TYPES';
