import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import PrintStationSelect from '../../../common/forms/select/PrintStationSelect';
import FormFooter from '../../../common/forms/FormFooter';

const WorkStationForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Número de Serie" required>
        <Field
          name="serialNumber"
          component={TextInput}
          placeholder="Número de Serie"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Nombre" required>
        <Field
          name="name"
          component={TextInput}
          placeholder="Nombre"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Descripción">
        <Field
          name="description"
          component={TextInput}
          type="textarea"
          placeholder="Descripción"
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem>
        <Label>
          <Field name="contingency" component="input" type="checkbox" />{' '}
          Contingencia
        </Label>
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Impresoras">
        <Field name="printStationList" component={PrintStationSelect} isMulti />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

WorkStationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'AgencyForm',
})(WorkStationForm);

export default formComponent;
