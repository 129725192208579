import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MAINTENANCE_BUS_GROUP_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import MaintenanceBusGroupForm from './MaintenanceBusGroupForm';
import { postMaintenanceBusGroup } from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';

export class NewMaintenanceBusGroup extends Component {
  onSubmit = (formValues) => {
    const registeredBusList = [];
    formValues.registeredBus.split(',').forEach((value) => {
      const bus = { id: value };
      registeredBusList.push(bus);
    });
    const postMaintenanceBusGroupFormValues = {
      name: formValues.name,
      description: formValues.description,
      registeredBusList,
    };
    this.props.dispatchPostMaintenanceBusGroup(
      postMaintenanceBusGroupFormValues,
    );
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nueva Agrupación de Buses para Mantenimiento"
        subtitle="Crear nueva agrupación de buses para mantenimiento"
        content={<MaintenanceBusGroupForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupo de Buses para Mantenimiento',
      href: MAINTENANCE_BUS_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostMaintenanceBusGroup: postMaintenanceBusGroup,
};

NewMaintenanceBusGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenanceBusGroup: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMaintenanceBusGroup);
