import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import TextInput from '../../../common/forms/input/TextInput';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_SERVICE_ORDER_PRIORITY,
  MAINTENANCE_SERVICE_ORDER_STATUS,
} from '../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import {
  clearMaintenancePlannings,
  clearMaintenanceWarnings,
  getMaintenancePlanningActivities,
  getMaintenancePlanningsByCompanyResource,
  getMaintenancePlanningsByRegisteredBus,
  getMaintenanceWarnings,
  postMaterialsByActivity,
} from '../../../../actions';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  ACTIVITY_MATERIAL_FORM_COLUMNS,
  generateMaintenanceWarningColumns,
} from '../../../../config/dynamicFormFields';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import Alert from '../../../common/informative/Alert';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { maintenanceWarningGenerator } from '../../../../utils/app/json-generator-from-reducer';
import { maintenanceWarningBasicInformationPropTypes } from '../maintenance-warning/prop-types/MaintenanceWarningPropTypes';
import CompanyResourceSelect from '../../../common/forms/select/CompanyResourceSelect';

const hasEqualWarning = ({ value }, formValues) => {
  let duplicateCount = 0;
  formValues.preventiveMaintenanceWarningList.forEach((warning) => {
    if (
      warning.maintenanceWarningId &&
      warning.maintenanceWarningId.value === value
    )
      duplicateCount += 1;
  });
  formValues.correctiveMaintenanceWarningList.forEach((warning) => {
    if (
      warning.maintenanceWarningId &&
      warning.maintenanceWarningId.value === value
    )
      duplicateCount += 1;
  });

  return duplicateCount >= 2 ? 'Ocurrencia repetida' : undefined;
};

export const MaintenanceServiceOrderForm = ({
  handleSubmit,
  onSubmit,
  loading,
  dispatchChange,
  editionMode,
  stepMode,
  dispatchGetMaintenanceWarnings,
  dispatchClearMaintenanceWarnings,
  maintenanceWarnings,
  loadingMaintenanceWarnings,
  initialValues,
  maintenanceServiceOrderId,
  maintenancePlannings,
  dispatchClearMaintenancePlannings,
  dispatchGetMaintenancePlanningsByRegisteredBus,
  dispatchGetMaintenancePlanningActivities,
  dispatchPostMaterialsByActivity,
  loadingMaterials,
  dispatchGetMaintenancePlanningsByCompanyResource,
}) => {
  const [registeredBus, setRegisteredBus] = useState(
    initialValues && initialValues.registeredBusId,
  );

  const [companyResource, setCompanyResource] = useState(
    initialValues && initialValues.companyResourceId,
  );

  const [selectedPlans, setSelectedPlans] = useState([]);

  useEffect(() => {
    if (maintenanceWarnings.length > 0) {
      const preventiveMaintenanceWarningListValue = maintenanceWarnings.filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value,
      );

      dispatchChange(
        'MaintenanceServiceOrderForm',
        'preventiveMaintenanceWarningList',
        preventiveMaintenanceWarningListValue.map((warning) => ({
          maintenanceWarningId: maintenanceWarningGenerator(warning),
        })),
      );

      const correctiveMaintenanceWarningListValue = maintenanceWarnings.filter(
        (warning) =>
          warning.creationType === MAINTENANCE_ACTIVITY_TYPE.CORRECTIVE.value,
      );

      dispatchChange(
        'MaintenanceServiceOrderForm',
        'correctiveMaintenanceWarningList',
        correctiveMaintenanceWarningListValue.map((warning) => ({
          maintenanceWarningId: maintenanceWarningGenerator(warning),
        })),
      );
    }

    return () => dispatchClearMaintenanceWarnings();
  }, [maintenanceWarnings]);

  if (loading) return <Loader />;

  const onChangeRegisteredBus = (option) => {
    if (
      !registeredBus ||
      (registeredBus && registeredBus.value !== option.value)
    ) {
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'preventiveMaintenanceWarningList',
        null,
      );
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'correctiveMaintenanceWarningList',
        null,
      );
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'maintenancePlanning',
        null,
      );

      dispatchClearMaintenanceWarnings();
      dispatchGetMaintenanceWarnings({
        ...DEFAULT_QUERY_GET_ALL,
        query: [`registeredBusId:${option.value}`, `serviceOrderId:Ø`],
      });
      dispatchClearMaintenancePlannings();
      dispatchGetMaintenancePlanningsByRegisteredBus(
        { registeredBusId: option.value },
        {
          ...DEFAULT_QUERY_GET_ALL,
        },
      );
    }

    setRegisteredBus(option);
  };

  const onChangeCompanyResource = (option) => {
    if (
      !companyResource ||
      (companyResource && companyResource.value !== option.value)
    ) {
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'preventiveMaintenanceWarningList',
        null,
      );
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'correctiveMaintenanceWarningList',
        null,
      );
      dispatchChange(
        'MaintenanceServiceOrderForm',
        'maintenancePlanning',
        null,
      );

      dispatchClearMaintenanceWarnings();
      dispatchGetMaintenanceWarnings({
        ...DEFAULT_QUERY_GET_ALL,
        query: [`companyResourceId:${option.value}`, `serviceOrderId:Ø`],
      });
      dispatchClearMaintenancePlannings();
      dispatchGetMaintenancePlanningsByCompanyResource(
        { companyResourceId: option.value },
        {
          ...DEFAULT_QUERY_GET_ALL,
        },
      );
    }

    setCompanyResource(option);
  };

  const onChangeMaintenancePlan = async (selectedOptions) => {
    dispatchChange('MaintenanceServiceOrderForm', 'materialList', []);

    let selectedPlanOptions;

    if (
      selectedOptions instanceof Object &&
      !(selectedOptions instanceof Array)
    ) {
      selectedPlanOptions = Object.keys(selectedOptions)
        .filter((key) => !Number.isNaN(Number(key)))
        .map((key) => selectedOptions[key]);
    } else {
      selectedPlanOptions = selectedOptions;
    }

    if (!selectedPlanOptions || selectedPlanOptions.length === 0) {
      setSelectedPlans([]);
      return;
    }

    const plansData = await Promise.all(
      selectedPlanOptions.map(async (selectedPlan) => {
        const response = await dispatchGetMaintenancePlanningActivities({
          maintenancePlanningId: selectedPlan.value,
        });
        return {
          maintenancePlanningId: selectedPlan.value,
          planLabel: selectedPlan.label,
          activities: response.map((activity) => ({
            id: activity.id,
            name: activity.name,
            order: activity.activityOrder,
          })),
          selectedActivityOrder: null,
        };
      }),
    );

    setSelectedPlans(plansData);
  };

  const selectActivityForPlan = (maintenancePlanningId, activity) => {
    setSelectedPlans((prevPlans) =>
      prevPlans.map((plan) =>
        plan.maintenancePlanningId === maintenancePlanningId
          ? {
              ...plan,
              selectedActivityOrder: activity.order,
              activityId: activity.id,
            }
          : plan,
      ),
    );
  };

  const getSelectedActivityIds = () =>
    selectedPlans
      .filter((plan) => plan.activityId)
      .map((plan) => plan.activityId);

  const calculateMaterials = async () => {
    const selectedActivitiesIds = getSelectedActivityIds();

    if (selectedActivitiesIds.length === 0) {
      toastr.error('Error', 'No hay actividades Seleccionadas');
    } else {
      const materials = await dispatchPostMaterialsByActivity(
        selectedActivitiesIds,
      );

      if (materials && materials.length > 0) {
        const formattedMaterials = materials.map((item) => ({
          materialId: {
            value: item.material.id,
            label: `${item.material.externalId} - ${item.material.name} - ${item.material.measurementUnit}`,
          },
          quantity: item.quantity,
        }));

        dispatchChange(
          'MaintenanceServiceOrderForm',
          'materialList',
          formattedMaterials,
        );
      }
    }
  };

  const footer =
    stepMode && maintenanceServiceOrderId ? (
      <FormFooter
        saveButtonIcon="fa fa-arrow-circle-right"
        saveButtonText="Continuar"
      >
        <Link
          className="btn btn-secondary"
          type="button"
          to={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}`}
        >
          Cancelar
        </Link>
      </FormFooter>
    ) : (
      <FormFooter />
    );

  let preventiveMaintenanceWarningListComponent = (
    <Alert type="info" message="Seleccione un bus o recurso" />
  );
  let correctiveMaintenanceWarningListComponent = (
    <Alert type="info" message="Seleccione un bus o recurso" />
  );

  if (loadingMaintenanceWarnings) {
    preventiveMaintenanceWarningListComponent = <Loader />;
    correctiveMaintenanceWarningListComponent = <Loader />;
  } else if (registeredBus || companyResource) {
    const identifier = registeredBus
      ? registeredBus.value
      : companyResource.value;
    const warningType = registeredBus ? 'Preventiva' : 'Recurso';

    const searchByCompanyResource = !registeredBus;

    const preventiveDynamicFormProps = {
      name: 'preventiveMaintenanceWarningList',
      columns: generateMaintenanceWarningColumns(
        hasEqualWarning,
        identifier,
        warningType,
        searchByCompanyResource,
      ),
    };

    preventiveMaintenanceWarningListComponent = (
      <DynamicForm {...preventiveDynamicFormProps} />
    );

    const correctiveDynamicFormProps = {
      name: 'correctiveMaintenanceWarningList',
      columns: generateMaintenanceWarningColumns(
        hasEqualWarning,
        identifier,
        warningType,
      ),
    };

    correctiveMaintenanceWarningListComponent = (
      <DynamicForm {...correctiveDynamicFormProps} />
    );
  }

  let materialAssigmentListComponent = (
    <Alert type="info" message="Calcule los Materiales Necesarios" />
  );

  if (loadingMaterials) {
    materialAssigmentListComponent = <Loader />;
  } else if (selectedPlans.length !== 0) {
    materialAssigmentListComponent = (
      <DynamicForm
        name="materialList"
        columns={ACTIVITY_MATERIAL_FORM_COLUMNS}
      />
    );
  }

  const onHandleSubmit = (formValues) => {
    onSubmit(formValues, selectedPlans);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Bus">
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              onChange={onChangeRegisteredBus}
              isDisabled={editionMode}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Recurso de la Compañia">
            <Field
              name="companyResourceId"
              component={CompanyResourceSelect}
              onChange={onChangeCompanyResource}
              isDisabled={editionMode}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha Límite" required>
            <Field
              name="deadline"
              component={TextInput}
              type="datetime-local"
              placeholder={DATE_TIME_FORMAT}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Prioridad" required>
            <Field
              name="priority"
              component={Select}
              options={enumToSelectOptions(MAINTENANCE_SERVICE_ORDER_PRIORITY)}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Descripción">
            <Field
              name="description"
              component={TextInput}
              type="textarea"
              maxLength={0}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Estado" required>
            <Field
              name="status"
              component={Select}
              options={enumToSelectOptions(MAINTENANCE_SERVICE_ORDER_STATUS)}
              validate={[isRequired]}
              isDisabled={!editionMode}
            />
          </FormItem>
        </FormGroup>

        <h3>Reportes de Ocurrencia</h3>
        <Row>
          <Col md="6">{preventiveMaintenanceWarningListComponent}</Col>
          <Col md="6">{correctiveMaintenanceWarningListComponent}</Col>
        </Row>

        {!editionMode && (
          <>
            <h3>Plan de Mantenimiento</h3>
            <FormGroup row>
              <FormItem label="Plan">
                <Field
                  name="maintenancePlanning"
                  component={Select}
                  options={maintenancePlannings}
                  onChange={(selectedOptions) =>
                    onChangeMaintenancePlan(selectedOptions)
                  }
                  isMulti
                />
              </FormItem>
            </FormGroup>
            <Row>
              <Col md="6">
                {selectedPlans.map((plan) => (
                  <div key={plan.maintenancePlanningId}>
                    <h4>{plan.planLabel}</h4>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {plan.activities.map((activity) => (
                        <li
                          key={activity.id}
                          style={{
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color:
                                plan.selectedActivityOrder === activity.order
                                  ? 'green'
                                  : 'black',
                              marginRight: '15px',
                            }}
                          >
                            {activity.name} (Orden: {activity.order})
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              selectActivityForPlan(
                                plan.maintenancePlanningId,
                                activity,
                              )
                            }
                            className={
                              plan.selectedActivityOrder === activity.order
                                ? 'btn btn-success btn-sm'
                                : 'btn btn-primary btn-sm'
                            }
                          >
                            {plan.selectedActivityOrder === activity.order
                              ? 'Seleccionada'
                              : 'Seleccionar'}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Col>
              <Col md="6">
                <>
                  <button
                    type="button"
                    onClick={() => calculateMaterials()}
                    className="btn btn-primary"
                    disabled={selectedPlans.length === 0}
                  >
                    Calcular Materiales
                  </button>
                  <h3>Materiales asignados</h3>
                  {materialAssigmentListComponent}
                </>
              </Col>
            </Row>
          </>
        )}

        {footer}
      </Form>
    </>
  );
};

MaintenanceServiceOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool,
  stepMode: PropTypes.bool,
  dispatchGetMaintenanceWarnings: PropTypes.func.isRequired,
  dispatchClearMaintenanceWarnings: PropTypes.func.isRequired,
  maintenanceWarnings: PropTypes.arrayOf(
    PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
  ).isRequired,
  loadingMaintenanceWarnings: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    registeredBusId: optionPropTypes,
    preventiveMaintenanceWarningList: PropTypes.arrayOf(
      PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
    ),
    correctiveMaintenanceWarningList: PropTypes.arrayOf(
      PropTypes.shape(maintenanceWarningBasicInformationPropTypes),
    ),
    companyResourceId: optionPropTypes,
  }),
  maintenanceServiceOrderId: PropTypes.number,
  dispatchClearMaintenancePlannings: PropTypes.func.isRequired,
  maintenancePlannings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      activityOrder: PropTypes.number,
    }),
  ).isRequired,
  dispatchGetMaintenancePlanningActivities: PropTypes.func.isRequired,
  dispatchPostMaterialsByActivity: PropTypes.func.isRequired,
  dispatchGetMaintenancePlanningsByRegisteredBus: PropTypes.func.isRequired,
  loadingMaterials: PropTypes.bool.isRequired,
  dispatchGetMaintenancePlanningsByCompanyResource: PropTypes.func.isRequired,
};

MaintenanceServiceOrderForm.defaultProps = {
  initialValues: null,
  editionMode: false,
  stepMode: false,
  maintenanceServiceOrderId: null,
};

const mapStateToProps = (state) => ({
  loading: !state.MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  maintenanceWarnings: state.MechanicalMaintenanceUnit.MaintenanceWarning.getIn(
    ['all', 'content', 'content'],
  ),
  maintenancePlannings:
    state.MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
      'all',
      'content',
      'content',
    ]).map((planning) => ({
      value: planning.id,
      label: planning.description,
    })),
  loadingMaintenanceWarnings:
    state.MechanicalMaintenanceUnit.MaintenanceWarning.getIn([
      'all',
      'loading',
    ]),
  loadingMaterials: !state.MechanicalMaintenanceUnit.Activity.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetMaintenanceWarnings: getMaintenanceWarnings,
  dispatchClearMaintenanceWarnings: clearMaintenanceWarnings,
  dispatchClearMaintenancePlannings: clearMaintenancePlannings,
  dispatchGetMaintenancePlanningActivities: getMaintenancePlanningActivities,
  dispatchPostMaterialsByActivity: postMaterialsByActivity,
  dispatchGetMaintenancePlanningsByRegisteredBus:
    getMaintenancePlanningsByRegisteredBus,
  dispatchGetMaintenancePlanningsByCompanyResource:
    getMaintenancePlanningsByCompanyResource,
};

const formComponent = reduxForm({
  form: 'MaintenanceServiceOrderForm',
})(MaintenanceServiceOrderForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
