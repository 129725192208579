import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { MAINTENANCE_PLANNING_PATH } from '../../../../config/paths';
import {
  MAINTENANCE_PLANNING_STATUS_CREATED,
  MAINTENANCE_PLANNING_STATUS_STARTED,
} from '../../../../config/constants';
import EndMaintenancePlanningButton from './EndMaintenancePlanningButton';

const MaintenancePlanningToolbar = ({
  maintenancePlanningId,
  status,
  maintenancePlanning,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      {status === MAINTENANCE_PLANNING_STATUS_CREATED && (
        <EditButton
          path={`${MAINTENANCE_PLANNING_PATH}/${maintenancePlanningId}/edit`}
        />
      )}{' '}
      {status === MAINTENANCE_PLANNING_STATUS_STARTED && (
        <EndMaintenancePlanningButton
          maintenancePlanning={maintenancePlanning}
        />
      )}
    </div>
  </ButtonToolbar>
);

MaintenancePlanningToolbar.propTypes = {
  maintenancePlanningId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  maintenancePlanning: PropTypes.instanceOf(Object).isRequired,
};

export default MaintenancePlanningToolbar;
