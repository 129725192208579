import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { ButtonGroup, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaintenanceServiceOrderBasicInformation from './resource/MaintenanceServiceOrderBasicInformationResource';
import {
  getMaintenanceServiceOrder,
  clearMaintenanceServiceOrder,
} from '../../../../actions';
import Loader from '../../../common/Loader';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import MaintenanceServiceOrderMaintenanceWarningBadgeListInformation from './resource/MaintenanceServiceOrderMaintenanceWarningBadgeListInformation';
import Alert from '../../../common/informative/Alert';
import { SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE } from '../../../../config/messages';

const AssignExecutionToMaintenceServiceOrder = ({
  match: {
    params: { id: maintenanceServiceOrderId },
  },
  maintenanceServiceOrder,
  dispacthGetMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder,
  loading,
  breadcrumbs,
}) => {
  const [activityList, setActivityList] = useState([]);

  useLayoutEffect(() => {
    dispacthGetMaintenanceServiceOrder({ maintenanceServiceOrderId });
    return () => dispacthClearMaintenanceServiceOrder();
  }, []);

  const generateActivityList = (maintenancePlanningActivityExecutionList) =>
    maintenancePlanningActivityExecutionList.map((activityExecution) => ({
      id: activityExecution.id,
      name: activityExecution.maintenancePlanningActivityResource
        .maintenancePlanningActivityOrder.activity.name,
      startDate: activityExecution.startDate,
      endDate: activityExecution.endDate,
      workingHours: activityExecution.workingHours,
      status: activityExecution.status,
    }));

  useLayoutEffect(() => {
    if (activityList.length === 0 && !maintenanceServiceOrder.isEmpty()) {
      setActivityList(
        generateActivityList(
          maintenanceServiceOrder.toJS()
            .maintenancePlanningActivityExecutionList,
        ),
      );
    }
  }, [maintenanceServiceOrder]);

  let content;

  if (loading) {
    content = <Loader />;
  } else if (maintenanceServiceOrder.isEmpty()) {
    content = (
      <NoDataResource
        returnPage={`${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}`}
      />
    );
  } else {
    const {
      registeredBus,
      deadline,
      priority,
      description,
      status,
      maintenanceWarningList,
    } = maintenanceServiceOrder.toJS();

    const badgeListComponent = (
      <MaintenanceServiceOrderMaintenanceWarningBadgeListInformation
        maintenanceWarningList={maintenanceWarningList}
      />
    );

    const listComponent =
      activityList.length > 0 ? (
        <ListGroup>
          {activityList.map((activity) => {
            const to = `${MAINTENANCE_SERVICE_ORDER_PATH}/${maintenanceServiceOrderId}/execution/${activity.id}/edit-planning-activity-execution`;
            return (
              <ListGroupItem key={activity.id}>
                <div className="d-flex align-items-center justify-content-between">
                  <span>{activity.name}</span>
                  <ButtonGroup>
                    <Link className="btn btn-light border btn-lg" to={to}>
                      <i className="fa fa-pencil" />
                    </Link>
                  </ButtonGroup>
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      ) : (
        <Alert
          type="danger"
          message={SERVICE_ORDER_WITHOUT_ACTIVITY_DANGER_MESSAGE}
        />
      );

    content = (
      <>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Datos de la Orden de Servicio</h3>
            <MaintenanceServiceOrderBasicInformation
              deadline={deadline}
              priority={priority}
              description={description}
              status={status}
              registeredBus={registeredBus}
            />
          </Col>
          <Col lg={6}>{badgeListComponent}</Col>
        </Row>
        <h3>Lista de Actividades</h3>
        {listComponent}
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Ejecuciones"
      subtitle="Aquí puede editar los datos de las ejecuciones asociadas a esta Orden de Servicio."
      content={content}
    />
  );
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit, authentication },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_SERVICE_ORDER_PATH}/${id}`,
    },
    {
      text: 'Ejecuciones',
      href: '',
    },
  ],
  maintenanceServiceOrder:
    MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
      'current',
      'content',
    ]),
  loading: !MechanicalMaintenanceUnit.MaintenanceServiceOrder.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  authenticatedUser: authentication.getIn(['user']),
});

AssignExecutionToMaintenceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  maintenanceServiceOrder: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispacthGetMaintenanceServiceOrder: PropTypes.func.isRequired,
  dispacthClearMaintenanceServiceOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: matchPropTypes.isRequired,
};

const mapDispatchToProps = {
  dispacthGetMaintenanceServiceOrder: getMaintenanceServiceOrder,
  dispacthClearMaintenanceServiceOrder: clearMaintenanceServiceOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignExecutionToMaintenceServiceOrder);
