import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonToolbar } from 'reactstrap';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import {
  CARGO_ITEM_PATH,
  CARGO_PATH,
  INTERNAL_TRANSFER_PATH,
} from '../../../../config/paths';
import {
  clearCargoItemsDetail,
  getCargoItemDetail,
  getInternalCargoItemDetail,
  postCargoItemComment,
  postInternalCargoItemComment,
} from '../../../../actions';
import Table from '../../../common/Table';
import {
  CARGO_ITEM_MOVEMENT_COLUMNS,
  CARGO_ITEM_PARCEL_DETAIL_COLUMNS,
  generateCargoItemManifestColumns,
  generatePrintBillingColumns,
} from '../../../../config/columns';
import PrintUnitCargoTicketButton from './PrintUnitCargoTicketButton';
import ChangeCargoItemStatusDropdownButton from './ChangeCargoItemStatusDropdownButton';
import CargoItemDetailBasicInformationResource from './resources/CargoItemDetailBasicInformationResource';
import ChangeInternalCargoItemStatusDropdownButton from './ChangeInternalCargoItemStatusDropdownButton';
import NoDataResource from '../../../common/resource/NoDataResource';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import {
  CARGO_ITEM_WITHOUT_GUIDES,
  CARGO_ITEM_WITHOUT_MANIFEST,
} from '../../../../config/messages';
import Alert from '../../../common/informative/Alert';
import printCargoItemManifest from '../../../../utils/printers/ItineraryManifest';
import { getManifestCargoItemById } from '../../../../actions/cargo/ItineraryManifest';
import { getPdfManualSenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';
import CargoItemCommentForm from './CargoItemCommentForm';
import FoundCargoItemButton from './FoundCargoItemButton';

export const CargoItemDetail = (props) => {
  const {
    breadcrumbs,
    dispatchGetCargoItemDetail,
    dispatchGetInternalCargoItemDetail,
    dispatchClearCargoItemDetail,
    dispatchGetManifestItemById,
    loading,
    match: {
      params: { id: cargoItemId },
    },
    cargoItemDetail,
    location,
    dispatchGetPdfManualSenderRemissionGuide,
    dispatchPostCargoItemComment,
    dispatchPostInternalCargoItemComment,
  } = props;

  const printManifest = (id) => {
    dispatchGetManifestItemById(id).then((response) => {
      printCargoItemManifest(response);
    });
  };

  const printRemissionGuide = (ticket) => {
    dispatchGetPdfManualSenderRemissionGuide(ticket);
  };
  const urlContainsInternal = location.pathname.includes('internal');

  const postComment = (formValues) => {
    if (!urlContainsInternal) {
      const newFormValues = {
        cargoItemId,
        description: formValues.cargoItemComment,
      };
      dispatchPostCargoItemComment(newFormValues);
    } else {
      const newFormValues = {
        internalCargoItemId: cargoItemId,
        description: formValues.cargoItemComment,
      };
      dispatchPostInternalCargoItemComment(newFormValues);
    }
  };

  useEffect(() => {
    if (!urlContainsInternal) {
      dispatchGetCargoItemDetail({ cargoItemId });
    } else {
      dispatchGetInternalCargoItemDetail({ cargoItemId });
    }

    return () => {
      dispatchClearCargoItemDetail();
    };
  }, [cargoItemId, location.pathname]);

  let content = null;

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        {cargoItemDetail.get('cargoItemId') && (
          <FoundCargoItemButton
            cargoItemDetail={cargoItemDetail}
            internal={urlContainsInternal}
          />
        )}{' '}
        {!urlContainsInternal && (
          <ChangeCargoItemStatusDropdownButton
            cargoItemId={cargoItemDetail.get('cargoItemId')}
          />
        )}
        {urlContainsInternal && (
          <ChangeInternalCargoItemStatusDropdownButton
            cargoItemId={cargoItemDetail.get('cargoItemId')}
          />
        )}
        {'  '}
        {cargoItemDetail.get('cargoItemId') && (
          <PrintUnitCargoTicketButton cargoItemDetail={cargoItemDetail} />
        )}
      </div>
    </ButtonToolbar>
  );

  const generateParcelItem = (cargoItemDetailParam) => [
    {
      id: cargoItemDetailParam.parcelId,
      parcelCorrelation: cargoItemDetailParam.parcelCorrelation,
      createUserFullName: cargoItemDetailParam.createUserFullName,
      createUserEmail: cargoItemDetailParam.createUserEmail,
      parcelCreateDate: cargoItemDetailParam.parcelCreateDate,
      parcelPaymentStatus: cargoItemDetailParam.parcelPaymentStatus,
    },
  ];

  if (loading) {
    content = <Loader />;
  } else if (cargoItemDetail.isEmpty()) {
    if (!urlContainsInternal) {
      content = <NoDataResource returnPage={CARGO_PATH} />;
    } else {
      content = <NoDataResource returnPage={INTERNAL_TRANSFER_PATH} />;
    }
  } else {
    const cargoItemDetailJson = cargoItemDetail.toJSON();

    cargoItemDetailJson.isInternalParcel = urlContainsInternal;

    const tableMovements = (
      <Table
        columns={CARGO_ITEM_MOVEMENT_COLUMNS}
        data={cargoItemDetailJson.movementList}
        showPagination={false}
        defaultPageSize={cargoItemDetailJson.movementList.length}
      />
    );

    const tableManifests =
      cargoItemDetailJson.manifestCargoItemList !== null &&
      cargoItemDetailJson.manifestCargoItemList.length !== 0 ? (
        <Table
          columns={generateCargoItemManifestColumns(printManifest)}
          data={cargoItemDetailJson.manifestCargoItemList}
          showPagination={cargoItemDetailJson.manifestCargoItemList.length > 10}
          defaultPageSize={
            cargoItemDetailJson.manifestCargoItemList.length > 10
              ? DEFAULT_PAGE_SIZE
              : cargoItemDetailJson.manifestCargoItemList.length
          }
        />
      ) : (
        <Alert message={CARGO_ITEM_WITHOUT_MANIFEST} type="warning" />
      );

    const tableParcelDetail = (
      <Table
        columns={CARGO_ITEM_PARCEL_DETAIL_COLUMNS}
        data={generateParcelItem(cargoItemDetailJson)}
        modelPath={CARGO_PATH}
        showPagination={false}
        navigateToModelOnRowClick
        defaultPageSize={generateParcelItem(cargoItemDetailJson).length}
      />
    );

    const tableRemissionGuides =
      cargoItemDetailJson.remissionGuideList.length !== 0 ? (
        <Table
          columns={generatePrintBillingColumns(printRemissionGuide)}
          data={cargoItemDetailJson.remissionGuideList}
          showPagination={cargoItemDetailJson.remissionGuideList.length > 10}
          defaultPageSize={
            cargoItemDetailJson.remissionGuideList.length > 10
              ? DEFAULT_PAGE_SIZE
              : cargoItemDetailJson.remissionGuideList.length
          }
        />
      ) : (
        <Alert message={CARGO_ITEM_WITHOUT_GUIDES} type="warning" />
      );
    content = (
      <>
        <CargoItemDetailBasicInformationResource {...cargoItemDetailJson} />
        {!urlContainsInternal && <h3>Detalle de Boleta de Carga</h3>}
        {!urlContainsInternal && tableParcelDetail}
        <h3>Detalle de Escaneos</h3>
        {tableMovements}
        <h3>Detalle de Manifiestos</h3>
        {tableManifests}
        <h3>Guías de Remisión</h3>
        {tableRemissionGuides}
        <h3>Comentarios</h3>{' '}
        <CargoItemCommentForm
          comments={cargoItemDetailJson.cargoItemCommentlist}
          onSubmit={postComment}
        />
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Encomienda"
      content={content}
      toolbar={toolbar}
    />
  );
};

CargoItemDetail.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCargoItemDetail: PropTypes.func.isRequired,
  dispatchGetCargoItemDetail: PropTypes.func.isRequired,
  dispatchGetInternalCargoItemDetail: PropTypes.func.isRequired,
  dispatchGetManifestItemById: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoItemDetail: PropTypes.instanceOf(Immutable.Map).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  dispatchGetPdfManualSenderRemissionGuide: PropTypes.func.isRequired,
  dispatchPostCargoItemComment: PropTypes.func.isRequired,
  dispatchPostInternalCargoItemComment: PropTypes.func.isRequired,
};

CargoItemDetail.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }, ownProps) => {
  const urlContainsInternal = ownProps.location.pathname.includes('internal');
  const breadcrumbs = urlContainsInternal
    ? [
        ...CargoUnit.UnitHome.get('breadcrumbs'),
        {
          text: 'Translados Internos',
          href: INTERNAL_TRANSFER_PATH,
        },
        {
          text: 'Encomienda',
          href: '',
        },
      ]
    : [
        ...CargoUnit.UnitHome.get('breadcrumbs'),
        {
          text: 'Encomiendas',
          href: CARGO_ITEM_PATH,
        },
        {
          text: 'Encomienda',
          href: '',
        },
      ];

  return {
    breadcrumbs,
    cargoItemDetail: CargoUnit.CargoItem.getIn(['current', 'detail']),
    loading: !CargoUnit.CargoItem.getIn(['current', 'activity']).isEmpty(),
  };
};

const mapDispatchToProps = {
  dispatchGetCargoItemDetail: getCargoItemDetail,
  dispatchGetInternalCargoItemDetail: getInternalCargoItemDetail,
  dispatchClearCargoItemDetail: clearCargoItemsDetail,
  dispatchGetManifestItemById: getManifestCargoItemById,
  dispatchGetPdfManualSenderRemissionGuide: getPdfManualSenderRemissionGuide,
  dispatchPostCargoItemComment: postCargoItemComment,
  dispatchPostInternalCargoItemComment: postInternalCargoItemComment,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CargoItemDetail),
);
