import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import InternalResource from '../../../../common/resource/InternalResource';
import {
  MaintenanceBusGroupBasicInformationPropTypes,
  maintenancePlanningDefaultProps,
} from './MaintenanceBusGroupBasicInformationPropTypes';

const MaintenanceBusGroupBasicInformationResource = ({
  id,
  name,
  description,
  registeredBusList,
  createDate,
  lastUpdate,
}) => (
  <>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">{description}</ResourceProperty>
    <h3>Buses</h3>
    {registeredBusList.map((registeredBus) => (
      <ResourceProperty
        key={registeredBus.id}
        label={`${registeredBus.companyBusId} :`}
      >
        {registeredBus.licensePlate}
      </ResourceProperty>
    ))}
    <InternalResource id={id} createDate={createDate} lastUpdate={lastUpdate} />
  </>
);

MaintenanceBusGroupBasicInformationResource.propTypes =
  MaintenanceBusGroupBasicInformationPropTypes;

MaintenanceBusGroupBasicInformationResource.defaultProps =
  maintenancePlanningDefaultProps;

export default MaintenanceBusGroupBasicInformationResource;
