import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { BUS_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import RegisteredBusForm from './RegisteredBusForm';
import {
  clearRegisteredBus,
  getRegisteredBus,
  putRegisteredBus,
} from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';

const EditRegisteredBus = ({
  breadcrumbs,
  dispatchGetRegisteredBus,
  dispatchClearRegisteredBus,
  dispatchPutRegisteredBus,
  match: {
    params: { id: registeredBusId },
  },
  loading,
  registeredBus,
}) => {
  useLayoutEffect(() => {
    dispatchGetRegisteredBus({ registeredBusId });

    return () => dispatchClearRegisteredBus();
  }, []);

  const onSubmit = (formValues) => {
    const { homeBaseLocation, busChassis, busBodywork, seatMap, documentList } =
      formValues;

    const updatedFormValues = {
      ...formValues,
      busBodyworkId: busBodywork.value,
      busChassisId: busChassis.value,
      homeBaseLocationId: homeBaseLocation.value,
      seatMapId: seatMap.value,
    };

    if (documentList) {
      updatedFormValues.documentSet = formValues.documentList.map(
        (document) => ({
          documentTypeId: document.documentType.value,
          effectiveDate: tzNormalizeDate({
            date: document.effectiveDate,
            format: TIMESTAMP_FORMAT,
          }),
          serialNumber: document.serialNumber,
          expirationDate: tzNormalizeDate({
            date: document.expirationDate,
            format: TIMESTAMP_FORMAT,
          }),
        }),
      );
    }

    const mergedValues = {
      ...registeredBus.toJS(),
      ...updatedFormValues,
    };
    dispatchPutRegisteredBus(registeredBusId, mergedValues);
  };

  const generateInitialValues = ({
    companyBusId,
    licensePlate,
    homeBaseLocation,
    busChassis,
    chassisSerialNumber,
    busBodywork,
    bodyworkSerialNumber,
    seatMap,
    year,
    documentSet,
    active,
  }) => ({
    companyBusId,
    licensePlate,
    homeBaseLocation: {
      value: homeBaseLocation.id,
      label: homeBaseLocation.name,
    },
    busChassis: {
      value: busChassis.id,
      label: `${busChassis.make} | ${busChassis.model} | ${busChassis.year}`,
    },
    chassisSerialNumber,
    busBodywork: {
      value: busBodywork.id,
      label: `${busBodywork.make} | ${busBodywork.model} | ${busBodywork.year}`,
    },
    bodyworkSerialNumber,
    seatMap: {
      value: seatMap.id,
      label: seatMap.name,
    },
    year: year.toString(),
    documentList: documentSet.map((document) => ({
      ...document,
      documentType: {
        value: document.documentType.id,
        label: document.documentType.name,
      },
    })),
    active,
  });

  let content;

  if (loading) content = <Loader />;
  else if (registeredBus.isEmpty())
    content = <NoDataResource returnPage={BUS_PATH} />;
  else
    content = (
      <RegisteredBusForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(registeredBus.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Bus"
      subtitle="Editar bus"
      content={content}
    />
  );
};

EditRegisteredBus.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearRegisteredBus: PropTypes.func.isRequired,
  dispatchGetRegisteredBus: PropTypes.func.isRequired,
  dispatchPutRegisteredBus: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  registeredBus: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditRegisteredBus.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearRegisteredBus: clearRegisteredBus,
  dispatchGetRegisteredBus: getRegisteredBus,
  dispatchPutRegisteredBus: putRegisteredBus,
};

const mapStateToProps = (
  { BusUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...BusUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Buses',
      href: BUS_PATH,
    },
    {
      text: 'Ver',
      href: `${BUS_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  registeredBus: BusUnit.RegisteredBus.getIn(['current', 'content']),
  loading: !BusUnit.RegisteredBus.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRegisteredBus);
