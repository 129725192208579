import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  MAINTENANCE_BUS_GROUP_PATH,
  NEW_MAINTENANCE_BUS_GROUP_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MAINTENANCE_BUS_GROUP_COLUMNS } from '../../../../config/columns';
import {
  clearMaintenanceBusGroups,
  getMaintenanceBusGroups,
} from '../../../../actions/mechanical-maintenance/MaintenanceBusGroup';

export const MaintenanceBusGroups = ({
  breadcrumbs,
  maintenanceBusGroups,
  loading,
  dispatchGetMaintenanceBusGroups,
  dispatchClearMaintenanceBusGroups,
}) => {
  useLayoutEffect(() => () => dispatchClearMaintenanceBusGroups(), []);

  const data = maintenanceBusGroups.get('content') || [];
  const pages = maintenanceBusGroups.get('totalPages') || null;
  const defaultPageSize = maintenanceBusGroups.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Grupo de Buses para Mantenimiento"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_MAINTENANCE_BUS_GROUP_PATH}
      tableStructure={{
        columns: MAINTENANCE_BUS_GROUP_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetMaintenanceBusGroups,
        modelPath: MAINTENANCE_BUS_GROUP_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetMaintenanceBusGroups: getMaintenanceBusGroups,
  dispatchClearMaintenanceBusGroups: clearMaintenanceBusGroups,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupo de Buses para Mantenimiento',
      href: MAINTENANCE_BUS_GROUP_PATH,
    },
  ],
  maintenanceBusGroups: MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.MaintenanceBusGroup.getIn([
    'all',
    'loading',
  ]),
});

MaintenanceBusGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  maintenanceBusGroups: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetMaintenanceBusGroups: PropTypes.func.isRequired,
  dispatchClearMaintenanceBusGroups: PropTypes.func.isRequired,
};

MaintenanceBusGroups.defaultProps = {
  maintenanceBusGroups: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaintenanceBusGroups);
