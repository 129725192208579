import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  COMPANY_RESOURCE_PATH,
  NEW_COMPANY_RESOURCE_PATH,
} from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { COMPANY_RESOURCES_COLUMNS } from '../../../../config/columns';
import {
  clearCompanyResources,
  getCompanyResources,
} from '../../../../actions/mechanical-maintenance/CompanyResource';

export const CompanyResources = ({
  breadcrumbs,
  companyResources,
  loading,
  dispatchGetCompanyResources,
  dispatchClearCompanyResources,
}) => {
  useLayoutEffect(() => () => dispatchClearCompanyResources(), []);

  const data = companyResources.get('content') || [];
  const pages = companyResources.get('totalPages') || null;
  const defaultPageSize = companyResources.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Recursos de la Compañia"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_COMPANY_RESOURCE_PATH}
      tableStructure={{
        columns: COMPANY_RESOURCES_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCompanyResources,
        modelPath: COMPANY_RESOURCE_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCompanyResources: getCompanyResources,
  dispatchClearCompanyResources: clearCompanyResources,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Recursos de la Compañia',
      href: COMPANY_RESOURCE_PATH,
    },
  ],
  companyResources: MechanicalMaintenanceUnit.CompanyResource.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.CompanyResource.getIn(['all', 'loading']),
});

CompanyResources.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  companyResources: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCompanyResources: PropTypes.func.isRequired,
  dispatchClearCompanyResources: PropTypes.func.isRequired,
};

CompanyResources.defaultProps = {
  companyResources: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyResources);
